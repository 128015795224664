import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ClientService } from '../client.service';
import { Client } from "../client";
import { SpinnerService } from '../../core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  q: string;
  paramPassQ: string;
  d = '1';
  filter: string;
  skip: any;
  take: any;
  clientsCount: any;
  clients: any;
  deleteClientId:any = 0;
  deleteClientConfirmPopup:any = false;

  constructor(private aroute: ActivatedRoute,
    private router: Router,
    private client: ClientService,
    private spinnerService: SpinnerService,
    private dataService: DataService) { }

  ngOnInit() {
    this.dataService.currentMenuClient.subscribe(d => setTimeout(() => this.d = d, 0));
    // Initial Load
    this.aroute.queryParams.subscribe(params =>
      {
      this.q = params['q'];
      this.paramPassQ = params['q']

      if( typeof params['q'] != undefined && params['q'] != null )
      {
        this.q = this.q.replace(/!!!/g,'&');
      }

      this.d = params['d'];
      this.filter = params['filter'];
      this.skip = 0;
      this.take = 12;
      this.refresh();
    });
    if(typeof this.d != "undefined"){
      this.dataService.changeMenuClient(this.d);
    }else{
      this.dataService.changeMenuClient('1');
    }
    
  }

  gotoClientView(event,clientId)
  {
    event.preventDefault();
    this.router.navigateByUrl('/clients/list/view/' + clientId);
  }

  refresh() {
    this.client.getClients(this.paramPassQ, this.filter, this.skip, this.take, this.d).subscribe(res => {
      this.clientsCount = res[0];
      this.clients = res[1];
      this.spinnerService.hide();
    });
  }

  deleteClient(clientId:any)
  {
    this.deleteClientId = clientId;
    this.deleteClientConfirmPopup = true;
  }

  deleteClientConfirm()
  {
    
    this.client.deleteClient(this.deleteClientId).subscribe(res => {
      this.deleteClientConfirmPopup = false;
      this.refresh();
    });
  }

  closeClientConfirm()
  {
    this.deleteClientConfirmPopup = false;
  }

  loadData(event) {
    this.skip = event.first;
    this.take = event.rows;
    this.refresh();
  }
  
}
