import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ViewComponent } from './view/view.component';
import { InvoiceComponent } from "./invoice/invoice.component";
import { RemitComponent } from "./remit/remit.component";
import { ListComponent } from "./list/list.component";
import { SendEmailComponent } from "./send-email/send-email.component";
import { CreateComponent } from "./create/create.component";
import { PolicyComponent } from "./policy/policy.component";
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';
import { ShipInfoComponent } from './ship-info/ship-info.component';
import { ViewClientComponent } from './view-client/view-client.component';
import { BrokerviewComponent } from "./brokersview/brokersview.component";
import { BrokersComponent } from "./brokers/brokers.component";
import { AgencyComponent } from "./agency/agency.component";
import { ReportsComponent } from "../reports/reports.component";
import { AgencyviewComponent } from "./agencyview/agencyview.component";
import { CarrierComponent } from './carrier/carrier.component';
import { CarrierviewComponent } from "./carrierview/carrierview.component";
import { PayrollComponent } from "./payroll/payroll.component";
import { PayrollviewComponent } from "./payrollview/payrollview.component";
import { IncompleteComponent } from "./incomplete/incomplete.component";
import { UploadInvoiceComponent } from "./upload-invoice/upload-invoice.component";
import { LandComponent } from "./land/land.component";

const routes: Routes = [
  {path: 'brokers/view/:id', component: BrokerviewComponent},
  {path: 'brokers', component: BrokersComponent},
  {path: 'land', component: LandComponent},
  {path: 'clients', component: DashboardComponent},
  {path: 'agency', component: AgencyComponent},
  {path: 'reports', component: ReportsComponent},
  {path: 'carriers', component: CarrierComponent},
  {path: 'agency/view/:id', component: AgencyviewComponent},
  {path: 'carriers/view/:id', component: CarrierviewComponent},
  {path: 'payroll-platform/view/:id', component: PayrollviewComponent},
  {path: 'payroll-platform', component: PayrollComponent},
  {path: 'incomplete-invoices', component: IncompleteComponent},
  {path: 'clients/list', component: ListComponent},
  {path: 'clients/send-email', component: SendEmailComponent},
  {path: 'clients/create/:id', component: CreateComponent},
  {path: 'clients/creates/:id', component: CreateComponent},
  {path: 'clients/list/view/:id', component: ViewComponent},
  {path: 'clients/list/policy/:id', component: PolicyComponent},
  {path: 'clients/list/shipinfo/:id', component: ShipInfoComponent},
  {path: 'clients/list/:id/invoice', component: InvoiceComponent},
  {path: 'clients/list/:id/invoice/create', component: InvoiceEditComponent},
  {path: 'clients/list/:id/invoice/revise/:invoice_id', component: InvoiceEditComponent},
  {path: 'clients/list/invoice/revise/:invoice', component: InvoiceEditComponent},
  {path: 'clients/list/remit/:id', component: RemitComponent},
  {path: 'clients/list/upload/:id', component: UploadInvoiceComponent},
  {path: 'clients/list/view-client/:id', component: ViewClientComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
