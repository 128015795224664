import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentPrinter } from '../document-printer';
import { Message } from 'primeng/api';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';

@Component({
  selector: 'app-printer-edit',
  templateUrl: './printer-edit.component.html',
  styleUrls: ['./printer-edit.component.css']
})
export class PrinterEditComponent implements OnInit {
  id: number;
  printer: DocumentPrinter;
  msgs: Message[] = [];
  public items: MenuItem[];

  constructor(aroute: ActivatedRoute, 
    private router: Router, 
    private docService: DocumentService,
    private spinnerService: SpinnerService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    // Load queue (if editing)
    if (this.id) {
      this.loadPrinter();
    } else {
      this.printer = new DocumentPrinter();
    }
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: [ '/settings' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Printer',
        icon: 'fa-print',
        routerLink: [ '/settings/printers/create' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

  loadPrinter() {
    this.docService.getPrinter(this.id).subscribe((res) => {
      this.printer = res;
      this.spinnerService.hide();
    });
  }

  savePrinter() {
    if (!this.id) {
      this.docService.createPrinter(this.printer).subscribe((res) => {
        // Display message for 2 seconds
        if(res.output == '1'){
          this.msgs.push({severity: 'success', summary: 'Printer Created', detail: res.message});
        }else{
          this.msgs.push({severity: 'error', summary: 'Message', detail: res.message});
          this.spinnerService.hide();
        }
        setTimeout(() => {
          this.msgs = [];
          if(res.output == '1'){
            this.router.navigate(['settings/printers']);
          }
        }, 2000);
      });
    } else {
      this.docService.savePrinter(this.id, this.printer).subscribe(res => {
        // Display message for 2 seconds
        if(res.output == '1'){
          this.msgs.push({severity: 'success', summary: 'Printer Updated', detail: res.message});
        }else{
          this.msgs.push({severity: 'error', summary: 'Message', detail: res.message});
          this.spinnerService.hide();
        }
        setTimeout(() => {
          this.msgs = [];
          if(res.output == '1'){
            this.router.navigate(['settings/printers']);
          }
        }, 2000);
      });
    }
  }

}
