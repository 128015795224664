import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  items: MenuItem[];
  isLogged = '0';
  LoginUserName: any;
  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.currentUserName.subscribe(LoginUserName => setTimeout(() => this.LoginUserName = LoginUserName, 0));
    //console.log(this.LoginUserName);
    if( this.LoginUserName == undefined)
    {
      this.LoginUserName = localStorage.getItem('userName');
      this.dataService.changeUserName(this.LoginUserName);
    }
    // console.log(localStorage.getItem('userName'));
    // console.log(this.LoginUserName+'111');
    // else
    // {
    //   this.dataService.currentUserName.subscribe(LoginUserName => setTimeout(() => this.LoginUserName = LoginUserName, 0));
    // }


    this.dataService.currentMessage.subscribe(isLogged => setTimeout(() => this.isLogged = isLogged, 0));
    this.dataService.currentRole.subscribe(items => setTimeout(() => this.items = items, 0));
    
    if (localStorage.getItem('logUser')) {
    
      this.isLogged = '1';
      this.dataService.changeMessage(this.isLogged);
      // Role DropDown
      this.items = [
        { label: 'Settings', icon: 'fa-gear', routerLink: '/settings' },
        { separator: true },
        { label: 'Logout', icon: 'fa-sign-out', routerLink: '/logout' }
      ];
      this.dataService.changeRole(this.items);
    }
  }
}
