import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MenubarComponent } from './menubar/menubar.component';
import { MenubarModule } from 'primeng/menubar';
import { PrintersComponent } from './printers/printers.component';
import { InvoiceDescriptionsComponent } from './invoice-descriptions/invoice-descriptions.component';
import { InvoiceDescriptionsEditComponent } from './invoice-descriptions-edit/invoice-descriptions-edit.component';
import { TemplatesComponent } from './templates/templates.component';
import { EmailComponent } from './email/email.component';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { QueuesComponent } from './queues/queues.component';
import { MessagesModule } from 'primeng/messages';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DocumentService } from './document.service';
import { QueueEditComponent } from './queue-edit/queue-edit.component';
import { PrinterEditComponent } from './printer-edit/printer-edit.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { UploadInvoicesComponent } from './upload-invoices/upload-invoices.component';
import { CarriersMasterComponent } from './carriers-master/carriers-master.component';
import { ConfirmDialogModule, DropdownModule, InputTextareaModule, InputTextModule, RadioButtonModule } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';


import { SafePipe }  from '../safe.pipe';

@NgModule({
  imports: [
    CommonModule,
   SettingsRoutingModule,
    MenubarModule,
    MessagesModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    InputTextareaModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    EditorModule,
    MultiSelectModule,
  ],
  providers: [AuthService, DocumentService, ConfirmationService],
  declarations: [SafePipe,DashboardComponent, MenubarComponent, PrintersComponent, InvoiceDescriptionsComponent, InvoiceDescriptionsEditComponent, TemplatesComponent, UploadInvoicesComponent,CarriersMasterComponent, EmailComponent, QueuesComponent, QueueEditComponent, PrinterEditComponent, TemplateEditComponent]
})
export class SettingsModule {
  constructor(auth: AuthService, router: Router) {

    // Check login only if we are trying to use this module
    router.events
      .subscribe((event: NavigationEnd) => {
        if(event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/settings')) {

          // Check Login
          auth.checkLogin();

          // Check Permissions
          auth.checkPermissions('settings');

        }
      });

  }
}
