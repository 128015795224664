import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'queue-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {

  public items: MenuItem[];

  constructor() { }

  ngOnInit() {
    this.items = [
      {
        label: 'Queues',
        icon: 'fa-list',
        routerLink: [ '/queues' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Manual Queues',
        icon: 'fa-list',
        routerLink: [ '/queues/manual' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Print Queues',
        icon: 'fa-list',
        routerLink: [ '/queues/print' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Fax Queues',
        icon: 'fa-list',
        routerLink: [ '/queues/fax' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: 'Text Queues',
        icon: 'fa-list',
        routerLink: [ '/queues/text' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

}
