import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Activity } from './activity';
import { ActivityChangeResponse } from './activity-change-response';
import { Observable } from 'rxjs';

@Injectable()
export class ActivityService {

    readonly apiUrl;
    public selectedActivity: Activity;
    public ltUrgentActivity: Activity;
    public popupActivities:Activity[];

    constructor(private http: HttpClient, private auth: AuthService, config: ConfigServiceService) {
        this.apiUrl = config.getApiUrl();
    }

    getActivityLog(client_id: Number, filter: string = '') {
        const token = this.auth.getToken();
        return this.http.get<Activity[]>(this.apiUrl + '/activities?client_id=' + client_id + '&filter=' + filter, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    saveActivity(activity: Activity): Observable<any>
    {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/activities', activity,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    getActivity(id: number) {
        return new Observable<any>((observer) => {
    
            // Clear Client
            this.selectedActivity = null;
    
            // Load Client
            const token = this.auth.getToken();
            this.http.get<Activity>(this.apiUrl + '/activities/' + id, {
              headers: {
                Authorization: 'Bearer ' + token
              }
            }).subscribe(res => {
              this.selectedActivity = res;
    
              observer.next(this.selectedActivity);
              observer.complete();
            });
    
          /*} else {
            // Return cached object
    
            observer.next(this.selectedActivity);
            observer.complete();
          }*/
        });
      }

      getLatestUrgent(clientId: number)
       {
        return new Observable<any>((observer) => {
    
            // Clear Activity
            this.ltUrgentActivity = null;
    
            // Load Activity
            const token = this.auth.getToken();
            this.http.get<Activity[]>(this.apiUrl + '/activity/latestUrgent?clientId=' + clientId, {
              headers: {
                Authorization: 'Bearer ' + token
              }
            }).subscribe(res => {
              // this.ltUrgentActivity = res;
              this.popupActivities = res
    
              observer.next(this.popupActivities);
              observer.complete();
            });
    
        });
      }

      updateActivityData(id: number, data: Activity) {

        const token = this.auth.getToken();
        return this.http.put<ActivityChangeResponse>(this.apiUrl + '/activities/' + id, data, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }
    
      deleteActivity(data: Activity)
      {
        const token = this.auth.getToken();
        return this.http.delete<ActivityChangeResponse>(this.apiUrl + '/activity/delete/' +data.id, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        });
      }
    

}
