import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from '../auth.service';
import { Auth } from '../auth';
import { AuthData } from '../auth-response';
import { Message } from 'primeng/components/common/api';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuItem } from 'primeng/api';
import { DataService } from '../../data.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})

export class CreatePasswordComponent implements OnInit {

  msgs: Message[] = [];
  passwordForm: FormGroup;
  passwordError = "none";
  confirm_passwordError = "none";
  passwords_not_matchError = "none";
  redError = "red";
  id: any;
  user_id: any;
  hideShow = 3;
  constructor(public auth: AuthService,
    private router: Router,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    aroute: ActivatedRoute) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {

    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      id: 0
    });
    this.auth.checkPassworLink(this.id).subscribe((res) => {
      if(res.length != 0){
        this.hideShow = 0;
        this.user_id = res[0].user_id;
        this.passwordForm.patchValue({
          id: res[0].user_id
        });
      }else{
        this.hideShow = 1;
      }
    });
  }

  doPasswordChange() {
    this.passwordError = "none";
    this.confirm_passwordError = "none";
    this.passwords_not_matchError = "none";
    if (this.passwordForm.valid) {
      if (this.passwordForm.value.password != this.passwordForm.value.confirm_password) {
        this.passwords_not_matchError = "block";
      } else {
        this.auth.changePassword(this.user_id, this.passwordForm.value).subscribe((res) => {
          this.msgs.push({ severity: 'success', summary: 'Password Updated', detail: res.message });
          setTimeout(() => {
            this.msgs = [];
            this.router.navigate(['/login']);
          }, 2000);
        });
      }
    } else {
      if (this.passwordForm.value.password == "") {
        this.passwordError = "block";
      }
      if (this.passwordForm.value.confirm_password == "") {
        this.confirm_passwordError = "block";
      }
    }
  }
}
