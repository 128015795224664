import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorklistListComponent } from './worklist-list/worklist-list.component';
import { WorklistEditComponent } from './worklist-edit/worklist-edit.component';

const routes: Routes = [
  {path: 'settings/worklists', component: WorklistListComponent},
  {path: 'settings/worklists/create', component: WorklistEditComponent},
  {path: 'settings/worklists/edit/:id', component: WorklistEditComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorklistRoutingModule { }
