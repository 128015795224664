import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Stats } from '../stats';
import { ClientService } from '../client.service';
import { SpinnerService } from '../../core';
import { DataService } from '../../data.service';
import { $ } from 'protractor';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.css']
})
export class LandComponent implements OnInit {
  stats: Stats;
  isLogged:any;
  homeClients:any = [];
  worklists:any = [];
  selectedUser:any = 0;
  notexTextVisible = false;
  notexText = "";
  nextAnticipatedCDDate: any;
  nextAnticipatedCDVisible = false;
  errorMsgs = 0;
  currentSortId = "arrow_company_name_asc";
  currentUpdateNextACDateId = 0;
  currentUpdateNextACDate = 0;

  remrec_start_date: string;
  remrec_end_date: string;

  remRecStDtInv:boolean = false;
  remRecEndDtInv:boolean = false;

  dtValStr:string='';
  serverYr:string='';
  chromePrevStr:string='';

  constructor(private router: Router, 
    private clientService: ClientService,
    private spinnerService: SpinnerService,
    private dataService: DataService) { }


  validatedateInv(inputText)
  {
    var isDateValid = true;
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if(inputText.match(dateformat))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var opera2 = inputText.split('-');
  var lopera1 = opera1.length;
  var lopera2 = opera2.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  else if (lopera2>1)
  {
  var pdate = inputText.split('-');
  }
  var mm  = parseInt(pdate[0]);
  var dd = parseInt(pdate[1]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  isDateValid = false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
    isDateValid = false;
  }
  if ((lyear==true) && (dd>29))
  {
    isDateValid = false;
  }
  }
  }
  else
  {
    isDateValid = false;
  }

  return isDateValid;
  }

  outOfDtField( event,eleId )
  {
    this.fillDtTab( eleId );

    if( eleId == "remrecStdDt" )
    {
      this.remRecStDtInv = false;
      setTimeout(() =>
      {
        if( ! this.remRecStDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.remrec_start_date = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "remrecEnddDt" )
    {
      this.remRecEndDtInv = false;
      setTimeout(() =>
      {
        if( ! this.remRecEndDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.remrec_end_date = this.dtValStr;
        }
      }, 15);
    }
  }

  fillDtTab( eleId )
  {
    var goFillField = false;
    var dtBkStr = "";

    var chkTempStr = this.dtValStr;
    if( chkTempStr.length == 8 && chkTempStr.includes("/") && chkTempStr.charAt(2)=="/" && chkTempStr.charAt(5)=="/" )
    {
        var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
            if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[2].length == 2 )
              {
                var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                if( this.validatedateInv(checkingDt1) )
                {
                  this.dtValStr = checkingDt1;
                  dtBkStr = checkingDt1;
                  goFillField = true;
                }
              }
            }
        }
    }
    
    if( goFillField )
    {
      if( eleId == "remrecStdDt" )
      {
        this.remRecStDtInv = false;
        this.remrec_start_date = dtBkStr;
      }
      else if( eleId == "remrecEnddDt" )
      {
        this.remRecEndDtInv = false;
        this.remrec_end_date = dtBkStr;
      }
    }
  }

  completeMonth( eleId )
  {
      var tempStr = this.dtValStr; 
      var compMGo = false;
      if( tempStr.length == 1 && (parseInt(tempStr) > 1 && parseInt(tempStr) < 10) )
      {
        this.dtValStr = "0" + this.dtValStr + "/";
        compMGo = true;
      }
      else if( tempStr.length == 2 && (parseInt(tempStr) >= 1 && parseInt(tempStr) <= 12) )
      {
        this.dtValStr = this.dtValStr + "/";
        compMGo = true;
      }

      if( eleId == "remrecStdDt" && compMGo )
      {
        this.remRecStDtInv = true;
        setTimeout(() =>
        {
          this.remrec_start_date = this.dtValStr;
        }, 100);
      }
      else if( eleId == "remrecEnddDt" && compMGo )
      {
        this.remRecEndDtInv = true;
        setTimeout(() =>
        {
          this.remrec_end_date = this.dtValStr;
        }, 100);
      }
  }

  completeDate( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 1  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" && parseInt(tempStr) >= 4 && parseInt(tempStr) < 10 )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + "0" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "remrecStdDt" )
          {
            this.remRecStDtInv = true;
            setTimeout(() =>
            {
              this.remrec_start_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "remrecEnddDt" )
          {
            this.remRecEndDtInv = true;
            setTimeout(() =>
            {
              this.remrec_end_date = this.dtValStr;
            }, 100);
          }
        }
      }

  }

  completeDt2( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 2  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "remrecStdDt" )
          {
            this.remRecStDtInv = true;
            setTimeout(() =>
            {
              this.remrec_start_date = this.dtValStr;
            }, 100);
          }
          else if( eleId == "remrecEnddDt" )
          {
            this.remRecEndDtInv = true;
            setTimeout(() =>
            {
              this.remrec_end_date = this.dtValStr;
            }, 100);
          }
        }
      }

  }
  setYear(ev)
  {
    var justBackSpaced = false;

    if( ev.inputType != undefined && ev.inputType != null )
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined  )
      {
        this.dtValStr = ev.srcElement.value;
      }
      if( ev.inputType.trim() == "deleteContentBackward" || ev.inputType.trim() == "deleteContentForward" )
      {
        this.dtValStr = ev.srcElement.value;
        justBackSpaced = true;
      }
    }
    else
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined )
      {
        if( ev.srcElement.value.length < this.chromePrevStr.length )
        {
          justBackSpaced = true;
        }
        this.dtValStr = ev.srcElement.value;
        this.chromePrevStr = ev.srcElement.value;
      }
    }

    if( ! justBackSpaced && (this.dtValStr.length == 4 && ! this.dtValStr.includes("/"))  )
    {
      this.appendYr( ev.srcElement.id );
    }
    else if( ! justBackSpaced && (this.dtValStr.length == 1 || this.dtValStr.length == 2) )
    {
        this.completeMonth( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 4 )
    {
        this.completeDate( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 5 )
    {
        this.completeDt2( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 3 && ! this.dtValStr.includes("/") )
    {
      this.completeDt1( ev.srcElement.id );
    }
  }

  appendYr( eleId )
  {
      var isValidDate = true;
      var checkingDt = this.dtValStr;
      var dtGoAhead = false;

      if( checkingDt.length == 4 && ! checkingDt.includes('/') )
      {
        checkingDt = checkingDt.substring(0,2) + "/" + checkingDt.substring(2,4) + "/";
        if( this.validatedateInv(checkingDt) )
        {
          this.dtValStr = checkingDt;
          dtGoAhead = true;
        } 
      }
      else if( checkingDt.length == 5 )
      {
        var dtArr = this.dtValStr.split("/");
        if( dtArr != undefined && dtArr != null && typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' )
        {
          checkingDt += "/";
          if( this.validatedateInv(checkingDt) )
          {
            this.dtValStr += "/";
            dtGoAhead = true;
          }
        }
      }

      if( dtGoAhead )
      {
        if( eleId == "remrecStdDt" )
        {
          this.remRecStDtInv = true;
          this.remrec_start_date = this.dtValStr;
          setTimeout(() =>
          {
            this.remRecStDtInv = false;
          }, 50);
        }
        if( eleId == "remrecEnddDt" )
        {
          this.remRecEndDtInv = true;
          this.remrec_end_date = this.dtValStr;
          setTimeout(() =>
          {
            this.remRecEndDtInv = false;
          }, 50);
        }
      }
  }

  resetDtVal( dtFieldNum:number = 0 )
  {
    setTimeout(() =>
    {
      this.dtValStr = '';
    }, 50);
  }

  completeDt1( eleId )
  {
      var tempStr = this.dtValStr;
      var tmpNumb = "";

      if( tempStr != undefined && tempStr != null && tempStr.length == 3 )
      {
        tmpNumb = tempStr.charAt(2);
      }

      if( tmpNumb != "" && parseInt(tmpNumb) >= 4 && parseInt(tmpNumb) < 10 )
      {
        var assignDtFill = tempStr.charAt(0) + tempStr.charAt(1) + "/" + "0" + tmpNumb + "/";
        this.dtValStr = assignDtFill;

        if( eleId == "remrecStdDt" )
        {
          this.remRecStDtInv = true;
          setTimeout(() =>
          {
            this.remrec_start_date = this.dtValStr;
          }, 100);
        }
        else if( eleId == "remrecEnddDt" )
        {
          this.remRecEndDtInv = true;
          setTimeout(() =>
          {
            this.remrec_end_date = this.dtValStr;
          }, 100);
        }
      }
  }

  opennextAnticipatedCDPop(id:any, datec:any)
  {
    this.errorMsgs = 0;
    this.currentUpdateNextACDateId = id;
    this.nextAnticipatedCDVisible = true;

    // var newdate1 = new Date(datec);
    // this.nextAnticipatedCDDate = newdate1;
    this.currentUpdateNextACDate = datec;
    this.nextAnticipatedCDDate = "";
  }

  removeNextAnticipatedCDMsg()
  {
    if(this.nextAnticipatedCDDate != "" && this.nextAnticipatedCDDate != null && this.nextAnticipatedCDDate != 'null')
    {
      this.errorMsgs = 0;
    }
  }

  updateNextAnticipatedCD()
  {
    if(this.nextAnticipatedCDDate == "" || this.nextAnticipatedCDDate == null || this.nextAnticipatedCDDate == 'null')
    {
      this.errorMsgs = 1;
    }
    else
    {
      this.nextAnticipatedCDVisible = false;
      this.errorMsgs = 0;
      this.clientService.updateNextACDate(this.nextAnticipatedCDDate, this.currentUpdateNextACDateId).subscribe(res => {

        this.getUserClients();
        
        // var index1 = 0;
        // var index2 = 0;
        // this.homeClients.forEach(caps => {
        //   index2 = 0;
        //   caps.forEach(cap => {
        //       if(this.currentUpdateNextACDateId == cap.id){
        //         this.homeClients[index1][index2].other_first_check_date = this.nextAnticipatedCDDate;
        //       }
        //     index2++;
        //   });
        //   index1++;
        // });
      });
    }
  }

  ngOnInit() {
    this.selectedUser = localStorage.getItem('userId');
    this.dataService.currentMessage.subscribe(isLogged => setTimeout(() => this.isLogged = isLogged,0));
    this.isLogged = '1';
    this.dataService.changeMessage(this.isLogged);
    
    var sd = "";
    var ed = "";
    if (this.remrec_start_date != undefined && this.remrec_start_date != null && this.remrec_start_date != "" )
    {
      sd = this.remrec_start_date;
    }
    if (this.remrec_end_date != undefined && this.remrec_end_date != null && this.remrec_end_date != "" )
    {
      ed = this.remrec_end_date;
    }

    this.spinnerService.show();
    this.clientService.getHomePageClients(this.selectedUser, 'company_name', 'asc', sd, ed).subscribe(res => {
      this.homeClients = res;
      this.clientService.getUsers().subscribe(res => {
        this.worklists = res;
        this.spinnerService.hide();
        document.getElementById(this.currentSortId).classList.remove("redColor");
        document.getElementById("arrow_company_name_asc").classList.add("redColor");
        this.currentSortId = "arrow_company_name_asc";
      });
    });

  }

  gotoClientView(clientId)
  {
    this.router.navigateByUrl('/clients/list/view/' + clientId);
  }

  getUserClientsDatesReset(){
    this.remrec_start_date = "";
    this.remrec_end_date = "";
    this.getUserClients();
  }

  getUserClientsDates(){
    var goAhead = true;
    if (this.remrec_start_date == undefined || this.remrec_start_date == null || this.remrec_start_date == "" )
    {
      goAhead = false;
    }
    if (this.remrec_end_date == undefined || this.remrec_end_date == null || this.remrec_end_date == "" )
    {
      goAhead = false;
    }
    if(goAhead){
      this.getUserClients();
    }else{
      alert('Please select Start & End Dates');
    }
  }

  getUserClients(){
    var sd = "";
    var ed = "";
    if (this.remrec_start_date != undefined && this.remrec_start_date != null && this.remrec_start_date != "" )
    {
      sd = this.remrec_start_date;
    }
    if (this.remrec_end_date != undefined && this.remrec_end_date != null && this.remrec_end_date != "" )
    {
      ed = this.remrec_end_date;
    }

    this.spinnerService.show();
    this.clientService.getHomePageClients(this.selectedUser, 'company_name', 'asc', sd, ed).subscribe(res => {
      this.homeClients = res;
      this.spinnerService.hide();
      document.getElementById(this.currentSortId).classList.remove("redColor");
      document.getElementById("arrow_company_name_asc").classList.add("redColor");
      this.currentSortId = "arrow_company_name_asc";
    });
  }

  sortTable(columnvalue, orderValue){
    var sd = "";
    var ed = "";
    if (this.remrec_start_date != undefined && this.remrec_start_date != null && this.remrec_start_date != "" )
    {
      sd = this.remrec_start_date;
    }
    if (this.remrec_end_date != undefined && this.remrec_end_date != null && this.remrec_end_date != "" )
    {
      ed = this.remrec_end_date;
    }

    this.spinnerService.show();
    this.clientService.getHomePageClients(this.selectedUser, columnvalue, orderValue, sd, ed).subscribe(res => {
      this.homeClients = res;
      this.spinnerService.hide();
      document.getElementById(this.currentSortId).classList.remove("redColor");
      document.getElementById('arrow_'+columnvalue+'_'+orderValue).classList.add("redColor");
      this.currentSortId = 'arrow_'+columnvalue+'_'+orderValue;
    });
  }
  
  search(q) {
    this.router.navigateByUrl('/clients/list?q=' + q);
  }

  openNotesPopup(notes:any){
    this.notexTextVisible = true;
    this.notexText = notes;
  }

}
