import { Component, OnInit, Inject } from '@angular/core';
import { Location } from "@angular/common";
import { Client } from "../client";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService } from "../client.service";
import { PolicyService } from '../policy.service';
import { SpinnerService } from '../../core';
import { ConfirmationService } from 'primeng/components/common/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { ConfigServiceService } from '../../config-service.service';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.css']
})
export class UploadInvoiceComponent implements OnInit
{
  id: number;
  client: Client;

  uploadedFile:any=null;
  uploadInProgress:boolean = false;
  showSelFile:boolean = false;
  invUplSuccess:boolean = false;
  invUplFail:boolean = false;
  invMsgNotUpl:boolean = false;

  uplInvoiceInfo: any = {};

  invUplRetMsg:String="";
  
  constructor(aroute: ActivatedRoute, private router: Router, public location: Location,
    private clientService: ClientService, private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService, private policyService: PolicyService
    , @Inject(LOCAL_STORAGE) private storage: WebStorageService
    , private messageService: MessageService, private configServiceService: ConfigServiceService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  ngOnInit()
  {
    this.spinnerService.show();

    // Load client
    this.clientService.getClient(this.id).subscribe(res =>
    {
      this.client = res;
      this.spinnerService.hide();
    });
  }

  uplInvoice()
  {
    this.uploadInProgress = true;

    this.showSelFile = false;
    this.invUplSuccess = false;
    this.invUplFail = false;
    this.invMsgNotUpl = false;
    this.invUplRetMsg='';

    var goAhead = false;
    if( this.uploadedFile != undefined && this.uploadedFile != null )
    {
      goAhead = true;
    }

    if( goAhead )
    {
      this.showSelFile = false;
      this.invUplSuccess = false;
      this.invUplFail = false;
      this.invMsgNotUpl = false;
      this.invUplRetMsg='';
      this.spinnerService.show();

      this.uplInvoiceInfo = {};
      this.uplInvoiceInfo.uploadedFile = this.uploadedFile;
      this.uplInvoiceInfo.client_id = this.id;
      this.uplInvoiceInfo.user_id = this.storage.get('app.auth').id;

      this.clientService.uploadInvoiceFile(this.uplInvoiceInfo).subscribe(response =>
      {
            this.spinnerService.hide();
            if( response instanceof HttpErrorResponse )
            {
              alert( "Could not be uploaded." );
            }
            else
            {
              if( response.output == '-1' || response.output == '-2' )
              {
                this.invUplFail = true;
              }
              if( response.output == '-10' )
              {
                this.invMsgNotUpl = true;
                this.invUplRetMsg = response.message;
              }

              this.uplInvoiceInfo = {};
              this.uploadedFile = null;
              document.getElementsByTagName('form')[0].reset();
              this.uploadInProgress = false;
              
              if( response.output == '3' )
              {
                this.invUplSuccess = true;
                if( response.invoiceId != undefined && response.invoiceId != null && response.invoiceId )
                {
                  setTimeout(() =>
                  {
                    this.router.navigateByUrl( '/clients/list/'+this.id+'/invoice/revise/'+response.invoiceId );
                  }, 2000);
                }
              }
        }
      }
      ,
      error => {
        this.uplInvoiceInfo = {};
        this.uploadedFile = null;
        document.getElementsByTagName('form')[0].reset();
        this.uploadInProgress = false;
        this.invUplFail = true;
      });
    }
    else
    {
        this.uploadInProgress = false;
        this.showSelFile = true;
    }
  }

  onFileChange(event)
  {
    this.showSelFile = false;

    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.uploadedFile = reader.result;
      
        // need to run CD since file load runs outside of zone
        // this.cd.markForCheck();
      };
    }
  }
  
}
