import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import { ActivityService } from '../activity.service';
import { Activity } from "../activity";
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';

@Component({
  selector: 'client-activity',
  templateUrl: './client-activity.component.html',
  styleUrls: ['./client-activity.component.css']
})
export class ClientActivityComponent implements OnInit {
	msgs: Message[] = [];
	activityTypes: SelectItem[];
	selectedActivityType: string;
	private _filter:any = ['urgent','phone', 'email', 'audit','sh','cr', 'misc'];
	@ViewChild('activityList', { static: true }) activityList;

	activities: any = [];
	newActivity:any = {};
	

	activityform: FormGroup;
	submitted: boolean = false;
	showActivityNew: boolean = false;
	showActivityUpdate: boolean = false;
	clientId: any;
	ltUrgentActivity:Activity;
	popupActivities:Activity[];
	popupActivityNum=0;
	showUrgPopUp: boolean = false;

	dontShowPopUp:boolean = false;
	loggedUserRole:any;

	addUpdMode: number;

	addUpdPopUpBgClr="#ffffff";
	showMsgBgColor="#ffffff";

	popupHidden = 0;
	headerNoteCust='Note';

	constructor(private fb: FormBuilder, private activityService:ActivityService,
		aroute:ActivatedRoute, private messageService: MessageService,
		@Inject(LOCAL_STORAGE) private storage: WebStorageService,
		private spinnerService: SpinnerService) { 
		aroute.params.subscribe(params => {
			this.clientId = params['id'];
		});
	}

	currActBgColor()
	{

		this.showMsgBgColor="#ffffff";
		this.headerNoteCust="Note";
		if( this.ltUrgentActivity.type.trim() != "" )
		{
			if( this.ltUrgentActivity.type.trim() == "urgent" )
			{
				this.showMsgBgColor="#F5B7B1";
				this.headerNoteCust="Call";
			}
			else if( this.ltUrgentActivity.type.trim() == "phone" )
			{
				this.showMsgBgColor="#F5CBA7";
				this.headerNoteCust="Email";
			}
			else if( this.ltUrgentActivity.type.trim() == "email" )
			{
				this.showMsgBgColor="#A2D9CE";
				this.headerNoteCust="Tax";
			}
			else if( this.ltUrgentActivity.type.trim() == "audit" )
			{
				this.showMsgBgColor="#D7BDE2";
				this.headerNoteCust="Prior";
			}
			else if( this.ltUrgentActivity.type.trim() == "sh" )
			{
				this.showMsgBgColor="#F9E79F";
				this.headerNoteCust="WC";
			}
			else if( this.ltUrgentActivity.type.trim() == "cr" )
			{
				this.showMsgBgColor="#AED6F1";
				this.headerNoteCust="Billing";
			}
			else if( this.ltUrgentActivity.type.trim() == "misc" )
			{
				this.showMsgBgColor="#E5E7E9";
				this.headerNoteCust="Misc";
			}
		}
		var shDiv:any = null;
		shDiv=document.querySelector('.content_desc_view');
		shDiv.style.backgroundColor = this.showMsgBgColor;
	}

	showNextPopUp()
	{
		this.showUrgPopUp = false;
		setTimeout(() =>
		{
			this.popupActivityNum++;
			if( typeof this.popupActivities[this.popupActivityNum] === 'undefined' )
			{
				// Nothing to do...
			}
			else
			{
				this.ltUrgentActivity = this.popupActivities[this.popupActivityNum];
				this.currActBgColor();
				this.showUrgPopUp = true;
			}
		}, 500);
	}

	updateLtUrgent()
	{
			this.showUrgPopUp=false;
			
			this.spinnerService.show();
    
		this.ltUrgentActivity.urgent_dismissed = 1;
		
		this.ltUrgentActivity.pop_up_show = 0;

		this.activityService.updateActivityData(this.ltUrgentActivity.id,this.ltUrgentActivity).subscribe(
		  response => {
			if(response instanceof HttpErrorResponse) {
			} else {
				this.spinnerService.hide();
				this.showNextPopUp();
	      }
		  },
		  error => {
			console.log(error);
		  });
	
	  }

	loadLatestUrgent()
	{
		this.loggedUserRole = localStorage.getItem('loggedUserRole');
		this.activityService.getLatestUrgent(this.clientId).subscribe(res =>
      {
				this.popupActivities = res;
				// console.log(this.popupActivities);
        if( this.popupActivities != undefined && this.popupActivities != null && this.popupActivities.length )
        {
					this.ltUrgentActivity = this.popupActivities[this.popupActivityNum];
					this.currActBgColor();
          this.showUrgPopUp = true;
        }
      });
  }


	loadAnActivity(id: number)
	{
		this.showActivityNew=false;
		// Load activity
		this.activityService.getActivity(id).subscribe(res =>
		{
			this.newActivity = res;

			if( this.newActivity.type.trim() != "" && this.newActivity.description.trim() !="" )
			{
				this.submitted=true;
			}
			this.addUpdMode = 2;
			this.showActivityUpdate=true;
		});
	}

	ngOnInit() {

		this.activities = [];
		
		this.newActivity = {};
		this.newActivity.type = '';
		this.newActivity.description = '';
		this.newActivity.pop_up_show = 0;

		this.refresh();

		this.activityform = this.fb.group({
			'description': new FormControl('', Validators.required),
			'type': new FormControl('', Validators.required),
			'pop_up_show': new FormControl('', Validators.required)
		});

		this.activityTypes = [
			{ label: 'Call', icon: 'fa fa-asterisk', value: 'urgent' },
			{ label: 'Email', icon: 'fa fa-phone', value: 'phone' },
			{ label: 'Tax', icon: 'fa fa-envelope', value: 'email' },
			{ label: 'Prior', icon: 'fa fa-clipboard', value: 'audit' },
			{ label: 'WC', icon: 'fa fa-asterisk', value: 'sh' },
			{ label: 'Billing', icon: 'fa fa-asterisk', value: 'cr' },
			{ label: 'Misc', icon: 'fa fa-asterisk', value: 'misc' }
		];
	}

	rpmResetForm()
	{
		this.newActivity.type = '';
		this.newActivity.description = '';
		this.newActivity.pop_up_show = 0;

		this.submitted=false;
		this.addUpdMode = 1;
		this.showActivityNew=true;
	}

	enableSubmit(  cntrl:number=0 )
	{
		if( this.newActivity.type.trim() != "" && this.newActivity.description.trim() !="" )
		{
            this.submitted = true;
        } else {
            this.submitted = false;
				}
				
				if( cntrl == 1 )
				{
					this.addUpdPopUpBgClr="#ffffff";
					if( this.newActivity.type.trim() != "" )
					{
						if( this.newActivity.type.trim() == "urgent" )
						{
							this.addUpdPopUpBgClr="#F5B7B1";
						}
						else if( this.newActivity.type.trim() == "phone" )
						{
							this.addUpdPopUpBgClr="#F5CBA7";
						}
						else if( this.newActivity.type.trim() == "email" )
						{
							this.addUpdPopUpBgClr="#A2D9CE";
						}
						else if( this.newActivity.type.trim() == "audit" )
						{
							this.addUpdPopUpBgClr="#D7BDE2";
						}
						else if( this.newActivity.type.trim() == "sh" )
						{
							this.addUpdPopUpBgClr="#F9E79F";
						}
						else if( this.newActivity.type.trim() == "cr" )
						{
							this.addUpdPopUpBgClr="#AED6F1";
						}
						else if( this.newActivity.type.trim() == "misc" )
						{
							this.addUpdPopUpBgClr="#E5E7E9";
						}
					}
				}
        // console.log('enableSubmit');
        // console.log(this.activityform.touched);
        // console.log(this.activityform.valid);
        // console.log(this.submitted);
    }


	set filter(value) {
		this._filter = value;
		/* this.activityList.filterBy = 'type';
		this.activityList.filter = ''; 
		this.activityList.filter(this._filter.join(' '));*/
		this.refresh();
		console.log(this._filter);
	}

	get filter() {
		return this._filter;
	}

	onSubmit(value: string) {
		this.saveActivity();
		this.submitted = true;
	}

	refresh() {
		this.activities = [];
		this.activityService.getActivityLog(this.clientId, this._filter).subscribe(
			response => {				
				if(response instanceof HttpErrorResponse) {
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
				} else {
					this.activities = <Activity[]> response;
					if( ! this.dontShowPopUp )
					{
						this.loadLatestUrgent();
						this.dontShowPopUp = false;
					}
				}
			},
			error => {
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.statusText});
		});		
	}

	callFormSave()
	 {
		 if( this.addUpdMode == 1 )
		 {
			this.saveActivity();
		 }
		 else if( this.addUpdMode == 2 )
		 {
			this.updateActivity();
		 }
	}

	saveActivity()
	{  
		if( this.submitted )
		{
			this.submitted=false;
			this.newActivity.client_id = this.clientId;	  
		this.newActivity.user_id = this.storage.get('app.auth').id;	  
		/****** Call Save Activity Service ******/
		this.activityService.saveActivity(this.newActivity).subscribe(
			response => {				
				if(response instanceof HttpErrorResponse) {
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
					this.showActivityNew=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
					this.newActivity.pop_up_show = 0;
				} else {
					this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Activity added successfully'});
					this.activities.push(response.activity);
					this.showActivityNew=false;
					this.dontShowPopUp = true;
					this.newActivity.type = '';
					this.newActivity.description = '';
					this.newActivity.pop_up_show = 0;
					this.refresh();
				}
			},
			error => {
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:'Something went wrong'});
		});		
		/**************************************/
	}
	}

	updateActivity()
	{
		if( this.submitted )
		{
			this.submitted=false;

			if( this.newActivity.pop_up_show == 1 )
			{
				this.newActivity.urgent_dismissed = 0;
			}

			this.activityService.updateActivityData(this.newActivity.id,this.newActivity).subscribe(
		  response => {
			if(response instanceof HttpErrorResponse) {
			  this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
			  this.showActivityUpdate=false;
				this.newActivity.type = '';
				this.newActivity.description = '';
				this.newActivity.pop_up_show = 0;
			} else {
			  this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Activity updated successfully'});
			  this.showActivityUpdate=false;
				this.dontShowPopUp = true;
				this.newActivity.type = '';
				this.newActivity.description = '';
				this.newActivity.pop_up_show = 0;
			this.refresh();
	  }
		  },
		  error => {
			console.log(error);
			this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
		  });
		}
	
	  }

	  deleteActivity()
	  {
			this.submitted=false;

			var r = confirm("Are you sure you want to delete activity ?");
			if( ! r )
			{
				this.submitted=true;
				return false;
			}
		  this.activityService.deleteActivity(this.newActivity).subscribe(
			response => {
			  if(response instanceof HttpErrorResponse) {
				this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
				this.showActivityUpdate=false;
				this.newActivity.type = '';
				this.newActivity.description = '';
			  } else {
				this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Activity deleted successfully'});
				this.showActivityUpdate=false;
				this.newActivity.type = '';
				this.newActivity.description = '';
				this.dontShowPopUp = true;
				this.refresh();
		}
			},
			error => {
			  console.log(error);
			  this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
			});
	  
		}
  
// 		 levels:Array<Object> = [
//       {num: 0, name: "Phone"},
//       {num: 1, name: "Email"},
// 	  {num: 1, name: "Audit"},
// 	  {num: 1, name: "Misc"}
//   ];
//    selectedLevel = {num: 0, name: "Phone"};
}