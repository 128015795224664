export class DocumentTemplate {
  id: number;
  type: string;
  cloud_document_id: string;
  body: string;
  name: string;
  description: string;

  created_at: Date;
  updated_at: Date;
  output:number;
  message:string;

  constructor(data: any = {}) {
    // Set normal fields
    this.id = data.id;
    this.type = data.type;
    this.cloud_document_id = data.cloud_document_id;
    this.body = data.body;
    this.name = data.name;
    this.description = data.description;

    // Parse date fields
    this.created_at = new Date(Date.parse(data.created_at));
    this.updated_at = new Date(Date.parse(data.updated_at));

    // Parse other fields
  }
}
