import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService } from "../client.service";
import { PolicyService } from '../policy.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/components/common/messageservice';
import { ConfirmationService } from 'primeng/components/common/api';
import { Client } from "../client";
import { Policy } from "../policy";
import { Rate } from "../rate";
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})

export class PolicyComponent implements OnInit {
  id: number;
  client: Client;
  policies: Policy[];
  activePolicy: Policy;
  showSelected: boolean;

  allowNewPolicy: string;
  allowRenewalPol: string;

  showPolicy: boolean = false;
  newPolicy: any = {};

  carriers: any = [];
  states: any = [];

  msgs = [];
  newPolicyForm: FormGroup;
  rateForm: FormGroup;
  submitted: boolean;

  rates = [];
  newRate: any = {};
  classCode: any = {};
  newRatesCount: number = -1;

  showNRC: boolean = false;
  nrcRates = [];
  allRatesArr = [];
  rateNRCForm: FormGroup;
  nccRatesCount: number = -1;
  nrc_btn_add: string = '';
  nrc_btn_upd: string = 'none';
  nrcPolicyForm: FormGroup;
  nrcOriRates = [];

  newPolBtn: any = true;
  endPolBtn: any = true;
  nrcPolBtn: any = true;
  cancPolBtn: any = true;
  reinPolBtn: any = true;
  renPolBtn: any = true;

  updatePolicy: any = {};
  nrcPostObj: any = {};

  showEndorsement: boolean = false;
  rateENDForm: FormGroup;
  endPolicyForm: FormGroup;
  end_btn_add: string = '';
  end_btn_upd: string = 'none';

  showCancellation: boolean = false;
  cancPolicyForm: FormGroup;

  showReinstatement: boolean = false;
  reinPolicyForm: FormGroup;

  showLogPopUp: boolean = false;
  currLogDesc: string = '';
  currLogHeader: string = '';

  showRenewal: boolean = false;
  renewPolicy: any = {};

  renewPolicyForm: FormGroup;
  renewRateForm: FormGroup;

  newSubmitted: boolean = false;
  endSubmitted: boolean = false;
  nrcSubmitted: boolean = false;
  cancSubmitted: boolean = true;
  reinSubmitted: boolean = true;
  renewSubmitted: boolean = false;

  clasCodeResults: string[];
  codesPostObj: any = {};

  codeDescResults: string[];
  LocNameResults: string[];

  carrierResults: any = [];
  carrierIdToSave:number=0;
  carrobj: any = {};

  redError = 'red';
  end_eff_date_change_error = 'none';
  pol_eff_date_change_error = 'none';
  nrc_eff_date_change_error = 'none';
  rein_eff_date_change_error = 'none';
  canc_eff_date_change_error = 'none';
  rpm_admin_fee_error = 'none';
  carrier_id_error = 'none';
  policy_number_error = 'none';
  effective_date_error = 'none';
  end_date_error = 'none';
  description_error = 'none';
  location_name_error = 'none';
  state_id_error = 'none';
  class_code_error = 'none';
  class_code_description_error = 'none';
  state_rate_error = 'none';
  net_rate_error = 'none';
  policy_rate_1_error = 'none';
  filteredStates: any;
  THOUSANDS_SEPARATOR = ",";

  displayMessage = 0;

  policyDeletion: boolean = false;

  policyActObj: any = {};

  loggedUserRole: any;

  dtValStr:string='';
  serverYr:string='';
  chromePrevStr:string='';

  efDtChngeInv:boolean = false;
  polEfDtInv:boolean = false;
  endDtInv:boolean = false;
 
  endChngeInv:boolean = false;
  endEfDtInv:boolean = false;
  endfinalDtInv:boolean = false;

  nrcChngeInv:boolean = false;
  nrcEfDtInv:boolean = false;
  nrcfinalDtInv:boolean = false;

  cancChngeInv:boolean = false;
  cancEfDtInv:boolean = false;
  cancfinalDtInv:boolean = false;

  reinChngeInv:boolean = false;
  reinEfDtInv:boolean = false;
  reinfinalDtInv:boolean = false;

  renewChngeInv:boolean = false;
  renewEfDtInv:boolean = false;
  renewfinalDtInv:boolean = false;

  actionOverridePrg:boolean = false;
  actionOvrPostObj:any = {};
  actionToBeChecked:number = 0;
  showActionOvr:boolean = false;
  showActionRem:boolean = false;

  clNewCarrier:any={};

  constructor(aroute: ActivatedRoute, private router: Router, public location: Location,
    private clientService: ClientService, private policyService: PolicyService, private fb: FormBuilder,
    private messageService: MessageService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
      this.showSelected = false;

    });
  }

  openCarrDD( event,selId )
  {
    if( event.relatedTarget != null )
    {
      document.getElementById(selId).click();
    }
  }

  hideNewPopUp()
  {
    this.showPolicy=false;
    this.newPolBtn = true;
  }
  
  scrollTo(className: string): void {
    setTimeout(function () {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }

  fillEndDate(givenForm)
  {
    var effective_date = new Date(givenForm.value.effective_date);
    var dateObj = new Date(givenForm.value.effective_date);

    dateObj.setFullYear(dateObj.getFullYear()+1, dateObj.getMonth(), dateObj.getDate() - 1);

    givenForm.controls['end_date'].setValue(dateObj.toLocaleDateString('en-Us'));
    givenForm.controls['end_date'].markAsTouched();
  }

  searchLocNames(event) {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.codesPostObj.autoType = 3;
    this.codesPostObj.policyId = 0;
    this.policyService.getPolStateClCodes(this.codesPostObj).subscribe(data => {
      if (data != undefined && data != null && data.locnames != undefined && data.locnames != null) {
        this.LocNameResults = data.locnames;
      }
    });
  }

  search(event) {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.codesPostObj.autoType = 1;
    this.codesPostObj.policyId = 0;
    this.policyService.getPolStateClCodes(this.codesPostObj).subscribe(data => {
      if (data != undefined && data != null && data.codes != undefined && data.codes != null) {
        this.clasCodeResults = data.codes;
      }
    });
  }

  searchState(event) {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.policyService.getSearchStates(this.codesPostObj).subscribe(data => {
      if (data != undefined && data != null && data.codes != undefined && data.codes != null) {
        this.states = data.codes;
      }
    });
  }

  searchCarriers(event)
  {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.policyService.getSearchCarriers(this.codesPostObj).subscribe(res =>
    {
      this.carrierResults = [];
      this.carrierResults = res;
    });
  }

  carrierSelected(event: any,formType)
  {
    this.carrierIdToSave = event.value;
  }
  
  searchRenewal(event) {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.codesPostObj.autoType = 1;
    this.codesPostObj.policyId = 0;
    this.policyService.getPolStateClCodes(this.codesPostObj).subscribe(data => {
      if (data != undefined && data != null && data.codes != undefined && data.codes != null) {
        this.clasCodeResults = data.codes;
      }
    });
  }

  getCodeDescs(event, form) {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.codesPostObj.autoType = 2;
    this.codesPostObj.policyId = 0;
    this.codesPostObj.classCodeSelected = form.value.class_code;
    this.policyService.getPolStateClCodes(this.codesPostObj).subscribe(data => {
      if (data != undefined && data != null && data.codeDescs != undefined && data.codeDescs != null) {
        this.codeDescResults = data.codeDescs;
      }
    });
  }

  getRenDescs(event, form) {
    this.codesPostObj = {};
    this.codesPostObj.codeSearched = event.query;
    this.codesPostObj.autoType = 2;
    this.codesPostObj.policyId = 0;
    this.codesPostObj.classCodeSelected = form.value.class_code;
    this.policyService.getPolStateClCodes(this.codesPostObj).subscribe(data => {
      if (data != undefined && data != null && data.codeDescs != undefined && data.codeDescs != null) {
        this.codeDescResults = data.codeDescs;
      }
    });
  }

  rpmAdminFeeFormate(typeForm) {
    if (typeForm == 1 && this.newPolicyForm.value.rpm_admin_fee != undefined && this.newPolicyForm.value.rpm_admin_fee != "") {
      if (!isNaN(this.newPolicyForm.value.rpm_admin_fee.replace(/,/g, ''))) {
        var rpmAdminFee = this.newPolicyForm.value.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        this.newPolicyForm.controls['rpm_admin_fee'].setValue(rpmAdminFee);
      }
    }
    else if (typeForm == 2 && this.endPolicyForm.value.rpm_admin_fee != undefined && this.endPolicyForm.value.rpm_admin_fee != "")
    {
      if (!isNaN(this.endPolicyForm.value.rpm_admin_fee.replace(/,/g, ''))) {
        var rpmAdminFee = this.endPolicyForm.value.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        this.endPolicyForm.controls['rpm_admin_fee'].setValue(rpmAdminFee);
      }
    }
    else if (typeForm == 3 && this.renewPolicyForm.value.rpm_admin_fee != undefined && this.renewPolicyForm.value.rpm_admin_fee != "")
    {
      if (!isNaN(this.renewPolicyForm.value.rpm_admin_fee.replace(/,/g, ''))) {
        var rpmAdminFee = this.renewPolicyForm.value.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        this.renewPolicyForm.controls['rpm_admin_fee'].setValue(rpmAdminFee);
      }
    }
  }

  netRateCalcln()
  {
    var estimatedPremium = 0;
    var downPayment = 0;
    // if (this.newPolicy.estimatedPremium != "" && isNaN(this.newPolicy.estimatedPremium)) {
    //   this.newPolicy.estimatedPremium = "";
    // }
    if (this.newPolicy.estimatedPremium != "") {
      estimatedPremium = this.newPolicy.estimatedPremium;
      estimatedPremium = parseFloat(estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.newPolicy.estimatedPremium = this.newPolicy.estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

      var stateRate = estimatedPremium;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.newPolicy.estimatedPremium = figure;
    }
    // if (this.newPolicy.downPayment != "" && isNaN(this.newPolicy.downPayment)) {
    //   this.newPolicy.downPayment = "";
    // }
    if (this.newPolicy.downPayment != "" && this.newPolicy.downPayment != null) {
      downPayment = this.newPolicy.downPayment;
      downPayment = parseFloat(downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      
      //this.newPolicy.downPayment = this.newPolicy.downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = downPayment;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.newPolicy.downPayment = figure;
    }

    var adjustedPrem = 0;
    this.newPolicy.adjustedPrem = '';
    if (this.newPolicy.estimatedPremium != "" && this.newPolicy.downPayment != "")
    {
      if (this.newPolicy.estimatedPremium != undefined && this.newPolicy.estimatedPremium !== null && this.newPolicy.downPayment != undefined && this.newPolicy.downPayment !== null)
      {
        if (!isNaN(estimatedPremium) && !isNaN(downPayment))
        {
          adjustedPrem = estimatedPremium - downPayment;
          this.newPolicy.adjustedPrem = adjustedPrem.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

          // this.newPolicy.adjustedPrem = this.newPolicy.adjustedPrem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
          var stateRate = parseFloat(this.newPolicy.adjustedPrem);

          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.newPolicy.adjustedPrem = figure;
        }
      }
    }

    this.newPolicy.premFactor = '';
    var manualPrem = 0;
    // if (this.newPolicy.manualPrem != "" && isNaN(this.newPolicy.manualPrem)) {
    //   this.newPolicy.manualPrem = "";
    // }
    if (this.newPolicy.manualPrem != "" && this.newPolicy.manualPrem != null) {
      manualPrem = this.newPolicy.manualPrem;
      manualPrem = parseFloat(manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.newPolicy.manualPrem = this.newPolicy.manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = manualPrem;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.newPolicy.manualPrem = figure;
    }

    if (this.newPolicy.manualPrem != "" && this.newPolicy.adjustedPrem != undefined && this.newPolicy.adjustedPrem !== null && this.newPolicy.manualPrem != undefined && this.newPolicy.manualPrem !== null)
    {
      if (!isNaN(adjustedPrem) && !isNaN(manualPrem))
      {
        this.newPolicy.premFactor = ((adjustedPrem / manualPrem) * 100).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        if (this.newPolicy.premFactor != undefined && this.newPolicy.premFactor != null && this.newPolicy.premFactor != "")
        {
          var pfArr = this.newPolicy.premFactor.split(".");
          if (pfArr.length == 2 && typeof pfArr[1] !== 'undefined' && pfArr[1] != "")
          {
            if (pfArr[1].toString().length < 4)
            {
              var paddingZ = "";
              for (var i = 0; i < (4 - pfArr[1].toString().length); i++)
              {
                paddingZ += "0";
              }
              pfArr[1] = pfArr[1].toString() + paddingZ;
              this.newPolicy.premFactor = pfArr[0].toString() + "." +pfArr[1].toString();
            }
          }
          else if (pfArr.length == 1 && typeof pfArr[0] !== 'undefined' && pfArr[0] != "")
          {
            this.newPolicy.premFactor = pfArr[0].toString() + ".0000";
          }
        }
      }
    }
  }

  netRateRenewal() {
    var estimatedPremium = 0;
    var downPayment = 0;
    // if (this.renewPolicy.estimatedPremium != "" && isNaN(this.renewPolicy.estimatedPremium)) {
    //   this.renewPolicy.estimatedPremium = "";
    // }
    if (this.renewPolicy.estimatedPremium != "") {
      estimatedPremium = this.renewPolicy.estimatedPremium;
      estimatedPremium = parseFloat(estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      
      // this.renewPolicy.estimatedPremium = this.renewPolicy.estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = estimatedPremium;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.renewPolicy.estimatedPremium = figure;
    }
    // if (this.renewPolicy.downPayment != "" && isNaN(this.renewPolicy.downPayment)) {
    //   this.renewPolicy.downPayment = "";
    // }
    if (this.renewPolicy.downPayment != "" && this.renewPolicy.downPayment != null) {
      downPayment = this.renewPolicy.downPayment;
      downPayment = parseFloat(downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.renewPolicy.downPayment = this.renewPolicy.downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = downPayment;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.renewPolicy.downPayment = figure;
    }

    var adjustedPrem = 0;
    this.renewPolicy.adjustedPrem = '';
    if (this.renewPolicy.estimatedPremium != "" && this.renewPolicy.downPayment != "") {
      if (this.renewPolicy.estimatedPremium != undefined && this.renewPolicy.estimatedPremium !== null && this.renewPolicy.downPayment != undefined && this.renewPolicy.downPayment !== null) {
        if (!isNaN(estimatedPremium) && !isNaN(downPayment)) {
          adjustedPrem = estimatedPremium - downPayment;
          this.renewPolicy.adjustedPrem = adjustedPrem.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

          // this.renewPolicy.adjustedPrem = this.renewPolicy.adjustedPrem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

          var stateRate = parseFloat(this.renewPolicy.adjustedPrem);

          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.renewPolicy.adjustedPrem = figure;
        }
      }
    }

    this.renewPolicy.premFactor = '';
    var manualPrem = 0;
    // if (this.renewPolicy.manualPrem != "" && isNaN(this.renewPolicy.manualPrem)) {
    //   this.renewPolicy.manualPrem = "";
    // }
    if (this.renewPolicy.manualPrem != "" && this.renewPolicy.manualPrem != null) {
      manualPrem = this.renewPolicy.manualPrem;
      manualPrem = parseFloat(manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.renewPolicy.manualPrem = this.renewPolicy.manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = manualPrem;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.renewPolicy.manualPrem = figure;
    }

    if (this.renewPolicy.manualPrem != "" && this.renewPolicy.adjustedPrem != undefined && this.renewPolicy.adjustedPrem !== null && this.renewPolicy.manualPrem != undefined && this.renewPolicy.manualPrem !== null) {
      if (!isNaN(adjustedPrem) && !isNaN(manualPrem)) {
        this.renewPolicy.premFactor = ((adjustedPrem / manualPrem) * 100).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        if (this.renewPolicy.premFactor != undefined && this.renewPolicy.premFactor != null && this.renewPolicy.premFactor != "") {
          var pfArr = this.renewPolicy.premFactor.split(".");
          if (pfArr.length == 2 && typeof pfArr[1] !== 'undefined' && pfArr[1] != "") {
            if (pfArr[1].toString().length < 4) {
              var paddingZ = "";
              for (var i = 0; i < (4 - pfArr[1].toString().length); i++) {
                paddingZ += "0";
              }
              pfArr[1] = pfArr[1].toString() + paddingZ;
              this.renewPolicy.premFactor = pfArr[0].toString() + "." + pfArr[1].toString();
            }
          }
          else if (pfArr.length == 1 && typeof pfArr[0] !== 'undefined' && pfArr[0] != "") {
            this.renewPolicy.premFactor = pfArr[0].toString() + ".0000";
          }
        }
      }
    }
  }

  netRateEND()
  {
    var estimatedPremium = 0;
    var downPayment = 0;
    // if (this.updatePolicy.estimatedPremium != "" && isNaN(this.updatePolicy.estimatedPremium)) {
    //   this.updatePolicy.estimatedPremium = "";
    // }
    if (this.updatePolicy.estimatedPremium != "" && this.updatePolicy.estimatedPremium != null) {
      estimatedPremium = this.updatePolicy.estimatedPremium;
      estimatedPremium = parseFloat(estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.updatePolicy.estimatedPremium = this.updatePolicy.estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = estimatedPremium;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.updatePolicy.estimatedPremium = figure;
    }
    // if (this.updatePolicy.downPayment != "" && isNaN(this.updatePolicy.downPayment)) {
    //   this.updatePolicy.downPayment = "";
    // }
    if (this.updatePolicy.downPayment != "" && this.updatePolicy.downPayment != null) {
      downPayment = this.updatePolicy.downPayment;
      downPayment = parseFloat(downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      
      // this.updatePolicy.downPayment = this.updatePolicy.downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = downPayment;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.updatePolicy.downPayment = figure;
    }

    var adjustedPrem = 0;
    this.updatePolicy.adjustedPrem = '';
    if (this.updatePolicy.estimatedPremium != "" && this.updatePolicy.downPayment != "") {
      if (this.updatePolicy.estimatedPremium != undefined && this.updatePolicy.estimatedPremium !== null && this.updatePolicy.downPayment != undefined && this.updatePolicy.downPayment !== null) {
        if (!isNaN(estimatedPremium) && !isNaN(downPayment)) {
          adjustedPrem = estimatedPremium - downPayment;
          this.updatePolicy.adjustedPrem = adjustedPrem.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

          // this.updatePolicy.adjustedPrem = this.updatePolicy.adjustedPrem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
          var stateRate = parseFloat(this.updatePolicy.adjustedPrem);

          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.updatePolicy.adjustedPrem = figure;
        }
      }
    }

    this.updatePolicy.premFactor = '';
    var manualPrem = 0;
    // if (this.updatePolicy.manualPrem != "" && isNaN(this.updatePolicy.manualPrem)) {
    //   this.updatePolicy.manualPrem = "";
    // }
    if (this.updatePolicy.manualPrem != "" && this.updatePolicy.manualPrem != null) {
      manualPrem = this.updatePolicy.manualPrem;
      manualPrem = parseFloat(manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.updatePolicy.manualPrem = this.updatePolicy.manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = manualPrem;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.updatePolicy.manualPrem = figure;
    }

    if (this.updatePolicy.manualPrem != "" && this.updatePolicy.adjustedPrem != undefined && this.updatePolicy.adjustedPrem !== null && this.updatePolicy.manualPrem != undefined && this.updatePolicy.manualPrem !== null) {
      if (!isNaN(adjustedPrem) && !isNaN(manualPrem)) {
        this.updatePolicy.premFactor = ((adjustedPrem / manualPrem) * 100).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        if (this.updatePolicy.premFactor != undefined && this.updatePolicy.premFactor != null && this.updatePolicy.premFactor != "") {
          var pfArr = this.updatePolicy.premFactor.split(".");
          if (pfArr.length == 2 && typeof pfArr[1] !== 'undefined' && pfArr[1] != "") {
            if (pfArr[1].toString().length < 4) {
              var paddingZ = "";
              for (var i = 0; i < (4 - pfArr[1].toString().length); i++) {
                paddingZ += "0";
              }
              pfArr[1] = pfArr[1].toString() + paddingZ;
              this.updatePolicy.premFactor = pfArr[0].toString() + "." + pfArr[1].toString();
            }
          }
          else if (pfArr.length == 1 && typeof pfArr[0] !== 'undefined' && pfArr[0] != "") {
            this.updatePolicy.premFactor = pfArr[0].toString() + ".0000";
          }
        }
      }
    }

  }

  netRateNRC()
  {
    var estimatedPremium = 0;
    var downPayment = 0;
    // if (this.updatePolicy.estimatedPremium != "" && isNaN(this.updatePolicy.estimatedPremium)) {
    //   this.updatePolicy.estimatedPremium = "";
    // }
    if (this.updatePolicy.estimatedPremium != "" && this.updatePolicy.estimatedPremium != null) {
      estimatedPremium = this.updatePolicy.estimatedPremium;
      estimatedPremium = parseFloat(estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.updatePolicy.estimatedPremium = this.updatePolicy.estimatedPremium.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = estimatedPremium;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.updatePolicy.estimatedPremium = figure;
    }
    // if (this.updatePolicy.downPayment != "" && isNaN(this.updatePolicy.downPayment)) {
    //   this.updatePolicy.downPayment = "";
    // }
    if (this.updatePolicy.downPayment != "" && this.updatePolicy.downPayment != null) {
      downPayment = this.updatePolicy.downPayment;
      downPayment = parseFloat(downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      
      // this.updatePolicy.downPayment = this.updatePolicy.downPayment.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = downPayment;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.updatePolicy.downPayment = figure;
    }

    var adjustedPrem = 0;
    this.updatePolicy.adjustedPrem = '';
    if (this.updatePolicy.estimatedPremium != "" && this.updatePolicy.downPayment != "") {
      if (this.updatePolicy.estimatedPremium != undefined && this.updatePolicy.estimatedPremium !== null && this.updatePolicy.downPayment != undefined && this.updatePolicy.downPayment !== null) {
        if (!isNaN(estimatedPremium) && !isNaN(downPayment)) {
          adjustedPrem = estimatedPremium - downPayment;
          this.updatePolicy.adjustedPrem = adjustedPrem.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

          // this.updatePolicy.adjustedPrem = this.updatePolicy.adjustedPrem.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
          var stateRate = parseFloat(this.updatePolicy.adjustedPrem);

          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.updatePolicy.adjustedPrem = figure;
        }
      }
    }

    this.updatePolicy.premFactor = '';
    var manualPrem = 0;
    // if (this.updatePolicy.manualPrem != "" && isNaN(this.updatePolicy.manualPrem)) {
    //   this.updatePolicy.manualPrem = "";
    // }
    if (this.updatePolicy.manualPrem != "" && this.updatePolicy.manualPrem != null) {
      manualPrem = this.updatePolicy.manualPrem;
      manualPrem = parseFloat(manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      // this.updatePolicy.manualPrem = this.updatePolicy.manualPrem.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);
      var stateRate = manualPrem;

      var parts = stateRate.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var figure = parts.join(".");
      this.updatePolicy.manualPrem = figure;
    }

    if (this.updatePolicy.manualPrem != "" && this.updatePolicy.adjustedPrem != undefined && this.updatePolicy.adjustedPrem !== null && this.updatePolicy.manualPrem != undefined && this.updatePolicy.manualPrem !== null) {
      if (!isNaN(adjustedPrem) && !isNaN(manualPrem)) {
        this.updatePolicy.premFactor = ((adjustedPrem / manualPrem) * 100).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        if (this.updatePolicy.premFactor != undefined && this.updatePolicy.premFactor != null && this.updatePolicy.premFactor != "") {
          var pfArr = this.updatePolicy.premFactor.split(".");
          if (pfArr.length == 2 && typeof pfArr[1] !== 'undefined' && pfArr[1] != "") {
            if (pfArr[1].toString().length < 4) {
              var paddingZ = "";
              for (var i = 0; i < (4 - pfArr[1].toString().length); i++) {
                paddingZ += "0";
              }
              pfArr[1] = pfArr[1].toString() + paddingZ;
              this.updatePolicy.premFactor = pfArr[0].toString() + "." + pfArr[1].toString();
            }
          }
          else if (pfArr.length == 1 && typeof pfArr[0] !== 'undefined' && pfArr[0] != "") {
            this.updatePolicy.premFactor = pfArr[0].toString() + ".0000";
          }
        }
      }
    }

  }

  changeFormateNetRate(typeForm) {
    if (typeForm == 1) {
      if (this.rateForm.value.net_rate != undefined && this.rateForm.value.net_rate !== null && this.rateForm.value.net_rate != "" && !isNaN(this.rateForm.value.net_rate.replace(/,/g, ''))) {
        var netRate = parseFloat(this.rateForm.value.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");

        this.rateForm.controls['net_rate'].setValue(figure);
      }
    } else if (typeForm == 2) {
      if (this.renewRateForm.value.net_rate != undefined && this.renewRateForm.value.net_rate !== null && this.renewRateForm.value.net_rate != "" && !isNaN(this.renewRateForm.value.net_rate.replace(/,/g, ''))) {
        var netRate = parseFloat(this.renewRateForm.value.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");

        this.renewRateForm.controls['net_rate'].setValue(figure);
      }
    } else if (typeForm == 3) {
      if (this.rateENDForm.value.net_rate != undefined && this.rateENDForm.value.net_rate !== null && this.rateENDForm.value.net_rate != "" && !isNaN(this.rateENDForm.value.net_rate.replace(/,/g, ''))) {
        var netRate = parseFloat(this.rateENDForm.value.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");

        this.rateENDForm.controls['net_rate'].setValue(figure);
      }
    } else if (typeForm == 4) {
      if (this.rateNRCForm.value.net_rate != undefined && this.rateNRCForm.value.net_rate !== null && this.rateNRCForm.value.net_rate != "" && !isNaN(this.rateNRCForm.value.net_rate.replace(/,/g, ''))) {
        var netRate = parseFloat(this.rateNRCForm.value.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");

        this.rateNRCForm.controls['net_rate'].setValue(figure);
      }
    }

    return false;
  }

  assignNetRate() {
    if (this.rateForm.value.state_rate != undefined && this.rateForm.value.state_rate !== null)
    {
      if (this.rateForm.value.state_rate != "" && !isNaN(this.rateForm.value.state_rate.replace(/,/g, '')))
      {
        var stateRate = parseFloat(this.rateForm.value.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = stateRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");

        this.rateForm.controls['state_rate'].setValue(figure);

        if (this.newPolicyForm.value.premFactor != "" && this.newPolicyForm.value.premFactor != undefined && this.newPolicyForm.value.premFactor !== null && !isNaN(this.newPolicyForm.value.premFactor))
        {
          var netRate = ((this.newPolicyForm.value.premFactor * parseFloat(stateRate)) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

          var parts = netRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
  
          this.rateForm.controls['net_rate'].setValue(figure);
        }
      }
    }
  }

  assignNetRen() {
    // console.log(this.renewRateForm.value);
    if (this.renewRateForm.value.state_rate != undefined && this.renewRateForm.value.state_rate !== null) {
      // if (!isNaN(this.renewRateForm.value.state_rate) && !isNaN(this.renewPolicyForm.value.premFactor)) {
      //   this.renewRateForm.controls['net_rate'].setValue(((this.renewPolicyForm.value.premFactor * this.renewRateForm.value.state_rate) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      // }
      if (this.renewRateForm.value.state_rate != "" && !isNaN(this.renewRateForm.value.state_rate.replace(/,/g, ''))) {
        var stateRate = parseFloat(this.renewRateForm.value.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = stateRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        
        this.renewRateForm.controls['state_rate'].setValue(figure);

        if (this.renewPolicyForm.value.premFactor != "" && this.renewPolicyForm.value.premFactor != undefined && this.renewPolicyForm.value.premFactor !== null && !isNaN(this.renewPolicyForm.value.premFactor)) {
          var netRate = ((this.renewPolicyForm.value.premFactor * parseFloat(stateRate) ) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

          var parts = netRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");

          this.renewRateForm.controls['net_rate'].setValue(figure);
        }
      }
    }
  }

  assignNetNRC() {
    // console.log(this.rateNRCForm.value);
    if (this.rateNRCForm.value.state_rate != undefined && this.rateNRCForm.value.state_rate !== null) {
      // if (!isNaN(this.rateNRCForm.value.state_rate) && !isNaN(this.nrcPolicyForm.value.premFactor)) {
      //   this.rateNRCForm.controls['net_rate'].setValue(((this.nrcPolicyForm.value.premFactor * this.rateNRCForm.value.state_rate) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      // }
      if (this.rateNRCForm.value.state_rate != "" && !isNaN(this.rateNRCForm.value.state_rate.replace(/,/g, ''))) {
        var stateRate = parseFloat(this.rateNRCForm.value.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = stateRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        
        this.rateNRCForm.controls['state_rate'].setValue(figure);

        if (this.nrcPolicyForm.value.premFactor != "" && this.nrcPolicyForm.value.premFactor != undefined && this.nrcPolicyForm.value.premFactor !== null && !isNaN(this.nrcPolicyForm.value.premFactor)) {
          var netRate = ((this.nrcPolicyForm.value.premFactor * parseFloat(stateRate) ) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

          var parts = netRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          
          this.rateNRCForm.controls['net_rate'].setValue(figure);
        }
      }
    }
  }

  assigneNetEnd() {
    // console.log(this.rateENDForm.value);
    if (this.rateENDForm.value.state_rate != undefined && this.rateENDForm.value.state_rate !== null) {
      // if (!isNaN(this.rateENDForm.value.state_rate) && !isNaN(this.endPolicyForm.value.premFactor)) {
      //   this.rateENDForm.controls['net_rate'].setValue(((this.endPolicyForm.value.premFactor * this.rateENDForm.value.state_rate) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      // }
      if (this.rateENDForm.value.state_rate != "" && !isNaN(this.rateENDForm.value.state_rate.replace(/,/g, ''))) {
        var stateRate = parseFloat(this.rateENDForm.value.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

        var parts = stateRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        
        this.rateENDForm.controls['state_rate'].setValue(figure);

        if (this.endPolicyForm.value.premFactor != "" && this.endPolicyForm.value.premFactor != undefined && this.endPolicyForm.value.premFactor !== null && !isNaN(this.endPolicyForm.value.premFactor)) {
          var netRate = ((this.endPolicyForm.value.premFactor * parseFloat(stateRate)  ) / 100).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];

          var parts = netRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");

          this.rateENDForm.controls['net_rate'].setValue(figure);
        }
      }
    }
  }

  deleteRate(rateId) {
    for (var i = 0; i < this.rates.length; i++) {
      var obj = this.rates[i];

      if (rateId == obj.id) {
        this.rates.splice(i, 1);
      }
    }

    return false;
  }

  addRate(newLocInfo) {
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    this.policy_rate_1_error = 'none';
    var rateValdtnFailed = false;
    if (!this.rateForm.valid) {
      // this.setMessage('error', 'Please fill the rate details.', '');
      rateValdtnFailed = true;
      if (this.rateForm.value.location_name == '' || this.rateForm.value.location_name == null) {
        this.location_name_error = 'block';
      }
      if (this.rateForm.value.state_id == '' || this.rateForm.value.state_id == null) {
        this.state_id_error = 'block';
      }
      if (this.rateForm.value.class_code == '' || this.rateForm.value.class_code == null) {
        this.class_code_error = 'block';
      }
      if (this.rateForm.value.class_code_description == '' || this.rateForm.value.class_code_description == null) {
        this.class_code_description_error = 'block';
      }
      if (this.rateForm.value.state_rate == '' || this.rateForm.value.state_rate == null) {
        // this.state_rate_error = 'block';
      }
      if (this.rateForm.value.net_rate == '' || this.rateForm.value.net_rate == null) {
        this.net_rate_error = 'block';
      }
    }

    if (!rateValdtnFailed) {
      this.newRatesCount = this.newRatesCount + 1;
      var newRate1: any = {};
      newRate1.id = this.newRatesCount;

      newRate1.location_name = newLocInfo.location_name;
      newRate1.state_id = newLocInfo.state_id;
      newRate1.class_code_id = 0;
      newRate1.class_code = {};
      newRate1.class_code.state_id = newLocInfo.state_id;
      newRate1.class_code.class_code = newLocInfo.class_code;
      newRate1.class_code.class_code_description = newLocInfo.class_code_description;

      if( newLocInfo.state_rate != undefined && newLocInfo.state_rate != null && newLocInfo.state_rate.trim() != "" )
      {
        newRate1.state_rate = parseFloat(newLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      }
      else
      {
        newRate1.state_rate = '';
      }

      newRate1.net_rate = parseFloat(newLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

      this.rates.push(newRate1);

      this.rateForm.reset();
    }
  }

  addRateRenewal(newLocInfo) {
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    this.policy_rate_1_error = 'none';
    var rateValdtnFailed = false;
    if (!this.renewRateForm.valid) {
      // this.setMessage('error', 'Please fill the rate details.', '');
      rateValdtnFailed = true;
      if (this.renewRateForm.value.location_name == '' || this.renewRateForm.value.location_name == null) {
        this.location_name_error = 'block';
      }
      if (this.renewRateForm.value.state_id == '' || this.renewRateForm.value.state_id == null) {
        // this.state_id_error = 'block';
      }
      if (this.renewRateForm.value.class_code == '' || this.renewRateForm.value.class_code == null) {
        this.class_code_error = 'block';
      }
      if (this.renewRateForm.value.class_code_description == '' || this.renewRateForm.value.class_code_description == null) {
        this.class_code_description_error = 'block';
      }
      if (this.renewRateForm.value.state_rate == '' || this.renewRateForm.value.state_rate == null) {
        // this.state_rate_error = 'block';
      }
      if (this.renewRateForm.value.net_rate == '' || this.renewRateForm.value.net_rate == null) {
        this.net_rate_error = 'block';
      }
    }

    if (!rateValdtnFailed) {
      this.newRatesCount = this.newRatesCount + 1;
      var newRate1: any = {};
      newRate1.id = this.newRatesCount;

      newRate1.location_name = newLocInfo.location_name;
      newRate1.state_id = newLocInfo.state_id;
      newRate1.class_code_id = 0;
      newRate1.class_code = {};
      newRate1.class_code.state_id = newLocInfo.state_id;
      newRate1.class_code.class_code = newLocInfo.class_code;
      newRate1.class_code.class_code_description = newLocInfo.class_code_description;
      // newRate1.state_rate = newLocInfo.state_rate;
      // newRate1.net_rate = newLocInfo.net_rate;

      if( newLocInfo.state_rate != undefined && newLocInfo.state_rate != null && newLocInfo.state_rate.trim() != "" )
      {
        newRate1.state_rate = parseFloat(newLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      }
      else
      {
        newRate1.state_rate = '';
      }

      newRate1.net_rate = parseFloat(newLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

      this.rates.push(newRate1);

      this.renewRateForm.reset();
    }
  }

  addRateNRC(newLocInfo) {
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    this.policy_rate_1_error = 'none';
    var rateValdtnFailed = false;
    if (!this.rateNRCForm.valid) {
      //  this.setMessage('error', 'Please fill the rate details.', '');
      rateValdtnFailed = true;
      if (this.rateNRCForm.value.location_name == '' || this.rateNRCForm.value.location_name == null) {
        this.location_name_error = 'block';
      }
      if (this.rateNRCForm.value.state_id == '' || this.rateNRCForm.value.state_id == null) {
        // this.state_id_error = 'block';
      }
      if (this.rateNRCForm.value.class_code == '' || this.rateNRCForm.value.class_code == null) {
        this.class_code_error = 'block';
      }
      if (this.rateNRCForm.value.class_code_description == '' || this.rateNRCForm.value.class_code_description == null) {
        this.class_code_description_error = 'block';
      }
      if (this.rateNRCForm.value.state_rate == '' || this.rateNRCForm.value.state_rate == null) {
        // this.state_rate_error = 'block';
      }
      if (this.rateNRCForm.value.net_rate == '' || this.rateNRCForm.value.net_rate == null) {
        this.net_rate_error = 'block';
      }
    }

    if (!rateValdtnFailed) {
      this.nccRatesCount = this.nccRatesCount + 1;
      var newRate1: any = {};
      newRate1.id = this.nccRatesCount;

      newRate1.location_name = newLocInfo.location_name;
      newRate1.state_id = newLocInfo.state_id;
      newRate1.class_code_id = 0;
      newRate1.class_code = {};
      newRate1.class_code.state_id = newLocInfo.state_id;
      newRate1.class_code.class_code = newLocInfo.class_code;
      newRate1.class_code.class_code_description = newLocInfo.class_code_description;
      // newRate1.state_rate = newLocInfo.state_rate;
      // newRate1.net_rate = newLocInfo.net_rate;

      if( newLocInfo.state_rate != undefined && newLocInfo.state_rate != null && newLocInfo.state_rate.trim() != "" )
      {
        newRate1.state_rate = parseFloat(newLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      }
      else
      {
        newRate1.state_rate = '';
      }

      newRate1.net_rate = parseFloat(newLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

      this.nrcRates.push(newRate1);

      this.rateNRCForm.reset();
    }
  }

  addRateEND(newLocInfo) {
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    this.policy_rate_1_error = 'none';
    var rateValdtnFailed = false;
    if (!this.rateENDForm.valid) {
      // this.setMessage('error', 'Please fill the rate details.', '');
      rateValdtnFailed = true;
      if (this.rateENDForm.value.location_name == '' || this.rateENDForm.value.location_name == null) {
        this.location_name_error = 'block';
      }
      if (this.rateENDForm.value.state_id == '' || this.rateENDForm.value.state_id == null) {
        this.state_id_error = 'block';
      }
      if (this.rateENDForm.value.class_code == '' || this.rateENDForm.value.class_code == null) {
        this.class_code_error = 'block';
      }
      if (this.rateENDForm.value.class_code_description == '' || this.rateENDForm.value.class_code_description == null) {
        this.class_code_description_error = 'block';
      }
      if (this.rateENDForm.value.state_rate == '' || this.rateENDForm.value.state_rate == null) {
        // this.state_rate_error = 'block';
      }
      if (this.rateENDForm.value.net_rate == '' || this.rateENDForm.value.net_rate == null) {
        this.net_rate_error = 'block';
      }
    }

    if (!rateValdtnFailed) {
      this.nccRatesCount = this.nccRatesCount + 1;
      var newRate1: any = {};
      newRate1.id = this.nccRatesCount;

      newRate1.location_name = newLocInfo.location_name;
      newRate1.state_id = newLocInfo.state_id;
      newRate1.class_code_id = 0;
      newRate1.class_code = {};
      newRate1.class_code.state_id = newLocInfo.state_id;
      newRate1.class_code.class_code = newLocInfo.class_code;
      newRate1.class_code.class_code_description = newLocInfo.class_code_description;
      // newRate1.state_rate = newLocInfo.state_rate;
      // newRate1.net_rate = newLocInfo.net_rate;

      if( newLocInfo.state_rate != undefined && newLocInfo.state_rate != null && newLocInfo.state_rate.trim() != "" )
      {
        newRate1.state_rate = parseFloat(newLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      }
      else
      {
        newRate1.state_rate = '';
      }

      newRate1.net_rate = parseFloat(newLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

      this.nrcRates.push(newRate1);

      this.rateENDForm.reset();
    }
  }

  deleteRateNRC(rateId) {
    for (var i = 0; i < this.nrcRates.length; i++) {
      var obj = this.nrcRates[i];

      if (rateId == obj.id) {
        this.nrcRates.splice(i, 1);
      }
    }
  }

  updRateNRC(rateId) {
    for (var i = 0; i < this.nrcRates.length; i++) {
      var obj = this.nrcRates[i];

      if (rateId == obj.id) {
        this.rateNRCForm.controls['id'].setValue(obj.id);
        this.rateNRCForm.controls['location_name'].setValue(obj.location_name);
        this.rateNRCForm.controls['state_id'].setValue(obj.state_id);
        this.rateNRCForm.controls['class_code'].setValue(obj.class_code.class_code);
        this.rateNRCForm.controls['class_code_description'].setValue(obj.class_code.class_code_description);
        // this.rateNRCForm.controls['state_rate'].setValue(obj.state_rate);
        // this.rateNRCForm.controls['net_rate'].setValue(obj.net_rate);

        this.rateNRCForm.controls['state_rate'].setValue('');
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate != "" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.rateNRCForm.controls['state_rate'].setValue(figure);
        }
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate == "0.0000" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.rateNRCForm.controls['state_rate'].setValue(figure);
        }

        var netRate = parseFloat(obj.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        this.rateNRCForm.controls['net_rate'].setValue(figure);

        this.nrc_btn_add = 'none';
        this.nrc_btn_upd = 'block';
        var pickerLocInputs:any = null;
        pickerLocInputs = document.querySelectorAll('[id="nrcLocNameInp"]');
        if( pickerLocInputs != undefined && pickerLocInputs != null )
        {
            for( var el = 0;el < pickerLocInputs.length;el++ )
            {
                if( pickerLocInputs[el] )
                {
                    pickerLocInputs[el].focus();
                }
            }
        }
      }
    }

    // console.log(this.nrcRates);
  }

  changeNRC(updLocInfo) {
    // console.log(updLocInfo);
    var rateId = updLocInfo.id;
    var ratesWereChanged = false;
    var currRateCopyObj:any={};  

    for (var i = 0; i < this.nrcRates.length; i++) {
      var obj = this.nrcRates[i];

      if (rateId == obj.id) {
        obj.location_name = updLocInfo.location_name;
        obj.state_id = updLocInfo.state_id;
        obj.class_code.class_code = updLocInfo.class_code;
        obj.class_code.class_code_description = updLocInfo.class_code_description;
        // obj.state_rate = updLocInfo.state_rate;
        // obj.net_rate = updLocInfo.net_rate;

        ratesWereChanged = true;
        currRateCopyObj.id = obj.id;
        currRateCopyObj.state_id = updLocInfo.state_id;
        currRateCopyObj.class_code = updLocInfo.class_code;
        currRateCopyObj.class_code_description = updLocInfo.class_code_description;
        currRateCopyObj.state_rate = '';
        if(  updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          currRateCopyObj.state_rate = updLocInfo.state_rate;
        }
        currRateCopyObj.net_rate = updLocInfo.net_rate;
        
        obj.state_rate = '';
        if( updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          obj.state_rate = parseFloat(updLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
        }

        obj.net_rate = parseFloat(updLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

        this.nrcRates[i] = obj;
        this.rateNRCForm.reset();
        this.nrc_btn_upd = 'none';
        this.nrc_btn_add = 'block';
        break;
      }
    }

    // console.log(ratesWereChanged);
    if( ratesWereChanged )
    {
        this.offerRatesCopy( currRateCopyObj,1 );
    }
  }

  updRateNew(rateId) {
    for (var i = 0; i < this.rates.length; i++) {
      var obj = this.rates[i];

      if (rateId == obj.id) {
        this.rateForm.controls['id'].setValue(obj.id);
        this.rateForm.controls['location_name'].setValue(obj.location_name);
        this.rateForm.controls['state_id'].setValue(obj.state_id);
        this.rateForm.controls['class_code'].setValue(obj.class_code.class_code);
        this.rateForm.controls['class_code_description'].setValue(obj.class_code.class_code_description);

        this.rateForm.controls['state_rate'].setValue('');
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate != "" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.rateForm.controls['state_rate'].setValue(figure);
        }

        var netRate = parseFloat(obj.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        this.rateForm.controls['net_rate'].setValue(figure);

        this.nrc_btn_add = 'none';
        this.nrc_btn_upd = 'block';
        var pickerLocInputs:any = null;
        pickerLocInputs = document.querySelectorAll('[id="newLocNameInp"]');
        if( pickerLocInputs != undefined && pickerLocInputs != null )
        {
            for( var el = 0;el < pickerLocInputs.length;el++ )
            {
                if( pickerLocInputs[el] )
                {
                    pickerLocInputs[el].focus();
                }
            }
        }
      }
    }
  }

  changeNew(updLocInfo) {
    var rateId = updLocInfo.id;
    var ratesWereChanged = false;
    var currRateCopyObj:any={};  

    for (var i = 0; i < this.rates.length; i++) {
      var obj = this.rates[i];

      if (rateId == obj.id) {
        obj.location_name = updLocInfo.location_name;
        obj.state_id = updLocInfo.state_id;
        obj.class_code.class_code = updLocInfo.class_code;
        obj.class_code.class_code_description = updLocInfo.class_code_description;

        ratesWereChanged = true;
        currRateCopyObj.id = obj.id;
        currRateCopyObj.state_id = updLocInfo.state_id;
        currRateCopyObj.class_code = updLocInfo.class_code;
        currRateCopyObj.class_code_description = updLocInfo.class_code_description;
        currRateCopyObj.state_rate = '';
        if(  updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          currRateCopyObj.state_rate = updLocInfo.state_rate;
        }
        currRateCopyObj.net_rate = updLocInfo.net_rate;
        
        obj.state_rate = '';
        if( updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          obj.state_rate = parseFloat(updLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
        }

        obj.net_rate = parseFloat(updLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

        this.rates[i] = obj;
        this.rateForm.reset();
        this.nrc_btn_upd = 'none';
        this.nrc_btn_add = 'block';
        break;
      }
    }
  
    // console.log(ratesWereChanged);
    if( ratesWereChanged )
    {
        this.offerRatesCopy( currRateCopyObj,0 );
    }
}

  updRateRenewal(rateId) {
    for (var i = 0; i < this.rates.length; i++) {
      var obj = this.rates[i];

      if (rateId == obj.id) {
        this.renewRateForm.controls['id'].setValue(obj.id);
        this.renewRateForm.controls['location_name'].setValue(obj.location_name);
        this.renewRateForm.controls['state_id'].setValue(obj.state_id);
        this.renewRateForm.controls['class_code'].setValue(obj.class_code.class_code);
        this.renewRateForm.controls['class_code_description'].setValue(obj.class_code.class_code_description);
        // this.renewRateForm.controls['state_rate'].setValue(obj.state_rate);
        // this.renewRateForm.controls['net_rate'].setValue(obj.net_rate);

        this.renewRateForm.controls['state_rate'].setValue('');
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate != "" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.renewRateForm.controls['state_rate'].setValue(figure);
        }
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate == "0.0000" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.renewRateForm.controls['state_rate'].setValue(figure);
        }

        var netRate = parseFloat(obj.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        this.renewRateForm.controls['net_rate'].setValue(figure);

        this.nrc_btn_add = 'none';
        this.nrc_btn_upd = 'block';
      }
    }
  }

  changeRenewal(updLocInfo) {
    var rateId = updLocInfo.id;
    var ratesWereChanged = false;
    var currRateCopyObj:any={};  

    for (var i = 0; i < this.rates.length; i++) {
      var obj = this.rates[i];

      if (rateId == obj.id) {
        obj.location_name = updLocInfo.location_name;
        obj.state_id = updLocInfo.state_id;
        obj.class_code.class_code = updLocInfo.class_code;
        obj.class_code.class_code_description = updLocInfo.class_code_description;
        // obj.state_rate = updLocInfo.state_rate;
        // obj.net_rate = updLocInfo.net_rate;

        ratesWereChanged = true;
        currRateCopyObj.id = obj.id;
        currRateCopyObj.state_id = updLocInfo.state_id;
        currRateCopyObj.class_code = updLocInfo.class_code;
        currRateCopyObj.class_code_description = updLocInfo.class_code_description;
        currRateCopyObj.state_rate = '';
        if( updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          currRateCopyObj.state_rate = updLocInfo.state_rate;
        }
        currRateCopyObj.net_rate = updLocInfo.net_rate;

        obj.state_rate = '';
        if( updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          obj.state_rate = parseFloat(updLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
        }

        obj.net_rate = parseFloat(updLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

        this.rates[i] = obj;
        this.renewRateForm.reset();
        this.nrc_btn_upd = 'none';
        this.nrc_btn_add = 'block';
        break;
      }
    }

    if( ratesWereChanged )
    {
        this.offerRatesCopy( currRateCopyObj,0 );
    }
  }

  updRateEND(rateId) {
    for (var i = 0; i < this.nrcRates.length; i++) {
      var obj = this.nrcRates[i];

      if (rateId == obj.id) {
        this.rateENDForm.controls['id'].setValue(obj.id);
        this.rateENDForm.controls['location_name'].setValue(obj.location_name);
        this.rateENDForm.controls['state_id'].setValue(obj.state_id);
        this.rateENDForm.controls['class_code'].setValue(obj.class_code.class_code);
        this.rateENDForm.controls['class_code_description'].setValue(obj.class_code.class_code_description);
        // this.rateENDForm.controls['state_rate'].setValue(obj.state_rate);
        // this.rateENDForm.controls['net_rate'].setValue(obj.net_rate);

        this.rateENDForm.controls['state_rate'].setValue('');
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate != "" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.rateENDForm.controls['state_rate'].setValue(figure);
        }
        if( obj.state_rate != undefined && obj.state_rate != null && obj.state_rate == "0.0000" )
        {
          var stateRate = parseFloat(obj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
          var parts = stateRate.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var figure = parts.join(".");
          this.rateENDForm.controls['state_rate'].setValue(figure);
        }

        var netRate = parseFloat(obj.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]).toFixed(4).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
        var parts = netRate.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        var figure = parts.join(".");
        this.rateENDForm.controls['net_rate'].setValue(figure);

        this.end_btn_add = 'none';
        this.end_btn_upd = 'block';

        var pickerLocInputs:any = null;
        pickerLocInputs = document.querySelectorAll('[id="endLocNameInp"]');
        if( pickerLocInputs != undefined && pickerLocInputs != null )
        {
            for( var el = 0;el < pickerLocInputs.length;el++ )
            {
                if( pickerLocInputs[el] )
                {
                    pickerLocInputs[el].focus();
                }
            }
        }
        }
    }

    // console.log(this.nrcRates);
  }

  changeEND(updLocInfo) {
    // console.log(updLocInfo);
    var rateId = updLocInfo.id;
    var ratesWereChanged = false;
    var currRateCopyObj:any={};  

    for (var i = 0; i < this.nrcRates.length; i++) {
      var obj = this.nrcRates[i];

      if (rateId == obj.id) {
        obj.location_name = updLocInfo.location_name;
        obj.state_id = updLocInfo.state_id;
        obj.class_code.class_code = updLocInfo.class_code;
        obj.class_code.class_code_description = updLocInfo.class_code_description;
        // obj.state_rate = updLocInfo.state_rate;
        // obj.net_rate = updLocInfo.net_rate;

        ratesWereChanged = true;
        currRateCopyObj.id = obj.id;
        currRateCopyObj.state_id = updLocInfo.state_id;
        currRateCopyObj.class_code = updLocInfo.class_code;
        currRateCopyObj.class_code_description = updLocInfo.class_code_description;
        currRateCopyObj.state_rate = '';
        if(  updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          currRateCopyObj.state_rate = updLocInfo.state_rate;
        }
        currRateCopyObj.net_rate = updLocInfo.net_rate;

        obj.state_rate = '';
        if( updLocInfo.state_rate != undefined && updLocInfo.state_rate != null && updLocInfo.state_rate.trim() != "" )
        {
          obj.state_rate = parseFloat(updLocInfo.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
        }

        obj.net_rate = parseFloat(updLocInfo.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);

        this.nrcRates[i] = obj;
        this.rateENDForm.reset();
        this.end_btn_upd = 'none';
        this.end_btn_add = 'block';
        break;
      }
    }

    // console.log(ratesWereChanged);
    if( ratesWereChanged )
    {
        this.offerRatesCopy( currRateCopyObj,1 );
    }
  }

  offerRatesCopy( currRateCopyObj:any={},ratesType:number = -1 )
  {
      var askConfirmation = false;
      var goAheadCopy = false;
      if( ratesType == 1 )
      {
        for( var i = 0; i < this.nrcRates.length; i++ )
        {
          var obj = this.nrcRates[i];
          if( (obj.id != currRateCopyObj.id) && ((obj.state_id == currRateCopyObj.state_id) && (obj.class_code.class_code == currRateCopyObj.class_code) && (obj.class_code.class_code_description == currRateCopyObj.class_code_description)) )
          {
            askConfirmation = true;
            break;
          }
        }
      }
      else if( ratesType == 0 )
      {
        for( var i = 0; i < this.rates.length; i++ )
        {
          var obj = this.rates[i];
          if( (obj.id != currRateCopyObj.id) && ((obj.state_id == currRateCopyObj.state_id) && (obj.class_code.class_code == currRateCopyObj.class_code) && (obj.class_code.class_code_description == currRateCopyObj.class_code_description)) )
          {
            askConfirmation = true;
            break;
          }
        }
      }

      if( askConfirmation )
      {
        this.confirmationService.confirm
        ({
          message: 'Do you want to copy rates ?',
          accept: () =>
          {
                var cCnt = 0;
                if( ratesType == 1 )
                {
                  for( var i = 0; i < this.nrcRates.length; i++ )
                  {
                    var obj = this.nrcRates[i];
                    // console.log(obj);
                    // console.log(currRateCopyObj);
                    if( (obj.id != currRateCopyObj.id) && ((obj.state_id == currRateCopyObj.state_id) && (obj.class_code.class_code == currRateCopyObj.class_code) && (obj.class_code.class_code_description == currRateCopyObj.class_code_description)) )
                    {
                      if( currRateCopyObj.state_rate != undefined && currRateCopyObj.state_rate != null && currRateCopyObj.state_rate.trim() != "" )
                      {
                        obj.state_rate = parseFloat(currRateCopyObj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
                      }
                      obj.net_rate = parseFloat(currRateCopyObj.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
                      this.nrcRates[i] = obj;
                      cCnt++;
                    }
                  }
                }
                else if( ratesType == 0 )
                {
                  for( var i = 0; i < this.rates.length; i++ )
                  {
                    var obj = this.rates[i];
                    // console.log(obj);
                    // console.log(currRateCopyObj);
                    if( (obj.id != currRateCopyObj.id) && ((obj.state_id == currRateCopyObj.state_id) && (obj.class_code.class_code == currRateCopyObj.class_code) && (obj.class_code.class_code_description == currRateCopyObj.class_code_description)) )
                    {
                      if( currRateCopyObj.state_rate != undefined && currRateCopyObj.state_rate != null && currRateCopyObj.state_rate.trim() != "" )
                      {
                        obj.state_rate = parseFloat(currRateCopyObj.state_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
                      }
                      obj.net_rate = parseFloat(currRateCopyObj.net_rate.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,4})?/)[0]);
                      this.rates[i] = obj;
                      cCnt++;
                    }
                  }
                }
                console.log(cCnt);
          },
          reject: () =>
          {
          }
        });
      }
  }

  showNewPopUp()
  {
    this.newPolicyForm.reset();

    this.msgs = [];

    this.newPolicy = {};
    this.newPolicy.carrier_id = 0;
    this.newPolicy.policy_number = '';
    this.newPolicy.effective_date = '';
    this.newPolicy.end_date = '';

    this.newPolicy.rpm_admin_fee = '';

    this.newPolicy.estimatedPremium = '';
    this.newPolicy.downPayment = '';
    this.newPolicy.adjustedPrem = '';
    this.newPolicy.manualPrem = '';
    this.newPolicy.premFactor = '';

    if( this.clNewCarrier != undefined && this.clNewCarrier != null && this.clNewCarrier.id != undefined && this.clNewCarrier.id != null )
    {
      this.newPolicyForm.controls['carrier_id'].setValue(this.clNewCarrier.id);
      this.newPolicy.carrier_id = this.clNewCarrier.id;
    }

    this.newPolicy.rates = [];
    this.rates = [];

    var pickedPolForNew = null;
    var pickedForNew = false;

    if (this.policies != undefined && this.policies !== null)
    {
      for( let policy of this.policies )
      {
        if( policy != undefined && policy !== null )
        {
          var goAheadPick = true;
          if( ! pickedForNew && goAheadPick )
          {
            pickedForNew = true;
            pickedPolForNew = JSON.parse(JSON.stringify(policy));
          }
        }
      }
    }
    
    if( pickedForNew && pickedPolForNew != undefined && pickedPolForNew != null && pickedPolForNew.nrc_rates != undefined && pickedPolForNew.nrc_rates != null )
    {
      for( let currNewRate of pickedPolForNew.nrc_rates )
      {
        this.newRatesCount = this.newRatesCount + 1;
        currNewRate.id = this.newRatesCount;
        if( currNewRate != undefined && currNewRate !== null )
        {
          if( currNewRate.state_rate != undefined && currNewRate.state_rate !== null )
          {
            currNewRate.state_rate = 0;
          }
          if( currNewRate.net_rate != undefined && currNewRate.net_rate !== null )
          {
            currNewRate.net_rate = 0;
          }
        }
      }
      this.rates = JSON.parse(JSON.stringify(pickedPolForNew.nrc_rates));
    }
  
    this.nrc_btn_add = '';
    this.nrc_btn_upd = 'none';

    setTimeout(() => {
      this.newPolBtn = false;
    }, 500);
  
    
    this.pol_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';

    this.showPolicy = true;
  }

  hideRenewPopUp()
  {
    this.nrc_btn_upd = 'none';
    this.nrc_btn_add = '';
    this.renPolBtn = true;
  }

  showRenewPopUp()
  {
    this.renewPolicyForm.reset();

    this.msgs = [];

    this.renewPolicy = {};
    this.renewPolicy.carrier_id = 0;
    this.renewPolicy.policy_number = '';
    this.renewPolicy.rpm_admin_fee = '';
    this.renewPolicy.effective_date = '';
    this.renewPolicy.end_date = '';

    this.renewPolicy.estimatedPremium = '';
    this.renewPolicy.downPayment = '';
    this.renewPolicy.adjustedPrem = '';
    this.renewPolicy.manualPrem = '';
    this.renewPolicy.premFactor = '';

    this.renewPolicy.rates = [];
    this.rates = [];

    if (this.policies != undefined && this.policies != null && this.policies.length && typeof this.policies[0] !== 'undefined')
    {
      var renPol = this.policies[0];

      this.renewPolicyForm.controls['carrier_id'].setValue(renPol.carrier_id);
      this.renewPolicyForm.controls['policy_number'].setValue(renPol.policy_number);

      this.renewPolicyForm.controls['rpm_admin_fee'].setValue(renPol.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR));

      var dtArrr = [];

      if ( renPol.pol_eff_date_change != undefined && renPol.pol_eff_date_change != null)
      {
        dtArrr = renPol.pol_eff_date_change.toString().split("-");
        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
        {
          var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(polActionDispDt);
        }
      }
    
      if (renPol.is_reinstated != undefined && renPol.is_reinstated != null && renPol.is_reinstated == 1 && renPol.rein_eff_date_change != undefined && renPol.rein_eff_date_change != null)
      {
        dtArrr = renPol.rein_eff_date_change.toString().split("-");
        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
        {
          var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(polActionDispDt);
        }
      }
      else if (renPol.is_cancelled != undefined && renPol.is_cancelled != null && renPol.is_cancelled == 1 && renPol.canc_eff_date_change != undefined && renPol.canc_eff_date_change != null)
      {
        dtArrr = renPol.canc_eff_date_change.toString().split("-");
        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
        {
          var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(polActionDispDt);
        }
      }
      else if (renPol.nrc_rates != undefined && renPol.nrc_rates != null && renPol.nrc_rates.length && typeof renPol.nrc_rates[renPol.nrc_rates.length - 1] !== 'undefined' && renPol.nrc_rates[renPol.nrc_rates.length - 1].nrc_eff_date_change != undefined && renPol.nrc_rates[renPol.nrc_rates.length - 1].nrc_eff_date_change != null)
      {
        dtArrr = renPol.nrc_rates[renPol.nrc_rates.length - 1].nrc_eff_date_change.toString().split("-");
        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
        {
          var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(polActionDispDt);
        }
      }
      else if (renPol.end_eff_date_change != undefined && renPol.end_eff_date_change != null)
      {
        dtArrr = renPol.end_eff_date_change.toString().split("-");
        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
        {
          var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(polActionDispDt);
        }
      }


      dtArrr = renPol.effective_date.toString().split("-");
      if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
      {
        var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
        this.renewPolicyForm.controls['effective_date'].setValue(polActionDispDt);
      }
    
      dtArrr = renPol.end_date.toString().split("-");
      if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
      {
        var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
        this.renewPolicyForm.controls['end_date'].setValue(polActionDispDt);
      }

      this.renewPolicy.estimatedPremium = '';
      this.renewPolicy.downPayment = '';
      this.renewPolicy.adjustedPrem = '';
      this.renewPolicy.manualPrem = '';
      this.renewPolicy.premFactor = '';

      // this.renewPolicyForm.controls['estimatedPremium'].setValue(renPol.estimatedPremium);
      // this.renewPolicyForm.controls['downPayment'].setValue(renPol.downPayment);
      // this.renewPolicyForm.controls['adjustedPrem'].setValue(renPol.adjustedPrem);
      // this.renewPolicyForm.controls['manualPrem'].setValue(renPol.manualPrem);
      // this.renewPolicyForm.controls['premFactor'].setValue(renPol.premFactor);

      if (renPol.nrc_rates != undefined && renPol.nrc_rates != null && renPol.nrc_rates)
      {
        this.rates = JSON.parse(JSON.stringify(renPol.nrc_rates));
      }

      for( var i = 0; i < this.rates.length; i++ )
      {
        var obj = this.rates[i];

        if( obj != undefined && obj != null && obj.state_rate != undefined )
        {
          obj.state_rate = "0.0000";
        }
        if( obj != undefined && obj != null && obj.net_rate != undefined )
        {
          obj.net_rate = "0.0000";
        }
      }
    }

    this.renewRateForm.reset();

    this.nrc_btn_add = '';
    this.nrc_btn_upd = 'none';
    setTimeout(() => {
      this.renPolBtn = false;
    }, 500);
    

    this.pol_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';

    this.showRenewal = true;
  }

  showNrcPopUp(policy_id: number)
  {
    this.msgs = [];

    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && policy.showThisPolicy != undefined && policy.showThisPolicy !== null && policy_id != undefined && policy_id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            policy.showThisPolicy = false;
            if (policy_id == policy.id) {
              policy.showThisPolicy = true;

              this.activePolicy = policy;
              policy.isActivePolicy = true;

              this.nrcRates = [];
              if (policy.nrc_rates != undefined && policy.nrc_rates != null && policy.nrc_rates.length) {
                this.nrcRates = JSON.parse(JSON.stringify(policy.nrc_rates));
              }
            }
          }
        }
      }
    }


    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.carrier != undefined )
    {
      this.nrcPolicyForm.controls['carrier_id'].setValue(this.activePolicy.policy_latest_action.carrier.id);
    }
    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.policy_number != undefined )
    {
      this.nrcPolicyForm.controls['policy_number'].setValue(this.activePolicy.policy_latest_action.policy_number);
    }

    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_effective_date != undefined && this.activePolicy.policy_latest_action.action_effective_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_effective_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.nrcPolicyForm.controls['effective_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }
    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_end_date != undefined && this.activePolicy.policy_latest_action.action_end_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_end_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.nrcPolicyForm.controls['end_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }
    
    this.nrcPolicyForm.controls['estimatedPremium'].setValue(this.activePolicy.estimatedPremium);
    this.nrcPolicyForm.controls['downPayment'].setValue(this.activePolicy.downPayment);
    this.nrcPolicyForm.controls['adjustedPrem'].setValue(this.activePolicy.adjustedPrem);
    this.nrcPolicyForm.controls['manualPrem'].setValue(this.activePolicy.manualPrem);
    this.nrcPolicyForm.controls['premFactor'].setValue(this.activePolicy.premFactor);

    this.rateNRCForm.reset();

    this.nrc_btn_add = '';
    this.nrc_btn_upd = 'none';
    setTimeout(() => {
      this.nrcPolBtn = false;
    }, 500);
    

    this.nrc_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';

    this.showNRC = true;
  }

  showEndPopUp(policy_id: number)
  {
    this.msgs = [];

    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && policy.showThisPolicy != undefined && policy.showThisPolicy !== null && policy_id != undefined && policy_id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            policy.showThisPolicy = false;
            if (policy_id == policy.id) {
              policy.showThisPolicy = true;

              this.activePolicy = policy;
              policy.isActivePolicy = true;

              this.nrcRates = [];
              if (policy.nrc_rates != undefined && policy.nrc_rates != null && policy.nrc_rates.length) {
                this.nrcRates = JSON.parse(JSON.stringify(policy.nrc_rates));
              }
            }
          }
        }
      }
    }


    if( this.activePolicy != undefined && this.activePolicy.actions_eff_date_change != undefined && this.activePolicy.actions_eff_date_change[0] != undefined && this.activePolicy.actions_eff_date_change[0].rpm_admin_fee != undefined )
    {
      this.endPolicyForm.controls['rpm_admin_fee'].setValue(this.activePolicy.actions_eff_date_change[0].rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR));
    }

    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.carrier != undefined )
    {
      this.endPolicyForm.controls['carrier_id'].setValue(this.activePolicy.policy_latest_action.carrier.id);
    }
    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.policy_number != undefined )
    {
      this.endPolicyForm.controls['policy_number'].setValue(this.activePolicy.policy_latest_action.policy_number);
    }

    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_effective_date != undefined && this.activePolicy.policy_latest_action.action_effective_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_effective_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.endPolicyForm.controls['effective_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }
    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_end_date != undefined && this.activePolicy.policy_latest_action.action_end_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_end_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.endPolicyForm.controls['end_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }

    this.updatePolicy.estimatedPremium = '';
    this.updatePolicy.downPayment = '';
    this.updatePolicy.adjustedPrem = '';
    this.updatePolicy.manualPrem = '';
    this.updatePolicy.premFactor = '';

    this.endPolicyForm.controls['estimatedPremium'].setValue(this.activePolicy.estimatedPremium);
    this.endPolicyForm.controls['downPayment'].setValue(this.activePolicy.downPayment);
    this.endPolicyForm.controls['adjustedPrem'].setValue(this.activePolicy.adjustedPrem);
    this.endPolicyForm.controls['manualPrem'].setValue(this.activePolicy.manualPrem);
    this.endPolicyForm.controls['premFactor'].setValue(this.activePolicy.premFactor);

    this.rateENDForm.reset();

    this.end_btn_add = '';
    this.end_btn_upd = 'none';
    setTimeout(() => {
      this.endPolBtn = false;
    }, 500);
    
    this.end_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';

    this.showEndorsement = true;
  }

  deletePolicyFromList(policy_id: number){
    this.spinnerService.show();
    this.policyService.dependencyOnPolicy(policy_id).subscribe(
        response => {
        if(response.message =='invoiceareexists'){
          this.spinnerService.hide();
          setTimeout(() =>
          {
            alert('Cannot delete this policy as it contains invoices.');         
          }, 50);
        }else{
          this.spinnerService.hide();
          this.confirmationService.confirm
          ({
            message: 'Are you sure you want to delete the Policy?',
            accept: () => {
              this.spinnerService.show();
              this.policyDeletion = true;
    
              this.doPolicyAction(policy_id);
            },
            reject: () => {
              this.policyDeletion = false;
            }
          });
        }
      },
      error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
      });
  }
  doPolicyAction(policy_id: number){
    this.policyActObj = {};
    this.policyActObj.policyId = policy_id;
    this.policyService.deletePolicy(policy_id).subscribe( 
      response => {
       console.log(response);
       if(response.message =='Policy deleted successfully'){
          alert('Policy deleted successfully');
          this.reloadPolicies();
       }
      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
      });
   
  }
 
  showCancPopUp(policy_id: number)
  {
    this.msgs = [];

    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && policy.showThisPolicy != undefined && policy.showThisPolicy !== null && policy_id != undefined && policy_id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            policy.showThisPolicy = false; 
            if (policy_id == policy.id) {
              policy.showThisPolicy = true;

              this.activePolicy = policy;
              policy.isActivePolicy = true;

              this.nrcRates = [];
              if (policy.nrc_rates != undefined && policy.nrc_rates != null && policy.nrc_rates.length) {
                this.nrcRates = JSON.parse(JSON.stringify(policy.nrc_rates));
              }
            }
          }
        }
      }
    }

    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.carrier != undefined )
    {
      this.cancPolicyForm.controls['carrier_id'].setValue(this.activePolicy.policy_latest_action.carrier.id);
    }
    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.policy_number != undefined )
    {
      this.cancPolicyForm.controls['policy_number'].setValue(this.activePolicy.policy_latest_action.policy_number);
    }

    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_effective_date != undefined && this.activePolicy.policy_latest_action.action_effective_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_effective_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.cancPolicyForm.controls['effective_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }
    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_end_date != undefined && this.activePolicy.policy_latest_action.action_end_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_end_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.cancPolicyForm.controls['end_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }

    setTimeout(() => {
      this.cancSubmitted = false;
    }, 500);


    this.canc_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';

    this.showCancellation = true;
  }

  showReinPopUp(policy_id: number)
  {
    this.msgs = [];

    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && policy.showThisPolicy != undefined && policy.showThisPolicy !== null && policy_id != undefined && policy_id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            policy.showThisPolicy = false;
            if (policy_id == policy.id) {
              policy.showThisPolicy = true;

              this.activePolicy = policy;
              policy.isActivePolicy = true;

              this.nrcRates = [];
              if (policy.nrc_rates != undefined && policy.nrc_rates != null && policy.nrc_rates.length) {
                this.nrcRates = JSON.parse(JSON.stringify(policy.nrc_rates));
              }
            }
          }
        }
      }
    }

    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.carrier != undefined )
    {
      this.reinPolicyForm.controls['carrier_id'].setValue(this.activePolicy.policy_latest_action.carrier.id);
    }
    if( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.policy_number != undefined )
    {
      this.reinPolicyForm.controls['policy_number'].setValue(this.activePolicy.policy_latest_action.policy_number);
    }

    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_effective_date != undefined && this.activePolicy.policy_latest_action.action_effective_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_effective_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.reinPolicyForm.controls['effective_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }
    if ( this.activePolicy != undefined && this.activePolicy.policy_latest_action != undefined && this.activePolicy.policy_latest_action.action_end_date != undefined && this.activePolicy.policy_latest_action.action_end_date != null)
    {
      var dtArr = this.activePolicy.policy_latest_action.action_end_date.toString().split("-");
      if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
      {
        this.reinPolicyForm.controls['end_date'].setValue(dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0]);
      }
    }
    

    if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.ori_end_date != undefined && this.activePolicy.ori_end_date != null)
    {
      var dtArr1 = this.activePolicy.ori_end_date.toString().split("-");
      if (typeof dtArr1[0] !== 'undefined' && typeof dtArr1[1] !== 'undefined' && typeof dtArr1[2] !== 'undefined')
      {
        this.reinPolicyForm.controls['end_date'].setValue(dtArr1[1] + '/' + dtArr1[2] + '/' + dtArr1[0]);
      }
    }

    setTimeout(() => {
      this.reinSubmitted = false;
    }, 500);
    

    this.rein_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';

    this.showReinstatement = true;
  }

  openClosePolicy(id: number, openClose: number) {
    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && policy.showThisPolicy != undefined && policy.showThisPolicy !== null && id != undefined && id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            policy.showThisPolicy = false;
            if (openClose == 1) {
              policy.showPolicyLogs = false;
            }
            if (id == policy.id) {
              if (openClose == 1) {
                policy.showThisPolicy = true;
                policy.policyLogTieUp = true;
                policy.showPolicyLogs = true;
              }
              else if (openClose == 2) {
                policy.showThisPolicy = false;
                policy.policyLogTieUp = false;
                policy.showPolicyLogs = false;
              }
            }
          }
        }
      }
    }
  }

  moveUpDown( sourceIndex:number,targetIndex:number,actionType:number )
  {
    var rateArr = [];
    if( actionType == 1 )
    {
      rateArr = this.rates;
    }
    if( actionType == 2 )
    {
      rateArr = this.nrcRates;
    }
    if( actionType == 3 )
    {
      rateArr = this.nrcRates;
    }
    if( actionType == 4 )
    {
      rateArr = this.rates;
    }

    var backupObj = rateArr[targetIndex];

    rateArr[targetIndex] = rateArr[sourceIndex];
    rateArr[sourceIndex] = backupObj;
  }

  validatedateInv(inputText)
  {
    var isDateValid = true;
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if(inputText.match(dateformat))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var opera2 = inputText.split('-');
  var lopera1 = opera1.length;
  var lopera2 = opera2.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  else if (lopera2>1)
  {
  var pdate = inputText.split('-');
  }
  var mm  = parseInt(pdate[0]);
  var dd = parseInt(pdate[1]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  isDateValid = false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
    isDateValid = false;
  }
  if ((lyear==true) && (dd>29))
  {
    isDateValid = false;
  }
  }
  }
  else
  {
    isDateValid = false;
  }

  return isDateValid;
  }
  
  appendYr( eleId )
  {
      var isValidDate = true;
      var checkingDt = this.dtValStr;
      var dtGoAhead = false;

      if( checkingDt.length == 4 && ! checkingDt.includes('/') )
      {
        checkingDt = checkingDt.substring(0,2) + "/" + checkingDt.substring(2,4) + "/";
        if( this.validatedateInv(checkingDt) )
        {
          this.dtValStr = checkingDt;
          dtGoAhead = true;
        } 
      }
      else if( checkingDt.length == 5 )
      {
        var dtArr = this.dtValStr.split("/");
        if( dtArr != undefined && dtArr != null && typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' )
        {
          checkingDt += "/";
          if( this.validatedateInv(checkingDt) )
          {
            this.dtValStr += "/";
            dtGoAhead = true;
          }
        }
      }

      if( dtGoAhead )
      {
        if( eleId == "newPolEfDtChng" )
        {
          this.efDtChngeInv = true;
          setTimeout(() =>
          {
            this.newPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.efDtChngeInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "newPolEffDt" )
        {
          this.polEfDtInv = true;
          setTimeout(() =>
          {
            this.newPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.polEfDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "newPolEndDt" )
        {
          this.endDtInv = true;
          setTimeout(() =>
          {
            this.newPolicyForm.controls['end_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.endDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "endChgEffDt" )
        {
          this.endChngeInv = true;
          setTimeout(() =>
          {
            this.endPolicyForm.controls['end_eff_date_change'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.endChngeInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "endEffDt" )
        {
          this.endEfDtInv = true;
          setTimeout(() =>
          {
            this.endPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.endEfDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "endFinalDt" )
        {
          this.endfinalDtInv = true;
          setTimeout(() =>
          {
            this.endPolicyForm.controls['end_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.endfinalDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "nrcChgEffDt" )
        {
          this.nrcChngeInv = true;
          setTimeout(() =>
          {
            this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.nrcChngeInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "nrcEffDt" )
        {
          this.nrcEfDtInv = true;
          setTimeout(() =>
          {
            this.nrcPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.nrcEfDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "nrcFinalDt" )
        {
          this.nrcfinalDtInv = true;
          setTimeout(() =>
          {
            this.nrcPolicyForm.controls['end_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.nrcfinalDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "cancChgEffDt" )
        {
          this.cancChngeInv = true;
          setTimeout(() =>
          {
            this.cancPolicyForm.controls['canc_eff_date_change'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.cancChngeInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "cancEffDt" )
        {
          this.cancEfDtInv = true;
          setTimeout(() =>
          {
            this.cancPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.cancEfDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "cancFinalDt" )
        {
          this.cancfinalDtInv = true;
          setTimeout(() =>
          {
            this.cancPolicyForm.controls['end_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.cancfinalDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "reinChgEffDt" )
        {
          this.reinChngeInv = true;
          setTimeout(() =>
          {
            this.reinPolicyForm.controls['rein_eff_date_change'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.reinChngeInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "reinEffDt" )
        {
          this.reinEfDtInv = true;
          setTimeout(() =>
          {
            this.reinPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.reinEfDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "reinFinalDt" )
        {
          this.reinfinalDtInv = true;
          setTimeout(() =>
          {
            this.reinPolicyForm.controls['end_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.reinfinalDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "renewChngDt" )
        {
          this.renewChngeInv = true;
          setTimeout(() =>
          {
            this.renewPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.renewChngeInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "renewEffDt" )
        {
          this.renewEfDtInv = true;
          setTimeout(() =>
          {
            this.renewPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.renewEfDtInv = false;
            }, 50);
          }, 100);
        }
        else if( eleId == "renewFinalDt" )
        {
          this.renewfinalDtInv = true;
          setTimeout(() =>
          {
            this.renewPolicyForm.controls['end_date'].setValue(this.dtValStr);
            setTimeout(() =>
            {
              this.renewfinalDtInv = false;
            }, 50);
          }, 100);
        }
      }
  }

  fillDtTab( eleId )
  {
    var goFillField = false;
    var bkDtStr = "";

    var chkTempStr = this.dtValStr;
    if( chkTempStr.length == 8 && chkTempStr.includes("/") && chkTempStr.charAt(2)=="/" && chkTempStr.charAt(5)=="/" )
    {
        var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
            if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[2].length == 2 )
              {
                var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                if( this.validatedateInv(checkingDt1) )
                {
                  this.dtValStr = checkingDt1;
                  bkDtStr = checkingDt1;
                  goFillField = true;
                }
              }
            }
        }
    }
    
    if( ! goFillField && chkTempStr != "" )
    {
	      var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
          if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[0] != "" && tempStrArr[1] != "" && tempStrArr[2] != "" )
              {
                if( tempStrArr[0].length == 1 )
                {
                  tempStrArr[0] = "0" + tempStrArr[0];
                }
                if( tempStrArr[1].length == 1 )
                {
                  tempStrArr[1] = "0" + tempStrArr[1];
                }
                
                if( tempStrArr[2].length == 2 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    bkDtStr = checkingDt1;
                    goFillField = true;
                  }
                }
                else if( tempStrArr[2].length == 4 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    bkDtStr = checkingDt1;
                    goFillField = true;
                  }
                }
              }
            }
        }
    }


    if( goFillField && bkDtStr != undefined && bkDtStr != null && bkDtStr !="" )
    {
      if( eleId == "newPolEfDtChng" )
      {
        this.efDtChngeInv = false;
        setTimeout(() =>
        {
          this.newPolicyForm.controls['pol_eff_date_change'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "newPolEffDt" )
      {
        this.polEfDtInv = false;
        setTimeout(() =>
        {
          this.newPolicyForm.controls['effective_date'].setValue(bkDtStr);
          this.fillEndDate( this.newPolicyForm );
        }, 50);
      }
      else if( eleId == "newPolEndDt" )
      {
        this.endDtInv = false;
        setTimeout(() =>
        {
          this.newPolicyForm.controls['end_date'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "endChgEffDt" )
      {
        this.endChngeInv = false;
        setTimeout(() =>
        {
          this.endPolicyForm.controls['end_eff_date_change'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "endEffDt" )
      {
        this.endEfDtInv = false;
        setTimeout(() =>
        {
          this.endPolicyForm.controls['effective_date'].setValue(bkDtStr);
          this.fillEndDate( this.endPolicyForm );
        }, 50);
      }
      else if( eleId == "endFinalDt" )
      {
        this.endfinalDtInv = false;
        setTimeout(() =>
        {
          this.endPolicyForm.controls['end_date'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "nrcChgEffDt" )
      {
        this.nrcChngeInv = false;
        setTimeout(() =>
        {
          this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "nrcEffDt" )
      {
        this.nrcEfDtInv = false;
        setTimeout(() =>
        {
          this.nrcPolicyForm.controls['effective_date'].setValue(bkDtStr);
          this.fillEndDate( this.nrcPolicyForm );
        }, 50);
      }
      else if( eleId == "nrcFinalDt" )
      {
        this.nrcfinalDtInv = false;
        setTimeout(() =>
        {
          this.nrcPolicyForm.controls['end_date'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "cancChgEffDt" )
      {
        this.cancChngeInv = false;
        setTimeout(() =>
        {
          this.cancPolicyForm.controls['canc_eff_date_change'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "cancEffDt" )
      {
        this.cancEfDtInv = false;
        setTimeout(() =>
        {
          this.cancPolicyForm.controls['effective_date'].setValue(bkDtStr);
          this.fillEndDate( this.cancPolicyForm );
        }, 50);
      }
      else if( eleId == "cancFinalDt" )
      {
        this.cancfinalDtInv = false;
        setTimeout(() =>
        {
          this.cancPolicyForm.controls['end_date'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "reinChgEffDt" )
      {
        this.reinChngeInv = false;
        setTimeout(() =>
        {
          this.reinPolicyForm.controls['rein_eff_date_change'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "reinEffDt" )
      {
        this.reinEfDtInv = false;
        setTimeout(() =>
        {
          this.reinPolicyForm.controls['effective_date'].setValue(bkDtStr);
          this.fillEndDate( this.reinPolicyForm );
        }, 50);
      }
      else if( eleId == "reinFinalDt" )
      {
        this.reinfinalDtInv = false;
        setTimeout(() =>
        {
          this.reinPolicyForm.controls['end_date'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "renewChngDt" )
      {
        this.renewChngeInv = false;
        setTimeout(() =>
        {
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(bkDtStr);
        }, 50);
      }
      else if( eleId == "renewEffDt" )
      {
        this.renewEfDtInv = false;
        setTimeout(() =>
        {
          this.renewPolicyForm.controls['effective_date'].setValue(bkDtStr);
          this.fillEndDate( this.renewPolicyForm );
        }, 50);
      }
      else if( eleId == "renewFinalDt" )
      {
        this.renewfinalDtInv = false;
        setTimeout(() =>
        {
          this.renewPolicyForm.controls['end_date'].setValue(bkDtStr);
        }, 50);
      }
    }
  }

  outOfDtField( event,eleId )
  {
    this.fillDtTab( eleId );

    if( eleId == "newPolEfDtChng" )
    {
      this.efDtChngeInv = false;
      setTimeout(() =>
      {
        if( ! this.efDtChngeInv &&  this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.newPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "newPolEffDt" )
    {
      this.polEfDtInv = false;
      setTimeout(() =>
      {
        if(! this.polEfDtInv &&  this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.newPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          this.fillEndDate( this.newPolicyForm );
        }
      }, 15);
    }
    else if( eleId == "newPolEndDt" )
    {
      this.endDtInv = false;

      setTimeout(() =>
      {
        if(! this.endDtInv &&  this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.newPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "endChgEffDt" )
    {
      this.endChngeInv = false;
      setTimeout(() =>
      {
        if(! this.endChngeInv &&  this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.endPolicyForm.controls['end_eff_date_change'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "endEffDt" )
    {
      this.endEfDtInv = false;
      setTimeout(() =>
      {
        if(! this.endEfDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.endPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          this.fillEndDate( this.endPolicyForm );
        }
      }, 15);
    }
    else if( eleId == "endFinalDt" )
    {
      this.endfinalDtInv = false;
      setTimeout(() =>
      {
        if(! this.endfinalDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.endPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "nrcChgEffDt" )
    {
      this.nrcChngeInv = false;
      setTimeout(() =>
      {
        if(! this.nrcChngeInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "nrcEffDt" )
    {
      this.nrcEfDtInv = false;
      setTimeout(() =>
      {
        if(! this.nrcEfDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.nrcPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          this.fillEndDate( this.nrcPolicyForm );
        }
      }, 15);
    }
    else if( eleId == "nrcFinalDt" )
    {
      this.nrcfinalDtInv = false;
      setTimeout(() =>
      {
        if(! this.nrcfinalDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.nrcPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "cancChgEffDt" )
    {
      this.cancChngeInv = false;
      setTimeout(() =>
      {
        if(! this.cancChngeInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.cancPolicyForm.controls['canc_eff_date_change'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "cancEffDt" )
    {
      this.cancEfDtInv = false;
      setTimeout(() =>
      {
        if(! this.cancEfDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.cancPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          this.fillEndDate( this.cancPolicyForm );
        }
      }, 15);
    }
    else if( eleId == "cancFinalDt" )
    {
      this.cancfinalDtInv = false;
      setTimeout(() =>
      {
        if(! this.cancfinalDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.cancPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "reinChgEffDt" )
    {
      this.reinChngeInv = false;
      setTimeout(() =>
      {
        if(! this.reinChngeInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.reinPolicyForm.controls['rein_eff_date_change'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "reinEffDt" )
    {
      this.reinEfDtInv = false;
      setTimeout(() =>
      {
        if(! this.reinEfDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.reinPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          this.fillEndDate( this.reinPolicyForm );
        }
      }, 15);
    }
    else if( eleId == "reinFinalDt" )
    {
      this.reinfinalDtInv = false;
      setTimeout(() =>
      {
        if(! this.reinfinalDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.reinPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "renewChngDt" )
    {
      this.renewChngeInv = false;
      setTimeout(() =>
      {
        if(! this.renewChngeInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
        }
      }, 15);
    }
    else if( eleId == "renewEffDt" )
    {
      this.renewEfDtInv = false;
      setTimeout(() =>
      {
        if(! this.renewEfDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.renewPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          this.fillEndDate( this.renewPolicyForm );
        }
      }, 15);
    }
    else if( eleId == "renewFinalDt" )
    {
      this.renewfinalDtInv = false;
      setTimeout(() =>
      {
        if(! this.renewfinalDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.renewPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }
      }, 15);
    }
  }

  completeMonth( eleId )
  {
      var tempStr = this.dtValStr; 
      var compMGo = false;
      if( tempStr.length == 1 && (parseInt(tempStr) > 1 && parseInt(tempStr) < 10) )
      {
        this.dtValStr = "0" + this.dtValStr + "/";
        compMGo = true;
      }
      else if( tempStr.length == 2 && (parseInt(tempStr) >= 1 && parseInt(tempStr) <= 12) )
      {
        this.dtValStr = this.dtValStr + "/";
        compMGo = true;
      }

      if( eleId == "newPolEfDtChng" && compMGo )
      {
        this.efDtChngeInv = true;
        setTimeout(() =>
        {
          this.newPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "newPolEffDt" && compMGo )
      {
        this.polEfDtInv = true;
        setTimeout(() =>
        {
          this.newPolicyForm.controls['effective_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "newPolEndDt" && compMGo )
      {
        this.endDtInv = true;
        setTimeout(() =>
        {
          this.newPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "endChgEffDt" && compMGo )
      {
        this.endChngeInv = true;
        setTimeout(() =>
        {
          this.endPolicyForm.controls['end_eff_date_change'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "endEffDt" && compMGo )
      {
        this.endEfDtInv = true;
        setTimeout(() =>
        {
          this.endPolicyForm.controls['effective_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "endFinalDt" && compMGo )
      {
        this.endfinalDtInv = true;
        setTimeout(() =>
        {
          this.endPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "nrcChgEffDt" && compMGo )
      {
        this.nrcChngeInv = true;
        setTimeout(() =>
        {
          this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "nrcEffDt" && compMGo )
      {
        this.nrcEfDtInv = true;
        setTimeout(() =>
        {
          this.nrcPolicyForm.controls['effective_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "nrcFinalDt" && compMGo )
      {
        this.nrcfinalDtInv = true;
        setTimeout(() =>
        {
          this.nrcPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "cancChgEffDt" && compMGo )
      {
        this.cancChngeInv = true;
        setTimeout(() =>
        {
          this.cancPolicyForm.controls['canc_eff_date_change'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "cancEffDt" && compMGo )
      {
        this.cancEfDtInv = true;
        setTimeout(() =>
        {
          this.cancPolicyForm.controls['effective_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "cancFinalDt" && compMGo )
      {
        this.cancfinalDtInv = true;
        setTimeout(() =>
        {
          this.cancPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "reinChgEffDt" && compMGo )
      {
        this.reinChngeInv = true;
        setTimeout(() =>
        {
          this.reinPolicyForm.controls['rein_eff_date_change'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "reinEffDt" && compMGo )
      {
        this.reinEfDtInv = true;
        setTimeout(() =>
        {
          this.reinPolicyForm.controls['effective_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "reinFinalDt" && compMGo )
      {
        this.reinfinalDtInv = true;
        setTimeout(() =>
        {
          this.reinPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "renewChngDt" && compMGo )
      {
        this.renewChngeInv = true;
        setTimeout(() =>
        {
          this.renewPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "renewEffDt" && compMGo )
      {
        this.renewEfDtInv = true;
        setTimeout(() =>
        {
          this.renewPolicyForm.controls['effective_date'].setValue(this.dtValStr);
        }, 100);
      }
      else if( eleId == "renewFinalDt" && compMGo )
      {
        this.renewfinalDtInv = true;
        setTimeout(() =>
        {
          this.renewPolicyForm.controls['end_date'].setValue(this.dtValStr);
        }, 100);
      }
  }

  completeDate( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 1  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" && parseInt(tempStr) >= 4 && parseInt(tempStr) < 10 )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + "0" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "newPolEfDtChng" )
          {
            this.efDtChngeInv = true;
            setTimeout(() =>
            {
              this.newPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "newPolEffDt" )
          {
            this.polEfDtInv = true;
            setTimeout(() =>
            {
              this.newPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "newPolEndDt" )
          {
            this.endDtInv = true;
            setTimeout(() =>
            {
              this.newPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "endChgEffDt" )
          {
            this.endChngeInv = true;
            setTimeout(() =>
            {
              this.endPolicyForm.controls['end_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "endEffDt" )
          {
            this.endEfDtInv = true;
            setTimeout(() =>
            {
              this.endPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "endFinalDt" )
          {
            this.endfinalDtInv = true;
            setTimeout(() =>
            {
              this.endPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "nrcChgEffDt" )
          {
            this.nrcChngeInv = true;
            setTimeout(() =>
            {
              this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "nrcEffDt" )
          {
            this.nrcEfDtInv = true;
            setTimeout(() =>
            {
              this.nrcPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "nrcFinalDt" )
          {
            this.nrcfinalDtInv = true;
            setTimeout(() =>
            {
              this.nrcPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "cancChgEffDt" )
          {
            this.cancChngeInv = true;
            setTimeout(() =>
            {
              this.cancPolicyForm.controls['canc_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "cancEffDt" )
          {
            this.cancEfDtInv = true;
            setTimeout(() =>
            {
              this.cancPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "cancFinalDt" )
          {
            this.cancfinalDtInv = true;
            setTimeout(() =>
            {
              this.cancPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "reinChgEffDt" )
          {
            this.reinChngeInv = true;
            setTimeout(() =>
            {
              this.reinPolicyForm.controls['rein_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "reinEffDt" )
          {
            this.reinEfDtInv = true;
            setTimeout(() =>
            {
              this.reinPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "reinFinalDt" )
          {
            this.reinfinalDtInv = true;
            setTimeout(() =>
            {
              this.reinPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "renewChngDt" )
          {
            this.renewChngeInv = true;
            setTimeout(() =>
            {
              this.renewPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "renewEffDt" )
          {
            this.renewEfDtInv = true;
            setTimeout(() =>
            {
              this.renewPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "renewFinalDt" )
          {
            this.renewfinalDtInv = true;
            setTimeout(() =>
            {
              this.renewPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
        }
      }

  }

  completeDt1( eleId )
  {
      var tempStr = this.dtValStr;
      var tmpNumb = "";

      if( tempStr != undefined && tempStr != null && tempStr.length == 3 )
      {
        tmpNumb = tempStr.charAt(2);
      }

      if( tmpNumb != "" && parseInt(tmpNumb) >= 4 && parseInt(tmpNumb) < 10 )
      {
        var assignDtFill = tempStr.charAt(0) + tempStr.charAt(1) + "/" + "0" + tmpNumb + "/";
        this.dtValStr = assignDtFill;

        if( eleId == "newPolEfDtChng" )
        {
          this.efDtChngeInv = true;
          setTimeout(() =>
          {
            this.newPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "newPolEffDt" )
        {
          this.polEfDtInv = true;
          setTimeout(() =>
          {
            this.newPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "newPolEndDt" )
        {
          this.endDtInv = true;
          setTimeout(() =>
          {
            this.newPolicyForm.controls['end_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "endChgEffDt" )
        {
          this.endChngeInv = true;
          setTimeout(() =>
          {
            this.endPolicyForm.controls['end_eff_date_change'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "endEffDt" )
        {
          this.endEfDtInv = true;
          setTimeout(() =>
          {
            this.endPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "endFinalDt" )
        {
          this.endfinalDtInv = true;
          setTimeout(() =>
          {
            this.endPolicyForm.controls['end_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "nrcChgEffDt" )
        {
          this.nrcChngeInv = true;
          setTimeout(() =>
          {
            this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "nrcEffDt" )
        {
          this.nrcEfDtInv = true;
          setTimeout(() =>
          {
            this.nrcPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "nrcFinalDt" )
        {
          this.nrcfinalDtInv = true;
          setTimeout(() =>
          {
            this.nrcPolicyForm.controls['end_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "cancChgEffDt" )
        {
          this.cancChngeInv = true;
          setTimeout(() =>
          {
            this.cancPolicyForm.controls['canc_eff_date_change'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "cancEffDt" )
        {
          this.cancEfDtInv = true;
          setTimeout(() =>
          {
            this.cancPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "cancFinalDt" )
        {
          this.cancfinalDtInv = true;
          setTimeout(() =>
          {
            this.cancPolicyForm.controls['end_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "reinChgEffDt" )
        {
          this.reinChngeInv = true;
          setTimeout(() =>
          {
            this.reinPolicyForm.controls['rein_eff_date_change'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "reinEffDt" )
        {
          this.reinEfDtInv = true;
          setTimeout(() =>
          {
            this.reinPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "reinFinalDt" )
        {
          this.reinfinalDtInv = true;
          setTimeout(() =>
          {
            this.reinPolicyForm.controls['end_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "renewChngDt" )
        {
          this.renewChngeInv = true;
          setTimeout(() =>
          {
            this.renewPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "renewEffDt" )
        {
          this.renewEfDtInv = true;
          setTimeout(() =>
          {
            this.renewPolicyForm.controls['effective_date'].setValue(this.dtValStr);
          }, 100);
        }
        else if( eleId == "renewFinalDt" )
        {
          this.renewfinalDtInv = true;
          setTimeout(() =>
          {
            this.renewPolicyForm.controls['end_date'].setValue(this.dtValStr);
          }, 100);
        }
      }
  }

  completeDt2( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 2  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "newPolEfDtChng" )
          {
            this.efDtChngeInv = true;
            setTimeout(() =>
            {
              this.newPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "newPolEffDt" )
          {
            this.polEfDtInv = true;
            setTimeout(() =>
            {
              this.newPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "newPolEndDt" )
          {
            this.endDtInv = true;
            setTimeout(() =>
            {
              this.newPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "endChgEffDt" )
          {
            this.endChngeInv = true;
            setTimeout(() =>
            {
              this.endPolicyForm.controls['end_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "endEffDt" )
          {
            this.endEfDtInv = true;
            setTimeout(() =>
            {
              this.endPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "endFinalDt" )
          {
            this.endfinalDtInv = true;
            setTimeout(() =>
            {
              this.endPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "nrcChgEffDt" )
          {
            this.nrcChngeInv = true;
            setTimeout(() =>
            {
              this.nrcPolicyForm.controls['nrc_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "nrcEffDt" )
          {
            this.nrcEfDtInv = true;
            setTimeout(() =>
            {
              this.nrcPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "nrcFinalDt" )
          {
            this.nrcfinalDtInv = true;
            setTimeout(() =>
            {
              this.nrcPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "cancChgEffDt" )
          {
            this.cancChngeInv = true;
            setTimeout(() =>
            {
              this.cancPolicyForm.controls['canc_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "cancEffDt" )
          {
            this.cancEfDtInv = true;
            setTimeout(() =>
            {
              this.cancPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "cancFinalDt" )
          {
            this.cancfinalDtInv = true;
            setTimeout(() =>
            {
              this.cancPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "reinChgEffDt" )
          {
            this.reinChngeInv = true;
            setTimeout(() =>
            {
              this.reinPolicyForm.controls['rein_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "reinEffDt" )
          {
            this.reinEfDtInv = true;
            setTimeout(() =>
            {
              this.reinPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "reinFinalDt" )
          {
            this.reinfinalDtInv = true;
            setTimeout(() =>
            {
              this.reinPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "renewChngDt" )
          {
            this.renewChngeInv = true;
            setTimeout(() =>
            {
              this.renewPolicyForm.controls['pol_eff_date_change'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "renewEffDt" )
          {
            this.renewEfDtInv = true;
            setTimeout(() =>
            {
              this.renewPolicyForm.controls['effective_date'].setValue(this.dtValStr);
            }, 100);
          }
          else if( eleId == "renewFinalDt" )
          {
            this.renewfinalDtInv = true;
            setTimeout(() =>
            {
              this.renewPolicyForm.controls['end_date'].setValue(this.dtValStr);
            }, 100);
          }
        }
      }

  }
  
  setYear(ev)
  {
    var justBackSpaced = false;

    if( ev.inputType != undefined && ev.inputType != null )
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined  )
      {
        this.dtValStr = ev.srcElement.value;
      }
      if( ev.inputType.trim() == "deleteContentBackward" || ev.inputType.trim() == "deleteContentForward" )
      {
        this.dtValStr = ev.srcElement.value;
        justBackSpaced = true;
      }
    }
    else
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined )
      {
        if( ev.srcElement.value.length < this.chromePrevStr.length )
        {
          justBackSpaced = true;
        }
        this.dtValStr = ev.srcElement.value;
        this.chromePrevStr = ev.srcElement.value;
      }
    }

    if( ! justBackSpaced && (this.dtValStr.length == 4 && ! this.dtValStr.includes("/"))  )
    {
      // this.appendYr( ev.srcElement.id );
    }
    else if( ! justBackSpaced && (this.dtValStr.length == 1 || this.dtValStr.length == 2) )
    {
       // this.completeMonth( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 4 )
    {
       // this.completeDate( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 5 )
    {
       // this.completeDt2( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 3 && ! this.dtValStr.includes("/") )
    {
      // this.completeDt1( ev.srcElement.id );
    }
  }

  resetDtVal( dtFieldNum:number = 0 )
  {
    setTimeout(() =>
    {
      this.dtValStr = '';
    }, 50);
  }


  ngOnInit() {

    this.newPolicy = {};
    this.newPolicy.carrier_id = 0;
    this.newPolicy.policy_number = '';

    this.newPolicy.rpm_admin_fee = '';
    this.newPolicy.estimatedPremium = '';
    this.newPolicy.downPayment = '';
    this.newPolicy.adjustedPrem = '';
    this.newPolicy.manualPrem = '';
    this.newPolicy.premFactor = '';

    this.newPolicy.rates = [];

    this.spinnerService.show();

    this.newPolicyForm = this.fb.group({
      'carrier_id': new FormControl('', Validators.required),
      'policy_number': new FormControl('', Validators.required),
      'effective_date': new FormControl('', Validators.required),
      'end_date': new FormControl('', Validators.required),
      'estimatedPremium': new FormControl(''),
      'downPayment': new FormControl(''),
      'adjustedPrem': new FormControl(''),
      'manualPrem': new FormControl(''),
      'premFactor': new FormControl(''),
      'pol_eff_date_change': new FormControl('', Validators.required),
      'rpm_admin_fee': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required)
    });

    this.nrcPolicyForm = this.fb.group({
      'nrc_eff_date_change': new FormControl('', Validators.required),
      'carrier_id': new FormControl('', Validators.required),
      'policy_number': new FormControl('', Validators.required),
      'effective_date': new FormControl('', Validators.required),
      'end_date': new FormControl('', Validators.required),
      'estimatedPremium': new FormControl(''),
      'downPayment': new FormControl(''),
      'adjustedPrem': new FormControl(''),
      'manualPrem': new FormControl(''),
      'premFactor': new FormControl(''),
      'description': new FormControl('', Validators.required)
    });

    this.endPolicyForm = this.fb.group({
      'end_eff_date_change': new FormControl('', Validators.required),
      'carrier_id': new FormControl('', Validators.required),
      'policy_number': new FormControl('', Validators.required),
      'effective_date': new FormControl('', Validators.required),
      'end_date': new FormControl('', Validators.required),
      'estimatedPremium': new FormControl(''),
      'downPayment': new FormControl(''),
      'adjustedPrem': new FormControl(''),
      'manualPrem': new FormControl(''),
      'premFactor': new FormControl(''),
      'description': new FormControl('', Validators.required),
      'rpm_admin_fee': new FormControl('', Validators.required),
    });

    this.cancPolicyForm = this.fb.group({
      'canc_eff_date_change': new FormControl('', Validators.required),
      'carrier_id': new FormControl('', Validators.required),
      'policy_number': new FormControl('', Validators.required),
      'effective_date': new FormControl('', Validators.required),
      'end_date': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required)
    });

    this.reinPolicyForm = this.fb.group({
      'rein_eff_date_change': new FormControl('', Validators.required),
      'carrier_id': new FormControl('', Validators.required),
      'policy_number': new FormControl('', Validators.required),
      'effective_date': new FormControl('', Validators.required),
      'end_date': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required)
    });

    this.rateForm = this.fb.group({
      location_name: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      class_code: ['', [Validators.required]],
      class_code_description: ['', [Validators.required]],
      state_rate: ['', ''],
      net_rate: ['', [Validators.required]],
      id: ['', '']
    });

    this.rateNRCForm = this.fb.group({
      location_name: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      class_code: ['', [Validators.required]],
      class_code_description: ['', [Validators.required]],
      state_rate: ['', ''],
      net_rate: ['', [Validators.required]],
      id: ['', '']
    });

    this.rateENDForm = this.fb.group({
      location_name: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      class_code: ['', [Validators.required]],
      class_code_description: ['', [Validators.required]],
      state_rate: ['', ''],
      net_rate: ['', [Validators.required]],
      id: ['', '']
    });

    this.renewPolicyForm = this.fb.group({
      'carrier_id': new FormControl('', Validators.required),
      'policy_number': new FormControl('', Validators.required),
      'effective_date': new FormControl('', Validators.required),
      'end_date': new FormControl('', Validators.required),
      'estimatedPremium': new FormControl(''),
      'downPayment': new FormControl(''),
      'adjustedPrem': new FormControl(''),
      'manualPrem': new FormControl(''),
      'premFactor': new FormControl(''),
      'pol_eff_date_change': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'rpm_admin_fee': new FormControl('', Validators.required),
    });

    this.renewRateForm = this.fb.group({
      location_name: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      class_code: ['', [Validators.required]],
      class_code_description: ['', [Validators.required]],
      state_rate: ['', ''],
      net_rate: ['', [Validators.required]],
      id: ['', '']
    });

    this.reloadPolicies();

    this.clientService.getCarriers().subscribe(res => {
      this.carriers = res;
      this.carriers.unshift({ label: 'Select', value: '' });
    });

    this.clientService.getStates().subscribe(res => {
      this.states = res;
      this.states.unshift({ label: 'Select', value: '' });
    });

    this.clientService.getDispServerTime().subscribe(res =>
      {
        if( res != undefined && res != null && typeof res[0] !== 'undefined' )
        
        this.serverYr = res[0];
        // console.log(this.serverYr);
      });
  }

  changeDateFormate(oldDate)
  {
    if (typeof oldDate !== 'undefined' && oldDate !== '' && oldDate !== null)
    {
        var dtArrr = oldDate.toString().split("-");
        if (typeof dtArrr[0] !== 'undefined' && typeof dtArrr[1] !== 'undefined' && typeof dtArrr[2] !== 'undefined')
        {
          var polActionDispDt = dtArrr[1] + '/' + dtArrr[2] + '/' + dtArrr[0];
          return polActionDispDt;
        }
        else
        {
          return 'No date';
        }
    }
    else
    {
      return 'No date';
    }
  }

  reloadPolicies()
  {
    this.spinnerService.show();
    // Load client
    this.clientService.getClientPolicies(this.id).subscribe(res =>
      {
      this.client = res;
      this.policies = [];
      this.policies = res.policies;
      if( res.carrier_data != undefined && res.carrier_data != null )
      {
          this.clNewCarrier = res.carrier_data;
      }
      // alert();
      // console.log(this.policies[this.policies.length - 1]);
      this.activePolicy = null;

      // Load active policy
      this.loggedUserRole = localStorage.getItem('loggedUserRole');
      var currMonthPolFound = false;
      var currMonthPolicy = null;
      var polIndex = 0;
      for (let policy of this.policies)
      {
        if (policy != undefined && policy !== null)
        {
          //  console.log(policy);

          policy.isActivePolicy = false;
          policy.showThisPolicy = false;
          policy.showPolicyLogs = false;
          policy.policyLogTieUp = false;
          polIndex++;
          if (policy.effective_date != undefined && policy.effective_date != null)
          {
            var todayDateObj1 = new Date();
            var effDateObj = new Date(policy.effective_date);
            // if (todayDateObj1.getMonth() + 1 == effDateObj.getMonth() + 1) {
            if (polIndex == 1)
            {
              currMonthPolFound = true;

              if (currMonthPolicy != undefined && currMonthPolicy != null)
              {
                currMonthPolicy.showThisPolicy = false;
                currMonthPolicy.isActivePolicy = false;
                currMonthPolicy.showPolicyLogs = false;
                currMonthPolicy.policyLogTieUp = false;
              }
              currMonthPolicy = policy;

              policy.showThisPolicy = true;
              this.activePolicy = policy;
              policy.isActivePolicy = true;
              policy.showPolicyLogs = true;
              policy.policyLogTieUp = true;
            }
          }


          this.allRatesArr = [];

          if (policy.policyRatesArr != undefined && policy.policyRatesArr !== null)
          {
            for (let currRate of policy.policyRatesArr) {
              if (currRate != undefined && currRate !== null && currRate.rate_type != undefined && currRate.rate_type !== null && currRate.rate_type == 500 ) {
                if (typeof this.allRatesArr[polIndex - 1] === 'undefined') {
                  this.allRatesArr[polIndex - 1] = [];
                }
                if (typeof this.allRatesArr[polIndex - 1][0] === 'undefined') {
                  this.allRatesArr[polIndex - 1][0] = [];
                }
                this.allRatesArr[polIndex - 1][0].push(currRate);
              }
            }

            policy.nrc_rates = [];
            if (typeof this.allRatesArr[polIndex - 1] !== 'undefined' && typeof this.allRatesArr[polIndex - 1][0] !== 'undefined') {
              var nrcIndex = 0;
              policy.nrc_rates = this.allRatesArr[polIndex - 1][nrcIndex];
            }
          }

          if (policy.end_date != undefined && policy.end_date != null) {
            var todayDateObj = new Date();
            var endDateObj = new Date(policy.end_date);
            var endGrThanCurr = endDateObj.getTime() > todayDateObj.getTime();
            if (endGrThanCurr) {
              // this.allowNewPolicy = 'none';
            }
            else {
              // this.allowRenewalPol = 'none';
            }
          }

          policy.topDisplayYear = "";
          if (policy.effective_date != undefined && policy.effective_date != null)
          {
            var dtArr = policy.effective_date.toString().split("-");
            if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
            {
              policy.topDisplayYear = dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
            }
          }
          if (policy.end_date != undefined && policy.end_date != null)
          {
            var dtArr = policy.end_date.toString().split("-");
            if (typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' && typeof dtArr[2] !== 'undefined')
            {
              policy.topDisplayYear += " - " + dtArr[1] + '/' + dtArr[2] + '/' + dtArr[0];
            }
          }
        }
      }

      if (typeof this.policies !== 'undefined' && this.policies.length && typeof this.policies[this.policies.length - 1] !== 'undefined' && !currMonthPolFound) {
        this.policies[this.policies.length - 1].showThisPolicy = true;
        this.policies[this.policies.length - 1].showPolicyLogs = true;
        this.policies[this.policies.length - 1].policyLogTieUp = true;
        this.activePolicy = this.policies[this.policies.length - 1];
        this.policies[this.policies.length - 1].isActivePolicy = true;

        if( this.allRatesArr.length && typeof this.allRatesArr[this.policies.length - 1] !== 'undefined' && typeof this.allRatesArr[this.policies.length - 1][0] !== 'undefined' )
        {
          var nrcIndex = 0;
          this.nrcRates = this.allRatesArr[this.policies.length - 1][nrcIndex];

          this.nrcOriRates = JSON.parse(JSON.stringify(this.nrcRates));
        }
      }

      if( this.policies != undefined && this.policies !== null )
      {
          for( let policy of this.policies )
          {
            if( policy != undefined && policy !== null && policy.actions_eff_date_change != undefined && policy.actions_eff_date_change !== null )
            {
              for( let action of policy.actions_eff_date_change )
              {
                if( action != undefined && action !== null && action.id != undefined && action.id !== null )
                {
                  if( action.action_to_override == 1 )
                  {
                    this.actionToBeChecked = action.id;
                  }
                }
              }
            }
          }
      }

      this.spinnerService.hide();
      if (this.policies == undefined || this.policies == null || !this.policies.length) {
        this.displayMessage = 1;
      }
    });
  }

  validateForm() {
    Object.keys(this.newPolicyForm.controls).forEach(item => {
      if (this.newPolicyForm.controls[item].status === 'INVALID' && this.newPolicyForm.controls[item].touched === true) {
        this.setMessage('error', 'Validaion Error:', '');
      }
    });
  }

  submitPolicy(value)
  {
    var polValdtnFailed = false;
    this.pol_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    if (!this.newPolicyForm.valid) {
      polValdtnFailed = true;
      if (this.newPolicyForm.value.pol_eff_date_change == '' || this.newPolicyForm.value.pol_eff_date_change == null) {
        this.pol_eff_date_change_error = 'block';
      }
      if (this.newPolicyForm.value.rpm_admin_fee == '' || this.newPolicyForm.value.rpm_admin_fee == null) {
        this.rpm_admin_fee_error = 'block';
      }
      if (this.newPolicyForm.value.carrier_id == '' || this.newPolicyForm.value.carrier_id == null) {
        this.carrier_id_error = 'block';
      }
      if (this.newPolicyForm.value.policy_number == '' || this.newPolicyForm.value.policy_number == null) {
        this.policy_number_error = 'block';
      }
      if (this.newPolicyForm.value.effective_date == '' || this.newPolicyForm.value.effective_date == null) {
        this.effective_date_error = 'block';
      }
      if (this.newPolicyForm.value.end_date == '' || this.newPolicyForm.value.end_date == null) {
        this.end_date_error = 'block';
      }
      if (this.newPolicyForm.value.description == '' || this.newPolicyForm.value.description == null) {
        this.description_error = 'block';
      }
    }

    if (!polValdtnFailed) {
      if (!this.rates.length) {
        this.policy_rate_1_error = 'block';
        polValdtnFailed = true;
      }
    }

    if (!polValdtnFailed) {
      this.newSubmitted = true;
      this.newPolBtn = true;
      this.spinnerService.show();

      this.newPolicy = {};

      this.newPolicy.client_id = this.id;
      this.newPolicy.status = 'active';

      this.newPolicy.carrier_id = value.carrier_id;
      this.newPolicy.policy_number = value.policy_number;

      this.newPolicy.rpm_admin_fee = parseFloat(value.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);

      this.newPolicy.estimatedPremium = value.estimatedPremium;
      this.newPolicy.downPayment = value.downPayment;
      this.newPolicy.adjustedPrem = value.adjustedPrem;
      this.newPolicy.manualPrem = value.manualPrem;
      this.newPolicy.premFactor = value.premFactor;

      if (value.pol_eff_date_change != undefined && value.pol_eff_date_change != null && value.pol_eff_date_change != "")
      {
        if( this.newPolicyForm.controls['pol_eff_date_change'].touched === true )
        {
          this.newPolicy.pol_eff_date_change = value.pol_eff_date_change;
        }
        else
        {
          this.newPolicy.pol_eff_date_change = value.pol_eff_date_change;
        }
      }
      if (value.effective_date != undefined && value.effective_date != null && value.effective_date != "")
      {
        if( this.newPolicyForm.controls['effective_date'].touched === true )
        {
          this.newPolicy.effective_date = value.effective_date;
        }
        else
        {
          this.newPolicy.effective_date = value.effective_date;
        }
      }
      if (value.end_date != undefined && value.end_date != null && value.end_date != "")
      {
        if( this.newPolicyForm.controls['end_date'].touched === true )
        {
          this.newPolicy.end_date = value.end_date;
        }
        else
        {
          this.newPolicy.end_date = value.end_date;
        }
      }

      this.newPolicy.policy_log_desc = '';
      this.newPolicy.user_id = this.storage.get('app.auth').id;
      if (value.description != undefined && value.description != null && value.description.trim() != "") {
        this.newPolicy.policy_log_desc = value.description.trim();
      }


      this.newPolicy.rates = this.rates;

      this.savePolicy(false);
    }
  }

  savePolicy(refresh: boolean = false)
  {
    if (this.newPolicyForm.valid) {
      if (this.rates.length) {
        this.policyService.savePolicy(this.newPolicy).subscribe(
          response => {
            if (response instanceof HttpErrorResponse) {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              alert("Sorry, Policy Not Saved");
              this.newSubmitted = false;
              this.newPolBtn = false;
            } else {
              if( response.output==0 ){ 
                console.log(response);
                this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Policy is already created for this effective date.' }); 
               this.setMessage('error', 'Validaion Error:', 'Policy is already created for this effective date.');
              }else{
                // this.allowNewPolicy = 'none'; 
                
                this.showPolicy = false;
                this.newPolicy = {};
                this.newPolicyForm.reset();
                this.rates = [];
                // alert("Policy created successfully.");
                this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Policy saved successfully' });
                this.newSubmitted = false;
                this.newPolBtn = false;
  
                this.spinnerService.show();
                this.displayMessage = 0;
                this.reloadPolicies();  
              }
              
           
            }
          },
          error => {
            console.log(error);
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
          });
      }
      else {
        this.setMessage('error', 'Please enter policy rates.', '');
      }
    }
    else {
      this.setMessage('error', 'Please select carrier, enter policy number, dates and premuims.', '');
    }
  }


  renewCrPolicy(value) {
    var polValdtnFailed = false;
    this.pol_eff_date_change_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    if (!this.renewPolicyForm.valid) {
      polValdtnFailed = true;
      if (this.renewPolicyForm.value.pol_eff_date_change == '' || this.renewPolicyForm.value.pol_eff_date_change == null) {
        this.pol_eff_date_change_error = 'block';
      }
      if (this.renewPolicyForm.value.rpm_admin_fee == '' || this.renewPolicyForm.value.rpm_admin_fee == null)
  	  {
        this.rpm_admin_fee_error = 'block';
      }
      if (this.renewPolicyForm.value.carrier_id == '' || this.renewPolicyForm.value.carrier_id == null) {
        this.carrier_id_error = 'block';
      }
      if (this.renewPolicyForm.value.policy_number == '' || this.renewPolicyForm.value.policy_number == null) {
        this.policy_number_error = 'block';
      }
      if (this.renewPolicyForm.value.effective_date == '' || this.renewPolicyForm.value.effective_date == null) {
        this.effective_date_error = 'block';
      }
      if (this.renewPolicyForm.value.end_date == '' || this.renewPolicyForm.value.end_date == null) {
        this.end_date_error = 'block';
      }
      if (this.renewPolicyForm.value.description == '' || this.renewPolicyForm.value.description == null) {
        this.description_error = 'block';
      }
    }

    if (!polValdtnFailed) {
      if (!this.rates.length) {
        this.policy_rate_1_error = 'block';
        polValdtnFailed = true;
      }
    }

    if (!polValdtnFailed) {
      this.renewSubmitted = true;

      this.renewPolicy = {};

      this.renewPolicy.client_id = this.id;
      this.renewPolicy.status = 'active';

      this.renewPolicy.carrier_id = value.carrier_id;
      this.renewPolicy.policy_number = value.policy_number;

      this.renewPolicy.rpm_admin_fee = 0;
      if (value.rpm_admin_fee != undefined && value.rpm_admin_fee != null)
  	  {
        this.renewPolicy.rpm_admin_fee = parseFloat(value.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      }

      this.renewPolicy.estimatedPremium = value.estimatedPremium;
      this.renewPolicy.downPayment = value.downPayment;
      this.renewPolicy.adjustedPrem = value.adjustedPrem;
      this.renewPolicy.manualPrem = value.manualPrem;
      this.renewPolicy.premFactor = value.premFactor;

      if (value.pol_eff_date_change != undefined && value.pol_eff_date_change != null && value.pol_eff_date_change != "") {
        var dateObj = new Date(value.pol_eff_date_change);
        let day = dateObj.getDate();
        let month = dateObj.getMonth() + 1; // add 1 because months are indexed from 0
        let year = dateObj.getFullYear();
        this.renewPolicy.pol_eff_date_change = year + '-' + month + '-' + day;
      }
      if (value.effective_date != undefined && value.effective_date != null && value.effective_date != "") {
        var dateObj = new Date(value.effective_date);
        let day = dateObj.getDate();
        let month = dateObj.getMonth() + 1; // add 1 because months are indexed from 0
        let year = dateObj.getFullYear();
        this.renewPolicy.effective_date = year + '-' + month + '-' + day;
      }
      if (value.end_date != undefined && value.end_date != null && value.end_date != "") {
        var dateObj = new Date(value.end_date);
        let day = dateObj.getDate();
        let month = dateObj.getMonth() + 1; // add 1 because months are indexed from 0
        let year = dateObj.getFullYear();
        this.renewPolicy.end_date = year + '-' + month + '-' + day;
      }

      this.renewPolicy.policy_log_desc = '';
      this.renewPolicy.user_id = this.storage.get('app.auth').id;
      if (value.description != undefined && value.description != null && value.description.trim() != "") {
        this.renewPolicy.policy_log_desc = value.description.trim();
      }

      this.renewPolicy.isRenewal = 1;

      this.renewPolicy.rates = this.rates;

      this.saveRenewPolicy(false);
    }
  }


  saveRenewPolicy(refresh: boolean = false)
  {
    if (this.renewPolicyForm.valid) {
      if (this.rates.length) {
        this.policyService.savePolicy(this.renewPolicy).subscribe(
          response => {
            if (response instanceof HttpErrorResponse) {
              this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
              alert("Sorry, Policy Not Saved");
              this.renewSubmitted = false;
            } else {
              // this.allowNewPolicy = 'none';
              this.showRenewal = false;
              this.renewPolicy = {};
              this.renewPolicyForm.reset();
              this.rates = [];
              alert("Policy renewed successfully.");
              this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Policy saved successfully' });
              this.renewSubmitted = false;

              this.reloadPolicies();
            }
          },
          error => {
            console.log(error);
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
          });
      }
      else {
        this.setMessage('error', 'Please enter policy rates.', '');
      }
    }
    else {
      this.setMessage('error', 'Please select carrier, enter policy number, dates and premuims.', '');
    }
  }

  private setMessage(severity: string, summary: string, detail: string) {
    this.msgs = [];
    this.msgs.push({ severity: severity, summary: summary, detail: detail });
  }


  nrcJustDone() {
    this.showNRC = false;
    alert("NRC successful");
    this.nrcPolicyForm.reset();
    this.rateNRCForm.reset();
    this.nrcSubmitted = false;

    this.reloadPolicies();
  }

  submitNRCRates()
  {
    var polValdtnFailed = false;
    this.nrc_eff_date_change_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    if (!this.nrcPolicyForm.valid) {
      polValdtnFailed = true;
      if (this.nrcPolicyForm.value.nrc_eff_date_change == '' || this.nrcPolicyForm.value.nrc_eff_date_change == null) {
        this.nrc_eff_date_change_error = 'block';
      }
      if (this.nrcPolicyForm.value.carrier_id == '' || this.nrcPolicyForm.value.carrier_id == null) {
        this.carrier_id_error = 'block';
      }
      if (this.nrcPolicyForm.value.policy_number == '' || this.nrcPolicyForm.value.policy_number == null) {
        this.policy_number_error = 'block';
      }
      if (this.nrcPolicyForm.value.effective_date == '' || this.nrcPolicyForm.value.effective_date == null) {
        this.effective_date_error = 'block';
      }
      if (this.nrcPolicyForm.value.end_date == '' || this.nrcPolicyForm.value.end_date == null) {
        this.end_date_error = 'block';
      }
      if (this.nrcPolicyForm.value.description == '' || this.nrcPolicyForm.value.description == null) {
        this.description_error = 'block';
      }
    }

    if (!polValdtnFailed) {
      if (!this.nrcRates.length) {
        this.policy_rate_1_error = 'block';
        polValdtnFailed = true;
      }
    }

    if (!polValdtnFailed) {
      this.nrcSubmitted = true;
      this.spinnerService.show();

      var value = this.nrcPolicyForm.value;

      this.updatePolicy = {};

      this.updatePolicy.client_id = this.id;
      this.updatePolicy.status = 'active';

      this.updatePolicy.carrier_id = value.carrier_id;
      this.updatePolicy.policy_number = value.policy_number;

      this.updatePolicy.rpm_admin_fee = 0;
      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.rpm_admin_fee != undefined && this.activePolicy.rpm_admin_fee != null) {
        this.updatePolicy.rpm_admin_fee = this.activePolicy.rpm_admin_fee;
      }

      this.updatePolicy.estimatedPremium = value.estimatedPremium;
      this.updatePolicy.downPayment = value.downPayment;
      this.updatePolicy.adjustedPrem = value.adjustedPrem;
      this.updatePolicy.manualPrem = value.manualPrem;
      this.updatePolicy.premFactor = value.premFactor;

      if (value.pol_eff_date_change != undefined && value.pol_eff_date_change != null && value.pol_eff_date_change != "")
      {
        this.updatePolicy.pol_eff_date_change = value.pol_eff_date_change;
      }
      if (value.effective_date != undefined && value.effective_date != null && value.effective_date != "")
      {
        if( this.nrcPolicyForm.controls['effective_date'].dirty === true )
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
        else
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
      }
      if (value.end_date != undefined && value.end_date != null && value.end_date != "")
      {
        if( this.nrcPolicyForm.controls['end_date'].touched === true )
        {
          this.updatePolicy.end_date = value.end_date;
        }
        else
        {
          this.updatePolicy.end_date = value.end_date;
        }
      }

      // var dateObj = new Date(this.nrcPolicyForm.value.nrc_eff_date_change);

      this.nrcPostObj = {};
      this.nrcPostObj.policy_id = 0;
      this.nrcPostObj.client_id = this.id;
      this.nrcPostObj.rates = this.nrcRates;

      if( this.nrcPolicyForm.controls['nrc_eff_date_change'].dirty === true )
      {
        this.nrcPostObj.nrc_eff_date_change = this.nrcPolicyForm.value.nrc_eff_date_change;
      }
      else
      {
        this.nrcPostObj.nrc_eff_date_change = this.nrcPolicyForm.value.nrc_eff_date_change;
      }

      this.nrcPostObj.action_type = "NRC";

      if (typeof this.nrcRates[0] !== 'undefined' && this.nrcRates[0].rate_type != undefined) {
        if (this.nrcRates[0].rate_type == 2) {
          this.nrcPostObj.rate_type = 4;
        }
        else if (this.nrcRates[0].rate_type >= 3) {
          this.nrcPostObj.rate_type = this.nrcRates[0].rate_type + 1;
        }
      }
      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.id != undefined && this.activePolicy.id != null) {
        this.nrcPostObj.policy_id = this.activePolicy.id;
      }

      this.nrcPostObj.description = '';
      this.nrcPostObj.user_id = this.storage.get('app.auth').id;
      if (this.nrcPolicyForm.value.description != undefined && this.nrcPolicyForm.value.description != null && this.nrcPolicyForm.value.description.trim() != "") {
        this.nrcPostObj.description = this.nrcPolicyForm.value.description.trim();
      }

      this.nrcPostObj.policyToUpdate = this.updatePolicy;

      this.policyService.saveRates(this.nrcPostObj).subscribe(
        response => {
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            alert("Sorry, NRC not successful.");
            this.nrcSubmitted = false;
          } else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'NRC successful' });
            this.nrcPostObj = {};
            this.spinnerService.show();
            this.nrcJustDone();
          }
        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        });
    }
  }


  endJustDone() {
    this.showEndorsement = false;
    alert("END successful");
    this.endPolicyForm.reset();
    this.rateENDForm.reset();
    this.endSubmitted = false;

    this.reloadPolicies();
  }

  submitENDRates()
  {
    var polValdtnFailed = false;
    this.end_eff_date_change_error = 'none';
    this.rpm_admin_fee_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    if (!this.endPolicyForm.valid) {
      polValdtnFailed = true;
      if (this.endPolicyForm.value.end_eff_date_change == '' || this.endPolicyForm.value.end_eff_date_change == null) {
        this.end_eff_date_change_error = 'block';
      }
      if (this.endPolicyForm.value.rpm_admin_fee == '' || this.endPolicyForm.value.rpm_admin_fee == null) {
        this.rpm_admin_fee_error = 'block';
      }
      if (this.endPolicyForm.value.carrier_id == '' || this.endPolicyForm.value.carrier_id == null) {
        this.carrier_id_error = 'block';
      }
      if (this.endPolicyForm.value.policy_number == '' || this.endPolicyForm.value.policy_number == null) {
        this.policy_number_error = 'block';
      }
      if (this.endPolicyForm.value.effective_date == '' || this.endPolicyForm.value.effective_date == null) {
        this.effective_date_error = 'block';
      }
      if (this.endPolicyForm.value.end_date == '' || this.endPolicyForm.value.end_date == null) {
        this.end_date_error = 'block';
      }
      if (this.endPolicyForm.value.description == '' || this.endPolicyForm.value.description == null) {
        this.description_error = 'block';
      }
    }

    if (!polValdtnFailed) {
      if (!this.nrcRates.length) {
        this.policy_rate_1_error = 'block';
        polValdtnFailed = true;
      }
    }


    if (!polValdtnFailed) {
      this.endSubmitted = true;
      // this.showEndorsement = false;
      this.spinnerService.show();

      var value = this.endPolicyForm.value;

      this.updatePolicy = {};

      this.updatePolicy.client_id = this.id;
      this.updatePolicy.status = 'active';

      this.updatePolicy.carrier_id = value.carrier_id;
      this.updatePolicy.policy_number = value.policy_number;

      this.updatePolicy.rpm_admin_fee = 0;
      if (value.rpm_admin_fee != undefined && value.rpm_admin_fee != null) {
        this.updatePolicy.rpm_admin_fee = parseFloat(value.rpm_admin_fee.toString().replace(/,/g, '').match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      }

      this.updatePolicy.estimatedPremium = value.estimatedPremium;
      this.updatePolicy.downPayment = value.downPayment;
      this.updatePolicy.adjustedPrem = value.adjustedPrem;
      this.updatePolicy.manualPrem = value.manualPrem;
      this.updatePolicy.premFactor = value.premFactor;

      if (value.pol_eff_date_change != undefined && value.pol_eff_date_change != null && value.pol_eff_date_change != "")
      {
        if( this.endPolicyForm.controls['pol_eff_date_change'].touched === true )
        {
          this.updatePolicy.pol_eff_date_change = value.pol_eff_date_change;
        }
        else
        {
          this.updatePolicy.pol_eff_date_change = value.pol_eff_date_change;
        }
      }
      if (value.effective_date != undefined && value.effective_date != null && value.effective_date != "")
      {
        if( this.endPolicyForm.controls['effective_date'].dirty === true )
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
        else
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
      }
      if (value.end_date != undefined && value.end_date != null && value.end_date != "")
      {
        if( this.endPolicyForm.controls['end_date'].touched === true )
        {
          this.updatePolicy.end_date = value.end_date;
        }
        else
        {
          this.updatePolicy.end_date = value.end_date;
        }
      }

      // var dateObj = new Date(this.endPolicyForm.value.end_eff_date_change);

      this.nrcPostObj = {};
      this.nrcPostObj.client_id = this.id;
      this.nrcPostObj.policy_id = 0;
      this.nrcPostObj.rates = this.nrcRates;

      if( this.endPolicyForm.controls['end_eff_date_change'].touched === true )
      {
        this.nrcPostObj.end_eff_date_change = this.endPolicyForm.value.end_eff_date_change;
      }
      else
      {
        this.nrcPostObj.end_eff_date_change = this.endPolicyForm.value.end_eff_date_change;
      }

      this.nrcPostObj.action_type = "END";

      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.id != undefined && this.activePolicy.id != null) {
        this.nrcPostObj.policy_id = this.activePolicy.id;
      }

      this.nrcPostObj.description = '';
      this.nrcPostObj.user_id = this.storage.get('app.auth').id;
      if (this.endPolicyForm.value.description != undefined && this.endPolicyForm.value.description != null && this.endPolicyForm.value.description.trim() != "") {
        this.nrcPostObj.description = this.endPolicyForm.value.description.trim();
      }

      this.nrcPostObj.policyToUpdate = this.updatePolicy;

      this.policyService.saveRates(this.nrcPostObj).subscribe(
        response => {
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            this.endSubmitted = false;
            alert("Sorry, END not successful.");
          } else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'NRC successful' });
            this.nrcPostObj = {};
            this.spinnerService.show();
            this.endJustDone();
          }
        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        });
    }
  }

  cancJustDone() {
    this.showCancellation = false;

    alert("Cancellation successful");
    this.cancPolicyForm.reset();
    this.cancSubmitted = false;

    this.reloadPolicies();
  }

  cancelPolicy()
  {
    var polValdtnFailed = false;
    this.canc_eff_date_change_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    if (!this.cancPolicyForm.valid) {
      polValdtnFailed = true;
      if (this.cancPolicyForm.value.canc_eff_date_change == '' || this.cancPolicyForm.value.canc_eff_date_change == null) {
        this.canc_eff_date_change_error = 'block';
      }
      if (this.cancPolicyForm.value.carrier_id == '' || this.cancPolicyForm.value.carrier_id == null) {
        this.carrier_id_error = 'block';
      }
      if (this.cancPolicyForm.value.policy_number == '' || this.cancPolicyForm.value.policy_number == null) {
        this.policy_number_error = 'block';
      }
      if (this.cancPolicyForm.value.effective_date == '' || this.cancPolicyForm.value.effective_date == null) {
        this.effective_date_error = 'block';
      }
      if (this.cancPolicyForm.value.end_date == '' || this.cancPolicyForm.value.end_date == null) {
        this.end_date_error = 'block';
      }
      if (this.cancPolicyForm.value.description == '' || this.cancPolicyForm.value.description == null) {
        this.description_error = 'block';
      }
    }

    if (!polValdtnFailed) {
      // this.cancSubmitted = true;

      var value = this.cancPolicyForm.value;

      this.updatePolicy = {};

      this.updatePolicy.client_id = this.id;
      this.updatePolicy.status = 'active';

      this.updatePolicy.carrier_id = value.carrier_id;
      this.updatePolicy.policy_number = value.policy_number;

      if (value.pol_eff_date_change != undefined && value.pol_eff_date_change != null && value.pol_eff_date_change != "")
      {
        this.updatePolicy.pol_eff_date_change = value.pol_eff_date_change;
      }
      if (value.effective_date != undefined && value.effective_date != null && value.effective_date != "")
      {
        if( this.cancPolicyForm.controls['effective_date'].dirty === true )
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
        else
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
      }
      if (value.end_date != undefined && value.end_date != null && value.end_date != "")
      {
        if( this.cancPolicyForm.controls['end_date'].touched === true )
        {
          this.updatePolicy.end_date = value.end_date;
        }
        else
        {
          this.updatePolicy.end_date = value.end_date;
        }
      }

      // var dateObj = new Date(this.cancPolicyForm.value.end_date);

      this.nrcPostObj = {};
      this.nrcPostObj.policy_id = 0;
      this.nrcPostObj.client_id = this.id;
      this.nrcPostObj.rates = this.nrcRates;

      if( this.cancPolicyForm.controls['end_date'].touched === true )
      {
        this.nrcPostObj.end_date = this.cancPolicyForm.value.end_date;
      }
      else
      {
        this.nrcPostObj.end_date = this.cancPolicyForm.value.end_date;
      }

      this.nrcPostObj.action_type = "CANC";

      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.id != undefined && this.activePolicy.id != null)
      {
        this.nrcPostObj.policy_id = this.activePolicy.id;
      }

      // var dateObj1 = new Date(this.cancPolicyForm.value.canc_eff_date_change);
      if( this.cancPolicyForm.controls['canc_eff_date_change'].dirty === true )
      {
        this.nrcPostObj.canc_eff_date_change = this.cancPolicyForm.value.canc_eff_date_change;
      }
      else
      {
        this.nrcPostObj.canc_eff_date_change = this.cancPolicyForm.value.canc_eff_date_change;
      }


      this.nrcPostObj.ori_end_date = '';
      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.end_date != undefined && this.activePolicy.end_date != null)
      {
        this.nrcPostObj.ori_end_date = this.activePolicy.end_date.toString();
      }

      this.nrcPostObj.description = '';
      this.nrcPostObj.user_id = this.storage.get('app.auth').id;
      if (this.cancPolicyForm.value.description != undefined && this.cancPolicyForm.value.description != null && this.cancPolicyForm.value.description.trim() != "") {
        this.nrcPostObj.description = this.cancPolicyForm.value.description.trim();
      }

      this.nrcPostObj.policyToUpdate = this.updatePolicy;

      this.policyService.saveRates(this.nrcPostObj).subscribe(
        response => {
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            alert("Sorry, Cancellation not successful.");
            this.cancSubmitted = false;
          } else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'NRC successful' });
            this.nrcPostObj = {};
            this.cancJustDone();
          }
        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        });
    }
  }


  reinJustDone() {
    this.showReinstatement = false;

    alert("Reinstatement successful");
    this.reinPolicyForm.reset();
    this.reinSubmitted = false;

    this.reloadPolicies();
  }


  reinPolicy()
  {
    var polValdtnFailed = false;
    this.rein_eff_date_change_error = 'none';
    this.carrier_id_error = 'none';
    this.policy_number_error = 'none';
    this.effective_date_error = 'none';
    this.end_date_error = 'none';
    this.description_error = 'none';
    this.policy_rate_1_error = 'none';
    this.location_name_error = 'none';
    this.state_id_error = 'none';
    this.class_code_error = 'none';
    this.class_code_description_error = 'none';
    this.state_rate_error = 'none';
    this.net_rate_error = 'none';
    if (!this.reinPolicyForm.valid) {
      polValdtnFailed = true;
      if (this.reinPolicyForm.value.rein_eff_date_change == '' || this.reinPolicyForm.value.rein_eff_date_change == null) {
        this.rein_eff_date_change_error = 'block';
      }
      if (this.reinPolicyForm.value.carrier_id == '' || this.reinPolicyForm.value.carrier_id == null) {
        this.carrier_id_error = 'block';
      }
      if (this.reinPolicyForm.value.policy_number == '' || this.reinPolicyForm.value.policy_number == null) {
        this.policy_number_error = 'block';
      }
      if (this.reinPolicyForm.value.effective_date == '' || this.reinPolicyForm.value.effective_date == null) {
        this.effective_date_error = 'block';
      }
      if (this.reinPolicyForm.value.end_date == '' || this.reinPolicyForm.value.end_date == null) {
        this.end_date_error = 'block';
      }
      if (this.reinPolicyForm.value.description == '' || this.reinPolicyForm.value.description == null) {
        this.description_error = 'block';
      }
    }

    if (!polValdtnFailed) {
      this.reinSubmitted = true;

      var value = this.reinPolicyForm.value;

      this.updatePolicy = {};

      this.updatePolicy.client_id = this.id;
      this.updatePolicy.status = 'active';

      this.updatePolicy.carrier_id = value.carrier_id;
      this.updatePolicy.policy_number = value.policy_number;

      if (value.pol_eff_date_change != undefined && value.pol_eff_date_change != null && value.pol_eff_date_change != "")
      {
        this.updatePolicy.pol_eff_date_change = value.pol_eff_date_change;
      }
      if (value.effective_date != undefined && value.effective_date != null && value.effective_date != "")
      {
        if( this.reinPolicyForm.controls['effective_date'].dirty === true )
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
        else
        {
          this.updatePolicy.effective_date = value.effective_date;
        }
      }
      if (value.end_date != undefined && value.end_date != null && value.end_date != "")
      {
        if( this.reinPolicyForm.controls['end_date'].touched === true )
        {
          this.updatePolicy.end_date = value.end_date;
        }
        else
        {
          this.updatePolicy.end_date = value.end_date;
        }
      }

      // var dateObj = new Date(this.reinPolicyForm.value.end_date);

      this.nrcPostObj = {};
      this.nrcPostObj.policy_id = 0;
      this.nrcPostObj.client_id = this.id;
      this.nrcPostObj.rates = this.nrcRates;

      if( this.reinPolicyForm.controls['end_date'].touched === true )
      {
        this.nrcPostObj.end_date = this.reinPolicyForm.value.end_date;
      }
      else
      {
        this.nrcPostObj.end_date = this.reinPolicyForm.value.end_date;
      }

      this.nrcPostObj.action_type = "REIN";

      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.id != undefined && this.activePolicy.id != null)
      {
        this.nrcPostObj.policy_id = this.activePolicy.id;
      }

      // var dateObj1 = new Date(this.reinPolicyForm.value.rein_eff_date_change);
      if( this.reinPolicyForm.controls['rein_eff_date_change'].dirty === true )
      {
        this.nrcPostObj.rein_eff_date_change = this.reinPolicyForm.value.rein_eff_date_change;
      }
      else
      {
        this.nrcPostObj.rein_eff_date_change = this.reinPolicyForm.value.rein_eff_date_change;
      }

      this.nrcPostObj.cancel_end_date = '';
      if (this.activePolicy != undefined && this.activePolicy != null && this.activePolicy.end_date != undefined && this.activePolicy.end_date != null)
      {
        this.nrcPostObj.cancel_end_date = this.activePolicy.end_date.toString();
      }

      this.nrcPostObj.description = '';
      this.nrcPostObj.user_id = this.storage.get('app.auth').id;
      if (this.reinPolicyForm.value.description != undefined && this.reinPolicyForm.value.description != null && this.reinPolicyForm.value.description.trim() != "") {
        this.nrcPostObj.description = this.reinPolicyForm.value.description.trim();
      }

      this.nrcPostObj.policyToUpdate = this.updatePolicy;

      this.policyService.saveRates(this.nrcPostObj).subscribe(
        response => {
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            alert("Sorry, Reinstatement not successful.");
            this.reinSubmitted = false;
          } else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'NRC successful' });
            this.nrcPostObj = {};
            this.reinJustDone();
          }
        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        });
    }
  }


  resetNRCPopup() {
    this.showNRC = false;
    this.nrcPolicyForm.reset();
    this.rateNRCForm.reset();

    this.nrc_btn_upd = 'none';
    this.nrc_btn_add = '';
    this.nrcPolBtn = true;

    this.nccRatesCount = -1;

    this.nrcRates = [];
  }

  resetENDPopup() {
    this.showEndorsement = false;
    this.endPolicyForm.reset();
    this.rateENDForm.reset();

    this.end_btn_add = '';
    this.end_btn_upd = 'none';
    this.endPolBtn = true;

    this.nccRatesCount = -1;

    this.nrcRates = [];
  }

  resetCancPopup() {
    this.showCancellation = false;
    this.cancPolicyForm.reset();
    this.cancSubmitted = true;
  }

  resetReinPopup() {
    this.showReinstatement = false;
    this.reinPolicyForm.reset();
    this.reinSubmitted = true;
  }

  onNew() {

    // alert();
    //this.showSelected = true;
  }

  activeOpenClosePolicy(id: number, openClose: number) {
    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && policy.showThisPolicy != undefined && policy.showThisPolicy !== null && id != undefined && id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            policy.showPolicyLogs = false;
            policy.policyLogTieUp = false;
            if (id == policy.id) {
              if (openClose == 1) {
                policy.showPolicyLogs = true;
                policy.policyLogTieUp = true;
              }
              else if (openClose == 2) {
                policy.showPolicyLogs = false;
                policy.policyLogTieUp = false;
              }
            }
          }
        }
      }
    }
  }

  showFullLog(id: number, log_id: number) {
    if (this.policies != undefined && this.policies !== null) {
      for (let policy of this.policies) {
        if (policy != undefined && policy !== null && id != undefined && id !== null) {
          if (policy.id != undefined && policy.id !== null) {
            if (id == policy.id) {
              if (policy.logs_reverse != undefined && policy.logs_reverse !== null) {
                for (let log of policy.logs_reverse) {
                  if (log != undefined && log !== null && log.id != undefined && log.id !== null && log.description != undefined && log.description !== null) {
                    if (log.id == log_id) {
                      this.currLogDesc = log.description;
                      this.currLogHeader = policy.topDisplayYear;
                      this.showLogPopUp = true;
                      if (log.activity != undefined && log.activity !== null) {
                        // this.currLogHeader += " : " + log.activity;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  setActionToOverride( actionId:number = 0,origActChkd:number )
  {
    if( this.actionToBeChecked != undefined && this.actionToBeChecked != null && origActChkd != undefined && origActChkd != null && origActChkd == 1 && (this.actionToBeChecked == actionId) )
    {
      this.spinnerService.show();
      this.actionOvrPostObj = {};
      this.actionOvrPostObj.type = 3;
      this.actionOvrPostObj.client_id = this.id;
      this.actionOvrPostObj.action_id = actionId;
      this.policyService.saveOverrideOption(this.actionOvrPostObj).subscribe(data =>
      {
          this.spinnerService.hide();
          this.actionOvrPostObj = {};
        
          if( data != undefined && data != null )
          {
            if( data.actionOvrRem != undefined && data.actionOvrRem != null )
            {
              this.actionOverridePrg = false;
              this.actionToBeChecked = 0;

              if( this.policies != undefined && this.policies !== null )
              {
                  for( let policy of this.policies )
                  {
                    if( policy != undefined && policy !== null && policy.actions_eff_date_change != undefined && policy.actions_eff_date_change !== null )
                    {
                      for( let action of policy.actions_eff_date_change )
                      {
                        if( action != undefined && action !== null && action.id != undefined && action.id !== null )
                        {
                          action.action_to_override = 0;
                        }
                      }
                    }
                  }
              }
            
              this.showActionRem= true;                        
            }
            else
            {
              this.actionOverridePrg = false;
              alert( "We faced an issue removing action override, please try again." );
            }
          }
          else
          {
            this.actionOverridePrg = false;
            alert( "We faced an issue removing action override, please try again." );
          }
      });
    }
    else if( ! this.actionOverridePrg )
    {
      this.actionOverridePrg = true;
      this.spinnerService.show();
      this.actionOvrPostObj = {};
      this.actionOvrPostObj.type = 1;
      this.actionOvrPostObj.client_id = this.id;
      this.actionOvrPostObj.action_id = actionId;
      this.policyService.saveOverrideOption(this.actionOvrPostObj).subscribe(data =>
      {
        this.spinnerService.hide();
        this.actionOvrPostObj = {};
  
        if( data != undefined && data != null )
        {
          if( data.actionOvrExists != undefined && data.actionOvrExists != null )
          {
            this.confirmationService.confirm
            ({
              message: 'Another action set to override, do you want to continue ?',
              accept: () =>
              {
                this.spinnerService.show();
                this.actionOvrPostObj = {};
                this.actionOvrPostObj.type = 2;
                this.actionOvrPostObj.client_id = this.id;
                this.actionOvrPostObj.action_id = actionId;
                this.policyService.saveOverrideOption(this.actionOvrPostObj).subscribe(data =>
                {
                    this.spinnerService.hide();
                    this.actionOvrPostObj = {};
                  
                    if( data != undefined && data != null )
                    {
                      if( data.actionOverriden != undefined && data.actionOverriden != null )
                      {
                        if( this.policies != undefined && this.policies !== null )
                        {
                            for( let policy of this.policies )
                            {
                              if( policy != undefined && policy !== null && policy.actions_eff_date_change != undefined && policy.actions_eff_date_change !== null )
                              {
                                for( let action of policy.actions_eff_date_change )
                                {
                                  if( action != undefined && action !== null && action.id != undefined && action.id !== null )
                                  {
                                    if( (action.id == actionId) )
                                    {
                                      action.action_to_override = 1;
                                    }
                                    else
                                    {
                                      action.action_to_override = 0;
                                    }
                                  }
                                }
                              }
                            }
                        }
                                
                        this.actionOverridePrg = false;

                        this.showActionOvr= true;                        
                      }
                      else
                      {
                        this.actionOverridePrg = false;
                        alert( "We faced an issue setting action to override, please try again." );
                      }
                    }
                    else
                    {
                      this.actionOverridePrg = false;
                      alert( "We faced an issue setting action to override, please try again." );
                    }
                });
              },
              reject: () =>
              {
                if( this.policies != undefined && this.policies !== null )
                {
                    for( let policy of this.policies )
                    {
                      if( policy != undefined && policy !== null && policy.actions_eff_date_change != undefined && policy.actions_eff_date_change !== null )
                      {
                        for( let action of policy.actions_eff_date_change )
                        {
                          if( action != undefined && action !== null && action.id != undefined && action.id !== null )
                          {
                            if( data.actionIdExisting != undefined && data.actionIdExisting != null && (action.id == data.actionIdExisting) )
                            {
                              this.actionToBeChecked = action.id;
                            }
                          }
                        }
                      }
                    }
                }
                              
                this.actionOverridePrg = false;
              }
            });
          }
          else
          {
            if( data != undefined && data != null )
            {
              if( data.actionOverriden != undefined && data.actionOverriden != null )
              {
                this.actionOverridePrg = false;

                if( this.policies != undefined && this.policies !== null )
                {
                    for( let policy of this.policies )
                    {
                      if( policy != undefined && policy !== null && policy.actions_eff_date_change != undefined && policy.actions_eff_date_change !== null )
                      {
                        for( let action of policy.actions_eff_date_change )
                        {
                          if( action != undefined && action !== null && action.id != undefined && action.id !== null )
                          {
                            if( (action.id == actionId) )
                            {
                              action.action_to_override = 1;
                            }
                            else
                            {
                              action.action_to_override = 0;
                            }
                          }
                        }
                      }
                    }
                }
        
                this.showActionOvr= true;                        
              }
              else
              {
                this.actionOverridePrg = false;
                alert( "We faced an issue setting action to override, please try again." );
              }
            }
            else
            {
              this.actionOverridePrg = false;
              alert( "We faced an issue setting action to override, please try again." );
            }
          }
        }
        else
        {
          this.actionOverridePrg = false;
          alert( "We faced an issue setting action to override, please try again." );
        }
      });
    }
  }
}
