import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { CalendarModule, DropdownModule, InputTextModule, RadioButtonModule, TabViewModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/checkbox';

import { ClientRoutingModule } from './client-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewComponent } from './view/view.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { RemitComponent } from './remit/remit.component';
import { MenubarComponent } from './menubar/menubar.component';
import { ClientInfoComponent } from './client-info/client-info.component';
import { ClientActivityComponent } from './client-activity/client-activity.component';
import { ClientProspectComponent } from './client-prospect/prospect-activity.component';
import { ClientSetupComponent } from './client-setup/setup-activity.component';
import { ClientNotesComponent } from './client-notes/client-notes.component';
import { ListComponent } from './list/list.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { CreateComponent } from './create/create.component';
import { DataViewModule } from 'primeng/dataview';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ClientService } from './client.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientMenuComponent } from './client-menu/client-menu.component';
import { PolicyComponent } from './policy/policy.component';
import { ListboxModule } from 'primeng/listbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PolicyService } from './policy.service';
import { TableModule } from 'primeng/table';
import { DataTableModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataScrollerModule } from 'primeng/datascroller';
import { ClientContactsComponent } from './client-contacts/client-contacts.component';
import { GrowlModule } from 'primeng/growl';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessageService } from 'primeng/components/common/messageservice';
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';
import { ShipInfoComponent } from './ship-info/ship-info.component';
import { ActivityService } from './activity.service';
import { SetupService } from './setup.service';
import { ProspectService } from './prospect.service';
import { ContactsService } from './contacts.service';
import { AgencyService } from './agency.service';
import { ViewClientComponent } from './view-client/view-client.component';
import { BrokersService } from './brokers.service';
import { BrokerviewComponent } from "./brokersview/brokersview.component";
import { BrokersComponent } from "./brokers/brokers.component";
import { AgencyComponent } from './agency/agency.component';
import { ReportsComponent } from "../reports/reports.component";
import { AgencyviewComponent } from './agencyview/agencyview.component';
import { CarrierComponent } from './carrier/carrier.component';
import { EditorModule } from 'primeng/editor';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { CarrierService } from './carrier.service';
import { CarrierviewComponent } from './carrierview/carrierview.component';
import { PayrollviewComponent } from './payrollview/payrollview.component';
import { PayrollComponent } from "./payroll/payroll.component";
import { PayrollService } from './payroll.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { IncompleteComponent } from './incomplete/incomplete.component'
import { UploadInvoiceComponent } from "./upload-invoice/upload-invoice.component";
import { LandComponent } from "./land/land.component";
import { AutomatedRemitReportComponent } from '../reports/automated-remit-report/automated-remit-report.component';

@NgModule({
  imports: [
    CommonModule,
    ClientRoutingModule,
    MenubarModule,
    PanelModule,
    ButtonModule,
    InputTextModule,
    DataViewModule,
    FormsModule,
    ReactiveFormsModule,
    ListboxModule,
    InputTextareaModule,
    SelectButtonModule,
    TableModule,
    DataTableModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    CalendarModule,
    TabViewModule,
    RadioButtonModule,
    GrowlModule,
    ConfirmDialogModule,
    DataScrollerModule,
    MessagesModule,
    KeyFilterModule,
    EditorModule,
    AutoCompleteModule,
    MultiSelectModule,
    PaginatorModule
  ],
  providers: [BrokersService, AuthService, ClientService, PolicyService, ActivityService, ProspectService, SetupService, MessageService, ContactsService, AgencyService, CarrierService, PayrollService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [BrokerviewComponent, BrokersComponent, DashboardComponent, ViewComponent, InvoiceComponent, RemitComponent, MenubarComponent, ClientInfoComponent,IncompleteComponent,UploadInvoiceComponent,
    ClientActivityComponent,ClientProspectComponent,ClientSetupComponent,ClientNotesComponent, ListComponent, SendEmailComponent, CreateComponent, ClientMenuComponent, PolicyComponent,
    ClientContactsComponent, ShipInfoComponent, LandComponent, InvoiceEditComponent, ViewClientComponent, AgencyComponent, AgencyviewComponent, CarrierComponent, CarrierviewComponent, PayrollComponent, PayrollviewComponent,ReportsComponent,AutomatedRemitReportComponent]
})
export class ClientModule {
  constructor(auth: AuthService, router: Router) {

    // Check login only if we are trying to use this module
    router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/land')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/clients')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/agency')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/agencyview')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/carrierview')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/carriers')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
		    if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/payroll-platform')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/brokers')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/brokersview')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/reports')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/incomplete-invoices')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
      });
  }
}
