import { Component, OnInit, Inject } from '@angular/core';
import { Location } from "@angular/common";
import { Client } from "../client";
import { ActivatedRoute, Router } from "@angular/router";
import { ClientService } from "../client.service";
import { PolicyService } from '../policy.service';
import { SpinnerService } from '../../core';
import { ConfirmationService } from 'primeng/components/common/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { ConfigServiceService } from '../../config-service.service';

@Component({
  selector: 'app-remit',
  templateUrl: './remit.component.html',
  styleUrls: ['./remit.component.css']
})
export class RemitComponent implements OnInit {
  id: number;
  client: Client;

  collectedList: any = [];
  invRemsArr: string[];
  remInProgress: boolean = false;
  invActionPostObj: any = {};

  remitLogs: any = [];
  corrInProgress: boolean = false;
  voidInProgress: boolean = false;

  showRemInvoices: boolean = false;
  remittedList: any = [];

  startDate: string;
  endDate: string;
  overallInProgress: boolean = false;
  overallLogs: any = [];

  redError = 'red';
  startdt_Error = 'none';
  enddt_Error = 'none';

  showReportDates: boolean = false;
  startDateReport: string;
  endDateReport: string;
  reportDatesInProgress: boolean = false;
  startDateReport_Error = 'none';
  endDateReport_Error = 'none';
  repSbmtType_Error = 'none';

  dtValStr:string='';
  serverYr:string='';
  chromePrevStr:string='';

  rovStDtInv: boolean = false;
  rovEndDtInv: boolean = false;

  ovStDtInv:boolean = false;
  ovEndDtInv:boolean = false;

  repStDtInv:boolean = false;
  repEndDtInv:boolean = false;

  rempRepRadio:any = '';

  startDtBk:string='';
  endDtBk:string = '';
  todayDt:string='';
  todayChkChecked:string[];

  remitStartDate: string;
  remitEndDate: string;
  remitOverallInProgress: boolean = false;
  remitStartDate_Error = 'none';
  remitEndDate_Error = 'none';
  remitOverallLogs: any = [];

  constructor(aroute: ActivatedRoute, private router: Router, public location: Location,
    private clientService: ClientService, private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService, private policyService: PolicyService
    , @Inject(LOCAL_STORAGE) private storage: WebStorageService
    , private messageService: MessageService, private configServiceService: ConfigServiceService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  //function to run overall remit report
  runRemitOverall() {
    if (!this.remitOverallInProgress) {
      this.remitOverallInProgress = true;
      var genInv = true;
      this.remitStartDate_Error = 'none';
      this.remitEndDate_Error = 'none';

      //check remit start date
      if (this.remitStartDate == undefined || this.remitStartDate == null || this.remitStartDate == "") {
        this.remitStartDate_Error = 'block';
        genInv = false;
      }
      else {
        this.remitStartDate_Error = 'none';
      }
      //check remit end date
      if (this.remitEndDate == undefined || this.remitEndDate == null || this.remitEndDate == "") {
        this.remitEndDate_Error = 'block';
        genInv = false;
      }
      else {
        this.remitEndDate_Error = 'none';
      }

      if (genInv) {
        this.spinnerService.show();
        this.overallWithSave("remitOverAll");
      }
      else {
        this.remitOverallInProgress = false;
      }
    }
  }

  loadCollected() {
    this.spinnerService.show();
    this.invRemsArr = [];
    this.collectedList = [];
    this.policyService.getCollected(this.id).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.collectedList = <any[]>response;
          this.spinnerService.hide();
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
      });
  }

  validatedateInv(inputText)
  {
    var isDateValid = true;
    var dateformat = /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  // Match the date format through regular expression
  if(inputText.match(dateformat))
  {
  //Test which seperator is used '/' or '-'
  var opera1 = inputText.split('/');
  var opera2 = inputText.split('-');
  var lopera1 = opera1.length;
  var lopera2 = opera2.length;
  // Extract the string into month, date and year
  if (lopera1>1)
  {
  var pdate = inputText.split('/');
  }
  else if (lopera2>1)
  {
  var pdate = inputText.split('-');
  }
  var mm  = parseInt(pdate[0]);
  var dd = parseInt(pdate[1]);
  var yy = parseInt(pdate[2]);
  // Create list of days of a month [assume there is no leap year by default]
  var ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
  if (mm==1 || mm>2)
  {
  if (dd>ListofDays[mm-1])
  {
  isDateValid = false;
  }
  }
  if (mm==2)
  {
  var lyear = false;
  if ( (!(yy % 4) && yy % 100) || !(yy % 400)) 
  {
  lyear = true;
  }
  if ((lyear==false) && (dd>=29))
  {
    isDateValid = false;
  }
  if ((lyear==true) && (dd>29))
  {
    isDateValid = false;
  }
  }
  }
  else
  {
    isDateValid = false;
  }

  return isDateValid;
  }
  
  appendYr( eleId )
  {
      var isValidDate = true;
      var checkingDt = this.dtValStr;
      var dtGoAhead = false;

      if( checkingDt.length == 4 && ! checkingDt.includes('/') )
      {
        checkingDt = checkingDt.substring(0,2) + "/" + checkingDt.substring(2,4) + "/";
        if( this.validatedateInv(checkingDt) )
        {
          this.dtValStr = checkingDt;
          dtGoAhead = true;
        } 
      }
      else if( checkingDt.length == 5 )
      {
        var dtArr = this.dtValStr.split("/");
        if( dtArr != undefined && dtArr != null && typeof dtArr[0] !== 'undefined' && typeof dtArr[1] !== 'undefined' )
        {
          checkingDt += "/";
          if( this.validatedateInv(checkingDt) )
          {
            this.dtValStr += "/";
            dtGoAhead = true;
          }
        }
      }

      if( dtGoAhead )
      {
        if( eleId == "ovStInp" )
        {
          this.ovStDtInv = true;
          this.startDate = this.dtValStr;
          setTimeout(() =>
          {
            this.ovStDtInv = false;
          }, 50);
        }
        else if( eleId == "ovEndInp" )
        {
          this.ovEndDtInv = true;
          this.endDate = this.dtValStr;
          setTimeout(() =>
          {
            this.ovEndDtInv = false;
          }, 50);
        }
        else if( eleId == "repStInp" )
        {
          this.repStDtInv = true;
          this.startDateReport = this.dtValStr;
          setTimeout(() =>
          {
            this.repStDtInv = false;
          }, 50);
        }
        else if( eleId == "repEndInp" )
        {
          this.repEndDtInv = true;
          this.endDateReport = this.dtValStr;
          setTimeout(() =>
          {
            this.repEndDtInv = false;
          }, 50);
        }
      }
  }

  fillDtTab( eleId )
  {
    var goFillField = false;
    var bkDtStr = "";

    var chkTempStr = this.dtValStr;
    if( chkTempStr.length == 8 && chkTempStr.includes("/") && chkTempStr.charAt(2)=="/" && chkTempStr.charAt(5)=="/" )
    {
        var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
            if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[2].length == 2 )
              {
                var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                if( this.validatedateInv(checkingDt1) )
                {
                  this.dtValStr = checkingDt1;
                  bkDtStr = checkingDt1;
                  goFillField = true;
                }
              }
            }
        }
    }
    
    if( ! goFillField && chkTempStr != "" )
    {
	      var tempStrArr = chkTempStr.split( '/' );
        if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 3 )
        {
          if( typeof tempStrArr[0] !== undefined && typeof tempStrArr[1] !== undefined && typeof tempStrArr[2] !== undefined && tempStrArr[1] != undefined && tempStrArr[0] != undefined && tempStrArr[2] != undefined && tempStrArr[0] != null && tempStrArr[1] != null && tempStrArr[2] != null )
            {
              if( tempStrArr[0] != "" && tempStrArr[1] != "" && tempStrArr[2] != "" )
              {
                if( tempStrArr[0].length == 1 )
                {
                  tempStrArr[0] = "0" + tempStrArr[0];
                }
                if( tempStrArr[1].length == 1 )
                {
                  tempStrArr[1] = "0" + tempStrArr[1];
                }
                
                if( tempStrArr[2].length == 2 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + "20" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    bkDtStr = checkingDt1;
                    goFillField = true;
                  }
                }
                else if( tempStrArr[2].length == 4 )
                {
                  var checkingDt1 = tempStrArr[0] + "/" + tempStrArr[1] + "/" + tempStrArr[2];
                  if( this.validatedateInv(checkingDt1) )
                  {
                    this.dtValStr = checkingDt1;
                    bkDtStr = checkingDt1;
                    goFillField = true;
                  }
                }
              }
            }
        }
    }
    

    if( goFillField && bkDtStr != undefined && bkDtStr != null && bkDtStr !="" )
    {
      if( eleId == "ovStInp" )
      {
        this.ovStDtInv = false;
        this.startDate = bkDtStr;
      }
      else if( eleId == "ovEndInp" )
      {
        this.ovEndDtInv = false;
        this.endDate = bkDtStr;
      }
      else if( eleId == "repStInp" )
      {
        this.repStDtInv = false;
        this.startDateReport = bkDtStr;
      }
      else if( eleId == "repEndInp" )
      {
        this.repEndDtInv = false;
        this.endDateReport = bkDtStr;
      }
    }
  }
  
  outOfDtField( event,eleId )
  {
    this.fillDtTab( eleId );

    if( eleId == "ovStInp" )
    {
      this.ovStDtInv = false;
      setTimeout(() =>
      {
        if( ! this.ovStDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.startDate = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "ovEndInp" )
    {
      this.ovEndDtInv = false;
      setTimeout(() =>
      {
        if( ! this.ovEndDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.endDate = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "repStInp" )
    {
      this.repStDtInv = false;
      setTimeout(() =>
      {
        if( ! this.repStDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.startDateReport = this.dtValStr;
        }
      }, 15);
    }
    else if( eleId == "repEndInp" )
    {
      this.repEndDtInv = false;
      setTimeout(() =>
      {
        if( ! this.repEndDtInv && this.dtValStr.length == 10 && this.validatedateInv(this.dtValStr) )
        {
          this.endDateReport = this.dtValStr;
        }
      }, 15);
    }
  }

  completeMonth( eleId )
  {
      var tempStr = this.dtValStr; 
      var compMGo = false;
      if( tempStr.length == 1 && (parseInt(tempStr) > 1 && parseInt(tempStr) < 10) )
      {
        this.dtValStr = "0" + this.dtValStr + "/";
        compMGo = true;
      }
      else if( tempStr.length == 2 && (parseInt(tempStr) >= 1 && parseInt(tempStr) <= 12) )
      {
        this.dtValStr = this.dtValStr + "/";
        compMGo = true;
      }

      if( eleId == "ovStInp" && compMGo )
      {
        this.ovStDtInv = true;
        setTimeout(() =>
        {
         this.startDate = this.dtValStr;
        }, 100);
      }
      else if( eleId == "ovEndInp" && compMGo )
      {
        this.ovEndDtInv = true;
        setTimeout(() =>
        {
         this.endDate = this.dtValStr;
        }, 100);
      }
      else if( eleId == "repStInp" && compMGo )
      {
        this.repStDtInv = true;
        setTimeout(() =>
        {
         this.startDateReport = this.dtValStr;
        }, 100);
      }
      else if( eleId == "repEndInp" && compMGo )
      {
        this.repEndDtInv = true;
        setTimeout(() =>
        {
         this.endDateReport = this.dtValStr;
        }, 100);
      }
  }

  completeDate( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 1  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" && parseInt(tempStr) >= 4 && parseInt(tempStr) < 10 )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + "0" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "ovStInp" )
          {
            this.ovStDtInv = true;
            setTimeout(() =>
            {
              this.startDate = this.dtValStr;
            }, 100);
          }
          else if( eleId == "ovEndInp" )
          {
            this.ovEndDtInv = true;
            setTimeout(() =>
            {
              this.endDate = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repStInp" )
          {
            this.repStDtInv = true;
            setTimeout(() =>
            {
              this.startDateReport = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repEndInp" )
          {
            this.repEndDtInv = true;
            setTimeout(() =>
            {
              this.endDateReport = this.dtValStr;
            }, 100);
          }
        }
      }

  }

  completeDt2( eleId )
  {
      var tempStrArr = this.dtValStr.split( '/' );

      var tempStr:string = '';
      if( tempStrArr != undefined && tempStrArr != null && Array.isArray(tempStrArr) && tempStrArr.length == 2 && typeof tempStrArr[1] !== undefined )
      {
          if( tempStrArr[1] != undefined && tempStrArr[1] != null && tempStrArr[1].length == 2  )
          {
            tempStr = tempStrArr[1];
          }
      }

      var compMGo = false;
      if( tempStr != "" )
      {
        if( tempStrArr[0] != undefined && tempStrArr[0] != null && tempStrArr[1] != undefined && tempStrArr[1] != null )
        {
          var assignDtFill = tempStrArr[0] + "/" + tempStr + "/";
          this.dtValStr = assignDtFill;

          if( eleId == "ovStInp" )
          {
            this.ovStDtInv = true;
            setTimeout(() =>
            {
              this.startDate = this.dtValStr;
            }, 100);
          }
          else if( eleId == "ovEndInp" )
          {
            this.ovEndDtInv = true;
            setTimeout(() =>
            {
              this.endDate = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repStInp" )
          {
            this.repStDtInv = true;
            setTimeout(() =>
            {
              this.startDateReport = this.dtValStr;
            }, 100);
          }
          else if( eleId == "repEndInp" )
          {
            this.repEndDtInv = true;
            setTimeout(() =>
            {
              this.endDateReport = this.dtValStr;
            }, 100);
          }
        }
      }

  }

  completeDt1( eleId )
  {
      var tempStr = this.dtValStr;
      var tmpNumb = "";

      if( tempStr != undefined && tempStr != null && tempStr.length == 3 )
      {
        tmpNumb = tempStr.charAt(2);
      }

      if( tmpNumb != "" && parseInt(tmpNumb) >= 4 && parseInt(tmpNumb) < 10 )
      {
        var assignDtFill = tempStr.charAt(0) + tempStr.charAt(1) + "/" + "0" + tmpNumb + "/";
        this.dtValStr = assignDtFill;

        if( eleId == "ovStInp" )
        {
          this.ovStDtInv = true;
          setTimeout(() =>
          {
            this.startDate = this.dtValStr;
          }, 100);
        }
        else if( eleId == "ovEndInp" )
        {
          this.ovEndDtInv = true;
          setTimeout(() =>
          {
            this.endDate = this.dtValStr;
          }, 100);
        }
        else if( eleId == "repStInp" )
        {
          this.repStDtInv = true;
          setTimeout(() =>
          {
            this.startDateReport = this.dtValStr;
          }, 100);
        }
        else if( eleId == "repEndInp" )
        {
          this.repEndDtInv = true;
          setTimeout(() =>
          {
            this.endDateReport = this.dtValStr;
          }, 100);
        }
      }
  }

  setYear(ev)
  {
    var justBackSpaced = false;

    if( ev.inputType != undefined && ev.inputType != null )
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined  )
      {
        this.dtValStr = ev.srcElement.value;
      }
      if( ev.inputType.trim() == "deleteContentBackward" || ev.inputType.trim() == "deleteContentForward" )
      {
        this.dtValStr = ev.srcElement.value;
        justBackSpaced = true;
      }
    }
    else
    {
      if( ev.srcElement != undefined && ev.srcElement.value != undefined )
      {
        if( ev.srcElement.value.length < this.chromePrevStr.length )
        {
          justBackSpaced = true;
        }
        this.dtValStr = ev.srcElement.value;
        this.chromePrevStr = ev.srcElement.value;
      }
    }

    if( ! justBackSpaced &&  (this.dtValStr.length == 4 && ! this.dtValStr.includes("/"))  )
    {
      // this.appendYr( ev.srcElement.id );
    }
    else if( ! justBackSpaced && (this.dtValStr.length == 1 || this.dtValStr.length == 2) )
    {
       // this.completeMonth( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 4 )
    {
       // this.completeDate( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 5 )
    {
       // this.completeDt2( ev.srcElement.id );
    }
    else if( ! justBackSpaced && this.dtValStr.length == 3 && ! this.dtValStr.includes("/") )
    {
      // this.completeDt1( ev.srcElement.id );
    }
  }

  resetDtVal( dtFieldNum:number = 0 )
  {
    setTimeout(() =>
    {
      this.dtValStr = '';
    }, 50);
  }
  
  ngOnInit() {

    this.spinnerService.show();
    this.loadCollected();
    this.showRemitLogs(0);
    this.showOverallLogs(0);
    this.showRemitOverallLogs(0);

    // Load client
    this.clientService.getClient(this.id).subscribe(res => this.client = res);

    this.clientService.getDispServerTime().subscribe(res =>
      {
        if( res != undefined && res != null && typeof res[0] !== 'undefined' )
        
        this.serverYr = res[0];
      });
    
      this.clientService.getTodayDateRpm().subscribe(res =>
      {
        if( res != undefined && res != null && typeof res[0] !== 'undefined' )
        {
          this.todayDt = res[0];
        }
      });
          
  }

  todaySelected(event)
  {
    if( event )
    {
      this.startDtBk = this.startDateReport;
      this.endDtBk = this.endDateReport;
      setTimeout(() =>
  	  {
        this.startDateReport = this.todayDt;
        this.endDateReport = this.todayDt;
      }, 20);
    }
    else
    {
      this.startDateReport = this.startDtBk;
      this.endDateReport = this.endDtBk;
    }
  }

  doRemit() {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceIdsArr = this.invRemsArr;
    this.invActionPostObj.client_id = this.id;
    this.invActionPostObj.startDateReport = this.startDateReport;
    this.invActionPostObj.endDateReport = this.endDateReport;
    this.invActionPostObj.rempRepRadio = this.rempRepRadio;
    this.invActionPostObj.user_id = this.storage.get('app.auth').id;

    this.policyService.remitAction(this.invActionPostObj).subscribe(
      response => {
        // console.log(response);
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
          this.invRemsArr = [];
          this.spinnerService.hide();
          this.remInProgress = false;
          alert("Could not perform remit action.");
        }
        else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          if( response != undefined && response != null && response.remRepExists != undefined && response.remRepExists != null )
          {
            this.invRemsArr = [];
            this.spinnerService.hide();
            this.remInProgress = false;
            alert( response.message );
          }
          else
          {
            for (var i = 0; i < this.collectedList.length; i++) {
              var invoice = this.collectedList[i];
              if (invoice != undefined && invoice !== null) {
                // console.log(invoice.is_remitted);
                if (invoice.id != undefined && invoice.id !== null && invoice.is_remitted != undefined && invoice.is_remitted !== null) {
                  if (this.invRemsArr.indexOf(invoice.id) !== -1) {
                    invoice.is_remitted = 1;
                    if (invoice.invoice_status != undefined && invoice.invoice_status != null) {
                      invoice.invoice_status = "Remitted";
                    }
                  }
                }
              }
            }
            this.invRemsArr = [];
            this.spinnerService.hide();
            this.remInProgress = false;
  
            // alert("Invoices Successfully set to  Remitted.");
  
            this.loadCollected();
            this.showRemitLogs(0);
  
            // console.log(response);
  
            // if (response != undefined && response != null && response.remitLog != undefined && response.remitLog != null) {
              // if (response.remitLog.id != undefined && response.remitLog.id != null)
              // {
                // Excel being stopped on client request.
                // window.open(this.configServiceService.getApiUrl() + '/remitrepexcel?id=' + response.remitLog.id, 'blank');
  
                // PDF Being Shown  on client request.
                // window.open(this.configServiceService.getApiUrl() + '/remitreppdf?download=pdf&remitLogId=' + response.remitLog.id + '&id=' + this.id, 'blank');
              // }
            // }
          }
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.invRemsArr = [];
        this.remInProgress = false;
        this.spinnerService.hide();
        alert("Could not perform remit action.");
      });
  }

  max_date(all_dates) {
    var max_dt = all_dates[0],
      max_dtObj = new Date(all_dates[0]);
    all_dates.forEach(function (dt, index) {
      if (new Date(dt) > max_dtObj) {
        max_dt = dt;
        max_dtObj = new Date(dt);
      }
    });
    return max_dt;
  }

  runRemit() {
    if (!this.remInProgress) {
      if (this.invRemsArr.length)
      {
          this.remInProgress = true;

          this.startDateReport_Error = 'none';
          this.endDateReport_Error = 'none';
          this.repSbmtType_Error = 'none';
          // console.log(this.collectedList);
         //  console.log(this.invRemsArr);
          /*
          var filteredCheckDates = [];
          for (var i = 0; i < this.invRemsArr.length; i++) {
            var result = this.collectedList.filter(a => a.id == this.invRemsArr[i]);
            filteredCheckDates.push(result[0].check_date);
          }
          */
          // console.log(filteredCheckDates);
        //   var now = new Date(this.max_date(filteredCheckDates));

          var now = new Date();
          if( now.getDate() > 15 )
          {            
           this.startDateReport = new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString("en-US");
           this.endDateReport = new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString("en-US");
          }
         else
         {         
          this.endDateReport   = new Date(now.getFullYear(), now.getMonth(), 0).toLocaleDateString("en-US");
          this.startDateReport = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1).toLocaleDateString("en-US");
         }
          // this.spinnerService.show();
          this.rempRepRadio = '';
          this.todayChkChecked = [];
          this.showReportDates = true;
          this.remInProgress = false;
          // this.doRemit();
      }
      else
      {
        alert("Please select atleast one invoice.");
      }
    }
  }

  submitReportDates() {
    if (!this.reportDatesInProgress) {
      this.reportDatesInProgress = true;

      var genInv = true;
      this.startDateReport_Error = 'none';
      this.endDateReport_Error = 'none';
      this.repSbmtType_Error = 'none';

      if (this.startDateReport == undefined || this.startDateReport == null || this.startDateReport == "") {
        this.startDateReport_Error = 'block';
        genInv = false;
      }
      else {
        this.startDateReport_Error = 'none';
      }

      if (this.endDateReport == undefined || this.endDateReport == null || this.endDateReport == "") {
        this.endDateReport_Error = 'block';
        genInv = false;
      }
      else {
        this.endDateReport_Error = 'none';
      }

      if( this.rempRepRadio == undefined || this.rempRepRadio == null || this.rempRepRadio == "" )
      {
        // genInv = false;
        // this.repSbmtType_Error = 'block';
      }
      else
      {
        this.repSbmtType_Error = 'none';
      }


      if (genInv) {
        this.reportDatesInProgress = false;
        this.showReportDates = false;
        this.spinnerService.show();
        this.doRemit();
      }
      else {
        this.reportDatesInProgress = false;
      }
    }
  }

  showRemitLogs(type: number = 0) {
    this.remitLogs = [];
    this.spinnerService.show();
    this.policyService.getRemitLog(this.id).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.remitLogs = <any[]>response;
          if (type == 1) {
            this.spinnerService.hide();
          }
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
      });
  }

  doVoidAction(remitLogId: number, actionType: number) {
    this.invActionPostObj = {};
    this.invActionPostObj.invoiceSettingId = remitLogId;
    this.invActionPostObj.invoiceAction = actionType;

    this.policyService.voidAction(this.invActionPostObj).subscribe(
      response => {
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });

          this.spinnerService.hide();

          this.corrInProgress = false;
          this.voidInProgress = false;

          alert("Could not perform void action.");
        } else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          this.corrInProgress = false;
          this.voidInProgress = false;

          if (response != undefined && response != null && response.invUpdated != undefined && response.invUpdated != null) {
            console.log("Void action updated : " + response.invUpdated);
          }

          this.spinnerService.hide();
          this.spinnerService.show();

          this.loadCollected();
          this.showRemitLogs(0);
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.spinnerService.hide();
        this.corrInProgress = false;
        this.voidInProgress = false;
        alert("Could not perform void action.");
      });
  }

  voidAction(remitLogId: number, actionType: number) {
    if (remitLogId != undefined && remitLogId != null && !this.corrInProgress) {
      this.corrInProgress = true;

      this.confirmationService.confirm
        ({
          message: 'Are you sure you want to Void the remitted Invoices?',
          accept: () => {
            this.spinnerService.show();
            this.voidInProgress = true;

            this.doVoidAction(remitLogId, actionType);
          },
          reject: () => {
            this.corrInProgress = false;
            this.voidInProgress = false;
          }
        });
    }
  }

  voidRemitInvoices(remitLogId: number) {
    console.log(remitLogId);
    this.voidAction(remitLogId, 2);
  }

  viewRemitReport(remitLogId: number) {
    console.log(remitLogId);
    this.showRemInvoices = false;

    this.spinnerService.show();
    this.remittedList = [];
    this.policyService.getRemitted(remitLogId).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.remittedList = <any[]>response;
          this.spinnerService.hide();
          this.showRemInvoices = true;
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
        this.spinnerService.hide();
      });
  }

  dwnldRemitReport(remitLogId: number) {
    console.log(remitLogId);
    window.open(this.configServiceService.getApiUrl() + '/remitrepexcel?id=' + remitLogId, 'blank');
  }

  dwnldPdfRemit(remitLogId: number) {
    window.open(this.configServiceService.getApiUrl() + '/remitreppdf?download=pdf&remitLogId=' + remitLogId + '&id=' + this.id, 'blank');
  }

  overallWithSave(overAllReportType: string) {
    this.invActionPostObj = {};
    this.invActionPostObj.client_id = this.id;
    this.invActionPostObj.user_id = this.storage.get('app.auth').id;

    if (overAllReportType === 'clientOverAll') {
    this.invActionPostObj.start_date = this.startDate;
    this.invActionPostObj.end_date = this.endDate;
    this.policyService.generateOverall(this.invActionPostObj).subscribe(
      response => {
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
          this.spinnerService.hide();
          this.overallInProgress = false;
          alert("Could not perform generate overall report.");
        }
        else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

          this.spinnerService.hide();
          this.overallInProgress = false;
          this.spinnerService.show();
          this.showOverallLogs(1);

          // console.log(response);

          if (response != undefined && response != null && response.overallLog != undefined && response.overallLog != null) {
            if (response.overallLog.id != undefined && response.overallLog.id != null) {
              window.open(this.configServiceService.getApiUrl() + '/excelOverallReport?id=' + response.overallLog.id, 'blank');
            }
          }
        }

      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
        this.overallInProgress = false;
        this.spinnerService.hide();
        alert("Could not perform generate overall report.");
      });
    } else {

      this.invActionPostObj.start_date = this.remitStartDate;
      this.invActionPostObj.end_date = this.remitEndDate;

      this.policyService.generateRemitOverall(this.invActionPostObj).subscribe(
        response => {
          // console.log("generateRemitOverall:-",response);
          if (response instanceof HttpErrorResponse) {
            this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
            this.spinnerService.hide();
            this.remitOverallInProgress = false;
            alert("Could not generate remit overall report.");
          }
          else {
            this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Note updated successfully' });

            this.spinnerService.hide();
            this.remitOverallInProgress = false;
            this.spinnerService.show();
            // console.log("ROL",response);
            this.showRemitOverallLogs(1);
            // console.log("API URL", this.configServiceService.getApiUrl() + '/excelRemitOverallReport?id=' + response.overallLog.id);
            if (response != undefined && response != null && response.overallLog != undefined && response.overallLog != null) {
              if (response.overallLog.id != undefined && response.overallLog.id != null) {
                window.open(this.configServiceService.getApiUrl() + '/excelRemitOverallReport?id=' + response.overallLog.id, 'blank');
              }
            }
          }

        },
        error => {
          console.log(error);
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
          this.remitOverallInProgress = false;
          this.spinnerService.hide();
          alert("Could not generate remit overall report.");
        });
    }
  }

  showRemitOverallLogs(type: number = 0) {

    this.remitOverallLogs = [];
    this.spinnerService.show();

    this.policyService.getRemitOverallLog(this.id).subscribe(
      response => {
        // console.log("remitOverallLogs",response)
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.remitOverallLogs = <any[]>response;
          if (type == 1) {
            this.spinnerService.hide();
          }
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
      });
  }

  runOverall() {
    if (!this.overallInProgress) {
      this.overallInProgress = true;

      var genInv = true;
      this.startdt_Error = 'none';
      this.enddt_Error = 'none';

      if (this.startDate == undefined || this.startDate == null || this.startDate == "") {
        this.startdt_Error = 'block';
        genInv = false;
      }
      else {
        this.startdt_Error = 'none';
      }

      if (this.endDate == undefined || this.endDate == null || this.endDate == "") {
        this.enddt_Error = 'block';
        genInv = false;
      }
      else {
        this.enddt_Error = 'none';
      }

      if (genInv) {
        this.spinnerService.show();
        this.overallWithSave('clientOverAll');
      }
      else {
        this.overallInProgress = false;
      }
    }
  }

  showOverallLogs(type: number = 0) {
    this.overallLogs = [];
    this.spinnerService.show();
    this.policyService.getOverallLog(this.id).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.overallLogs = <any[]>response;
          if (type == 1) {
            this.spinnerService.hide();
          }
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
      });
  }

  dwnldOverallReport(overallLogId: number) {
    console.log(overallLogId);
    window.open(this.configServiceService.getApiUrl() + '/excelOverallReport?id=' + overallLogId, 'blank');
  }


  dwnldOverallPdf(overallLogId: number) {
    console.log(overallLogId);
    window.open(this.configServiceService.getApiUrl() + '/pdfoverallreport?download=pdf&overallLogId=' + overallLogId + '&id=' + this.id, 'blank');
  }

  dwnldRemitOverallReport(overallLogId: number) {
    window.open(this.configServiceService.getApiUrl() + '/excelRemitOverallReport?id=' + overallLogId, 'blank');
  }

  dwnldRemitOverallPdf(overallLogId: number) {
    // window.open(this.configServiceService.getApiUrl() + '/pdfRemitOverallreport?download=pdf&overallLogId=' + overallLogId + '&id=' + this.id, 'blank');
    window.open(this.configServiceService.getApiUrl() + '/clientLevelRemitOverallreportPdf?download=pdf&overallLogId=' + overallLogId + '&id=' + this.id, 'blank');
  }

  changeDateFormate(oldDate) {
    if (typeof oldDate !== 'undefined' && oldDate !== '' && oldDate !== null) {
      var dtArrr = new Date(oldDate);
      return (dtArrr.getMonth() + 1) + '/' + dtArrr.getDate() + '/' + dtArrr.getFullYear();
    } else {
      return 'No date';
    }
  }

}
