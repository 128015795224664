import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../settings/document.service';
import { DocumentQueue } from '../../settings/document-queue';
import { ConfirmationService } from 'primeng/api';
import { SpinnerService } from '../../core';
import { ConfigServiceService } from '../../config-service.service';
import { DocumentTemplate } from '../../settings/document-template';

@Component({
  selector: 'app-dashboard-text',
  templateUrl: './dashboardtext.component.html',
  styleUrls: ['./dashboardtext.component.css']
})
export class DashboardTextComponent implements OnInit {

  queues = [];
  queueSettingIds = [];
  allQueuesCount = 0;
  skip = 0;
  take = 150;
  sortField = 'company_name';
  sortOrder = 1;
  showInvoiceAlert:boolean = false;
  selectedAll = false;

  textCronDt:any='';

  filterDates: any;
  selectedDates = [];
  first = 0;
  selectedCarriers = [];

  constructor(
    private documentService: DocumentService, 
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService
    ,private configServiceService: ConfigServiceService
  ) { }

  ngOnInit()
  {
    this.documentService.getTextCronTme().subscribe(res =>
    {
          if( res != undefined && res != null )
          {
            if( typeof res[0] !== 'undefined' )
            {
              this.textCronDt = res[0];
            }
          }
    }); 
  }

  filterQueueByDates( mode:number )
  {
    if( mode == 0 )
    {
      this.selectedDates = [];
    }
    this.skip = 0;
    this.take = 150;
    this.sortField = this.sortField;
    this.sortOrder = this.sortOrder;
    this.first = 0;
    this.documentService.getTextQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
      this.getAllQueues(response);
    });

  }
      
  getAllQueues(response){
    this.queues = response.invoiceSetting;
    this.allQueuesCount = response.invoiceSettingsCount;
    if( this.skip == 0 )
    {
      this.filterDates = response.achDatesArr;
    }

    this.selectedAll = false;
    if( this.queueSettingIds.length )
    {
      var idsArr = [];
      for( var i = 0; i < this.queueSettingIds.length;i++ )
      {
        idsArr.push(this.queueSettingIds[i].id)
      }

      var key = 0;
      var selectedIts = 0;
      this.queues.forEach((queueClientinfo) =>
      {
        if( idsArr.includes(this.queues[key].id) )
        {
          this.queues[key].selected_checkbox = true;
          selectedIts++;
        }
        key++;
      });
      if( key == selectedIts )
      {
        this.selectedAll = true;
      }
    }

    this.spinnerService.hide();
  }

  paginate(event) {
      this.skip = event.first;
      this.take = event.rows;
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      this.documentService.getTextQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
        this.getAllQueues(response);
      });
  }

  checkUncheckAll(e)
  {
    console.log(this.queueSettingIds);

    // this.queueSettingIds = [];

    if(e.target.checked == true){
      this.selectedAll = true;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        this.queues[key].selected_checkbox = true;
        var objectClient = {};
        objectClient['id'] = queueClientinfo.id;
        objectClient['clientId'] = queueClientinfo.clientId;
        objectClient['invType'] = queueClientinfo.invoice_type;
        objectClient['email'] = queueClientinfo.email;
        objectClient['company_name'] = queueClientinfo.company_name;
        objectClient['pfInvoiceTotal'] = queueClientinfo.pfInvoiceTotal;
        objectClient['check_date_formate'] = queueClientinfo.check_date_formate;
        this.queueSettingIds.push(objectClient);
        key++;
      });
    }else{
      this.selectedAll = false;
      var key = 0;
      this.queues.forEach((queueClientinfo) => {
        for( var em = 0;em < this.queueSettingIds.length;em++ )
        {
          if( this.queueSettingIds[em].id == queueClientinfo.id )
          {
            this.queueSettingIds.splice(em, 1);
          }
        }
        this.queues[key].selected_checkbox = false;
        key++;
      });
    }
  }

  checkBoxEvent(id, e, i)
  {

    if( i >= this.take )
    {
      i = i % this.take;
    }

    if(e.target.checked == true){
      this.queues[i].selected_checkbox = true;
      var objectClient = {};
      objectClient['id'] = id;
      var queueClientinfo = this.queues.filter(item => item.id == id);
      objectClient['clientId'] = queueClientinfo[0].clientId;
      objectClient['invType'] = queueClientinfo[0].invoice_type;
      objectClient['email'] = queueClientinfo[0].email;
      objectClient['company_name'] = queueClientinfo[0].company_name;
      objectClient['pfInvoiceTotal'] = queueClientinfo[0].pfInvoiceTotal;
      objectClient['check_date_formate'] = queueClientinfo[0].check_date_formate;
      this.queueSettingIds.push(objectClient);

      this.selectedAll = this.queues.every(function(item:any) {
        return item.selected_checkbox == true;
      });

    }else{
      this.selectedAll = false;
      this.queues[i].selected_checkbox = false;
      this.queueSettingIds = this.queueSettingIds.filter(item => item.id != id);
    }
  }

  openPDF(invSettingId: number,invType:number,clientId:number)
  { 

    if( invType == 1 )
    {
      window.open(this.configServiceService.getApiUrl() + '/pdfview?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0', '_blank');
    }
    else if( invType == 2 )
    {
      window.open(this.configServiceService.getApiUrl() + '/pfpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0', '_blank');
    }
    else if( invType == 3 )
    {
      window.open(this.configServiceService.getApiUrl() + '/nprpdf?download=pdf&invoiceId=' + invSettingId + '&id=' + clientId + '&up=0', '_blank');
    }

  }

  setDelivrdInvs()
  {
    if(this.queueSettingIds.length != 0)
    {
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to set the invoices as delivered ?',
          accept: () =>
          {
            this.documentService.createMQDel(this.queueSettingIds).subscribe(response =>
            {
              this.spinnerService.hide();
              if(response.output == '0')
              {
              }
              else
              {
                this.documentService.getQueues(this.skip, this.take, this.sortField, this.sortOrder, 2).subscribe(response => {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                });
              }
            });
          },
          reject: () => {}
      });
    }
    else
    {
      alert('Please select invoices');
    }
  }

  sendInvoicePdf(){
    if(this.queueSettingIds.length != 0){
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to send text for selected invoices?',
          accept: () => {
            this.documentService.textInvoices(this.queueSettingIds).subscribe(response => {
              this.spinnerService.hide();
              if(response.output == '0'){
                this.showInvoiceAlert = true;
              }else{
                this.documentService.getTextQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
                  this.queueSettingIds = [];
                  this.getAllQueues(response);
                });
              }
            });
          },
          reject: () => {}
      });
    }else{
      alert('Please select invoices');
    }
  }

  remFromTQ(){
    if(this.queueSettingIds.length != 0){
      this.confirmationService.confirm
      ({
          message: 'Are you sure you want to remove selected invoices from Text Queue ?',
          accept: () => {
            this.documentService.removeFromText(1, this.queueSettingIds).subscribe(response => {
              this.spinnerService.hide();
              this.documentService.getTextQueue(this.skip, this.take, this.sortField, this.sortOrder, 2, 0,this.selectedCarriers,this.selectedDates).subscribe(response => {
                this.queueSettingIds = [];
                this.getAllQueues(response);
              });
            });
          },
          reject: () => {}
      });
    }else{
      alert('Please select invoices');
    }
  }

}
