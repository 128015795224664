import { Component, OnInit, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Client } from '../client';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../client.service';
import { Validators, FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';
import { AutoCompleteModule } from 'primeng/autocomplete';

declare var $: any;

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css']
})
export class CreateComponent implements OnInit, AfterViewInit {
  elementRef: ElementRef;
  id: number;
  return_client_id = '0';
  clientForm: FormGroup;
  worklists: any;
  states: any;
  usDateValue: any;
  formattedNumber: any;
  area: any;
  front: any;
  end: any;
  c: any;
  payrollCompanies: any;
  payrollContacts: any;
  agencies: any;
  agents: any=[];
  currAgent:any={};
  brokers: any;
  success_message = false;
  activityInfo = [];
  redError = 'red';
  worklistError = 'none';
  company_nameError = 'none';
  other_namesError = 'none';
  contactError = 'none';
  feinError = 'none';
  suiError = 'none';
  addressError = 'none';
  cityError = 'none';
  stateError = 'none';
  zipError = 'none';
  address_mError = 'none';
  city_mError = 'none';
  state_mError = 'none';
  zip_mError = 'none';
  websiteError = 'none';
  phoneError = 'none';
  emailError = 'none';
  rc_faxError = 'none';
  token_idError = 'none';
  insurance_agencyError = 'none';
  broker_idError = 'none';
  payroll_companyError = 'none';
  payroll_frequencyError = 'none';
  payroll_agentError = 'none';
  payroll_client_idError = 'none';
  contact_name_1Error = 'none';
  contact_email_1Error = 'none';
  contact_phone_1Error = 'none';
  contact_role_1Error = 'none';
  activity_descriptionError = 'none';
  associate_companyError = 'none';
  method_contactError = 'none';
  other_first_check_dateError = 'none';
  other_enteredError = 'none';
  other_out_byError = 'none';
  other_feeError = 'none';
  other_garnishmentsError = 'none';
  other_deliveryError = 'none';
  other_chargeError = 'none';
  other_reportsError = 'none';
  other_upgrade_solutionsError = 'none';
  filteredStates: any[];

  clientData:any={};
  carriers: any = [];
  payrollPlatForms:any;
  platformWorklist:any;
  processors:any;

  editorDisp: boolean = true;
  ppostdt:boolean = false;
  clientNotes:any = "";
  filterValues: any;

  constructor(private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    aroute: ActivatedRoute,
    private router: Router,
    public location: Location,
    private clientService: ClientService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    @Inject(ElementRef) elementRef: ElementRef,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
    this.elementRef = elementRef;
  }

  ngAfterViewInit() {
    const y = this.elementRef.nativeElement.querySelectorAll('.ui-radiobutton-label');
    for (var i = 0; i < y.length; i++) {
      y[i].style.marginBottom = '0';
    }
  }

  doneEdit(){
    this.clientNotes = $('#clientNotes').summernote('code');
    this.editorDisp = true;

    $('#clientNotes').summernote('destroy');
    setTimeout(() => {
        $('#notesDisp').html(this.clientNotes);
    }, 50);
  }

  allowEdit(){
      this.editorDisp = false;

      setTimeout(() => {

        $('#clientNotes').summernote({
            toolbar: [
                //[groupname, [button list]]
                ["style", ['style']],
                ["font", ["bold",'italic', "underline", "clear"]],
                ['color', ['forecolor','backcolor']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['fontname', ['fontname']],
                ['fullscreen', ['fullscreen']],
                ['link', ['link']],
            ],
            height:320,
            focus:true
            
        });

        // $('.note-toolbar span').removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-style").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-fontname").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-para").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-table").find("span.note-icon-caret").removeClass('note-icon-caret');

        $('.note-editable').css('font-family','Times New Roman');
        $('div .note-btn-group .note-color i').css( 'background-color','#ffffff' );

        $('#clientNotes').summernote('fontName','Times New Roman');
        $('#clientNotes').summernote('code', this.clientNotes);
      
      }, 105);
    }

  percentageCommission(event: any, type:any) {
    var currentValue = event.target.value;
    var trimmedsubcontval = this.trimgivennumber(currentValue);
    if(trimmedsubcontval != "" && trimmedsubcontval <= 100 && trimmedsubcontval > 0){
      if(type == 1){
        this.clientForm.patchValue({
          commission_percentage: trimmedsubcontval
        });
      }else{
        this.clientForm.patchValue({
          broker_commission_percentage: trimmedsubcontval
        });
      }
    }else{
      if(type == 1){
        this.clientForm.patchValue({
          commission_percentage: ''
        });
      }else{
        this.clientForm.patchValue({
          broker_commission_percentage: ''
        });
      }
    }
  }

  trimgivennumber(num: any) {
    var trimmedcharacters = num.replace(/\D/g, '');
    var trimmedgrossval = trimmedcharacters.replace(/\$|,/g, "");
    return trimmedgrossval;
  }

  numberWithCommas(x: any) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  getpercentageCommission(event: any, type:any){
    var percentageCommission = event.target.value;
    if(percentageCommission != "" && !percentageCommission.includes("%")){
      if(percentageCommission > 100){
        if(type == 1){
          this.clientForm.patchValue({
            commission_percentage: ''
          });
        }else{
          this.clientForm.patchValue({
            broker_commission_percentage: ''
          });
        }
      }else{
        if(type == 1){
          this.clientForm.patchValue({
            commission_percentage: percentageCommission+'%'
          });
        }else{
          this.clientForm.patchValue({
            broker_commission_percentage: percentageCommission+'%'
          });
        }
      }
    }
  }

  filterStates(event) {
    this.filteredStates = [];
    for (let i = 0; i < this.states.length; i++) {
      let s = this.states[i].value;
      if (s.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredStates.push(s);
      }
    }
  }

  getPCompPlatforms(mode:number)
  {
    this.spinnerService.show();

    this.payrollPlatForms = [];
    if( this.clientForm != undefined && this.clientForm != null && this.clientForm.value != undefined && this.clientForm.value != null )
    {
      if( this.clientForm.value.payroll_company != undefined && this.clientForm.value.payroll_company != null && this.clientForm.value.payroll_company != '' )
      {
        this.clientService.getPCompPlatforms(this.clientForm.value.payroll_company).subscribe(res =>
        {
          this.payrollPlatForms = res;
          this.spinnerService.hide();
        });
      }
      else
      {
        this.spinnerService.hide();
      }
    }
    else
    {
      this.spinnerService.hide();
    }

    if( mode == 1 )
    {
      this.clientForm.patchValue({
        payroll_platform_id: ''           
      });
    }
    else
    {
        if( this.clientData.payroll_platform_id == null || this.clientData.payroll_platform_id == '' )
        {
          this.clientForm.patchValue({
            payroll_platform_id: ''           
          });
        }
        // this.getWlAndUsers(0);
    }
  }

  getWlAndUsers(mode:number)
  {
    this.spinnerService.show();

    this.platformWorklist = [];
    this.processors = [];

    if( mode == 1 )
    {
      this.clientForm.patchValue({
        worklist_master: ''
      });
      this.clientForm.patchValue({
        processor_id: ''
      });
    }
    else
    {
      if( this.clientData.worklist_master == null || this.clientData.worklist_master == '' )
      {
        this.clientForm.patchValue({
          worklist_master: ''
        });
        this.clientForm.patchValue({
          processor_id: ''
        });
      }
    }

    if( this.clientForm != undefined && this.clientForm != null && this.clientForm.value != undefined && this.clientForm.value != null )
    {
      if( this.clientForm.value.payroll_platform_id != undefined && this.clientForm.value.payroll_platform_id != null && this.clientForm.value.payroll_platform_id != '' )
      {
        this.clientService.getPayrollPlatform(this.clientForm.value.payroll_platform_id).subscribe(res =>
        {
          var platProcessorSet = false;
          if( res != undefined )
          {
            if( res.worklist_master_info != undefined && res.worklist_master_info != null )
            {
              var wlObj:any = {};
              wlObj.id = res.worklist_master_info.id;
              wlObj.name = res.worklist_master_info.name;
              this.platformWorklist.push(wlObj);
  
              this.clientForm.patchValue({
                worklist_master: res.worklist_master_info.id
              });
            }
            else
            {
              this.clientForm.patchValue({
                worklist_master: ''
              });
            }

            if( res.processor_user_info != undefined && res.processor_user_info != null )
            {
              var puObj:any = {};
              puObj.id = res.processor_user_info.id;
              puObj.name = res.processor_user_info.first_name;
              if( res.processor_user_info.last_name != undefined && res.processor_user_info.last_name != null && res.processor_user_info.last_name != '' )
              {
                puObj.name += " " + res.processor_user_info.last_name;
              }
              this.processors.push(puObj);

              platProcessorSet = true;
              this.clientForm.patchValue({
                processor_id: res.processor_user_info.id
              });
            }
          }
          this.spinnerService.hide();

          if( mode == 1 )
          {
            if( ! platProcessorSet )
            {
              this.clientForm.patchValue({
                processor_id: ''
              });
            }
          }
          else
          {
            if( this.clientData.worklist_master == null || this.clientData.worklist_master == '' )
            {
              this.clientForm.patchValue({
                processor_id: ''
              });
            }
            if( this.clientData.processor_id == null || this.clientData.processor_id == '' )
            {
              this.clientForm.patchValue({
                processor_id: ''
              });
            }
          }
        });
      }
      else
      {
        this.spinnerService.hide();
      }
    }
  }  
  ngOnInit() {
    this.filterValues = [
      {label: "EssHR", value: "EssHR"},
      {label: "AdvHR", value: "AdvHR"},
      {label: "Timekeeping", value: "Timekeeping"},
      {label: "401k", value: "401k"},
      {label: "HR", value: "HR"},
      {label: "RPM", value: "RPM"}
    ];
    this.clientForm = this.formBuilder.group({
      id: '0',
      is_active: '1',
      is_new: '1',
      is_new_c: true,
      is_client_relations: '0',
      is_client_relations_c: false,
      is_prospect: '0',
      is_prospect_c: false,
      worklist: '',
      company_name: ['', Validators.required],
      other_names: '',
      contact: '',
      fein: '',
      sui: '',
      authcontac1: '',
      authcontac2: '',
      authcontac3: '',
      authcontac4: '',
      address: '',
      address_m: '',
      city: '',
      city_m: '',
      state: '',
      state_m: '',
      zip: '',
      zip_m: '',
      website: '',
      phone: '',
      email: '',
      primary_phone: '',
      primary_role:'',
      email_status: '0',
      email_status_c: false,
      primary_phone_status: '0',
      primary_phone_status_c: false,
      rc_fax: '',
      putInFaxQ: 0,
      putInTextQ: 0,
      token_id: '',
      insurance_agency: '',
      insurance_agency_name: '',
      commission_percentage: '',
      broker_commission_percentage: '',
      client_agent: 0,
      agent2:0,
      sia_role: '',
      broker_sia_role: '',
      sia_phone: '',
      sia_email: '',
      broker_sia_email: '',
      am_role: '',
      am_email: '',
      insurance_agency_phone: '',
      insurance_agency_phone_main: '',
      broker_id: '',
      broker_phone: '',
      payroll_company: '',
      payroll_platform_id: '',
      worklist_master: '',
      processor_id: '',
      payroll_frequency: '',
      payroll_agent: '',
      payroll_client_id: '',
      contact_name_1: '',
      contact_email_1: '',
      contact_email_status_1: '0',
      contact_email_status_1_c: false,
      contact_phone_status_1: '0',
      contact_phone_status_1_c: false,
      contact_phone_1: '',
      contact_role_1: '',
      contact_name_2: '',
      contact_email_2: '',
      contact_email_status_2: '0',
      contact_email_status_2_c: false,
      contact_phone_status_2: '0',
      contact_phone_status_2_c: false,
      contact_phone_2: '',
      contact_role_2: '',
      contact_name_3: '',
      contact_email_3: '',
      contact_email_status_3: '0',
      contact_email_status_3_c: false,
      contact_phone_status_3: '0',
      contact_phone_status_3_c: false,
      contact_phone_3: '',
      contact_role_3: '',
      contact_name_4: '',
      contact_email_4: '',
      contact_email_status_4: '0',
      contact_email_status_4_c: false,
      contact_phone_status_4: '0',
      contact_phone_status_4_c: false,
      contact_phone_4: '',
      contact_role_4: '',
      activity_description: ['', Validators.required],
      insurance_carrier: '',
      insurance_carrier_policy: '',
      insurance_carrier_phone: '',
      associate_company_1: '0',
      associate_company_2: '0',
      associate_company_3: '0',
      associate_company_4: '0',
      associate_company_1_c: false,
      associate_company_2_c: false,
      associate_company_3_c: false,
      associate_company_4_c: false,
      method_contact_1: '0',
      method_contact_2: '0',
      method_contact_3: '0',
      method_contact_1_c: false,
      method_contact_2_c: false,
      method_contact_3_c: false,
      contact_def_cc_status: 0,
      contact_def_cc_status_c: false,
      def_inv_type:2,
      other_first_check_date: '',
      other_entered: '',
      other_out_by: '',
      other_fee: '',
      other_garnishments: '',
      other_delivery: '',
      other_charge: '',
      other_reports: '',
      other_upgrade_solutions: [],
      notes: '',
      user_id: this.storage.get('app.auth').id
    });

    this.spinnerService.show();
    //Get worklist users
    this.clientService.getUsers().subscribe(res => {
      // this.worklists = res;
      this.processors = res;
    });
    //Get states
    this.clientService.getStates().subscribe(res => {
      this.states = res;
    });
    //Get Payroll companies
    this.clientService.getPayrollCompaniesClients().subscribe(res => {
      this.payrollCompanies = res;
    });
    //Get Brokers
    this.clientService.getAllBrokers().subscribe(res => {
      this.brokers = res;
    });
    // Get Carriers.
    this.clientService.getCarriers().subscribe(res => {
      this.carriers = res;
    });
    //Get client data
    if (this.id != 0)
    {
      this.spinnerService.show();
      this.clientService.getClient(this.id).subscribe(res => {
        var clientInfo = res;
        this.clientData = res;

        var is_new_c = false;
        var is_client_relations_c = false;
        var is_prospect_c = false;
        if (clientInfo.is_new == 1) {
          is_new_c = true
        }
        if (clientInfo.is_client_relations == 1) {
          is_client_relations_c = true
        }
        if (clientInfo.is_prospect == 1) {
          is_prospect_c = true
        }
        var broker_main_phone = "";
        var broker_sia_role = "";
        var broker_sia_email = "";
        if (clientInfo.broker_data != null) {
          broker_main_phone = clientInfo.broker_data.broker_main_phone;
          broker_sia_role = clientInfo.broker_data.contact_1_role;
          broker_sia_email = clientInfo.broker_data.contact_1_email;
        }
        var agency_web_site = "";
        if (clientInfo.agency_data != null) {
          agency_web_site = clientInfo.agency_data.agency_web_site;
        }
        var agency_main_fax = "";
        if (clientInfo.agency_data != null) {
          agency_main_fax = clientInfo.agency_data.agency_main_fax;
        }
        var agency_main_phone = "";
        if (clientInfo.agency_data != null) {
          agency_main_phone = clientInfo.agency_data.agency_main_phone;
        }
        var broker_id = "";
        if (clientInfo.broker_id != null) {
          broker_id = clientInfo.broker_id;
        }
        var worklist = "";
        if (clientInfo.worklist != null) {
          worklist = clientInfo.worklist;
        }
        var insurance_agency = "";
        if (clientInfo.insurance_agency != null) {
          insurance_agency = clientInfo.insurance_agency;
        }
        var payroll_company = "";
        if (clientInfo.payroll_company != null) {
          payroll_company = clientInfo.payroll_company;
        }
        var payroll_frequency = "";
        if (clientInfo.payroll_frequency != null) {
          payroll_frequency = clientInfo.payroll_frequency;
        }

        // var insurance_carrier = '';
        // var insurance_carrier_policy = '';
        // var insurance_carrier_phone = '';
        // if( clientInfo != null && clientInfo.client_latest_policy != null && clientInfo.client_latest_policy.policy_latest_action != null && clientInfo.client_latest_policy.policy_latest_action.carrier != null ) {
        //   insurance_carrier = clientInfo.client_latest_policy.policy_latest_action.carrier.carrier_name;
        //   insurance_carrier_phone = clientInfo.client_latest_policy.policy_latest_action.carrier.carrier_main_phone;
        // }
        // if( clientInfo != null && clientInfo.client_latest_policy != null && clientInfo.client_latest_policy.policy_latest_action != null && clientInfo.client_latest_policy.policy_latest_action.policy_number != null ) {
        //   insurance_carrier_policy = clientInfo.client_latest_policy.policy_latest_action.policy_number;
        // }

        var email_status_c = false;
        if (clientInfo.email_status == 1) {
          email_status_c = true;
        }
        var primary_phone_status_c = false;
        if (clientInfo.primary_phone_status == 1) {
          primary_phone_status_c = true;
        }
        var contact_def_cc_status_c = false;
        if (clientInfo.enable_def_cc == 1)
        {
          contact_def_cc_status_c = true;
        }
        var other_entered = "";
        if (clientInfo.other_entered != null) {
          other_entered = clientInfo.other_entered;
        }
        var other_out_by = "";
        if (clientInfo.other_out_by != null) {
          other_out_by = clientInfo.other_out_by;
        }
        var other_fee = "";
        if (clientInfo.other_fee != null) {
          other_fee = clientInfo.other_fee;
        }
        var other_garnishments = "";
        if (clientInfo.other_garnishments != null) {
          other_garnishments = clientInfo.other_garnishments;
        }
        var other_delivery = "";
        if (clientInfo.other_delivery != null) {
          other_delivery = clientInfo.other_delivery;
        }
        var other_charge = "";
        if (clientInfo.other_charge != null) {
          other_charge = clientInfo.other_charge;
        }
        var other_reports = "";
        if (clientInfo.other_reports != null) {
          other_reports = clientInfo.other_reports;
        }
        var other_upgrade_solutions = [];
        if (clientInfo.other_upgrade_solutions != null) {
          other_upgrade_solutions = (clientInfo.other_upgrade_solutions).split(', ');
        }

        var clientInfoIsActive = clientInfo.is_active;
        if (clientInfo.is_active == '0' && clientInfo.is_terminated == '1') {
          clientInfoIsActive = '2';
        }
        this.clientForm.patchValue({
          id: this.id,
          is_active: clientInfoIsActive,
          is_new: clientInfo.is_new,
          is_new_c: is_new_c,
          is_client_relations: clientInfo.is_client_relations,
          is_client_relations_c: is_client_relations_c,
          is_prospect:clientInfo.is_prospect,
          is_prospect_c: is_prospect_c,
          worklist: worklist,
          company_name: clientInfo.company_name,
          other_names: clientInfo.other_names,
          contact: clientInfo.contact,
          primary_phone: clientInfo.primary_phone,
          primary_role: clientInfo.primary_role,
          fein: clientInfo.fein,
          sui: clientInfo.sui,
          authcontac1: clientInfo.authcontac1,
          authcontac2: clientInfo.authcontac2,
          authcontac3: clientInfo.authcontac3,
          authcontac4: clientInfo.authcontac4,
          address_m: clientInfo.address_m,
          address: clientInfo.address,
          city: clientInfo.city,
          city_m: clientInfo.city_m,
          state: clientInfo.state,
          state_m: clientInfo.state_m,
          zip: clientInfo.zip,
          zip_m: clientInfo.zip_m,
          website: clientInfo.website,
          phone: clientInfo.phone,
          primary_phone_status: clientInfo.primary_phone_status,
          email: clientInfo.email,
          email_status: clientInfo.email_status,
          email_status_c: email_status_c,
          primary_phone_status_c: primary_phone_status_c,
          contact_def_cc_status: clientInfo.enable_def_cc,
          def_inv_type: clientInfo.def_inv_type,
          contact_def_cc_status_c: contact_def_cc_status_c,
          rc_fax: clientInfo.rc_fax,
          putInFaxQ: clientInfo.put_in_fax_q,
          putInTextQ: clientInfo.text_q_place,
          token_id: clientInfo.token_id,
          insurance_agency: insurance_agency,
          insurance_agency_name: agency_web_site,
          commission_percentage: clientInfo.commission_percentage,
          broker_commission_percentage: clientInfo.broker_commission_percentage,
          client_agent: clientInfo.agent,
          insurance_agency_phone: agency_main_fax,
          insurance_agency_phone_main: agency_main_phone,
          broker_id: broker_id,
          broker_phone: broker_main_phone,
          broker_sia_role: broker_sia_role,
          broker_sia_email: broker_sia_email,
          payroll_company: payroll_company,
          payroll_frequency: payroll_frequency,
          payroll_agent: clientInfo.payroll_agent,
          payroll_client_id: clientInfo.payroll_client_id,
          payroll_platform_id: clientInfo.payroll_platform_id,
          worklist_master: clientInfo.worklist_master,
          processor_id: clientInfo.processor_id,
          // insurance_carrier: insurance_carrier,
          // insurance_carrier_policy: insurance_carrier_policy,
          // insurance_carrier_phone: clientInfo.insurance_carrier_phone,
          other_first_check_date: new Date(clientInfo.other_first_check_date),
          other_entered: other_entered,
          other_out_by: other_out_by,
          other_fee: other_fee,
          other_garnishments: other_garnishments,
          other_delivery: other_delivery,
          other_charge: other_charge,
          other_reports: other_reports,
          other_upgrade_solutions: other_upgrade_solutions,
          user_id: this.storage.get('app.auth').id
        });

        this.getPCompPlatforms(0);

        if(clientInfo.notes != "" && clientInfo.notes != null){
          $('#notesDisp').html(clientInfo.notes);
          this.clientNotes = clientInfo.notes;
        }

        if(clientInfo.carrier != "" && clientInfo.carrier != null){
          // Get Carriers.
          // this.clientService.getCarriers().subscribe(res => {
          //   this.carriers = res;

            this.clientForm.patchValue({
              insurance_carrier:clientInfo.carrier
            });

            // this.displayCarrierInfo();
          // });
        }

        if( this.clientData != undefined && this.clientData != null && this.clientData.agency_data != undefined && this.clientData.agency_data != null && this.clientData.agency_data.id != undefined && this.clientData.agency_data.id != null && clientInfo.insurance_agency != null && clientInfo.insurance_agency )
        {
          this.spinnerService.show();

          //Get Agencies
          this.clientService.getAllAgencies().subscribe(res =>
          {
            this.agencies = res;

            this.displayAgencyInfo();

            if( clientInfo.agent != null )
            {
              this.clientForm.patchValue({
                client_agent:clientInfo.agent
              });
              if( clientInfo.agent )
              {
                this.showAgentInfo(1);
              }
            }
            else
            {
              this.clientForm.patchValue({
                client_agent:0
              });
            }

            if( clientInfo.agent2 != null )
            {
              this.clientForm.patchValue({
                agent2:clientInfo.agent2
              });
              if( clientInfo.agent2 )
              {
                this.showAgentInfo(2);
              }
            }
            else
            {
              this.clientForm.patchValue({
                agent2:0
              });
            }

            this.spinnerService.hide();
          });
        }
        else
        {
          this.spinnerService.show();
          this.clientService.getAllAgencies().subscribe(res =>
          {
            this.agencies = res;

            this.agents = [];
            this.clientForm.patchValue({
              client_agent:0,
              agent2:0,
              sia_role:'',
              sia_email:'',
              sia_phone:'',
              am_role:'',
              am_email:''     
              });

              this.spinnerService.hide();
          });
        }

        this.clientService.getContactsClients(this.id).subscribe(res => {
          var contactsInfo = res;
          if (contactsInfo.length == 1) {
            var contactsInfo1 = contactsInfo[0];
            var contact_email_status_1_c = false;
            if (contactsInfo1.email_status == 1) {
              contact_email_status_1_c = true;
            }
            var contact_phone_status_1_c = false;
            if (contactsInfo1.phone_status == 1) {
              contact_phone_status_1_c = true;
            }
            this.clientForm.patchValue({
              contact_name_1: contactsInfo1.name,
              contact_email_1: contactsInfo1.email,
              contact_email_status_1: contactsInfo1.email_status,
              contact_email_status_1_c: contact_email_status_1_c,
              contact_phone_status_1: contactsInfo1.phone_status,
              contact_phone_status_1_c: contact_phone_status_1_c,
              contact_phone_1: contactsInfo1.phone,
              contact_role_1: contactsInfo1.role
            });
          } else if (contactsInfo.length == 2) {
            var contactsInfo1 = contactsInfo[0];
            var contactsInfo2 = contactsInfo[1];
            var contact_email_status_1_c = false;
            if (contactsInfo1.email_status == 1) {
              contact_email_status_1_c = true;
            }
            var contact_email_status_2_c = false;
            if (contactsInfo2.email_status == 1) {
              contact_email_status_2_c = true;
            }
            var contact_phone_status_1_c = false;
            if (contactsInfo1.phone_status == 1) {
              contact_phone_status_1_c = true;
            }
            var contact_phone_status_2_c = false;
            if (contactsInfo2.phone_status == 1) {
              contact_phone_status_2_c = true;
            }
            this.clientForm.patchValue({
              contact_name_1: contactsInfo1.name,
              contact_email_1: contactsInfo1.email,
              contact_email_status_1: contactsInfo1.email_status,
              contact_email_status_1_c: contact_email_status_1_c,
              contact_phone_1: contactsInfo1.phone,
              contact_phone_status_1: contactsInfo1.phone_status,
              contact_phone_status_1_c: contact_phone_status_1_c,
              contact_role_1: contactsInfo1.role,

              contact_name_2: contactsInfo2.name,
              contact_email_2: contactsInfo2.email,
              contact_email_status_2: contactsInfo2.email_status,
              contact_email_status_2_c: contact_email_status_2_c,
              contact_phone_2: contactsInfo2.phone,
              contact_phone_status_2: contactsInfo2.phone_status,
              contact_phone_status_2_c: contact_phone_status_2_c,
              contact_role_2: contactsInfo2.role
            });
          } else if (contactsInfo.length == 3) {
            var contactsInfo1 = contactsInfo[0];
            var contactsInfo2 = contactsInfo[1];
            var contactsInfo3 = contactsInfo[2];
            var contact_email_status_1_c = false;
            if (contactsInfo1.email_status == 1) {
              contact_email_status_1_c = true;
            }
            var contact_email_status_2_c = false;
            if (contactsInfo2.email_status == 1) {
              contact_email_status_2_c = true;
            }
            var contact_email_status_3_c = false;
            if (contactsInfo3.email_status == 1) {
              contact_email_status_3_c = true;
            }
            var contact_phone_status_1_c = false;
            if (contactsInfo1.phone_status == 1) {
              contact_phone_status_1_c = true;
            }
            var contact_phone_status_2_c = false;
            if (contactsInfo2.phone_status == 1) {
              contact_phone_status_2_c = true;
            }
            var contact_phone_status_3_c = false;
            if (contactsInfo3.phone_status == 1) {
              contact_phone_status_3_c = true;
            }
            this.clientForm.patchValue({
              contact_name_1: contactsInfo1.name,
              contact_email_1: contactsInfo1.email,
              contact_email_status_1: contactsInfo1.email_status,
              contact_email_status_1_c: contact_email_status_1_c,
              contact_phone_1: contactsInfo1.phone,
              contact_phone_status_1: contactsInfo1.phone_status,
              contact_phone_status_1_c: contact_phone_status_1_c,
              contact_role_1: contactsInfo1.role,

              contact_name_2: contactsInfo2.name,
              contact_email_2: contactsInfo2.email,
              contact_email_status_2: contactsInfo2.email_status,
              contact_email_status_2_c: contact_email_status_2_c,
              contact_phone_status_2: contactsInfo2.phone_status,
              contact_phone_status_2_c: contact_phone_status_2_c,
              contact_phone_2: contactsInfo2.phone,
              contact_role_2: contactsInfo2.role,

              contact_name_3: contactsInfo3.name,
              contact_email_3: contactsInfo3.email,
              contact_email_status_3: contactsInfo3.email_status,
              contact_email_status_3_c: contact_email_status_3_c,
              contact_phone_3: contactsInfo3.phone,
              contact_phone_status_3: contactsInfo3.phone_status,
              contact_phone_status_3_c: contact_phone_status_3_c,
              contact_role_3: contactsInfo3.role
            });
          } else if (contactsInfo.length == 4) {
            var contactsInfo1 = contactsInfo[0];
            var contactsInfo2 = contactsInfo[1];
            var contactsInfo3 = contactsInfo[2];
            var contactsInfo4 = contactsInfo[3];
            var contact_email_status_1_c = false;
            if (contactsInfo1.email_status == 1) {
              contact_email_status_1_c = true;
            }
            var contact_email_status_2_c = false;
            if (contactsInfo2.email_status == 1) {
              contact_email_status_2_c = true;
            }
            var contact_email_status_3_c = false;
            if (contactsInfo3.email_status == 1) {
              contact_email_status_3_c = true;
            }
            var contact_email_status_4_c = false;
            if (contactsInfo4.email_status == 1) {
              contact_email_status_4_c = true;
            }
            var contact_phone_status_1_c = false;
            if (contactsInfo1.phone_status == 1) {
              contact_phone_status_1_c = true;
            }
            var contact_phone_status_2_c = false;
            if (contactsInfo2.phone_status == 1) {
              contact_phone_status_2_c = true;
            }
            var contact_phone_status_3_c = false;
            if (contactsInfo3.phone_status == 1) {
              contact_phone_status_3_c = true;
            }
            var contact_phone_status_4_c = false;
            if (contactsInfo4.phone_status == 1) {
              contact_phone_status_4_c = true;
            }
            this.clientForm.patchValue({
              contact_name_1: contactsInfo1.name,
              contact_email_1: contactsInfo1.email,
              contact_email_status_1: contactsInfo1.email_status,
              contact_email_status_1_c: contact_email_status_1_c,
              contact_phone_1: contactsInfo1.phone,
              contact_phone_status_1: contactsInfo1.phone_status,
              contact_phone_status_1_c: contact_phone_status_1_c,
              contact_role_1: contactsInfo1.role,

              contact_name_2: contactsInfo2.name,
              contact_email_2: contactsInfo2.email,
              contact_email_status_2: contactsInfo2.email_status,
              contact_email_status_2_c: contact_email_status_2_c,
              contact_phone_2: contactsInfo2.phone,
              contact_phone_status_2: contactsInfo2.phone_status,
              contact_phone_status_2_c: contact_phone_status_2_c,
              contact_role_2: contactsInfo2.role,

              contact_name_3: contactsInfo3.name,
              contact_email_3: contactsInfo3.email,
              contact_email_status_3: contactsInfo3.email_status,
              contact_email_status_3_c: contact_email_status_3_c,
              contact_phone_3: contactsInfo3.phone,
              contact_phone_status_3: contactsInfo3.phone_status,
              contact_phone_status_3_c: contact_phone_status_3_c,
              contact_role_3: contactsInfo3.role,

              contact_name_4: contactsInfo4.name,
              contact_email_4: contactsInfo4.email,
              contact_email_status_4: contactsInfo4.email_status,
              contact_email_status_4_c: contact_email_status_4_c,
              contact_phone_4: contactsInfo4.phone,
              contact_phone_status_4: contactsInfo4.phone_status,
              contact_phone_status_4_c: contact_phone_status_4_c,
              contact_role_4: contactsInfo4.role
            });
          }
          this.clientService.getActivityLog(this.id).subscribe(res => {
            this.activityInfo = res;
            this.clientService.getASCompanies(this.id).subscribe(res => {
              var asCompaniesInfo = res;
              if (asCompaniesInfo.length == 1) {
                var associate_company_c = false;
                if (asCompaniesInfo[0].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[0].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[0].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[0].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[0].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[0].options,
                    associate_company_4_c: associate_company_c
                  });
                }

              } else if (asCompaniesInfo.length == 2) {

                var associate_company_c = false;
                if (asCompaniesInfo[0].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[0].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[0].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[0].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[0].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[0].options,
                    associate_company_4_c: associate_company_c
                  });
                }

                associate_company_c = false;
                if (asCompaniesInfo[1].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[1].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[1].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[1].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[1].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[1].options,
                    associate_company_4_c: associate_company_c
                  });
                }
              } else if (asCompaniesInfo.length == 3) {
                var associate_company_c = false;
                if (asCompaniesInfo[0].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[0].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[0].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[0].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[0].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[0].options,
                    associate_company_4_c: associate_company_c
                  });
                }

                associate_company_c = false;
                if (asCompaniesInfo[1].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[1].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[1].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[1].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[1].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[1].options,
                    associate_company_4_c: associate_company_c
                  });
                }

                associate_company_c = false;
                if (asCompaniesInfo[2].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[2].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[2].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[2].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[2].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[2].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[2].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[2].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[2].options,
                    associate_company_4_c: associate_company_c
                  });
                }
              } else if (asCompaniesInfo.length == 4) {
                var associate_company_c = false;
                if (asCompaniesInfo[0].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[0].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[0].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[0].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[0].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[0].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[0].options,
                    associate_company_4_c: associate_company_c
                  });
                }

                associate_company_c = false;
                if (asCompaniesInfo[1].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[1].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[1].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[1].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[1].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[1].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[1].options,
                    associate_company_4_c: associate_company_c
                  });
                }

                associate_company_c = false;
                if (asCompaniesInfo[2].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[2].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[2].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[2].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[2].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[2].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[2].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[2].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[2].options,
                    associate_company_4_c: associate_company_c
                  });
                }

                associate_company_c = false;
                if (asCompaniesInfo[3].options != 0) {
                  associate_company_c = true;
                }
                if (asCompaniesInfo[3].options == 1) {
                  this.clientForm.patchValue({
                    associate_company_1: asCompaniesInfo[3].options,
                    associate_company_1_c: associate_company_c
                  });
                } else if (asCompaniesInfo[3].options == 2) {
                  this.clientForm.patchValue({
                    associate_company_2: asCompaniesInfo[3].options,
                    associate_company_2_c: associate_company_c
                  });
                } else if (asCompaniesInfo[3].options == 3) {
                  this.clientForm.patchValue({
                    associate_company_3: asCompaniesInfo[3].options,
                    associate_company_3_c: associate_company_c
                  });
                } else if (asCompaniesInfo[3].options == 4) {
                  this.clientForm.patchValue({
                    associate_company_4: asCompaniesInfo[3].options,
                    associate_company_4_c: associate_company_c
                  });
                }
              }
              this.clientService.getMethodOfContacts(this.id).subscribe(res => {
                var methodOfContactsInfo = res;
                if (methodOfContactsInfo.length == 1) {
                  var method_contact_c = false;
                  if (methodOfContactsInfo[0].methods != 0) {
                    method_contact_c = true;
                  }
                  if (methodOfContactsInfo[0].methods == 1) {
                    this.clientForm.patchValue({
                      method_contact_1: methodOfContactsInfo[0].methods,
                      method_contact_1_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[0].methods == 2) {
                    this.clientForm.patchValue({
                      method_contact_2: methodOfContactsInfo[0].methods,
                      method_contact_2_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[0].methods == 3) {
                    this.clientForm.patchValue({
                      method_contact_3: methodOfContactsInfo[0].methods,
                      method_contact_3_c: method_contact_c
                    });
                  }
                } else if (methodOfContactsInfo.length == 2) {
                  var method_contact_c = false;
                  if (methodOfContactsInfo[0].methods != 0) {
                    method_contact_c = true;
                  }
                  if (methodOfContactsInfo[0].methods == 1) {
                    this.clientForm.patchValue({
                      method_contact_1: methodOfContactsInfo[0].methods,
                      method_contact_1_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[0].methods == 2) {
                    this.clientForm.patchValue({
                      method_contact_2: methodOfContactsInfo[0].methods,
                      method_contact_2_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[0].methods == 3) {
                    this.clientForm.patchValue({
                      method_contact_3: methodOfContactsInfo[0].methods,
                      method_contact_3_c: method_contact_c
                    });
                  }

                  method_contact_c = false;
                  if (methodOfContactsInfo[1].methods != 0) {
                    method_contact_c = true;
                  }
                  if (methodOfContactsInfo[1].methods == 1) {
                    this.clientForm.patchValue({
                      method_contact_1: methodOfContactsInfo[1].methods,
                      method_contact_1_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[1].methods == 2) {
                    this.clientForm.patchValue({
                      method_contact_2: methodOfContactsInfo[1].methods,
                      method_contact_2_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[1].methods == 3) {
                    this.clientForm.patchValue({
                      method_contact_3: methodOfContactsInfo[1].methods,
                      method_contact_3_c: method_contact_c
                    });
                  }
                } else if (methodOfContactsInfo.length == 3) {
                  var method_contact_c = false;
                  if (methodOfContactsInfo[0].methods != 0) {
                    method_contact_c = true;
                  }
                  if (methodOfContactsInfo[0].methods == 1) {
                    this.clientForm.patchValue({
                      method_contact_1: methodOfContactsInfo[0].methods,
                      method_contact_1_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[0].methods == 2) {
                    this.clientForm.patchValue({
                      method_contact_2: methodOfContactsInfo[0].methods,
                      method_contact_2_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[0].methods == 3) {
                    this.clientForm.patchValue({
                      method_contact_3: methodOfContactsInfo[0].methods,
                      method_contact_3_c: method_contact_c
                    });
                  }

                  method_contact_c = false;
                  if (methodOfContactsInfo[1].methods != 0) {
                    method_contact_c = true;
                  }
                  if (methodOfContactsInfo[1].methods == 1) {
                    this.clientForm.patchValue({
                      method_contact_1: methodOfContactsInfo[1].methods,
                      method_contact_1_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[1].methods == 2) {
                    this.clientForm.patchValue({
                      method_contact_2: methodOfContactsInfo[1].methods,
                      method_contact_2_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[1].methods == 3) {
                    this.clientForm.patchValue({
                      method_contact_3: methodOfContactsInfo[1].methods,
                      method_contact_3_c: method_contact_c
                    });
                  }

                  method_contact_c = false;
                  if (methodOfContactsInfo[2].methods != 0) {
                    method_contact_c = true;
                  }
                  if (methodOfContactsInfo[2].methods == 1) {
                    this.clientForm.patchValue({
                      method_contact_1: methodOfContactsInfo[2].methods,
                      method_contact_1_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[2].methods == 2) {
                    this.clientForm.patchValue({
                      method_contact_2: methodOfContactsInfo[2].methods,
                      method_contact_2_c: method_contact_c
                    });
                  } else if (methodOfContactsInfo[2].methods == 3) {
                    this.clientForm.patchValue({
                      method_contact_3: methodOfContactsInfo[2].methods,
                      method_contact_3_c: method_contact_c
                    });
                  }
                }
                this.spinnerService.hide();
              });
            });
          });
        });
      });
    }
    else
    {
      //Get Agencies
      this.clientService.getAllAgencies().subscribe(res => {
        this.agencies = res;
        this.spinnerService.hide();

        if(Array.isArray(this.payrollCompanies)){
          if(this.payrollCompanies[0]) {
            this.clientForm.patchValue({
              payroll_company: this.payrollCompanies[0].id
            });
            this.getPCompPlatforms(1);
          }
        }
      });
    }
  }
  getIsNew(e) {
    if (e == true) {
      this.clientForm.patchValue({
        is_new: '1'
      });
    } else {
      this.clientForm.patchValue({
        is_new: '0'
      });
    }
  }
  getIsClientRelations(e) {
    if (e == true) {
      this.clientForm.patchValue({
        is_client_relations: '1'
      });
    } else {
      this.clientForm.patchValue({
        is_client_relations: '0'
      });
    }
  }
  getIsProspect(e) {
    if (e == true) {
      this.clientForm.patchValue({
        is_prospect: '1'
      });
    } else {
      this.clientForm.patchValue({
        is_prospect: '0'
      });
    }
  }

  fillMailingAsPhy(e)
  {
    if( e )
    {
      if( this.clientForm.controls['address'].value != undefined && this.clientForm.controls['address'].value != null )
      {
        this.clientForm.controls['address_m'].setValue(this.clientForm.controls['address'].value);
      }
      if( this.clientForm.controls['city'].value != undefined && this.clientForm.controls['city'].value != null )
      {
        this.clientForm.controls['city_m'].setValue(this.clientForm.controls['city'].value);
      }
      if( this.clientForm.controls['state'].value != undefined && this.clientForm.controls['state'].value != null )
      {
        this.clientForm.controls['state_m'].setValue(this.clientForm.controls['state'].value);
      }
      if( this.clientForm.controls['zip'].value != undefined && this.clientForm.controls['zip'].value != null )
      {
        this.clientForm.controls['zip_m'].setValue(this.clientForm.controls['zip'].value);
      }
    }
  }


  setContPhoneStatus(e, t)
  {
    if (e == true)
    {
      if (t == 1) {
        this.clientForm.patchValue({
          contact_phone_status_1: '1'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          contact_phone_status_2: '1'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          contact_phone_status_3: '1'
        });
      } else if (t == 4) {
        this.clientForm.patchValue({
          contact_phone_status_4: '1'
        });
      } else if (t == 5) {
        this.clientForm.patchValue({
          primary_phone_status: '1'
        });
      }
    }
    else
    {
      if (t == 1) {
        this.clientForm.patchValue({
          contact_phone_status_1: '0'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          contact_phone_status_2: '0'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          contact_phone_status_3: '0'
        });
      } else if (t == 4) {
        this.clientForm.patchValue({
          contact_phone_status_4: '0'
        });
      } else if (t == 5) {
        this.clientForm.patchValue({
          primary_phone_status: '0'
        });
      }
    }
  }

  setDefCCStatus(e)
  {
    if( e == true )
    {
      this.clientForm.patchValue({
        contact_def_cc_status: 1
      });
    }
    else
    {
      this.clientForm.patchValue({
        contact_def_cc_status: 0
      });
    }
  }

  setWlOfPrComp()
  {
    this.clientForm.controls['worklist'].setValue( '' );

    var pay_id = this.clientForm.value.payroll_company;
    if( pay_id != "")
    {
      var matchPay = this.payrollCompanies.filter(p => p.id == pay_id);
      if( matchPay != undefined && typeof matchPay[0] !== undefined && matchPay[0].worklist != undefined )
      {
        this.clientForm.controls['worklist'].setValue( matchPay[0].worklist );
      }
    }
  }

  getContactEmail(e, t) {
    if (e == true) {
      if (t == 1) {
        this.clientForm.patchValue({
          contact_email_status_1: '1'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          contact_email_status_2: '1'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          contact_email_status_3: '1'
        });
      } else if (t == 4) {
        this.clientForm.patchValue({
          contact_email_status_4: '1'
        });
      } else if (t == 0) {
        this.clientForm.patchValue({
          email_status: '1'
        });
      }
    } else {
      if (t == 1) {
        this.clientForm.patchValue({
          contact_email_status_1: '0'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          contact_email_status_2: '0'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          contact_email_status_3: '0'
        });
      } else if (t == 4) {
        this.clientForm.patchValue({
          contact_email_status_4: '0'
        });
      } else if (t == 0) {
        this.clientForm.patchValue({
          email_status: '0'
        });
      }
    }
  }
  associateComapny(e, t) {
    if (e == true) {
      if (t == 1) {
        this.clientForm.patchValue({
          associate_company_1: '1'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          associate_company_2: '2'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          associate_company_3: '3'
        });
      } else if (t == 4) {
        this.clientForm.patchValue({
          associate_company_4: '4'
        });
      }
    } else {
      if (t == 1) {
        this.clientForm.patchValue({
          associate_company_1: '0'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          associate_company_2: '0'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          associate_company_3: '0'
        });
      } else if (t == 4) {
        this.clientForm.patchValue({
          associate_company_4: '0'
        });
      }
    }
  }
  methodContact(e, t) {
    if (e == true) {
      if (t == 1) {
        this.clientForm.patchValue({
          method_contact_1: '1'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          method_contact_2: '2'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          method_contact_3: '3'
        });
      }
    } else {
      if (t == 1) {
        this.clientForm.patchValue({
          method_contact_1: '0'
        });
      } else if (t == 2) {
        this.clientForm.patchValue({
          method_contact_2: '0'
        });
      } else if (t == 3) {
        this.clientForm.patchValue({
          method_contact_3: '0'
        });
      }
    }
  }
  // displayPayrollInfo() {
  //   var pay_id = this.clientForm.value.payroll_company;
  //   if (pay_id != "") {
  //     var matchPay = this.payrollCompanies.filter(p => p.id == pay_id);
  //     this.clientForm.patchValue({
  //       payroll_agent: matchPay[0].payroll_rep_referring
  //     });
  //   }
  // }

  showAgentInfo( agentType:number=0 )
  {
    this.spinnerService.show();
    if( agentType == 1 )
    {
        var client_agent = this.clientForm.value.client_agent;
        var agency_id = this.clientForm.value.insurance_agency;
        if( client_agent != "" && agency_id != "" )
        {
          var matchAgency = this.agencies.filter(a => a.id == agency_id);
          var sia_role = "";
          var sia_email = "";
          var sia_phone = "";
          
          if( client_agent == 1 )
          {
            sia_role = matchAgency[0].contact_1_role;
            sia_email = matchAgency[0].contact_1_email;
            sia_phone = matchAgency[0].contact_1_phone;
          }
          else if( client_agent == 2 )
          {
            sia_role = matchAgency[0].contact_2_role;
            sia_email = matchAgency[0].contact_2_email;
            sia_phone = matchAgency[0].contact_2_phone;
          }
          else if( client_agent == 3 )
          {
            sia_role = matchAgency[0].contact_3_role;
            sia_email = matchAgency[0].contact_3_email;
            sia_phone = matchAgency[0].contact_3_phone;
          }
          else if( client_agent == 4 )
          {
            sia_role = matchAgency[0].contact_4_role;
            sia_email = matchAgency[0].contact_4_email;
            sia_phone = matchAgency[0].contact_4_phone;
          }
          else if( client_agent == 5 )
          {
            sia_role = matchAgency[0].contact_5_role;
            sia_email = matchAgency[0].contact_5_email;
            sia_phone = matchAgency[0].contact_5_phone;
          }
          else if( client_agent == 6 )
          {
            sia_role = matchAgency[0].contact_6_role;
            sia_email = matchAgency[0].contact_6_email;
            sia_phone = matchAgency[0].contact_6_phone;
          }
          else if( client_agent == 7 )
          {
            sia_role = matchAgency[0].contact_7_role;
            sia_email = matchAgency[0].contact_7_email;
            sia_phone = matchAgency[0].contact_7_phone;
          }
          else if( client_agent == 8 )
          {
            sia_role = matchAgency[0].contact_8_role;
            sia_email = matchAgency[0].contact_8_email;
            sia_phone = matchAgency[0].contact_8_phone;
          }
          else if( client_agent == 9 )
          {
            sia_role = matchAgency[0].contact_9_role;
            sia_email = matchAgency[0].contact_9_email;
            sia_phone = matchAgency[0].contact_9_phone;
          }
          else if( client_agent == 10 )
          {
            sia_role = matchAgency[0].contact_10_role;
            sia_email = matchAgency[0].contact_10_email;
            sia_phone = matchAgency[0].contact_10_phone;
          }

          this.clientForm.patchValue
          ({
            sia_role: sia_role,
            sia_email: sia_email,
            sia_phone: sia_phone,
          });
        }
    }
    else if( agentType == 2 )
    {
        var agent2 = this.clientForm.value.agent2;
        var agency_id = this.clientForm.value.insurance_agency;
        if( agent2 != "" && agency_id != "" )
        {
          var matchAgency = this.agencies.filter(a => a.id == agency_id);
          var am_role = "";
          var am_email = "";
          
          if( agent2 == 1 )
          {
            am_role = matchAgency[0].contact_1_role;
            am_email = matchAgency[0].contact_1_email;
          }
          else if( agent2 == 2 )
          {
            am_role = matchAgency[0].contact_2_role;
            am_email = matchAgency[0].contact_2_email;
          }
          else if( agent2 == 3 )
          {
            am_role = matchAgency[0].contact_3_role;
            am_email = matchAgency[0].contact_3_email;
          }
          else if( agent2 == 4 )
          {
            am_role = matchAgency[0].contact_4_role;
            am_email = matchAgency[0].contact_4_email;
          }
          else if( agent2 == 5 )
          {
            am_role = matchAgency[0].contact_5_role;
            am_email = matchAgency[0].contact_5_email;
          }
          else if( agent2 == 6 )
          {
            am_role = matchAgency[0].contact_6_role;
            am_email = matchAgency[0].contact_6_email;
          }
          else if( agent2 == 7 )
          {
            am_role = matchAgency[0].contact_7_role;
            am_email = matchAgency[0].contact_7_email;
          }
          else if( agent2 == 8 )
          {
            am_role = matchAgency[0].contact_8_role;
            am_email = matchAgency[0].contact_8_email;
          }
          else if( agent2 == 9 )
          {
            am_role = matchAgency[0].contact_9_role;
            am_email = matchAgency[0].contact_9_email;
          }
          else if( agent2 == 10 )
          {
            am_role = matchAgency[0].contact_10_role;
            am_email = matchAgency[0].contact_10_email;
          }

          this.clientForm.patchValue
          ({
            am_role: am_role,
            am_email: am_email,
          });
        }
    }
    this.spinnerService.hide();
  }

  displayCarrierInfo(){
    this.spinnerService.show();
    var carrier_id = this.clientForm.value.insurance_carrier;
    if (carrier_id != "")
    {
      var matchCarrier = this.carriers.filter(a => a.value == carrier_id);
      this.clientForm.patchValue({
        insurance_carrier_phone: matchCarrier[0].phone
      });
    }
    else
    {
      this.clientForm.patchValue({
        insurance_carrier_phone: ""
      });
    }
    this.spinnerService.hide();
  }

  displayAgencyInfo()
  {
    this.spinnerService.show();

    var agency_id = this.clientForm.value.insurance_agency;
    if (agency_id != "")
    {
      var matchAgency = this.agencies.filter(a => a.id == agency_id);
      this.clientForm.patchValue({
        insurance_agency_phone_main: matchAgency[0].agency_main_phone,
        sia_role: matchAgency[0].contact_1_role,
        sia_email: matchAgency[0].contact_1_email,
        sia_phone: matchAgency[0].contact_1_phone
      });

      this.agents = [];
      var tempAgents = [];

      if( matchAgency[0].contact_1_name != undefined && matchAgency[0].contact_1_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 1;
        this.currAgent.label = matchAgency[0].contact_1_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_2_name != undefined && matchAgency[0].contact_2_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 2;
        this.currAgent.label = matchAgency[0].contact_2_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_3_name != undefined && matchAgency[0].contact_3_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 3;
        this.currAgent.label = matchAgency[0].contact_3_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_4_name != undefined && matchAgency[0].contact_4_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 4;
        this.currAgent.label = matchAgency[0].contact_4_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_5_name != undefined && matchAgency[0].contact_5_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 5;
        this.currAgent.label = matchAgency[0].contact_5_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_6_name != undefined && matchAgency[0].contact_6_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 6;
        this.currAgent.label = matchAgency[0].contact_6_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_7_name != undefined && matchAgency[0].contact_7_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 7;
        this.currAgent.label = matchAgency[0].contact_7_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_8_name != undefined && matchAgency[0].contact_8_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 8;
        this.currAgent.label = matchAgency[0].contact_8_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_9_name != undefined && matchAgency[0].contact_9_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 9;
        this.currAgent.label = matchAgency[0].contact_9_name;
        tempAgents.push(this.currAgent);
      }
      if( matchAgency[0].contact_10_name != undefined && matchAgency[0].contact_10_name != null )
      {
        this.currAgent = {};
        this.currAgent.value = 10;
        this.currAgent.label = matchAgency[0].contact_10_name;
        tempAgents.push(this.currAgent);
      }

      this.agents = tempAgents;
      
      var firstAgentValue = 1;
      if(this.agents.length != 0)
      {
        firstAgentValue = this.agents[0].value;
      }
      this.clientForm.patchValue
      ({
        client_agent: firstAgentValue,
        // agent2:0,
        // sia_role:'',
        // sia_email:'',
        // am_role:'',
        // am_email:''     
      });
      this.spinnerService.hide();
    }
    else
    {
      this.agents = [];
      this.clientForm.patchValue({
        client_agent:0,
        insurance_agency_phone_main: '',
        agent2:0,
        sia_role:'',
        sia_email:'',
        sia_phone:'',
        am_role:'',
        am_email:''     
      });
      this.spinnerService.hide();
    }
  }

  displayBrokerInfo() {
    var broker_idd = this.clientForm.value.broker_id;
    if (broker_idd != "") {
      var matchBroker = this.brokers.filter(a => a.id == broker_idd);
      this.clientForm.patchValue({
        broker_phone: matchBroker[0].broker_main_phone,
        broker_commission_percentage: matchBroker[0].broker_commission_percentage,
        broker_sia_role: matchBroker[0].contact_1_role,
        broker_sia_email: matchBroker[0].contact_1_email
      });
    }
  }

  usFormattedFein(){
    var usDateValue = this.clientForm.value.fein;
    if(usDateValue != ""){
      var formattedNumber = String(usDateValue.replace(/[- )(]/g, ''));
      
      var start = formattedNumber.substring(0, 2);
      var end = formattedNumber.substring(2, 9);

      var finalFormattedNumber = start + "-" + end;

      this.clientForm.controls['fein'].setValue(finalFormattedNumber);
    }
  }

  usFormattedFax(faxlabel) {
    if (faxlabel == 1) {
      this.usDateValue = this.clientForm.value.rc_fax;
    }

    this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
    
    // # (###) ###-#### as c (area) front-end
    this.area = this.formattedNumber.substring(0, 3);
    this.front = this.formattedNumber.substring(3, 6);
    this.end = this.formattedNumber.substring(6, this.formattedNumber.length);

    if (this.front) {
      this.formattedNumber = ("(" + this.area + ") " + this.front);
    }
    if (this.end) {
      this.formattedNumber += ("-" + this.end);
    }
    if (faxlabel == 1) {
      this.clientForm.controls['rc_fax'].setValue(this.formattedNumber);
    }
  }

  usFormatteddate(phonenumbrerlabel) {
    if (phonenumbrerlabel == 1) {
      this.usDateValue = this.clientForm.value.phone;
    } else if (phonenumbrerlabel == 2) {
      this.usDateValue = this.clientForm.value.contact_phone_1;
    } else if (phonenumbrerlabel == 3) {
      this.usDateValue = this.clientForm.value.contact_phone_2;
    } else if (phonenumbrerlabel == 4) {
      this.usDateValue = this.clientForm.value.contact_phone_3;
    }
    else if (phonenumbrerlabel == 5)
    {
      this.usDateValue = this.clientForm.value.contact_phone_4;
    }
    else if (phonenumbrerlabel == 6)
    {
      this.usDateValue = this.clientForm.value.primary_phone;
    }

    this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
    //  this.c = (this.formattedNumber[0] == '1') ? '1 ' : '';
    //   this.formattedNumber = this.formattedNumber[0] == '1' ? this.formattedNumber.slice(1) : this.formattedNumber;

    // # (###) ###-#### as c (area) front-end
    this.area = this.formattedNumber.substring(0, 3);
    this.front = this.formattedNumber.substring(3, 6);
    this.end = this.formattedNumber.substring(6);

    if (this.front) {
      this.formattedNumber = ("(" + this.area + ") " + this.front);
    }
    if (this.end) {
      this.formattedNumber += ("-" + this.end);
    }

    // New Code for Ext Cleaned
    if( this.formattedNumber != undefined && this.formattedNumber != null )
    {
      var pContent = this.formattedNumber.toLowerCase().split( "ext" );
      var finalNum = "";
      if( typeof pContent !== 'undefined' && pContent.length )
      {
        if( typeof pContent[0] !== 'undefined' )
        {
          finalNum = pContent[0];
        }
        if( typeof pContent[1] !== 'undefined' )
        {
          finalNum += " Ext " + pContent[1];
        }
        this.formattedNumber = finalNum;
      }
    }

    if (phonenumbrerlabel == 1) {
      this.clientForm.controls['phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 2) {
      this.clientForm.controls['contact_phone_1'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 3) {
      this.clientForm.controls['contact_phone_2'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 4) {
      this.clientForm.controls['contact_phone_3'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 5)
    {
      this.clientForm.controls['contact_phone_4'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 6)
    {
      this.clientForm.controls['primary_phone'].setValue(this.formattedNumber);
    }

  }
  submitClientForm() {
    this.worklistError = 'none';
    this.company_nameError = 'none';
    this.other_namesError = 'none';
    this.contactError = 'none';
    this.feinError = 'none';
    this.suiError = 'none';
    this.addressError = 'none';
    this.cityError = 'none';
    this.stateError = 'none';
    this.zipError = 'none';
    this.address_mError = 'none';
    this.city_mError = 'none';
    this.state_mError = 'none';
    this.zip_mError = 'none';
    this.websiteError = 'none';
    this.phoneError = 'none';
    this.emailError = 'none';
    this.rc_faxError = 'none';
    this.token_idError = 'none';
    this.insurance_agencyError = 'none';
    this.broker_idError = 'none';
    this.payroll_companyError = 'none';
    this.payroll_frequencyError = 'none';
    this.payroll_agentError = 'none';
    this.contact_name_1Error = 'none';
    this.contact_email_1Error = 'none';
    this.contact_phone_1Error = 'none';
    this.contact_role_1Error = 'none';
    this.activity_descriptionError = 'none';
    this.associate_companyError = 'none';
    this.method_contactError = 'none';
    this.payroll_client_idError = 'none';
    if (this.clientForm.valid) {
      // var associate = 0;
      // var associate_company_1_c = this.clientForm.value.associate_company_1_c;
      // var associate_company_2_c = this.clientForm.value.associate_company_2_c;
      // var associate_company_3_c = this.clientForm.value.associate_company_3_c;
      // var associate_company_4_c = this.clientForm.value.associate_company_4_c;
      // var method = 0;
      // var method_contact_1_c = this.clientForm.value.method_contact_1_c;
      // var method_contact_2_c = this.clientForm.value.method_contact_2_c;
      // var method_contact_3_c = this.clientForm.value.method_contact_3_c;
      // if (associate_company_1_c == true || associate_company_2_c == true || associate_company_3_c == true || associate_company_4_c == true) {
      //   this.associate_companyError = 'none';
      // } else {
      //   this.associate_companyError = 'block';
      //   associate = 1;
      // }
      // if (method_contact_1_c == true || method_contact_1_c == true || method_contact_3_c == true) {
      //   this.method_contactError = 'none';
      // } else {
      //   this.method_contactError = 'block';
      //   method = 1;
      // }
      // if (associate == 0 && method == 0) {

        if(this.editorDisp == true){
          this.clientForm.patchValue({
            notes: $('#notesDisp').html()
          });
        }else{
          this.clientForm.patchValue({
            notes: $('#clientNotes').summernote('code')
          });
        }
        
        this.clientService.saveClientDataa(this.clientForm.value).subscribe(res => {
          this.spinnerService.hide();
          this.success_message = true;
          this.return_client_id = res.client_id;
        });
      // }
    } else {
      //console.log(this.clientForm.value);
      // if (this.clientForm.value.worklist == "" || this.clientForm.value.worklist == null) {
      //   this.worklistError = 'block';
      // } else {
      //   this.worklistError = 'none';
      // }
      if (this.clientForm.value.company_name == "" || this.clientForm.value.company_name == null) {
        this.company_nameError = 'block';
      } else {
        this.company_nameError = 'none';
      }
      // if (this.clientForm.value.other_names == "" || this.clientForm.value.other_names == null) {
      //   this.other_namesError = 'block';
      // } else {
      //   this.other_namesError = 'none';
      // }
      // if (this.clientForm.value.contact == "" || this.clientForm.value.contact == null) {
      //   this.contactError = 'block';
      // } else {
      //   this.contactError = 'none';
      // }
      // if (this.clientForm.value.fein == "" || this.clientForm.value.fein == null) {
      //   this.feinError = 'block';
      // } else {
      //   this.feinError = 'none';
      // }
      // if (this.clientForm.value.address == "" || this.clientForm.value.address == null) {
      //   this.addressError = 'block';
      // } else {
      //   this.addressError = 'none';
      // }
      // if (this.clientForm.value.city == "" || this.clientForm.value.city == null) {
      //   this.cityError = 'block';
      // } else {
      //   this.cityError = 'none';
      // }
      // if (this.clientForm.value.state == "" || this.clientForm.value.state == null) {
      //   this.stateError = 'block';
      // } else {
      //   this.stateError = 'none';
      // }
      // if (this.clientForm.value.zip == "" || this.clientForm.value.zip == null) {
      //   this.zipError = 'block';
      // } else {
      //   this.zipError = 'none';
      // }
      // if (this.clientForm.value.address_m == "" || this.clientForm.value.address_m == null) {
      //   this.address_mError = 'block';
      // } else {
      //   this.address_mError = 'none';
      // }
      // if (this.clientForm.value.city_m == "" || this.clientForm.value.city_m == null) {
      //   this.city_mError = 'block';
      // } else {
      //   this.city_mError = 'none';
      // }
      // if (this.clientForm.value.state_m == "" || this.clientForm.value.state_m == null) {
      //   this.state_mError = 'block';
      // } else {
      //   this.state_mError = 'none';
      // }
      // if (this.clientForm.value.zip_m == "" || this.clientForm.value.zip_m == null) {
      //   this.zip_mError = 'block';
      // } else {
      //   this.zip_mError = 'none';
      // }
      // if (this.clientForm.value.website == "" || this.clientForm.value.website == null) {
      //   this.websiteError = 'block';
      // } else {
      //   this.websiteError = 'none';
      // }
      // if (this.clientForm.value.phone == "" || this.clientForm.value.phone == null) {
      //   this.phoneError = 'block';
      // } else {
      //   this.phoneError = 'none';
      // }
      // if (this.clientForm.value.email == "" || this.clientForm.value.email == null) {
      //   this.emailError = 'block';
      // } else {
      //   this.emailError = 'none';
      // }
      // if (this.clientForm.value.rc_fax == "" || this.clientForm.value.rc_fax == null) {
      //   this.rc_faxError = 'block';
      // } else {
      //   this.rc_faxError = 'none';
      // }
      // if (this.clientForm.value.token_id == "" || this.clientForm.value.token_id == null) {
      //   this.token_idError = 'block';
      // } else {
      //   this.token_idError = 'none';
      // }
      // if (this.clientForm.value.insurance_agency == "" || this.clientForm.value.insurance_agency == null) {
      //   this.insurance_agencyError = 'block';
      // } else {
      //   this.insurance_agencyError = 'none';
      // }
      // if (this.clientForm.value.broker_id == "" || this.clientForm.value.broker_id == null) {
      //   this.broker_idError = 'block';
      // } else {
      //   this.broker_idError = 'none';
      // }
      // if (this.clientForm.value.payroll_company == "" || this.clientForm.value.payroll_company == null) {
      //   this.payroll_companyError = 'block';
      // } else {
      //   this.payroll_companyError = 'none';
      // }
      // if (this.clientForm.value.payroll_frequency == "" || this.clientForm.value.payroll_frequency == null) {
      //   this.payroll_frequencyError = 'block';
      // } else {
      //   this.payroll_frequencyError = 'none';
      // }
      // if (this.clientForm.value.payroll_agent == "" || this.clientForm.value.payroll_agent == null) {
      //   this.payroll_agentError = 'block';
      // } else {
      //   this.payroll_agentError = 'none';
      // }
      // if (this.clientForm.value.payroll_client_id == "" || this.clientForm.value.payroll_client_id == null) {
      //   this.payroll_client_idError = 'block';
      // } else {
      //   this.payroll_client_idError = 'none';
      // }
      // if (this.clientForm.value.contact_name_1 == "" || this.clientForm.value.contact_name_1 == null) {
      //   this.contact_name_1Error = 'block';
      // } else {
      //   this.contact_name_1Error = 'none';
      // }
      // if (this.clientForm.value.contact_email_1 == "" || this.clientForm.value.contact_email_1 == null) {
      //   this.contact_email_1Error = 'block';
      // } else {
      //   this.contact_email_1Error = 'none';
      // }
      // if (this.clientForm.value.contact_phone_1 == "" || this.clientForm.value.contact_phone_1 == null) {
      //   this.contact_phone_1Error = 'block';
      // } else {
      //   this.contact_phone_1Error = 'none';
      // }
      // if (this.clientForm.value.contact_role_1 == "" || this.clientForm.value.contact_role_1 == null) {
      //   this.contact_role_1Error = 'block';
      // } else {
      //   this.contact_role_1Error = 'none';
      // }

      if (this.clientForm.value.activity_description == "" || this.clientForm.value.activity_description == null) {
       this.activity_descriptionError = 'block';
      } else {
      this.activity_descriptionError = 'none';
      }

      // var associate_company_1_c = this.clientForm.value.associate_company_1_c;
      // var associate_company_2_c = this.clientForm.value.associate_company_2_c;
      // var associate_company_3_c = this.clientForm.value.associate_company_3_c;
      // var associate_company_4_c = this.clientForm.value.associate_company_4_c;
      // var method_contact_1_c = this.clientForm.value.method_contact_1_c;
      // var method_contact_2_c = this.clientForm.value.method_contact_2_c;
      // var method_contact_3_c = this.clientForm.value.method_contact_3_c;
      // if (associate_company_1_c == true || associate_company_2_c == true || associate_company_3_c == true || associate_company_4_c == true) {
      //   this.associate_companyError = 'none';
      // } else {
      //   this.associate_companyError = 'block';
      // }
      // if (method_contact_1_c == true || method_contact_1_c == true || method_contact_3_c == true) {
      //   this.method_contactError = 'none';
      // } else {
      //   this.method_contactError = 'block';
      // }
    }
  }
  goToClientsListPage() {
    this.router.navigate(['/clients/list/view/', this.return_client_id]);
  }
}
