import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Setup } from './setup';
import { CompletedActivity } from './completedactivity';
import { SetupChangeResponse } from './setup-change-response';
import { CompletedActivityChangeResponse } from './completedactivity-change-response';
import { Observable } from 'rxjs';

@Injectable()
export class SetupService {

    readonly apiUrl;
    public selectedSetup: Setup;

    constructor(private http: HttpClient, private auth: AuthService,
      config: ConfigServiceService) {
        this.apiUrl = config.getApiUrl();
    }

    getSetUpLog(client_id: Number, filter: string = '') {
        const token = this.auth.getToken();
        return this.http.get<Setup[]>(this.apiUrl + '/setups?client_id=' + client_id + '&filter=' + filter, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    saveSetup(setup: Setup): Observable<any>
    {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/setups', setup,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    getSetup(id: number)
    {
        return new Observable<any>((observer) => {
    
            // Clear Client
            this.selectedSetup = null;
    
            // Load Client
            const token = this.auth.getToken();
            this.http.get<Setup>(this.apiUrl + '/setups/' + id, {
              headers: {
                Authorization: 'Bearer ' + token
              }
            }).subscribe(res => {
              this.selectedSetup = res;
    
              observer.next(this.selectedSetup);
              observer.complete();
            });
    
          /*} else {
            // Return cached object
    
            observer.next(this.selectedSetup);
            observer.complete();
          }*/
        });
    }

    updateSetupData(id: number, data: Setup)
    {

      const token = this.auth.getToken();
      return this.http.put<SetupChangeResponse>(this.apiUrl + '/setups/' + id, data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    
    deleteSetup(data: Setup)
    {
      const token = this.auth.getToken();
      return this.http.delete<SetupChangeResponse>(this.apiUrl + '/setup/delete/' +data.id, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    }

    saveCompActivity(completedActivity: CompletedActivity): Observable<any>
    {
        const token = this.auth.getToken();
        return this.http.post(this.apiUrl + '/completedactivities', completedActivity,{
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    getCompletedActivities(client_id: Number)
    {
      const token = this.auth.getToken();
      return this.http.get<CompletedActivity[]>(this.apiUrl + '/completedactivities?client_id=' + client_id, {
          headers: {
              Authorization: 'Bearer ' + token
          }
      });
    }
  
    deleteCompletedActivity(data: CompletedActivity)
    {
      const token = this.auth.getToken();
      return this.http.delete<CompletedActivityChangeResponse>(this.apiUrl + '/completedactivity/delete/' +data.id, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    
}
