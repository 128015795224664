import { Component, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Client } from "../client";
import { ActivatedRoute, Router} from "@angular/router";
import { ClientService } from "../client.service";
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from '../../core';

declare var $: any;

@Component({
  selector: 'client-notes',
  templateUrl: './client-notes.component.html',
  styleUrls: ['./client-notes.component.css']
})
export class ClientNotesComponent implements OnInit {

    id: number;
    client: Client;

    submitted: boolean = false;
    showSavedMsg:boolean = false;

    editorDisp: boolean = true;

    notesPanelDisp:string = 'none';

    constructor(aroute:ActivatedRoute, private router:Router,
        public location:Location, private clientService:ClientService,
        private fb: FormBuilder,private messageService: MessageService
        ,private spinnerService: SpinnerService) {
        aroute.params.subscribe(params => {
            this.id = params['id'];
        });
    }

    editorLoadedCl()
    {
        console.log('Loaded...');
        var pickerSpans:any = null;
        pickerSpans = document.querySelectorAll('[data-value="#facccc"]');
        if( pickerSpans != undefined && pickerSpans != null )
        {
            for( var el = 0;el < pickerSpans.length;el++ )
            {
                if( pickerSpans[el] )
                {
                    pickerSpans[el].style.backgroundColor = "#fa00cc";
                    pickerSpans[el].setAttribute('data-value','#fa00cc');
                }
            }
        }

        var pickerSelects:any = null;
        pickerSelects = document.querySelectorAll('[value="#facccc"]');
        if( pickerSelects != undefined && pickerSelects != null )
        {
            for( var op = 0;op < pickerSelects.length;op++ )
            {
                if( pickerSelects[op] )
                {
                    pickerSelects[op].setAttribute('value','#fa00cc');
                }
            }
        }
    }

    allowEdit()
    {
        this.editorDisp = false;

        setTimeout(() => {

            $('#clientNotes').summernote({
                toolbar: [
                    //[groupname, [button list]]
                    ["style", ['style']],
                    ["font", ["bold",'italic', "underline", "clear"]],
                    ['color', ['forecolor','backcolor']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['fontname', ['fontname']],
                    ['fullscreen', ['fullscreen']],
                    ['link', ['link']],
                ],
                height:320,
                focus:true
               
            });
    
            // $('.note-toolbar span').removeClass('note-icon-caret');
            $('div .note-toolbar').find("div.note-style").find("span.note-icon-caret").removeClass('note-icon-caret');
            $('div .note-toolbar').find("div.note-fontname").find("span.note-icon-caret").removeClass('note-icon-caret');
            $('div .note-toolbar').find("div.note-para").find("span.note-icon-caret").removeClass('note-icon-caret');
            $('div .note-toolbar').find("div.note-table").find("span.note-icon-caret").removeClass('note-icon-caret');

            $('.note-editable').css('font-family','Times New Roman');
            $('div .note-btn-group .note-color i').css( 'background-color','#ffffff' );

            $('#clientNotes').summernote('fontName','Times New Roman');
            $('#clientNotes').summernote('code', this.client.notes);
        
        }, 105);
    }

    ngAfterViewInit()
    {
        $(document).ready(function()
        {
            setTimeout(() => {

                if( this.client != undefined && this.client != null && this.client.notes != undefined && this.client.notes != null )
                {
                  $('#notesDisp').html(this.client.notes);
                }
        
            }, 3000);
                
        }.bind(this));
    }
    
    ngOnInit() {

        this.spinnerService.show();

        // Load client
        this.clientService.getOnlyClientData(this.id).subscribe(res => {
          this.client = res;

          this.notesPanelDisp = 'block';
          this.spinnerService.hide();
          /*
          setTimeout(() => {

            // this.editorDisp = false;
            this.spinnerService.hide();

            }, 1000);
        */

                
        });
    }

    saveNote(){
        this.submitted = true;

        this.client.notes = $('#clientNotes').summernote('code');

        this.clientService.saveClientData(this.id,this.client).subscribe(
            response => {
                console.log(response);              
                if(response instanceof HttpErrorResponse) {
                    this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
                } else {
                    // this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Note updated successfully'});
                    this.submitted = false;
                    this.editorDisp = true;
                    this.showSavedMsg = true;

                    $('#clientNotes').summernote('destroy');

                    setTimeout(() => {
                        $('#notesDisp').html(this.client.notes);
                    }, 50);

                    setTimeout(() => {
                        this.showSavedMsg = false;
                    }, 2500);
                }
                
            },
            error => {
                // console.log(error);
                this.submitted = false;
                this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
        });
    }
    
}