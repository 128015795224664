import { QueueOptions } from './queue-options';
import { DocumentBatch } from './document-batch';

export class DocumentQueue {
  id: number;
  type: string;
  name: string;
  description: string;
  settings: QueueOptions;

  batches: DocumentBatch[];
  batch: DocumentBatch;

  created_at: Date;
  updated_at: Date;

  constructor(data: any = {}) {
    // Set normal fields
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.description = data.description;

    // Parse date fields
    this.created_at = new Date(Date.parse(data.created_at));
    this.updated_at = new Date(Date.parse(data.updated_at));

    // Parse other fields
    if(data.settings) {
      this.settings = new QueueOptions(data.settings);
    } else {
      this.settings = new QueueOptions();
    }
    this.batches = data.batches;
    this.batch = data.batch;
  }
}
