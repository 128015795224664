import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ClientService } from '../client.service';
import { Client } from "../client";
import { SpinnerService } from '../../core';
import { DataService } from '../../data.service';
import { ConfirmationService } from 'primeng/api';
import { DocumentService } from '../../settings/document.service';

declare var $: any;

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {
  q: string;
  paramPassQ: string;
  d = '1';
  filter: string;
  first = 0;
  skip = 0;
  take = 100;
  clientsCount: any;
  clients: any;
  deleteClientId:any = 0;
  deleteClientConfirmPopup:any = false;

  sortField = 'company_name';
  sortOrder = 1;
  selectedAll = false;
  queueSettingIds = [];
  showInvoiceAlert = false;
  sendEmailEmails:any = "";
  sendEmailSubject:any = "";

  constructor(private aroute: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private client: ClientService,
    private docService: DocumentService,
    private spinnerService: SpinnerService,
    private dataService: DataService) { }

  ngOnInit() {
    this.dataService.currentMenuClient.subscribe(d => setTimeout(() => this.d = d, 0));
    // Initial Load
    this.aroute.queryParams.subscribe(params =>
      {
      this.q = params['q'];
      this.paramPassQ = params['q']

      if( typeof params['q'] != undefined && params['q'] != null )
      {
        this.q = this.q.replace(/!!!/g,'&');
      }

      this.d = params['d'];
      this.filter = params['filter'];
      this.skip = 0;
      this.take = 100;
      this.first = 0;
      this.refresh();
    });
    if(typeof this.d != "undefined"){
      this.dataService.changeMenuClient(this.d);
    }else{
      this.dataService.changeMenuClient('1');
    }
    
  }

  gotoClientView(event,clientId)
  {
    event.preventDefault();
    this.router.navigateByUrl('/clients/list/view/' + clientId);
  }

  refresh() {
    this.client.getClientsEmail(this.skip, this.take, this.sortField, this.sortOrder).subscribe(res => {
      console.log(res.clients);
     
      this.clientsCount = res.allClients;
      this.clients = res.clients;
      this.spinnerService.hide();
    });
  }

  deleteClient(clientId:any)
  {
    this.deleteClientId = clientId;
    this.deleteClientConfirmPopup = true;
  }

  deleteClientConfirm()
  {
    
    this.client.deleteClient(this.deleteClientId).subscribe(res => {
      this.deleteClientConfirmPopup = false;
      this.refresh();
    });
  }

  closeClientConfirm()
  {
    this.deleteClientConfirmPopup = false;
  }

  loadData(event) {
    this.skip = event.first;
    this.take = event.rows;
    this.sortField = event.sortField;
    this.sortOrder = event.sortOrder;
    this.refresh();
  }

  checkUncheckAll(e){
    
    if(e.target.checked == true){
      this.selectedAll = true;
      var key = 0;
      this.clients.forEach((queueClientinfo) => {
        this.clients[key].selected_checkbox = true;
        var objectClient = {};
        objectClient['id'] = queueClientinfo.id;
        objectClient['email'] = queueClientinfo.email;
        objectClient['company_name'] = queueClientinfo.company_name;
        this.queueSettingIds.push(objectClient);
        key++;
      });
    }else{
      this.queueSettingIds = [];
      this.selectedAll = false;
      var key = 0;
      this.clients.forEach((queueClientinfo) => {
        this.clients[key].selected_checkbox = false;
        key++;
      });
    }
  }

  checkBoxEvent(id, e, i){

    if( i >= this.take )
    {
      i = i % this.take;
    }

    if(e.target.checked == true){
      this.clients[i].selected_checkbox = true;
      var objectClient = {};
      objectClient['id'] = id;
      var queueClientinfo = this.clients.filter(item => item.id == id);
 
	 var contact_emails = queueClientinfo[0].email + ',';
	  queueClientinfo[0].contacts_data.forEach((emailslist) => {
		  if(emailslist.email != null && emailslist.email != '' && emailslist.email != 'null'){
		  contact_emails += emailslist.email + ','; 
	  } 
	  });
	  contact_emails = contact_emails.slice(0, -1);
      objectClient['email'] = contact_emails;
      objectClient['company_name'] = queueClientinfo[0].company_name;
      this.queueSettingIds.push(objectClient);

      this.selectedAll = this.clients.every(function(item:any) {
        return item.selected_checkbox == true;
      });

    }else{
      this.selectedAll = false;
      this.clients[i].selected_checkbox = false;
      this.queueSettingIds = this.queueSettingIds.filter(item => item.id != id);
    }
  }
  
  sendEmailToClientsClose()
  {
    this.showInvoiceAlert = false;
    this.sendEmailEmails = "";
    this.sendEmailSubject = "";
    $('#templBodyEd').summernote('code', "");
  }
  
  openEmailTemplate()
  {
    if(this.queueSettingIds.length != 0)
    {
		
		
      this.showInvoiceAlert = true;
      var allSelectedEmails = "";
      this.queueSettingIds.forEach((emailC) => {
        if(emailC.email != "")
        { 
          allSelectedEmails += emailC.email+',';
        }
      });
      this.sendEmailEmails = allSelectedEmails.slice(0, -1);

      this.docService.getTemplate(3).subscribe((res) => {
        this.sendEmailSubject = res.description;
        $('#templBodyEd').summernote('code', res.body);
        this.spinnerService.hide();
      });
    }
    else
    {
      alert('Please select client');
    }
  }

  sendEmailToClients()
  {
    var sendEmailBody = $('#templBodyEd').summernote('code');
    let postObject = {};
    postObject['clientEmailIdName'] = this.queueSettingIds;
    postObject['sendEmailEmails'] = this.sendEmailEmails;
    postObject['subject'] = this.sendEmailSubject;
    postObject['body'] = sendEmailBody;

    if(this.sendEmailEmails != "" && this.sendEmailSubject != "" && sendEmailBody != "")
    {
      this.client.sendMailsToClinets(postObject).subscribe(() => {
        this.spinnerService.hide();
        this.sendEmailEmails = "";
        this.sendEmailSubject = "";
        $('#templBodyEd').summernote('code', "");
        this.showInvoiceAlert = false;
        this.queueSettingIds = [];
        this.selectedAll = false;
        this.refresh();
      });
    }
    else
    {
      alert('Please fill all fields');
    }
  }

  ngAfterViewInit()
  {

    $(document).ready(function()
    {
        setTimeout(() => {
          $('#templBodyEd').summernote({
            toolbar: [
                ["style", ['style']],
                ["font", ["bold",'italic', "underline", "clear"]],
                ['color', ['forecolor','backcolor']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['fontname', ['fontname']],
                ['fullscreen', ['fullscreen']],
                ['link', ['link']],
            ],
            height:250
          });
  
          $('div .note-toolbar').find("div.note-style").find("span.note-icon-caret").removeClass('note-icon-caret');
          $('div .note-toolbar').find("div.note-fontname").find("span.note-icon-caret").removeClass('note-icon-caret');
          $('div .note-toolbar').find("div.note-para").find("span.note-icon-caret").removeClass('note-icon-caret');
          $('div .note-toolbar').find("div.note-table").find("span.note-icon-caret").removeClass('note-icon-caret');
      
          $('.note-editable').css('font-family','Times New Roman');
          $('div .note-btn-group .note-color i').css( 'background-color','#ffffff' );
    
          $('#templBodyEd').summernote('fontName','Times New Roman');
          $('#templBodyEd').summernote('code', "Test Abc");
        }, 1500);
    }.bind(this));
  }

}
