import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Client } from './client';
import { Agency } from './agency';
import { ClientChangeResponse } from './client-change-response';
import { Observable } from 'rxjs';
import { Stats } from './stats';
import { State } from './state';
import { ClassCode } from './class-code';
import { NumberSymbol } from '@angular/common';
import { SpinnerService } from '../core';

@Injectable()
export class AgencyService {

  readonly apiUrl;
  public selectedClient: Client;

  constructor(private http: HttpClient, 
    private auth: AuthService, 
    config: ConfigServiceService,
    private spinnerService: SpinnerService) {
    this.apiUrl = config.getApiUrl();
  }

  addAgency(activity: Agency): Observable<any> {
    const token = this.auth.getToken();
    return this.http.post(this.apiUrl + '/agencies', activity, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
 
  updateAgency(id: number,data: Agency) {
    const token = this.auth.getToken();
    return this.http.put<Agency>(this.apiUrl + '/agencies/' + id,data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getAllAgencies(skip, take, search) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Agency[]>(this.apiUrl + '/agencies?skip=' + skip + '&take=' +take + '&search=' +search, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
  }
  
  getAgency(agencyId:number) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Agency[]>(this.apiUrl + '/agencies?id='+agencyId, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
  }

  deleteAgency(agencyId:number): Observable<any>
  {
    const token = this.auth.getToken();
    return this.http.get<Agency>(this.apiUrl + '/agencies?deleteId=' +agencyId, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
}
