import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { WorklistService } from '../worklist.service';
import { Worklist } from '../worklist';
import { Message } from 'primeng/api';
import { SpinnerService } from '../../core';

@Component({
  selector: 'app-worklist-list',
  templateUrl: './worklist-list.component.html',
  styleUrls: ['./worklist-list.component.css']
})
export class WorklistListComponent implements OnInit {
  worklists: any;

  msgs: Message[] = [];
  redError = "red";

  q: string;
  skip: any;
  take: any;

  worklistsCount: any;

  confirmDialogVisible = false;
  confirmMessage: any;
  actions_user_id: any;

  actions_type: any;

  constructor(
    private formBuilder: FormBuilder,
    private worklistService: WorklistService,
    private authService: AuthService,
    private router: Router,
    private aroute: ActivatedRoute,
    private spinnerService: SpinnerService) { }

  ngOnInit()
  {
    this.aroute.queryParams.subscribe(params => {
      this.q = params['q'];
      this.skip = 0;
      this.take = 25;

      this.spinnerService.show();
      this.loadWorklists();
    });
  }

  loadWorklists()
  {
    if(typeof this.q == 'undefined')
    {
      this.q = "";
    }
    this.worklistService.getWorklistsAll(this.q, this.skip, this.take).subscribe(
      (res) => {
        this.worklistsCount = res[0];
        this.worklists = res[1];
        this.spinnerService.hide();
      });
  }

  loadData(event)
  {
    this.skip = event.first;
    this.take = event.rows;
    this.loadWorklists();
  }

  deleteWorklist(id)
  {
    this.actions_user_id = id;
    this.actions_type = 3;
    this.confirmMessage = 'Are you sure want to delete this worklist?';
    this.confirmDialogVisible = true;
  }

  cancelWorklistAction()
  {
    this.actions_user_id = 0;
    this.actions_type = 0;
    this.confirmMessage = '';
    this.confirmDialogVisible = false;
  }

  confirmWorklistAction()
  {
    if(this.actions_type == 3)
    {
        this.worklistService.deleteWorklist(this.actions_user_id).subscribe(res => {
          this.cancelWorklistAction();
          // Display message for 2 seconds
          this.msgs.push({ severity: 'success', summary: 'Message', detail: 'Worklist deleted successfully' });
          setTimeout(() => {
            this.msgs = [];
          }, 2000);
          // Reload Worklists
          this.loadWorklists();
        });
      }
    }
  }
