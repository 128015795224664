import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  isLogged:any;
  constructor(private auth: AuthService, 
    private router: Router) { }

  ngOnInit() {
    this.auth.logout().subscribe((res) => {
      localStorage.clear();
      this.router.navigateByUrl('/login');
    });
  }

}
