export class DocumentInvoiceDescriptions {
  id: number;
  name: string;
  commission: number;
  price: string;
  value: string;
  label: string;

  created_at: Date;
  updated_at: Date;

  constructor(data: any = {}) {
    // Set normal fields
    this.id = data.id;
    this.name = data.name;
    this.commission = data.commission;
    this.price = data.price;

    // Parse date fields
    this.created_at = new Date(Date.parse(data.created_at));
    this.updated_at = new Date(Date.parse(data.updated_at));

    // Parse other fields
  }
}
