import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { User } from '../user';
import { Message, SelectItem } from 'primeng/api';
import { emailValidator } from '../../shared';
import { SpinnerService } from '../../core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  userForm: FormGroup;
  id: number;
  user: User = {} as User;
  roles: SelectItem[];
  msgs: Message[] = [];
  newUser = 0;
  loggedUserId:any;
  LoginUserName: any;
  redError = 'red';
  first_nameError = 'none';
  last_nameError = 'none';
  emailError = 'none';
  roleError = 'none';
  emailError_message = "";
  constructor(
    private formBuilder: FormBuilder,
    aroute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dataService: DataService,
    private spinnerService: SpinnerService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
    
  }

  ngOnInit() {
    this.dataService.currentUserName.subscribe(LoginUserName => setTimeout(() => this.LoginUserName = LoginUserName, 0));
    this.userForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, emailValidator]],
      role: ['', Validators.required],
      userId: 0
    });

    if (typeof this.id != 'undefined') {
      this.newUser = this.id;
    }

    this.loadUser();
  }

  loadUser() {
    // Load User
    if (typeof this.id != 'undefined') {
      this.userService.getUser(this.id).subscribe(res => {
        this.user = res;
        this.userForm.patchValue({
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          role: this.user.role,
          userId: this.user.id,
        });
        this.spinnerService.hide();
      });
    }
    // Load Roles
    this.roles = [
      { label: 'Select Role', value: null },
      { label: 'Super Admin', value: 'Super Admin' },
      { label: 'Normal User', value: 'Normal User' }
    ];
  }

  saveUser() {
    this.first_nameError = 'none';
    this.last_nameError = 'none';
    this.emailError = 'none';
    this.roleError = 'none';
    this.emailError_message = "";
    this.msgs = [];
    if (this.userForm.valid) {
      //this.spinnerService.show();
      if (this.newUser == 0) {
        this.userService.createUser(this.userForm.value).subscribe(res => {
          if (res.output == 1) {
            this.msgs.push({ severity: 'success', summary: 'Message', detail: res.message });
          } else if(res.output == 2) {
            this.msgs.push({ severity: 'error', summary: 'Warnnig', detail: 'Please add email template before create the user' });
          }else {
            this.msgs.push({ severity: 'error', summary: 'Warnnig', detail: 'Email already exists' });
          }
          setTimeout(() => {
            if (res.output == 1) {
              this.msgs = [];
              this.spinnerService.hide();
              this.router.navigate(['settings/users']);
            }
          }, 2000);
        });
      } else {
        this.userService.updateUser(this.id, this.userForm.value).subscribe(res => {
          if (res.output == 1) {
            this.msgs.push({ severity: 'success', summary: 'Message', detail: res.message });
          } else {
            this.msgs.push({ severity: 'error', summary: 'Warnnig', detail: 'Email already exists' });
          }
          setTimeout(() => {
            if (res.output == 1) {
              this.msgs = [];
              this.spinnerService.hide();
              this.router.navigate(['settings/users']);
            }
          }, 2000);
        });
		this.loggedUserId = localStorage.getItem('userId');
		if(this.loggedUserId == this.id){
			this.LoginUserName = this.userForm.value.first_name+' '+this.userForm.value.last_name;
			localStorage.setItem('userName',this.LoginUserName);
			this.dataService.changeUserName(this.LoginUserName);
		}
      }
    } else {
      if (this.userForm.value.first_name == "") {
        this.first_nameError = 'block';
      }
      if (this.userForm.value.last_name == "") {
        this.last_nameError = 'block';
      }
      if (this.userForm.value.email == "") {
        this.emailError = 'block';
        this.emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.userForm.value.email)) {
          this.emailError = 'block';
          this.emailError_message = 'Email Format is not valid';
        }
      }
      if (this.userForm.value.role == "") {
        this.roleError = 'block';
      }
    }
  }

  logoutUser() {
    this.userService.logoutUser(this.id).subscribe(res => {
      // Display message for 2 seconds
      this.msgs.push({ severity: 'success', summary: 'Message', detail: 'User updated successfully' });
      setTimeout(() => {
        this.msgs = [];
      }, 2000);
      // Reload User
      this.loadUser();
    });
  }

}
