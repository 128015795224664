export class InvoiceMetadata {
  invoice_type: string;
  admin_fee: number;
  setup_fee: number;
  credit_card_fee: number;
  nsf_fee: number;
  retry_payment_fee: number;
  check_date: Date;
  payroll_end_date: Date;
  report_date: Date;
  ach_withdrawal_date: Date;

  constructor(data: any = {}) {
    this.invoice_type = data.invoice_type;
    this.admin_fee = data.admin_fee;
    this.setup_fee = data.setup_fee;
    this.nsf_fee = data.nsf_fee;
    this.retry_payment_fee = data.retry_payment_fee;

    // Parse date fields
    this.check_date = new Date(Date.parse(data.check_date));
    this.payroll_end_date = new Date(Date.parse(data.payroll_end_date));
    this.report_date = new Date(Date.parse(data.report_date));
    this.ach_withdrawal_date = new Date(Date.parse(data.ach_withdrawal_date));
  }
}
