import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../reports.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from '../../core';
import { ConfigServiceService } from '../../config-service.service';
import { PolicyService } from '../../client/policy.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-automated-remit-report',
  templateUrl: './automated-remit-report.component.html',
  styleUrls: ['./automated-remit-report.component.css']
})

export class AutomatedRemitReportComponent implements OnInit {

  msgs: Message[] = [];
  error: Message[] = [];

  collectedList: any = [];
  invRemsArr: string[];
  automatedRemitCarrIds = [];
  selectedClientIds = [];
  carriers: any = [];
  clients: any = [];
  start_checkDate: string;
  end_checkDate: string;
  start_reportingDate: string;
  end_reportingDate: string;
  sortedCarrierName: any = [];
  selectedCarrierNameString: string;
  loggedInUserId: any;
  remitLogResponse: any = [];
  clientIds: any = [];
  selectedCarrierIdsArray: any = [];
  showClientNameDropdown: boolean = true;
  dataObject: {};
  selectedClientsToString: string;
  isButtonDisabled: boolean = true;
  // error: string;
  isError:boolean = false;

  constructor(private reportService: ReportsService, private spinnerService: SpinnerService,
    private configServiceService: ConfigServiceService, private policyService: PolicyService,
    private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {

    this.reportService.getCarrierInfo().subscribe(
      response => { this.carriers = response },
      error => { alert('Something went wrong!!'); })

    this.loggedInUserId = localStorage.getItem('userId');
  }

  // loadCollected() {
  //   this.spinnerService.show();
  //   this.invRemsArr = [];
  //   this.collectedList = [];
  //   this.reportService.getCollected(this.clientIds).subscribe(
  //     response => {
  //       if (response instanceof HttpErrorResponse) {
  //         this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
  //       } else {
  //         this.collectedList = <any[]>response;
  //         this.spinnerService.hide();
  //       }
  //     },
  //     error => {
  //       this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
  //   });
  // }

  automatedRemitReport() {
    // console.log("@41", this.dataObject)
    this.reportService.automatedRemitAction(this.dataObject)
      .subscribe(
        (response) => {
          if (response instanceof HttpErrorResponse) {
            this.isError = true;
            // this.error = response.error.message;
            this.error = [{ severity: 'error', summary: 'Rejected', detail: response.error.message }];
          }
          else if (response.status == 200){
            let remitLogIdsArr = [];
            let clientIdsArr = [];
            this.remitLogResponse = response.remitLogData;
            this.spinnerService.hide();
            this.remitLogResponse.forEach(element => {
              remitLogIdsArr.push(element.id);
              clientIdsArr.push(element.client_id);
            });
            this.spinnerService.hide();

            let unique_clientIdsArr = [... new Set(clientIdsArr)];
            this.clientIds = unique_clientIdsArr;
           // this.loadCollected();
            //this.sendClientIds();

            for (var i = 0; i < this.collectedList.length; i++) {
              var invoice = this.collectedList[i];
              if (invoice != undefined && invoice !== null) {
                if (invoice.id != undefined && invoice.id !== null && invoice.is_remitted != undefined && invoice.is_remitted !== null) {
                  if (this.invRemsArr.indexOf(invoice.id) !== -1) {
                    invoice.is_remitted = 1;
                    if (invoice.invoice_status != undefined && invoice.invoice_status != null) {
                      invoice.invoice_status = "Remitted";
                    }
                  }
                }
              }
            }
            // console.log("API URL-", this.configServiceService.getApiUrl() + '/automatedremitreportpdf?download=pdf&remitLogId=' + remitLogIdsArr + '&clientIds=' + unique_clientIdsArr, 'blank');
            window.open(this.configServiceService.getApiUrl() + '/automatedremitreportpdf?download=pdf&remitLogId=' + remitLogIdsArr + '&clientIds=' + unique_clientIdsArr, 'blank');
          }
          else if (response.status == 500) {
            // this.error = "No invoices found to Remit !"
            this.error = [{ severity: 'error', summary: 'Rejected', detail: 'No invoices found to Remit !' }];
          }
          else {
            this.isError = true;
            // this.error = "Remit Report Exists with these dates, not allowed to run !!";
            this.error = [{ severity: 'error', summary: 'Rejected', detail: 'Remit Report Exists with these dates, not allowed to run !!' }];
            this.spinnerService.hide();
          }
        },
        (error) => {
          this.isError = true;
          // this.error = "Something went wrong !! Try Later";
          this.error = [{ severity: 'error', summary: 'Rejected', detail: 'Something went wrong !! Try Later' }];
          this.spinnerService.hide();
        }
      )
  }

  selectingCarrier(event) {
    this.selectedCarrierIdsArray.push(event.itemValue.id);
    // console.log(this.selectedCarrierIdsArray);
    this.reportService.getClientName(this.selectedCarrierIdsArray)
      .subscribe(
        (response) => {
          this.showClientNameDropdown = false;
          this.clients = response.data;
        },
        (error) => {
          alert("Something went wrong");
        }
      )
  }

  confirm() {
    let selectedCarrierNames = [];
    if (this.automatedRemitCarrIds !== null && this.automatedRemitCarrIds !== undefined) {
      this.automatedRemitCarrIds.forEach(element => {
        selectedCarrierNames.push(element.id);
        this.selectedCarrierNameString = selectedCarrierNames.toString();
      });
    }

    let selectedClients = [];
    if (this.selectedClientIds !== null && this.selectedClientIds !== undefined) {
      this.selectedClientIds.forEach(element => {
        selectedClients.push(element.id);
        this.selectedClientsToString = selectedClients.toString();
      });

    }

    // Inserted dummy data for testing purpose
    // this.selectedCarrierNameString = "127";
    // this.selectedClientsToString = "9000,9062";
    // this.start_checkDate = "08/19/2022";
    // this.end_checkDate = "09/16/2022";
    // this.start_reportingDate = "09/01/2022";
    // this.end_reportingDate = "09/30/2022";
  
    this.dataObject = {
      "carriers": this.selectedCarrierNameString,
      "clients": this.selectedClientsToString,
      "start_check_date": this.start_checkDate,
      "end_check_date": this.end_checkDate,
      "start_reporting_date": this.start_reportingDate,
      "end_reporting_date": this.end_reportingDate,
      "user_id": this.loggedInUserId
    }

    this.confirmationService.confirm({
      message: `Are you sure that you want to proceed with these details?<br>
      <b>Check Dates:-</b> ${this.start_checkDate} - ${this.end_checkDate} <br>
      <b>Reporting Dates:-</b> ${this.start_reportingDate} - ${this.end_reportingDate} <br>`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.spinnerService.show();
        this.automatedRemitReport();
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
        this.spinnerService.hide();
      }
    });
  }

  sendClientIds() {
    this.reportService.sendClientIdstoAutomatedRemitLog(this.clientIds).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.collectedList = <any[]>response;
          this.spinnerService.hide();
        }
      },
      error => {
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.statusText });
    });
  }

}
