import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ConfigServiceService } from '../config-service.service';
import { Worklist } from './worklist';
import { WorklistChangeResponse } from './worklist-change-response';
import { SpinnerService } from '../core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'

@Injectable()
export class WorklistService {

  readonly apiUrl;

  constructor(
    private http: HttpClient, 
    private auth: AuthService, 
    config: ConfigServiceService,
    private spinnerService: SpinnerService) {
    this.apiUrl = config.getApiUrl();
  }

  getMasterUsers()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/worklist-master-users', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  getWorklists() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Worklist[]>(this.apiUrl + '/worklists', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getWorklistsAll(q, skip, take) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Worklist[]>(this.apiUrl + '/worklists?q=' + q + '&skip=' + skip +'&take=' + take, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getWorklist(id: number): Observable<any> {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<Worklist>(this.apiUrl + '/worklists/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new Worklist(res);
    });
  }

  updateWorklist(id: number, data: Worklist) {
    const token = this.auth.getToken();
    return this.http.put<WorklistChangeResponse>(this.apiUrl + '/worklists/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  createWorklist(data: Worklist) {
    const token = this.auth.getToken();
    return this.http.post<WorklistChangeResponse>(this.apiUrl + '/worklists', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteWorklist(id: number) {
    const token = this.auth.getToken();
    return this.http.post<WorklistChangeResponse>(this.apiUrl + '/worklists/delete-worklist/' + id, null, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

}
