import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { DocumentService } from '../document.service';
import { DocumentInvoiceDescriptions } from '../document-invoice-descriptions';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';

@Component({
  selector: 'app-invoice-descriptions',
  templateUrl: './invoice-descriptions.component.html',
  styleUrls: ['./invoice-descriptions.component.css']
})
export class InvoiceDescriptionsComponent implements OnInit {
  printers: DocumentInvoiceDescriptions[];
  msgs: Message[] = [];
  public items: MenuItem[];

  constructor(private docService: DocumentService, 
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.loadPrinters();
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: [ '/settings' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Invoice Description',
        icon: 'fa-print',
        routerLink: [ '/settings/invoice-descriptions/create' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

  loadPrinters() {
    this.docService.getInvoiceDescriptions().subscribe((res) => {
      this.printers = res;
      this.spinnerService.hide();
    });
  }

  deletePrinter(id: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this invoice descriptions?',
      accept: () => {
        this.docService.deleteInvoiceDescription(id).subscribe(res => {
          // Display message for 2 seconds
          this.msgs.push({severity: 'success', summary: 'Invoice Descriptions Deleted', detail: res.message});
          setTimeout(() => {
            this.msgs = [];
            this.loadPrinters();
          }, 2000);
        });
      }
    });
  }

}
