import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SelectItem } from "primeng/api";
import { SetupService } from '../setup.service';
import { Setup } from "../setup";
import { CompletedActivity } from '../completedactivity';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { SpinnerService } from '../../core';
import { ClientService } from '../client.service';
import { Client } from "../client";

@Component({
  selector: 'client-setup',
  templateUrl: './setup-activity.component.html',
  styleUrls: ['./setup-activity.component.css']
})
export class ClientSetupComponent implements OnInit {
	msgs: Message[] = [];
	activityTypes: SelectItem[];
	// selectedActivityType: string;
	private _filter:any = ['welcomecall','welcomeemail','achapproval','priorpayroll','evoclassicsetup','evoonlinesetup','taxverifyreporting','workcomp','billinginvoice','misc'];
	@ViewChild('activityList') activityList;

	activities: any = [];
	newActivity:any = {};
	completedActivity:any = {};
	completedActivities: any = [];
	
	client: Client;
	is_new:number = -1;
	
	activityform: FormGroup;
	submitted: boolean = false;

	showActivityNew: boolean = false;
	showActivityUpdate: boolean = false;

	clientId: any;

	// loggedUserRole:any;

	addUpdMode: number;

	addUpdPopUpBgClr="#ffffff";
	headerNoteCust='Note';

	viewDispVar:string='none';
	showPlus:boolean=true;

	completedActivitiesStr: string[] = [];

	constructor(private fb: FormBuilder, private setupService:SetupService,
		aroute:ActivatedRoute, private messageService: MessageService,
		@Inject(LOCAL_STORAGE) private storage: WebStorageService,
		private spinnerService: SpinnerService,private clientService:ClientService) { 
		aroute.params.subscribe(params => {
			this.clientId = params['id'];
		});
	}

	openCloseView()
	{
		if( this.showPlus )
		{
			this.showPlus = false;
			this.viewDispVar = 'block';
		}
		else
		{
			this.showPlus = true;
			this.viewDispVar = 'none';
		}
	}

	loadASetup(id: number)
	{
		this.spinnerService.show();
		this.showActivityNew=false;
		// Load setup
		this.setupService.getSetup(id).subscribe(res =>
		{
			this.spinnerService.hide();
			
			this.newActivity = res;

			if( this.newActivity != undefined && this.newActivity.type.trim() != "" && this.newActivity.description.trim() !="" )
			{
				this.submitted=true;
			}
			this.addUpdMode = 2;
			this.showActivityUpdate=true;
		});
	}

	compAcClick( checked,type )
	{
		if( checked != undefined && checked != null && type != undefined && type != null )
		{
			if( checked )
			{
				this.spinnerService.show();
				this.completedActivity.client_id = this.clientId;	  
				this.completedActivity.user_id = this.storage.get('app.auth').id;	  
				this.completedActivity.type = type;
				/****** Call Save CompletedActivity Service ******/
				this.setupService.saveCompActivity(this.completedActivity).subscribe(
				response =>
				{
					this.spinnerService.hide();				
					if(response instanceof HttpErrorResponse)
					{
						this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
						this.completedActivity = {};
					}
					else
					{
						this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Activity marked completed'});
						this.completedActivity = {};
						this.getSavedCompleted();
					}
				},
				error =>
				{
					this.spinnerService.hide();				
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:'We faced an issue'});
				});		
				/**************************************/
			}
			else
			{
				this.completedActivity = {};
				for( var ca = 0; ca < this.completedActivities.length; ca++ )
				{
					if( this.completedActivities[ca].type == type )
					{
						this.completedActivity = this.completedActivities[ca];
					}
				}
				if( this.completedActivity != undefined && this.completedActivity.type != undefined )
				{
					this.spinnerService.show();
					
					this.setupService.deleteCompletedActivity(this.completedActivity).subscribe(
						response =>
						{
							this.spinnerService.hide();				
							if(response instanceof HttpErrorResponse)
							{
								this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
								this.completedActivity = {};
							}
							else
							{
								this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Completed activity deleted'});
								this.completedActivity = {};
								this.getSavedCompleted();
							}
						},
						error =>
						{
							this.spinnerService.hide();				
							console.log(error);
							this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
						});
				}
			}
		}
	}

	ngOnInit()
	{
		this.activities = [];
		
		this.newActivity = {};
		this.newActivity.type = '';
		this.newActivity.description = '';

		this.activityform = this.fb.group({
			'description': new FormControl('', Validators.required),
			'type': new FormControl('', Validators.required)
		});

		this.activityTypes = [
			{ label: 'Welcome Call', icon: 'fa fa-asterisk', value: 'welcomecall' },
			{ label: 'Welcome Email', icon: 'fa fa-asterisk', value: 'welcomeemail' },
			{ label: 'ACH Approval', icon: 'fa fa-asterisk', value: 'achapproval' },
			{ label: 'Prior Payroll', icon: 'fa fa-asterisk', value: 'priorpayroll' },
			{ label: 'Evo Classic Setup', icon: 'fa fa-asterisk', value: 'evoclassicsetup' },
			{ label: 'Evo Online Setup', icon: 'fa fa-asterisk', value: 'evoonlinesetup' },
			{ label: 'Tax Verify/Reporting', icon: 'fa fa-asterisk', value: 'taxverifyreporting' },
			{ label: 'Work Comp', icon: 'fa fa-asterisk', value: 'workcomp' },
			{ label: 'Billing Invoice', icon: 'fa fa-asterisk', value: 'billinginvoice' },
			{ label: 'Misc', icon: 'fa fa-asterisk', value: 'misc' }
			// { label: 'Payroll Linking', icon: 'fa fa-phone', value: 'payrolllinking' },
			// { label: 'Policy/Net rates', icon: 'fa fa-envelope', value: 'policynetrates' },
			// { label: 'Verify Exclusions', icon: 'fa fa-clipboard', value: 'verifyexclusions' },
			// { label: 'Payroll Departmentalized', icon: 'fa fa-asterisk', value: 'payrolldepart' },
		];

		this.refresh();
		this.getSavedCompleted();
	}

	refresh()
	{
		this.clientService.getClientNameOnly(this.clientId).subscribe(res =>
		{
			this.client = res;
			if( this.client != undefined && this.client != null && this.client.is_new != undefined && this.client.is_new != null )
			{
				this.is_new = this.client.is_new;
			}
		});
				
		this.activities = [];
		this.setupService.getSetUpLog(this.clientId, this._filter).subscribe(
			response => {				
				if(response instanceof HttpErrorResponse) {
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
				} else {
					this.activities = <Setup[]> response;
				}
			},
			error => {
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.statusText});
		});		
	}

	
	getSavedCompleted()
	{
		this.spinnerService.show();
		this.setupService.getCompletedActivities(this.clientId).subscribe(
			response => {		
				this.spinnerService.hide();		
				if(response instanceof HttpErrorResponse) {
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
				} else {
					this.completedActivities = <CompletedActivity[]> response;
					this.completedActivitiesStr = [];
					for( var ca = 0; ca < this.completedActivities.length; ca++ )
					{
						this.completedActivitiesStr.push( this.completedActivities[ca].type );
					}
				}
			},
			error => {
				this.spinnerService.hide();		
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.statusText});
		});		
	}

	rpmResetForm()
	{
		this.newActivity.type = '';
		this.newActivity.description = '';

		this.submitted=false;
		this.addUpdMode = 1;
		this.showActivityNew=true;
	}

	enableSubmit(  cntrl:number=0 )
	{
		if( this.newActivity.type.trim() != "" && this.newActivity.description.trim() !="" )
		{
            this.submitted = true;
		}
		else
		{
			this.submitted = false;
		}
				
		if( cntrl == 1 )
		{
			this.addUpdPopUpBgClr="#ffffff";
			if( this.newActivity.type.trim() != "" )
			{
				if( this.newActivity.type.trim() == "welcomecall" )
				{
					this.addUpdPopUpBgClr="#F5B7B1";
				}
				if( this.newActivity.type.trim() == "welcomeemail" )
				{
					this.addUpdPopUpBgClr="#A3E4D7";
				}
				else if( this.newActivity.type.trim() == "achapproval" )
				{
					this.addUpdPopUpBgClr="#FAD7A0";
				}
				else if( this.newActivity.type.trim() == "priorpayroll" )
				{
					this.addUpdPopUpBgClr="#AED6F1";
				}
				else if( this.newActivity.type.trim() == "evoclassicsetup" )
				{
					this.addUpdPopUpBgClr="#A2D9CE";
				}
				else if( this.newActivity.type.trim() == "evoonlinesetup" )
				{
					this.addUpdPopUpBgClr="#F9E79F";
				}
				else if( this.newActivity.type.trim() == "taxverifyreporting" )
				{
					this.addUpdPopUpBgClr="#E5E7E9";
				}
				else if( this.newActivity.type.trim() == "workcomp" )
				{
					this.addUpdPopUpBgClr="#D7BDE2";
				}
				else if( this.newActivity.type.trim() == "billinginvoice" )
				{
					this.addUpdPopUpBgClr="#E6B0AA";
				}
				else if( this.newActivity.type.trim() == "misc" )
				{
					this.addUpdPopUpBgClr="#A9CCE3";
				}
			}
		}

		// console.log('enableSubmit');
        // console.log(this.activityform.touched);
        // console.log(this.activityform.valid);
        // console.log(this.submitted);
    }

	set filter(value) {
		this._filter = value;
		/* this.activityList.filterBy = 'type';
		this.activityList.filter = ''; 
		this.activityList.filter(this._filter.join(' '));*/

		this.refresh();
	}

	get filter() {
		return this._filter;
	}

	onSubmit(value: string) {
		this.saveActivity();
		this.submitted = true;
	}

	callFormSave()
	{
		 if( this.addUpdMode == 1 )
		 {
			this.saveActivity();
		 }
		 else if( this.addUpdMode == 2 )
		 {
			this.updateActivity();
		 }
	}

	saveActivity()
	{  
		if( this.submitted )
		{
			this.submitted=false;
			this.newActivity.client_id = this.clientId;	  
			this.newActivity.user_id = this.storage.get('app.auth').id;	  
			/****** Call Save Setup Service ******/
			this.setupService.saveSetup(this.newActivity).subscribe(
			response =>
			{				
				if(response instanceof HttpErrorResponse)
				{
					this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:response.error.message});
					this.showActivityNew=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
				}
				else
				{
					this.messageService.add({key:'top-corner', severity:'success', summary:'Success', detail:'Setup Activity added successfully'});
					this.activities.push(response.setup);
					this.showActivityNew=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
					this.refresh();
				}
			},
			error =>
			{
				this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:'Something went wrong'});
			});		
			/**************************************/
		}
	}

	updateActivity()
	{
		if( this.submitted )
		{
			this.submitted=false;

			this.setupService.updateSetupData(this.newActivity.id,this.newActivity).subscribe(
			response =>
			{
				if(response instanceof HttpErrorResponse)
				{
					this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
					this.showActivityUpdate=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
				}
				else
				{
					this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Setup Activity updated successfully'});
					this.showActivityUpdate=false;
					this.newActivity.type = '';
					this.newActivity.description = '';
					this.refresh();
	  			}
		  },
		  error =>
		  {
			console.log(error);
			this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
		  });
		}
	}

	deleteActivity()
	{
		this.submitted=false;

		var r = confirm("Are you sure you want to delete setup ?");
		if( ! r )
		{
			this.submitted=true;
			return false;
		}
		this.setupService.deleteSetup(this.newActivity).subscribe(
		response =>
		{
			if(response instanceof HttpErrorResponse)
			{
			this.messageService.add({key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message});
			this.showActivityUpdate=false;
			this.newActivity.type = '';
			this.newActivity.description = '';
			}
			else
			{
			this.messageService.add({key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Setup deleted successfully'});
			this.showActivityUpdate=false;
			this.newActivity.type = '';
			this.newActivity.description = '';
			this.refresh();
			}
		},
		error =>
		{
			console.log(error);
			this.messageService.add({key:'top-corner', severity:'error', summary:'Error:', detail:error.message});
		});
	}
}