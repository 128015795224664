import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { DocumentService } from '../document.service';
import { DocumentPrinter } from '../document-printer';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';

@Component({
  selector: 'app-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.css']
})
export class PrintersComponent implements OnInit {
  printers: DocumentPrinter[];
  msgs: Message[] = [];
  public items: MenuItem[];

  constructor(private docService: DocumentService, 
    private confirmationService: ConfirmationService,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.loadPrinters();
    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: [ '/settings' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Printer',
        icon: 'fa-print',
        routerLink: [ '/settings/printers/create' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

  loadPrinters() {
    this.docService.getPrinters().subscribe((res) => {
      this.printers = res;
      this.spinnerService.hide();
    });
  }

  deletePrinter(id: number) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this printer?',
      accept: () => {
        this.docService.deletePrinter(id).subscribe(res => {
          // Display message for 2 seconds
          this.msgs.push({severity: 'success', summary: 'Printer Deleted', detail: res.message});
          setTimeout(() => {
            this.msgs = [];
            this.loadPrinters();
          }, 2000);
        });
      }
    });
  }

}
