import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { User } from '../user';
import { Message } from 'primeng/api';
import { SpinnerService } from '../../core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  passwordForm: FormGroup;
  users: any;
  passwordDialogVisible = false;
  selectedUser: User;
  myPassword: string;
  newPassword: string;
  msgs: Message[] = [];
  loggedInUser: User;
  passwordError = "none";
  confirm_passwordError = "none";
  passwords_not_matchError = "none";
  redError = "red";
  q: string;
  skip: any;
  take: any;
  usersCount: any;
  confirmDialogVisible = false;
  confirmMessage: any;
  actions_user_id: any;
  actions_type: any;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private aroute: ActivatedRoute,
    private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      userId: 0
    });
    this.aroute.queryParams.subscribe(params => {
      this.q = params['q'];
      this.skip = 0;
      this.take = 25;
      this.loadUsers();
    });
    this.loggedInUser = this.authService.getAuth();
  }

  loadUsers() {
    if(typeof this.q == 'undefined'){
      this.q = "";
    }
    this.userService.getUsersAll(this.q, this.skip, this.take).subscribe(
      (res) => {
        this.usersCount = res[0];
        this.users = res[1];
        this.spinnerService.hide();
      });
  }

  loadData(event) {
    this.skip = event.first;
    this.take = event.rows;
    this.loadUsers();
  }

  changePassword(user_id: number) {
    for (const user of this.users) {
      if (user.id === user_id) {
        this.selectedUser = user;
      }
    }
    this.passwordError = "none";
    this.confirm_passwordError = "none";
    this.passwords_not_matchError = "none";
    this.passwordForm.patchValue({
      password: "",
      confirm_password: "",
      userId: user_id
    });
    this.passwordDialogVisible = true;
  }

  doPasswordChange() {
    this.passwordError = "none";
    this.confirm_passwordError = "none";
    this.passwords_not_matchError = "none";
    if (this.passwordForm.valid) {
      if (this.passwordForm.value.password != this.passwordForm.value.confirm_password) {
        this.passwords_not_matchError = "block";
      } else {
        this.userService.changePassword(this.selectedUser.id, this.passwordForm.value).subscribe(res => {
          // Clear fields
          this.myPassword = '';
          this.newPassword = '';
          // Close dialog
          this.passwordDialogVisible = false;
          // Display message for 2 seconds
          this.msgs.push({ severity: 'success', summary: 'Message', detail: 'Password updated successfully' });
          setTimeout(() => {
            this.msgs = [];
          }, 2000);
        });
      }
    } else {
      if (this.passwordForm.value.password == "") {
        this.passwordError = "block";
      }
      if (this.passwordForm.value.confirm_password == "") {
        this.confirm_passwordError = "block";
      }
    }
  }

  disableUser(id: number) {
    this.actions_user_id = id;
    this.actions_type = 1;
    this.confirmMessage = 'Are you sure want to disable this user?';
    this.confirmDialogVisible = true;
  }

  enableUser(id) {
    this.actions_user_id = id;
    this.actions_type = 2;
    this.confirmMessage = 'Are you sure want to enable this user?';
    this.confirmDialogVisible = true;
  }

  deleteUser(id) {
    this.actions_user_id = id;
    this.actions_type = 3;
    this.confirmMessage = 'Are you sure want to delete this user?';
    this.confirmDialogVisible = true;
  }

  cancelUserAction(){
    this.actions_user_id = 0;
    this.actions_type = 0;
    this.confirmMessage = '';
    this.confirmDialogVisible = false;
  }

  confirmUserAction(){
    if(this.actions_type == 1){
      this.userService.disableUser(this.actions_user_id).subscribe(res => {
        this.cancelUserAction();
        // Display message for 2 seconds
        this.msgs.push({ severity: 'success', summary: 'Message', detail: 'User updated successfully' });
        setTimeout(() => {
          this.msgs = [];
        }, 2000);
        // Reload Users
        this.loadUsers();
      });
    }else if(this.actions_type == 2){
      this.userService.enableUser(this.actions_user_id).subscribe(res => {
        this.cancelUserAction();
        // Display message for 2 seconds
        this.msgs.push({ severity: 'success', summary: 'Message', detail: 'User updated successfully' });
        setTimeout(() => {
          this.msgs = [];
        }, 2000);
        // Reload Users
        this.loadUsers();
      });
    }else if(this.actions_type == 3){
      this.userService.deleteUser(this.actions_user_id).subscribe(res => {
        this.cancelUserAction();
        // Display message for 2 seconds
        this.msgs.push({ severity: 'success', summary: 'Message', detail: 'User deleted successfully' });
        setTimeout(() => {
          this.msgs = [];
        }, 2000);
        // Reload Users
        this.loadUsers();
      });
    }
  }
}
