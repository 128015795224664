import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrintersComponent } from './printers/printers.component';
import { InvoiceDescriptionsComponent } from './invoice-descriptions/invoice-descriptions.component';
import { InvoiceDescriptionsEditComponent } from './invoice-descriptions-edit/invoice-descriptions-edit.component';
import { TemplatesComponent } from './templates/templates.component';
import { EmailComponent } from './email/email.component';
import { QueuesComponent } from './queues/queues.component';
import { QueueEditComponent } from './queue-edit/queue-edit.component';
import { PrinterEditComponent } from './printer-edit/printer-edit.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';
import { UploadInvoicesComponent } from './upload-invoices/upload-invoices.component';
import { CarriersMasterComponent } from './carriers-master/carriers-master.component';

const routes: Routes = [
  {path: 'settings', component: DashboardComponent},
  {path: 'settings/upload-invoices', component: UploadInvoicesComponent},

  {path: 'settings/carriers-master', component: CarriersMasterComponent},

  {path: 'settings/queues', component: QueuesComponent},
  {path: 'settings/queues/create', component: QueueEditComponent},
  {path: 'settings/queues/edit/:id', component: QueueEditComponent},

  {path: 'settings/printers', component: PrintersComponent},
  {path: 'settings/printers/create', component: PrinterEditComponent},
  {path: 'settings/printers/edit/:id', component: PrinterEditComponent},

  {path: 'settings/invoice-descriptions', component: InvoiceDescriptionsComponent},
  {path: 'settings/invoice-descriptions/create', component: InvoiceDescriptionsEditComponent},
  {path: 'settings/invoice-descriptions/edit/:id', component: InvoiceDescriptionsEditComponent},

  {path: 'settings/templates', component: TemplatesComponent},
  {path: 'settings/templates/create', component: TemplateEditComponent},
  {path: 'settings/templates/edit/:id', component: TemplateEditComponent},

  {path: 'settings/email', component: EmailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
