export class DocumentPrinter {
  id: number;
  cloud_printer_id: string;
  name: string;
  location: string

  created_at: Date;
  updated_at: Date;

  constructor(data: any = {}) {
    // Set normal fields
    this.id = data.id;
    this.cloud_printer_id = data.cloud_printer_id;
    this.name = data.name;
    this.location = data.location;

    // Parse date fields
    this.created_at = new Date(Date.parse(data.created_at));
    this.updated_at = new Date(Date.parse(data.updated_at));

    // Parse other fields
  }
}
