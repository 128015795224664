import { Component, OnInit, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Client } from '../client';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from '../client.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerService } from '../../core';
@Component({
  selector: 'client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit, AfterViewInit {
  elementRef: ElementRef;
  id: number;
  client: Client;
  statuses: SelectItem[];
  status: string;

  showEditClient: boolean;
  showContactCard: boolean;

  payrollFrequency: any;
  payrollCompanies: any;
  payrollContacts: any;
  states: any;
  workList: any;

  msgs = [];
  editClientform: FormGroup;
  submitted: boolean;

  statusFalse = '0';
  statusTrue = 1;

  siaName:string='';
  amName:string='';

  contacts: any = [];
  authContactsStr='';

  payrollCompsLoaded: boolean = false;

  deleteClientId:any = 0;
  deleteClientConfirmPopup:any = false;

  constructor(private confirmationService: ConfirmationService,
    aroute: ActivatedRoute,
    public router: Router,
    public location: Location,
    private clientService: ClientService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private spinnerService: SpinnerService, 
    @Inject(ElementRef) elementRef: ElementRef) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
    this.elementRef = elementRef;
  }

  ngAfterViewInit() {
    const y = this.elementRef.nativeElement.querySelectorAll('.ui-radiobutton-label');
    for (var i = 0; i < y.length; i++) {
      y[i].style.marginBottom = '0';
    }
  }

  ngOnInit() {
    this.editClientform = this.fb.group({
      'company_name': new FormControl('', Validators.required),
      'address': new FormControl('', Validators.required),
      'city': new FormControl('', Validators.required),
      'state': new FormControl('', Validators.required),
      'zip': new FormControl('', Validators.required),
      'phone': new FormControl('', Validators.required),
      'fein': new FormControl('', Validators.required),

      'other_names': new FormControl(''),
      'payroll_frequency': new FormControl(''),
      'payroll_company': new FormControl(''),
      'payroll_rep': new FormControl(''),
      'worklist': new FormControl('')
    });
    this.spinnerService.show();
    this.clientService.getContacts(this.id).subscribe(res => {
      // this.payrollContacts = res;
      // this.payrollContacts.unshift({label: 'Select', value: ''});
    });

    this.clientService.getWorkList().subscribe(res => {
      this.workList = res;
      this.workList.unshift({ label: 'Select', value: '' });
    });

    this.clientService.getStates().subscribe(res => {
      this.states = res;
      this.states.unshift({ label: 'Select', value: '' });
    });

    this.payrollFrequency = [
      { label: 'Select', value: 'none' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Every 2 Weeks', value: '2 weeks' },
      { label: 'Semi-Monthly', value: 'semi-monthly' },
      { label: 'Monthly', value: 'monthly' }
    ];

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'New', value: 'new' },
      { label: 'Client Relations', value: 'client-relations' }
    ];

    this.refresh();

  }

  validateForm() {
    Object.keys(this.editClientform.controls).forEach(item => {
      if (this.editClientform.controls[item].status === 'INVALID' && this.editClientform.controls[item].touched === true) {
        this.setMessage('error', 'Validaion Error:', '');
      }
    });
  }

  private setMessage(severity: string, summary: string, detail: string) {
    this.msgs = [];
    this.msgs.push({ severity: severity, summary: summary, detail: detail });
  }

  refresh() {
    // Load client

    this.clientService.getOnlyClientData(this.id).subscribe(res => {
      this.client = res;
      var selectedPayrollCompany = 0;

      if( this.client != undefined && this.client != null )
      {
        if( this.client.agency_data != undefined && this.client.agency_data != null )
        {
            if( this.client.agent != undefined && this.client.agent != null && this.client.agent )
            {
                    if( this.client.agent == 1 )
                    {
                      this.siaName = this.client.agency_data.contact_1_name;
                    }
                    else if( this.client.agent == 2 )
                    {
                      this.siaName = this.client.agency_data.contact_2_name;
                    }
                    else if( this.client.agent == 3 )
                    {
                      this.siaName = this.client.agency_data.contact_3_name;
                    }
                    else if( this.client.agent == 4 )
                    {
                      this.siaName = this.client.agency_data.contact_4_name;
                    }
                    else if( this.client.agent == 5 )
                    {
                      this.siaName = this.client.agency_data.contact_5_name;
                    }
                    else if( this.client.agent == 6 )
                    {
                      this.siaName = this.client.agency_data.contact_6_name;
                    }
                    else if( this.client.agent == 7 )
                    {
                      this.siaName = this.client.agency_data.contact_7_name;
                    }
                    else if( this.client.agent == 8 )
                    {
                      this.siaName = this.client.agency_data.contact_8_name;
                    }
                    else if( this.client.agent == 9 )
                    {
                      this.siaName = this.client.agency_data.contact_9_name;
                    }
                    else if( this.client.agent == 10 )
                    {
                      this.siaName = this.client.agency_data.contact_10_name;
                    }
                }

                if( this.client.agent != undefined && this.client.agent != null && this.client.agent )
                {
                        if( this.client.agent == 1 )
                        {
                          this.amName = this.client.agency_data.contact_1_email;
                        }
                        else if( this.client.agent == 2 )
                        {
                          this.amName = this.client.agency_data.contact_2_email;
                        }
                        else if( this.client.agent == 3 )
                        {
                          this.amName = this.client.agency_data.contact_3_email;
                        }
                        else if( this.client.agent == 4 )
                        {
                          this.amName = this.client.agency_data.contact_4_email;
                        }
                        else if( this.client.agent == 5 )
                        {
                          this.amName = this.client.agency_data.contact_5_email;
                        }
                        else if( this.client.agent == 6 )
                        {
                          this.amName = this.client.agency_data.contact_6_email;
                        }
                        else if( this.client.agent == 7 )
                        {
                          this.amName = this.client.agency_data.contact_7_email;
                        }
                        else if( this.client.agent == 8 )
                        {
                          this.amName = this.client.agency_data.contact_8_email;
                        }
                        else if( this.client.agent == 9 )
                        {
                          this.amName = this.client.agency_data.contact_9_email;
                        }
                        else if( this.client.agent == 10 )
                        {
                          this.amName = this.client.agency_data.contact_10_email;
                        }
                    }
    
        }

        this.clientService.getContactsFull(this.id).subscribe(res =>
          {
            this.contacts = res;

            if( this.client.contact != null && this.client.contact != undefined && this.client.contact != '' )
            {
              this.authContactsStr = this.client.contact;
            }
      
            if( this.contacts[0] != null && this.contacts[0] != undefined && this.contacts[0].name != undefined && this.contacts[0].name != null && this.contacts[0].name != "" )
            {
              if( this.authContactsStr != "" )
              {
                this.authContactsStr += " / ";
              }
              this.authContactsStr += this.contacts[0].name;
            }
            if( this.contacts[1] != null && this.contacts[1] != undefined && this.contacts[1].name != undefined && this.contacts[1].name != null && this.contacts[1].name != "" )
            {
              if( this.authContactsStr != "" )
              {
                this.authContactsStr += " / ";
              }
              this.authContactsStr += this.contacts[1].name;
            }
            if( this.contacts[2] != null && this.contacts[2] != undefined && this.contacts[2].name != undefined && this.contacts[2].name != null && this.contacts[2].name != "" )
            {
              if( this.authContactsStr != "" )
              {
                this.authContactsStr += " / ";
              }
              this.authContactsStr += this.contacts[2].name;
            }
            if( this.contacts[3] != null && this.contacts[3] != undefined && this.contacts[3].name != undefined && this.contacts[3].name != null && this.contacts[3].name != "" )
            {
              if( this.authContactsStr != "" )
              {
                this.authContactsStr += " / ";
              }
              this.authContactsStr += this.contacts[3].name;
            }
          });
      

      }

      if (!this.payrollCompsLoaded) {
        this.clientService.getPayrollCompanies().subscribe(res => {
          this.payrollCompanies = res;
          this.payrollCompanies.unshift({ label: 'Select', value: '' });

          this.payrollCompsLoaded = true;

          if (this.client.payroll_company_data != null) {
            for (var i = 1; i < this.payrollCompanies.length; i++) {
              if (this.payrollCompanies[i].label == this.client.payroll_company_data.payroll_company_name) {
                selectedPayrollCompany = this.payrollCompanies[i].value;
                break;
              }
            }
          }

          this.clientService.getPayrollReps(selectedPayrollCompany).subscribe(res => {
            this.payrollContacts = res;
            this.payrollContacts.unshift({ label: 'Select', value: '' });
            // this.scrollTo('scroll123');
            this.spinnerService.hide();
          });
        });
      }
      else {
        if (this.client.payroll_company_data != null) {
          for (var i = 1; i < this.payrollCompanies.length; i++) {
            if (this.payrollCompanies[i].label == this.client.payroll_company_data.payroll_company_name) {
              selectedPayrollCompany = this.payrollCompanies[i].value;
              break;
            }
          }
        }

        this.clientService.getPayrollReps(selectedPayrollCompany).subscribe(res => {
          this.payrollContacts = res;
          this.payrollContacts.unshift({ label: 'Select', value: '' });
          // this.scrollTo('scroll123');
          this.spinnerService.hide();
        });
      }


      // console.log(this.client);
      if (this.client.is_active) {
        this.status = 'active';
      } else {
        if(this.client.is_terminated) {
          this.status = 'terminated';
        } else {
          this.status = 'inactive';
        }
      }

      // if (this.client.is_new) {
        this.statusTrue = this.client.is_new;
      // }

    });
    
  }

  scrollTo(className: string):void {
      setTimeout(function(){
          const elementList = document.querySelectorAll('.' + className);
          const element = elementList[0] as HTMLElement;
          element.scrollIntoView({ behavior: 'smooth' });
      },1000);
  }

  confirm(status) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      key: 'changeStatus',
      accept: () => {
        // Actual logic to perform a confirmation
      },
      reject: () => {
        if (status == 'active') {
          this.status = 'inactive';
        } else {
          this.status = 'active';
        }
      }
    });
  }

  onSubmit(value: string) {
    this.saveClient(true);
    this.showEditClient = false;
  }

  saveClient(refresh: boolean = false) {
    this.clientService.saveClientData(this.id, this.client).subscribe(
      response => {
        if (response instanceof HttpErrorResponse) {
          this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: response.error.message });
        } else {
          this.messageService.add({ key: 'top-corner', severity: 'success', summary: 'Success', detail: 'Information updated successfully' });
          if (refresh) {
            this.refresh();
          }
        }
      },
      error => {
        console.log(error);
        this.messageService.add({ key: 'top-corner', severity: 'error', summary: 'Error:', detail: error.message });
      });

  }

  onPayrollCompanySelect(selectedPayrollCompany) {

    this.clientService.getPayrollReps(selectedPayrollCompany).subscribe(res => {
      this.payrollContacts = res;
      this.payrollContacts.unshift({ label: 'Select', value: '' });
    });

    for (var i = 1; i < this.payrollCompanies.length; i++) {
      if (this.payrollCompanies[i].value == selectedPayrollCompany) {
        // this.client.payroll_company_data.payroll_company_name = this.payrollCompanies[i].label;
        break;
      }
    }
  }

  onWorkListSelect(selectedWorkList) {
    for (var i = 1; i < this.workList.length; i++) {
      if (this.workList[i].value == selectedWorkList) {
        // this.client.payroll_company_data.worklist = selectedWorkList;
        this.client.worklist = this.workList[i].value;
        break;
      }
    }
  }

  onHideEdiForm() {
    this.msgs = [];
    this.editClientform.reset();
    this.refresh();
  }

  deleteClient(clientId:any)
  {
    this.deleteClientId = clientId;
    this.deleteClientConfirmPopup = true;
  }

  deleteClientConfirm()
  {
    
    this.clientService.deleteClient(this.deleteClientId).subscribe(res => {
      this.deleteClientConfirmPopup = false;
      this.router.navigate(['/clients']);
    });
  }

  closeClientConfirm()
  {
    this.deleteClientConfirmPopup = false;
  }
  
}
