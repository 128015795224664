export class QueueOptions {
  printer: number;
  sort_field: string;
  from_email: string;
  from_phone: string;
  fax_cover: number;

  constructor(data: any = {}) {
    this.printer = data.printer;
    this.sort_field = data.sort_field;
    this.from_email = data.from_email;
    this.fax_cover = data.fax_cover;
  }
}
