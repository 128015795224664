import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ConfigServiceService } from '../config-service.service';
import { SettingChange } from './setting-change';
import { Document } from './document';
import { DocumentQueue } from './document-queue';
import { DocumentPrinter } from './document-printer';
import { DocumentInvoiceDescriptions } from './document-invoice-descriptions';
import { DocumentTemplate } from './document-template';
import { DocumentBatch } from './document-batch';
import { SpinnerService } from '../core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'

@Injectable()
export class DocumentService {

  readonly apiUrl;

  constructor(
    private http: HttpClient, 
    private auth: AuthService, 
    config: ConfigServiceService,
    private spinnerService: SpinnerService
  ) {
    this.apiUrl = config.getApiUrl();
  }

  getAllCarriersPrinters() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<DocumentQueue[]>(this.apiUrl + '/googlecloudprint', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  printGoogleCloud(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/googlecloudprint', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  // <editor-fold desc="Queue Operations">
  getQueues(skip, take, sortField, sortOrder, qm,getPrintQueue=0, carrierData=[],filterDatesData=[]) {

    this.spinnerService.show();

    var selCars = encodeURIComponent(carrierData.toString());
    var selDates = encodeURIComponent(filterDatesData.toString());

    const token = this.auth.getToken();
    var id = 'skip=' + skip + '&take=' +take+'&sortField=' +sortField+'&sortOrder=' +sortOrder+'&qm=' +qm+'&getPrintQueue='+getPrintQueue+'&carrierData='+selCars+'&filterDatesData='+selDates;
    return this.http.get<any>(this.apiUrl + '/queues?'+id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  FaxInvoices(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/fax-queues', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getFaxQueue(skip, take, sortField, sortOrder, qm,getPrintQueue=0, carrierData=[],filterDatesData=[]) {
    this.spinnerService.show();

    var selCars = encodeURIComponent(carrierData.toString());
    var selDates = encodeURIComponent(filterDatesData.toString());

    const token = this.auth.getToken();
    var id = 'skip=' + skip + '&take=' +take+'&sortField=' +sortField+'&sortOrder=' +sortOrder+'&qm=' +qm+'&getPrintQueue='+getPrintQueue+'&carrierData='+selCars+'&filterDatesData='+selDates;
    return this.http.get<any>(this.apiUrl + '/fax-queues?'+id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  textInvoices(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/text-queues', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getTextQueue(skip, take, sortField, sortOrder, qm,getPrintQueue=0, carrierData=[],filterDatesData=[]) {
    this.spinnerService.show();

    var selCars = encodeURIComponent(carrierData.toString());
    var selDates = encodeURIComponent(filterDatesData.toString());
    
    const token = this.auth.getToken();
    var id = 'skip=' + skip + '&take=' +take+'&sortField=' +sortField+'&sortOrder=' +sortOrder+'&qm=' +qm+'&getPrintQueue='+getPrintQueue+'&carrierData='+selCars+'&filterDatesData='+selDates;
    return this.http.get<any>(this.apiUrl + '/text-queues?'+id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getQueue(id: number) {
    const token = this.auth.getToken();
    return this.http.get<DocumentQueue>(this.apiUrl + '/queues/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new DocumentQueue(res);
    });
  }

  createMQDel(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/mqueues-del', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  sendSingleMail( mqSngleEmPostObj:any )
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/single-queue', mqSngleEmPostObj, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  createQueue(data) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/queues', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteQueues(id, data)
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/remove-from-queues', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  removeFromText(id, data)
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/text-queue-del', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  removeFromFax(id, data)
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/fax-queue-del', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  saveQueue(id: number, data: DocumentQueue) {
    const token = this.auth.getToken();
    return this.http.put<SettingChange>(this.apiUrl + '/queues/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteQueue(id: number) {
    const token = this.auth.getToken();
    return this.http.delete<SettingChange>(this.apiUrl + '/queues/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  // </editor-fold>

  // <editor-fold desc="Printer Operations">
  getPrinters() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<DocumentPrinter[]>(this.apiUrl + '/printers', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getPrinter(id: number) {
    const token = this.auth.getToken();
    return this.http.get<DocumentPrinter>(this.apiUrl + '/printers/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new DocumentPrinter(res);
    });
  }

  createPrinter(data: DocumentPrinter) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/printers', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  savePrinter(id: number, data: DocumentPrinter) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.put<SettingChange>(this.apiUrl + '/printers/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deletePrinter(id: number) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.delete<SettingChange>(this.apiUrl + '/printers/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  // </editor-fold>

  // <editor-fold desc="Template Operations">
  getTemplates() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<DocumentTemplate[]>(this.apiUrl + '/templates', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getTemplate(id: number) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<DocumentTemplate>(this.apiUrl + '/templates/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new DocumentTemplate(res);
    });
  }

  createTemplate(data: DocumentTemplate) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/templates', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  saveTemplate(id: number, data: DocumentTemplate) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.put<SettingChange>(this.apiUrl + '/templates/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteTemplate(id: number) {
    const token = this.auth.getToken();
    return this.http.delete<SettingChange>(this.apiUrl + '/templates/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  checkTemplate(templateName: string) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/check-template?tName=' + templateName, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  uploadInvoices(data){
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<any>(this.apiUrl + '/uploadinvoices', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getCronTimes()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/cronTimes', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getTextCronTme()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/textTime', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getFaxCronTme()
  {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/faxTime', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  combinePrintPDF(data)
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/single-print-pdf', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  clearFromPQ(data)
  {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/clear-from-print-queue', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  getCarriersByName() {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/clients/carriersByName', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  saveCarriersSet( invPolPostObj:any ): Observable<any>
  {
    const token = this.auth.getToken();
    return this.http.post(this.apiUrl + '/save-carriers-set', invPolPostObj,{
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
   }
   getCarrierSet(): Observable<any> {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/getCarrierSet', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
 
  // </editor-fold>

  getInvoiceDescriptionsList() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<DocumentInvoiceDescriptions[]>(this.apiUrl + '/invoice-descriptions-list', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getInvoiceDescriptions() {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.get<DocumentInvoiceDescriptions[]>(this.apiUrl + '/invoice-descriptions', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getInvoiceDescription(id: number) {
    const token = this.auth.getToken();
    return this.http.get<DocumentInvoiceDescriptions>(this.apiUrl + '/invoice-descriptions/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).map(res => {
      return new DocumentInvoiceDescriptions(res);
    });
  }

  createInvoiceDescription(data: DocumentInvoiceDescriptions) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<SettingChange>(this.apiUrl + '/invoice-descriptions', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  saveInvoiceDescription(id: number, data: DocumentInvoiceDescriptions) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.put<SettingChange>(this.apiUrl + '/invoice-descriptions/' + id, data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  deleteInvoiceDescription(id: number) {
    this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.delete<SettingChange>(this.apiUrl + '/invoice-descriptions/' + id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

}
