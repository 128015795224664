import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DocumentService } from '../document.service';
import { Message, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentTemplate } from '../document-template';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'carriers-master',
  templateUrl: './carriers-master.component.html',
  styleUrls: ['./carriers-master.component.css']
})

export class CarriersMasterComponent implements OnInit
{
  uploadInvoiceForm: FormGroup;

  msgs: Message[] = [];

  public items: MenuItem[];

  carriers:any = [];
  selectedCarriers = [];
  carrNotSelected:boolean = false;
  carrSetSaved:boolean = false;
  invPolPostObj: any = {};
  
  constructor(aroute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private docService: DocumentService,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService) {
  }

  ngOnInit()
  {
    this.spinnerService.show();

    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: ['/settings'],
        routerLinkActiveOptions: { exact: true }
      },
    ];

    this.docService.getCarriersByName().subscribe(res => {
      this.carriers = res;
    });

    this.docService.getCarrierSet().subscribe(res =>
    {
      this.loadSelectedSet( res );
    });
    
  }

  loadSelectedSet( res )
  {
    var pickSelCars:any = [];
    if( res != undefined && res != null && res.length )
    {
      for( let carrier of res)
      {
        if( carrier != undefined && carrier != null )
        {
          if( carrier.carrier_name != undefined && carrier.carrier_name != null )
          {
            pickSelCars.push(carrier.carrier_name);
          }
        }
      }
    }

    this.selectedCarriers = pickSelCars;
    this.spinnerService.hide();
  }

  setCarrierSet()
  {
    this.carrNotSelected = false;
    this.carrSetSaved = false;
    if( this.selectedCarriers == undefined || this.selectedCarriers == null || this.selectedCarriers.length == 0 )
    {
        this.carrNotSelected = true;
    }
    else
    {
      // console.log(this.selectedCarriers);

      this.spinnerService.show();
      this.invPolPostObj = {};
      this.invPolPostObj.selectedCarriers = this.selectedCarriers;
      this.docService.saveCarriersSet(this.invPolPostObj).subscribe(data =>
      {
        this.spinnerService.hide();
        this.invPolPostObj = {};
  
        if( data != undefined && data != null )
        {
          if( data.carrierSetNotSaved != undefined && data.carrierSetNotSaved != null )
          {
            alert( "Could Not Save Carrier Set." );
          }
          else
          {
            this.carrSetSaved = true;
          }
        }
        else
        {
          alert( "Could Not Save Carrier Set." );
        }
      });
    }
  }  

}
