import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigServiceService } from '../config-service.service';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { SpinnerService } from '../core';
import { Carrier } from './automated-remit-report/carrier';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  readonly apiUrl;
  constructor(private http: HttpClient,
    private auth: AuthService,
    config: ConfigServiceService,
    private spinnerService: SpinnerService) {
    this.apiUrl = config.getApiUrl();
  }

  getCarrierInfo(): Observable<Carrier[]> {
    const token = this.auth.getToken();
    return this.http.get<Carrier[]>(this.apiUrl + '/get-carrierInfo', {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  getAutomatedRemitReport(data: any) {
    const token = this.auth.getToken();
    return this.http.post<any>(this.apiUrl + '/get-automatedRemitReport', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  // automatedRemitAction(data: any) {
  //   const token = this.auth.getToken();
  //   return this.http.post<any>(this.apiUrl + '/get-automatedRemitReport', data, {
  //     headers: {
  //       Authorization: 'Bearer ' + token
  //     }
  //   })
  // }

  automatedRemitAction(data: any) {
    const token = this.auth.getToken();
    return this.http.post<any>(this.apiUrl + '/automated-remit-action', data, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  // getCollected(client_id: any = []) {
  //   var filter = "";
  //   const token = this.auth.getToken();
  //   console.log("api",this.apiUrl + '/client-automatedCollected-premium?id=' + client_id);
  //   return this.http.get<any>(this.apiUrl + '/client-automatedCollected-premium?id=' + client_id, {
  //     headers: {
  //       Authorization: 'Bearer ' + token
  //     }
  //   });
  // }

  getClientName(carrierIds: any = []) {
    const token = this.auth.getToken();
    return this.http.get<any>(this.apiUrl + '/client-info?carrierIds=' + carrierIds, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  sendClientIdstoAutomatedRemitLog(client_id: any = []) {
    const token = this.auth.getToken();
    return this.http.get(this.apiUrl + '/automated-remit-log?client_id=' + client_id, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }
  
  // getExcelPremiumPayableReport(data){
  //   this.spinnerService.show();
  //   const token = this.auth.getToken();
  //   return this.http.post<any>(this.apiUrl + '/excelPremiumPayableReport', data, {
  //     headers: {
  //       Authorization: 'Bearer ' + token
  //     }
  //   });
  // }

  filterReportExcelPost(payload){
    // this.spinnerService.show();
    const token = this.auth.getToken();
    return this.http.post<any>(this.apiUrl + '/filterReportExcelPost', payload, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
    );
  }
}
