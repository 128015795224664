import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { QueueRoutingModule } from './queue-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardManualComponent } from './dashboardmanual/dashboardmanual.component';
import { DashboardFaxComponent } from './dashboardfax/dashboardfax.component';
import { DashboardTextComponent } from './dashboardtext/dashboardtext.component';
import { DashboardPrintComponent } from './dashboardprint/dashboardprint.component';
import { DocumentService } from '../settings/document.service';
import { MenubarComponent } from './menubar/menubar.component';
import { PanelModule } from 'primeng/panel';
import { MenubarModule } from 'primeng/menubar';
import { FormsModule } from '@angular/forms';
import { ViewComponent } from './view/view.component';
import { ConfirmDialogModule, MessagesModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { DataTableModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    QueueRoutingModule,
    MenubarModule,
    FormsModule,
    PanelModule,
    MessagesModule,
    TableModule,
    DataTableModule,
    ButtonModule,
    ConfirmDialogModule,
    DialogModule,
    MultiSelectModule,
    DropdownModule
  ],
  declarations: [
    DashboardComponent, 
    DashboardManualComponent,
    DashboardPrintComponent,
    DashboardFaxComponent,
    DashboardTextComponent,
    MenubarComponent, 
    ViewComponent
  ],
  providers: [DocumentService, AuthService]
})
export class QueueModule { 
  constructor(auth: AuthService, router: Router) {
    // Check login only if we are trying to use this module
    router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/queues')) {
          // Check Login
          auth.checkLogin();
          // Check Permissions
          auth.checkPermissions('client');
        }
      });
    }
}
