import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../document.service';
import { Message, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentTemplate } from '../document-template';
import { MenuItem } from "primeng/api";
import { SpinnerService } from '../../core';

declare var $: any;

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.css']
})
export class TemplateEditComponent implements OnInit {
  id: number;
  template: DocumentTemplate;
  templateTypes: SelectItem[];
  templateNames = [];
  msgs: Message[] = [];
  public items: MenuItem[];
  currentTemplateName: any;

  invNotesBk:string='';
  
  constructor(aroute: ActivatedRoute,
    private router: Router,
    private docService: DocumentService,
    private spinnerService: SpinnerService) {
    aroute.params.subscribe(params => {
      this.id = params['id'];
    });
  }


  ngAfterViewInit()
  {
    setTimeout(() => {
      this.spinnerService.hide();
    }, 1650);

    $(document).ready(function()
    {
        setTimeout(() => {

          $('#templBodyEd').summernote({
            toolbar: [
                //[groupname, [button list]]
                ["style", ['style']],
                ["font", ["bold",'italic', "underline", "clear"]],
                ['color', ['forecolor','backcolor']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['fontname', ['fontname']],
                ['fullscreen', ['fullscreen']],
                ['link', ['link']],
            ],
            height:320,
            focus:true
           
        });
  
        // $('.note-toolbar span').removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-style").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-fontname").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-para").find("span.note-icon-caret").removeClass('note-icon-caret');
        $('div .note-toolbar').find("div.note-table").find("span.note-icon-caret").removeClass('note-icon-caret');
    
        $('.note-editable').css('font-family','Times New Roman');
        $('div .note-btn-group .note-color i').css( 'background-color','#ffffff' );
  
        $('#templBodyEd').summernote('fontName','Times New Roman');
        $('#templBodyEd').summernote('code', this.invNotesBk);
    
      }, 1500);
    
    }.bind(this));
  }
  
  ngOnInit() {
    // Load template (if editing)
    if (this.id) {
      this.loadTemplate();
    } else {
      this.template = new DocumentTemplate();
    }

    // Load queue types
    this.templateNames = [
      { label: 'Select Email Template', value: null },
      { label: 'User Creation Mail', value: 'User Creation Mail' },
      // { label: 'Premium Invoice Deliver Email', value: 'Premium Invoice Deliver Email' },
      { label: 'Payroll Service Fee Invoice Deliver Email', value: 'Policy Fee Invoice Deliver Email' },
      // { label: 'NPR Invoice Deliver Email', value: 'NPR Invoice Deliver Email' }
    ];

    this.templateTypes = [
      { label: 'Select Email Template Content Type', value: null },
      //{ label: 'Plain Text', value: 'plain' },
      { label: 'HTML', value: 'html' },
      //{ label: 'Google Document', value: 'google_doc' },
      //{ label: 'Google Sheet', value: 'google_sheet' }
    ];

    this.items = [
      {
        label: 'Settings',
        icon: 'fa-gear',
        routerLink: ['/settings'],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Create Template',
        icon: 'fa-file-text-o',
        routerLink: ['/settings/templates/create'],
        routerLinkActiveOptions: { exact: true }
      }
    ];
  }

  loadTemplate() {
    this.docService.getTemplate(this.id).subscribe((res) => {
      this.template = res;
      this.invNotesBk = this.template.body;
      this.currentTemplateName = this.template.name;
      // this.spinnerService.hide();
      // this.spinnerService.show();
    });
  }

  checkTemplateExists() {
    var checkLoad = 0;
    if (this.template.name != null) {
      if (this.id) {
        if (this.template.name == this.currentTemplateName) {
          checkLoad = 1;
        }
      }
    }
    if (checkLoad == 0) {
      this.docService.checkTemplate(this.template.name).subscribe((res) => {
        this.spinnerService.hide();
        console.log(res.output);
        if (res.output == 1) {
          this.template.name = null;
          this.msgs.push({ severity: 'error', summary: 'Message', detail: res.message });
          setTimeout(() => {
            this.msgs = [];
          },5000);
        }
      });
    }
  }

  saveTemplate() {
    if (!this.id) {
      this.template.body = $('#templBodyEd').summernote('code');
      this.docService.createTemplate(this.template).subscribe((res) => {
        // Display message for 2 seconds
        if (res.output == '1') {
          this.msgs.push({ severity: 'success', summary: 'Template Created', detail: res.message });
        } else {
          this.msgs.push({ severity: 'error', summary: 'Message', detail: res.message });
          this.spinnerService.hide();
        }
        setTimeout(() => {
          this.msgs = [];
          if (res.output == '1') {
            this.router.navigate(['settings/templates']);
          }
        }, 2000);
      });
    } else {
      this.template.body = $('#templBodyEd').summernote('code');
      this.docService.saveTemplate(this.id, this.template).subscribe(res => {
        // Display message for 2 seconds
        if (res.output == '1') {
          this.msgs.push({ severity: 'success', summary: 'Template Updated', detail: res.message });
        } else {
          this.msgs.push({ severity: 'error', summary: 'Message', detail: res.message });
          this.spinnerService.hide();
        }
        setTimeout(() => {
          this.msgs = [];
          if (res.output == '1') {
            this.router.navigate(['settings/templates']);
          }
        }, 2000);
      });
    }
  }
}
