import { Component, OnInit } from '@angular/core';
import { MenuItem } from "primeng/api";
import { DropdownModule } from 'primeng/dropdown';
import { ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { DataService } from '../../data.service';

@Component({
  selector: 'client-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {

  public items: MenuItem[];
  public q: string;
  d = '1';
  searchDropDown = [];

  searchRan:boolean = false;

  constructor(private aroute:ActivatedRoute, 
    private router:Router,
    private dataService: DataService  ) { }

  ngOnInit() {
    this.dataService.currentMenuClient.subscribe(d => setTimeout(() => this.d = d, 0));
    this.items = [
      {
        label: 'Client Home',
        icon: 'fa-home',
        routerLink: [ '/clients' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Onboard Client',
        icon: 'fa-plus', 
        routerLink: [ '/clients/creates/0' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'All Clients',
        icon: 'fa-address-book',
        routerLink: [ '/clients/list' ],
        routerLinkActiveOptions: { exact: true }
      },
      {
        label: '|'
      },
      {
        label: 'Send Email',
        icon: 'fa-envelope-o',
        routerLink: [ '/clients/send-email' ],
        routerLinkActiveOptions: { exact: true }
      }
    ];

    this.searchDropDown = [
        {label:'All', value:'0'},
        {label:'Client Name', value:'1'},
        {label:'Client Number', value:'17'},
        {label:'Contact', value:'4'},
        {label:'Address', value:'9'},
        {label:'City', value:'10'},
        {label:'State', value:'5'},
        {label:'Phone', value:'6'},
        {label:'Fax', value:'11'},
        {label:'Email', value:'7'},
        {label:'FEIN', value:'3'},
        {label:'SUI', value:'18'},
        // {label:'Other Names', value:'2'},
        {label:'Policy Number', value:'8'},
        {label:'Insurance Carrier', value:'12'},
        {label:'Payroll Company', value:'13'},
        {label:'Agency Name', value:'14'},
        {label:'Agent Name', value:'15'},
        {label:'Effective Date', value:'16'}
    ];

    // Initial Load
    this.aroute.queryParams.subscribe(params => {
      this.q = params['q'];
      if( typeof params['q'] != undefined && params['q'] != null )
      {
        this.q = this.q.replace(/!!!/g,'&');
      }
    });

    // Navigation Change
    this.router.events
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd && event.urlAfterRedirects.startsWith('/clients/list')) {
          this.aroute.queryParams.subscribe(params =>
        {
            this.q = params['q'];
            if( typeof params['q'] != undefined && params['q'] != null )
            {
              this.q = this.q.replace(/!!!/g,'&');
            }
         });
        }
      });
  }

  getSelectCategory(e)
  {
    // Drop Down Value Captured.
    this.d = e.value;
    this.dataService.changeMenuClient(this.d);

    // 12/05/2019:Bhargav.
    // On Change of Dropdown, Search Input clears.
    // Also, Search Would not run : Except when search had already happened.
    if( typeof this.q != 'undefined' && this.q != "" )
    {
        this.searchRan = true;
    }
    this.q = "";

    if( this.searchRan )
    {
      this.searchRan = false;

      var q = this.q;
      var newQ = q;
      if( typeof newQ != "undefined" && ( newQ.includes('&') ) )
      {
        newQ = newQ.replace(/&/g,'!!!');
      }

      this.router.navigateByUrl('/clients/list?q=' + newQ + '&d=' + this.d);
    }
  }

  search(q)
  {
    this.q = q;

    var newQ = q;

    if( typeof q != "undefined" && ( q.includes('&') ) )
    {
      newQ = newQ.replace(/&/g,'!!!');
    }
    // this.q = q;

    this.router.navigateByUrl('/clients/list?q=' + newQ + '&d=' + this.d);
  }

}
