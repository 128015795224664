import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AgencyService } from '../agency.service';
import { ClientService } from '../client.service';
import { SpinnerService } from '../../core';
import { emailValidator, phoneNumberValidator, zipCodeValidator } from '../../shared';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Location } from '@angular/common';
import { Message } from 'primeng/components/common/api';

@Component({
  selector: 'app-agencyview',
  templateUrl: './agencyview.component.html',
  styleUrls: ['./agencyview.component.css']
})
export class AgencyviewComponent implements OnInit {
  msgs: Message[] = [];
  agencyId: any;
  btnShow:string='none';
  getAgenciesObject: any;
  getAgenciesObject1: any;
  states: any;
  agencyViewForm: FormGroup;
  editAgency = false;
  deleteAgency = false;
  usDateValue: any;
  formattedNumber: any;
  area: any;
  front: any;
  end: any;
  c: any;
  redError = 'red';
  agency_nameError = 'none';
  agency_web_siteError = 'none';
  agency_main_phoneError = 'none';
  agency_main_faxError = 'none';
  physical_addressError = 'none';
  physical_cityError = 'none';
  physical_stateError = 'none';
  physical_zipError = 'none';
  mailing_addressError = 'none';
  mailing_cityError = 'none';
  mailing_stateError = 'none';
  mailing_zipError = 'none';
  contact_1_nameError = 'none';
  contact_1_roleError = 'none';
  contact_1_phoneError = 'none';
  contact_2_phoneError = 'none';
  contact_3_phoneError = 'none';
  contact_4_phoneError = 'none';
  contact_5_phoneError = 'none';
  contact_6_phoneError = 'none';
  contact_7_phoneError = 'none';
  contact_8_phoneError = 'none';
  contact_9_phoneError = 'none';
  contact_10_phoneError = 'none';
  contact_1_emailError = 'none';
  contact_2_emailError = 'none';
  contact_3_emailError = 'none';
  contact_4_emailError = 'none';
  contact_5_emailError = 'none';
  contact_6_emailError = 'none';
  contact_7_emailError = 'none';
  contact_8_emailError = 'none';
  contact_9_emailError = 'none';
  contact_10_emailError = 'none';
  agency_notesError = 'none';
  agency_main_phoneError_message = "";
  physical_zipError_message = "";
  mailing_zipError_message = "";
  contact_1_phoneError_message = "";
  contact_2_phoneError_message = "";
  contact_3_phoneError_message = "";
  contact_4_phoneError_message = "";
  contact_5_phoneError_message = "";
  contact_6_phoneError_message = "";
  contact_7_phoneError_message = "";
  contact_8_phoneError_message = "";
  contact_9_phoneError_message = "";
  contact_10_phoneError_message = "";
  contact_1_emailError_message = "";
  contact_2_emailError_message = "";
  contact_3_emailError_message = "";
  contact_4_emailError_message = "";
  contact_5_emailError_message = "";
  contact_6_emailError_message = "";
  contact_7_emailError_message = "";
  contact_8_emailError_message = "";
  contact_9_emailError_message = "";
  contact_10_emailError_message = "";
  filteredStates: any[];

  clientExists:boolean = false;

  constructor(
    private agencyService: AgencyService,
    private clientService: ClientService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinnerService: SpinnerService,
    public location: Location
  ) { }

  goToAgenciesList()
  {
    this.editAgency = false;
    this.deleteAgency = false;
    this.router.navigate(['/agency']);
  }

  filterStates(event) {
    this.filteredStates = [];
    for (let i = 0; i < this.states.length; i++) {
      let s = this.states[i].value;
      if (s.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredStates.push(s);
      }
    }
  }

  scrollTo(className: string): void {
    setTimeout(function () {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }
  ngOnInit() {
    this.agencyId = this.activatedRoute.snapshot.params['id'];
    this.agencyViewForm = this.formBuilder.group({
      agency_name: ['', Validators.required],
      agency_web_site: '',
      agency_main_phone: '',
      agency_main_fax: '',
      physical_address: '',
      physical_city: '',
      physical_state: '',
      physical_zip: '',
      mailing_address: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zip: '',
      contact_1_name: '',
      contact_1_role: '',
      contact_1_phone: '',
      contact_1_email: '',
      contact_2_name: '',
      contact_2_role: '',
      contact_2_phone: '',
      contact_2_email: '',
      contact_3_name: '',
      contact_3_role: '',
      contact_3_phone: '',
      contact_3_email: '',
      contact_4_name: '',
      contact_4_role: '',
      contact_4_phone: '',
      contact_4_email: '',
      contact_5_name: '',
      contact_5_role: '',
      contact_5_phone: '',
      contact_5_email: '',
      contact_6_name: '',
      contact_6_role: '',
      contact_6_phone: '',
      contact_6_email: '',
      contact_7_name: '',
      contact_7_role: '',
      contact_7_phone: '',
      contact_7_email: '',
      contact_8_name: '',
      contact_8_role: '',
      contact_8_phone: '',
      contact_8_email: '',
      contact_9_name: '',
      contact_9_role: '',
      contact_9_phone: '',
      contact_9_email: '',
      contact_10_name: '',
      contact_10_role: '',
      contact_10_phone: '',
      contact_10_email: '',
      agency_notes: '',
      id: ''
    });

    this.getAgencyInfo();

    this.clientService.getStates().subscribe(res => {
      this.states = res;
      this.states.unshift({ label: 'Select', value: '' });
    });
  }

  feleFocus()
  {
    this.btnShow = 'block';
  }
  
  // Hide All Errors in the popup 
  hideAllErrors() {

    this.btnShow='none';
    
    this.agency_nameError = 'none';
    this.agency_web_siteError = 'none';
    this.agency_main_phoneError = 'none';
    this.agency_main_faxError = 'none';
    this.physical_addressError = 'none';
    this.physical_cityError = 'none';
    this.physical_stateError = 'none';
    this.physical_zipError = 'none';
    this.mailing_addressError = 'none';
    this.mailing_cityError = 'none';
    this.mailing_stateError = 'none';
    this.mailing_zipError = 'none';
    this.contact_1_nameError = 'none';
    this.contact_1_roleError = 'none';
    this.contact_1_phoneError = 'none';
    this.contact_2_phoneError = 'none';
    this.contact_3_phoneError = 'none';
    this.contact_4_phoneError = 'none';
    this.contact_5_phoneError = 'none';
    this.contact_6_phoneError = 'none';
    this.contact_7_phoneError = 'none';
    this.contact_8_phoneError = 'none';
    this.contact_9_phoneError = 'none';
    this.contact_10_phoneError = 'none';
    this.contact_1_emailError = 'none';
    this.contact_2_emailError = 'none';
    this.contact_3_emailError = 'none';
    this.contact_4_emailError = 'none';
    this.contact_5_emailError = 'none';
    this.contact_6_emailError = 'none';
    this.contact_7_emailError = 'none';
    this.contact_8_emailError = 'none';
    this.contact_9_emailError = 'none';
    this.contact_10_emailError = 'none';
    this.contact_1_phoneError_message = "";
    this.contact_2_phoneError_message = "";
    this.contact_3_phoneError_message = "";
    this.contact_4_phoneError_message = "";
    this.contact_5_phoneError_message = "";
    this.contact_6_phoneError_message = "";
    this.contact_7_phoneError_message = "";
    this.contact_8_phoneError_message = "";
    this.contact_9_phoneError_message = "";
    this.contact_10_phoneError_message = "";
    this.contact_1_emailError_message = "";
    this.contact_2_emailError_message = "";
    this.contact_3_emailError_message = "";
    this.contact_4_emailError_message = "";
    this.contact_5_emailError_message = "";
    this.contact_6_emailError_message = "";
    this.contact_7_emailError_message = "";
    this.contact_8_emailError_message = "";
    this.contact_9_emailError_message = "";
    this.contact_10_emailError_message = "";
    this.agency_notesError = 'none';
    this.agency_main_phoneError_message = "";
    this.physical_zipError_message = "";
    this.mailing_zipError_message = "";
  }

  usFormattedFax() {
    this.usDateValue = this.agencyViewForm.value.agency_main_fax;
    this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
    // # (###) ###-#### as c (area) front-end
    this.area = this.formattedNumber.substring(0, 3);
    this.front = this.formattedNumber.substring(3, 6);
    this.end = this.formattedNumber.substring(6, this.formattedNumber.length);
    if (this.front) {
      this.formattedNumber = ("(" + this.area + ") " + this.front);
    }
    if (this.end) {
      this.formattedNumber += ("-" + this.end);
    }
    this.agencyViewForm.controls['agency_main_fax'].setValue(this.formattedNumber);
  }

  // Update Agency Form
  updateAgencyForm() {
    if (this.agencyViewForm.valid) {
      this.agency_nameError = 'none';
      var validateFlag = 0;
      if (this.agencyViewForm.value.agency_main_phone == '' || this.agencyViewForm.value.agency_main_phone == null) {
        // this.agency_main_phoneError = 'block';
        // this.agency_main_phoneError_message = 'Required';
      } else {
      }

      if (this.agencyViewForm.value.physical_zip == '' || this.agencyViewForm.value.physical_zip == null) {
        // this.physical_zipError = 'block';
        // this.physical_zipError_message = 'Required.';
      } else {
        const pattern = /^[0-9=]*$/;
        if (!this.agencyViewForm.value.physical_zip.match(pattern)) {
          this.physical_zipError = 'block';
          this.physical_zipError_message = 'Zip Code is not valid.';
          validateFlag = 1;
        } else {
          this.physical_zipError = 'none';
        }
      }

      if (this.agencyViewForm.value.mailing_zip == '' || this.agencyViewForm.value.mailing_zip == null) {
        // this.mailing_zipError = 'block';
        // this.mailing_zipError_message = 'Required.';
      } else {
        const pattern = /^[0-9=]*$/;
        if (!this.agencyViewForm.value.mailing_zip.match(pattern)) {
          this.mailing_zipError = 'block';
          this.mailing_zipError_message = 'Zip Code is not valid.';
          validateFlag = 1;
        } else {
          this.mailing_zipError = 'none';
        }
      }

      if (this.agencyViewForm.value.contact_1_phone == '' || this.agencyViewForm.value.contact_1_phone == null) {
        // this.contact_1_phoneError = 'block';
        // this.contact_1_phoneError_message = 'Required';
      } else {
      }

      if (this.agencyViewForm.value.contact_1_email == '' || this.agencyViewForm.value.contact_1_email == null) {
        // this.contact_1_emailError = 'block';
        // this.contact_1_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_1_email)) {
          this.contact_1_emailError = 'block';
          this.contact_1_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_1_emailError = 'none';
        }
      }

      if (this.agencyViewForm.value.contact_2_phone == '' || this.agencyViewForm.value.contact_2_phone == null) {
        // this.contact_2_phoneError = 'block';
        // this.contact_2_phoneError_message = 'Required';
      } else {
      }

      if (this.agencyViewForm.value.contact_2_email == '' || this.agencyViewForm.value.contact_2_email == null) {
        // this.contact_2_emailError = 'block';
        // this.contact_2_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_2_email)) {
          this.contact_2_emailError = 'block';
          this.contact_2_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_2_emailError = 'none';
        }
      }

      if (this.agencyViewForm.value.contact_3_phone == '' || this.agencyViewForm.value.contact_3_phone == null) {
        // this.contact_3_phoneError = 'block';
        // this.contact_3_phoneError_message = 'Required';
      } else {
      }

      if (this.agencyViewForm.value.contact_3_email == '' || this.agencyViewForm.value.contact_3_email == null) {
        // this.contact_3_emailError = 'block';
        // this.contact_3_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_3_email)) {
          this.contact_3_emailError = 'block';
          this.contact_3_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_3_emailError = 'none';
        }
      }

      if (this.agencyViewForm.value.contact_4_phone == '' || this.agencyViewForm.value.contact_4_phone == null) {
        // this.contact_4_phoneError = 'block';
        // this.contact_4_phoneError_message = 'Required';
      } else {
      }

      if (this.agencyViewForm.value.contact_4_email == '' || this.agencyViewForm.value.contact_4_email == null) {
        // this.contact_4_emailError = 'block';
        // this.contact_4_emailError_message = 'Required';
      } else {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_4_email)) {
          this.contact_4_emailError = 'block';
          this.contact_4_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_4_emailError = 'none';
        }
      }
      
      if (this.agencyViewForm.value.contact_5_phone == '' || this.agencyViewForm.value.contact_5_phone == null)
	  {
        // this.contact_5_phoneError = 'block';
        // this.contact_5_phoneError_message = 'Required';
      }
	  else
	  {
    }

      if (this.agencyViewForm.value.contact_5_email == '' || this.agencyViewForm.value.contact_5_email == null) {
        // this.contact_5_emailError = 'block';
        // this.contact_5_emailError_message = 'Required';
      }
	  else
	  {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_5_email)) {
          this.contact_5_emailError = 'block';
          this.contact_5_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_5_emailError = 'none';
        }
    }

    if (this.agencyViewForm.value.contact_6_phone == '' || this.agencyViewForm.value.contact_6_phone == null)
	  {
        // this.contact_6_phoneError = 'block';
        // this.contact_6_phoneError_message = 'Required';
      }
	  else
	  {
    }

      if (this.agencyViewForm.value.contact_6_email == '' || this.agencyViewForm.value.contact_6_email == null) {
        // this.contact_6_emailError = 'block';
        // this.contact_6_emailError_message = 'Required';
      }
	  else
	  {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_6_email)) {
          this.contact_6_emailError = 'block';
          this.contact_6_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_6_emailError = 'none';
        }
      }
    

      if (this.agencyViewForm.value.contact_7_phone == '' || this.agencyViewForm.value.contact_7_phone == null)
	  {
        // this.contact_7_phoneError = 'block';
        // this.contact_7_phoneError_message = 'Required';
      }
	  else
	  {
    }

      if (this.agencyViewForm.value.contact_7_email == '' || this.agencyViewForm.value.contact_7_email == null) {
        // this.contact_7_emailError = 'block';
        // this.contact_7_emailError_message = 'Required';
      }
	  else
	  {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_7_email)) {
          this.contact_7_emailError = 'block';
          this.contact_7_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_7_emailError = 'none';
        }
      }


      if (this.agencyViewForm.value.contact_8_phone == '' || this.agencyViewForm.value.contact_8_phone == null)
	  {
        // this.contact_8_phoneError = 'block';
        // this.contact_8_phoneError_message = 'Required';
      }
	  else
	  {
    }

      if (this.agencyViewForm.value.contact_8_email == '' || this.agencyViewForm.value.contact_8_email == null) {
        // this.contact_8_emailError = 'block';
        // this.contact_8_emailError_message = 'Required';
      }
	  else
	  {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_8_email)) {
          this.contact_8_emailError = 'block';
          this.contact_8_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_8_emailError = 'none';
        }
      }


      if (this.agencyViewForm.value.contact_9_phone == '' || this.agencyViewForm.value.contact_9_phone == null)
	  {
        // this.contact_9_phoneError = 'block';
        // this.contact_9_phoneError_message = 'Required';
      }
	  else
	  {
    }

      if (this.agencyViewForm.value.contact_9_email == '' || this.agencyViewForm.value.contact_9_email == null) {
        // this.contact_9_emailError = 'block';
        // this.contact_9_emailError_message = 'Required';
      }
	  else
	  {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_9_email)) {
          this.contact_9_emailError = 'block';
          this.contact_9_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_9_emailError = 'none';
        }
      }
      

      if (this.agencyViewForm.value.contact_10_phone == '' || this.agencyViewForm.value.contact_10_phone == null)
	  {
        // this.contact_10_phoneError = 'block';
        // this.contact_10_phoneError_message = 'Required';
      }
	  else
	  {
    }

      if (this.agencyViewForm.value.contact_10_email == '' || this.agencyViewForm.value.contact_10_email == null) {
        // this.contact_10_emailError = 'block';
        // this.contact_10_emailError_message = 'Required';
      }
	  else
	  {
        const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_10_email)) {
          this.contact_10_emailError = 'block';
          this.contact_10_emailError_message = 'Email Format is not valid.';
          validateFlag = 1;
        } else {
          this.contact_10_emailError = 'none';
        }
      }

      
      if (validateFlag == 0) {
        var agencyViewFormObject = this.agencyViewForm.value;
        this.agencyService.updateAgency(this.agencyId, agencyViewFormObject).subscribe(response => {
          this.getAgencyInfo();
          this.editAgency = false;
        });
      }

    } else {
      if (this.agencyViewForm.value.agency_name == '' || this.agencyViewForm.value.agency_name == null) {
        this.agency_nameError = 'block';
      } else {
        this.agency_nameError = 'none';
      }
      // if (this.agencyViewForm.value.agency_web_site == '' || this.agencyViewForm.value.agency_web_site == null) {
      //   this.agency_web_siteError = 'block';
      // } else {
      //   this.agency_web_siteError = 'none';
      // }
      // if (this.agencyViewForm.value.agency_main_phone == '' || this.agencyViewForm.value.agency_main_phone == null) {
      //   this.agency_main_phoneError = 'block';
      //   this.agency_main_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.agencyViewForm.value.agency_main_phone.match(pattern1) && !this.agencyViewForm.value.agency_main_phone.match(pattern2)) {
      //     this.agency_main_phoneError = 'block';
      //     this.agency_main_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.agency_main_phoneError = 'none';
      //   }
      // }
      // if (this.agencyViewForm.value.agency_main_fax == '' || this.agencyViewForm.value.agency_main_fax == null) {
      //   this.agency_main_faxError = 'block';
      // } else {
      //   this.agency_main_faxError = 'none';
      // }
      // if (this.agencyViewForm.value.physical_address == '' || this.agencyViewForm.value.physical_address == null) {
      //   this.physical_addressError = 'block';
      // } else {
      //   this.physical_addressError = 'none';
      // }

      // if (this.agencyViewForm.value.physical_city == '' || this.agencyViewForm.value.physical_city == null) {
      //   this.physical_cityError = 'block';
      // } else {
      //   this.physical_cityError = 'none';
      // }
      // if (this.agencyViewForm.value.physical_state == '' || this.agencyViewForm.value.physical_state == null) {
      //   this.physical_stateError = 'block';
      // } else {
      //   this.physical_stateError = 'none';
      // }
      // if (this.agencyViewForm.value.physical_zip == '' || this.agencyViewForm.value.physical_zip == null) {
      //   this.physical_zipError = 'block';
      //   this.physical_zipError_message = 'Required.';
      // } else {
      //   const pattern = /^[0-9=]*$/;
      //   if (!this.agencyViewForm.value.physical_zip.match(pattern)) {
      //     this.physical_zipError = 'block';
      //     this.physical_zipError_message = 'Zip Code is not valid.';
      //   } else {
      //     this.physical_zipError = 'none';
      //   }
      // }

      // if (this.agencyViewForm.value.mailing_address == '' || this.agencyViewForm.value.mailing_address == null) {
      //   this.mailing_addressError = 'block';
      // } else {
      //   this.mailing_addressError = 'none';
      // }

      // if (this.agencyViewForm.value.mailing_city == '' || this.agencyViewForm.value.mailing_city == null) {
      //   this.mailing_cityError = 'block';
      // } else {
      //   this.mailing_cityError = 'none';
      // }
      // if (this.agencyViewForm.value.mailing_state == '' || this.agencyViewForm.value.mailing_state == null) {
      //   this.mailing_stateError = 'block';
      // } else {
      //   this.mailing_stateError = 'none';
      // }
      // if (this.agencyViewForm.value.mailing_zip == '' || this.agencyViewForm.value.mailing_zip == null) {
      //   this.mailing_zipError = 'block';
      //   this.mailing_zipError_message = 'Required.';
      // } else {
      //   const pattern = /^[0-9=]*$/;
      //   if (!this.agencyViewForm.value.mailing_zip.match(pattern)) {
      //     this.mailing_zipError = 'block';
      //     this.mailing_zipError_message = 'Zip Code is not valid.';
      //   } else {
      //     this.mailing_zipError = 'none';
      //   }
      // }

      // if (this.agencyViewForm.value.contact_1_name == '' || this.agencyViewForm.value.contact_1_name == null) {
      //   this.contact_1_nameError = 'block';
      // } else {
      //   this.contact_1_nameError = 'none';
      // }

      // if (this.agencyViewForm.value.contact_1_role == '' || this.agencyViewForm.value.contact_1_role == null) {
      //   this.contact_1_roleError = 'block';
      // } else {
      //   this.contact_1_roleError = 'none';
      // }

      // if (this.agencyViewForm.value.contact_1_phone == '' || this.agencyViewForm.value.contact_1_phone == null) {
      //   this.contact_1_phoneError = 'block';
      //   this.contact_1_phoneError_message = 'Required';
      // } else {
      //   const pattern1 = /^\d{10}$/;
      //   const pattern2 = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      //   if (!this.agencyViewForm.value.contact_1_phone.match(pattern1) && !this.agencyViewForm.value.contact_1_phone.match(pattern2)) {
      //     this.contact_1_phoneError = 'block';
      //     this.contact_1_phoneError_message = 'Format allow (xxx) xxx-xxxx';
      //   } else {
      //     this.contact_1_phoneError = 'none';
      //   }
      // }

      // if (this.agencyViewForm.value.contact_1_email == '' || this.agencyViewForm.value.contact_1_email == null) {
      //   this.contact_1_emailError = 'block';
      //   this.contact_1_emailError_message = 'Required';
      // } else {
      //   const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //   if (!EMAIL_REGEXP.test(this.agencyViewForm.value.contact_1_email)) {
      //     this.contact_1_emailError = 'block';
      //     this.contact_1_emailError_message = 'Email Format is not valid.';
      //   } else {
      //     this.contact_1_emailError = 'none';
      //   }
      // }
      // if (this.agencyViewForm.value.agency_notes == '' || this.agencyViewForm.value.agency_notes == null) {
      //   this.agency_notesError = 'block';
      // } else {
      //   this.agency_notesError = 'none';
      // }
    }
  }
  deleteAgent() {
    this.agencyService.deleteAgency(this.agencyId).subscribe(response => {

      if( response != undefined && response != null && response.clientExists != undefined && response.clientExists != null )
      {
          this.editAgency = false;
          this.clientExists = true;
      }
      else
      {
        this.editAgency = false;
        this.deleteAgency = false;
        this.router.navigate(['/agency']);
      }
    });
  }
  // get Agency Information
  getAgencyInfo() {
    this.agencyService.getAgency(this.agencyId).subscribe(response => {
      this.updateAgency(response);
    });
  }
  usFormatteddate(phonenumbrerlabel)
  {
    if (phonenumbrerlabel == 1) {
      this.usDateValue = this.agencyViewForm.value.agency_main_phone;
    } else if (phonenumbrerlabel == 2) {
      this.usDateValue = this.agencyViewForm.value.contact_1_phone;
    } else if (phonenumbrerlabel == 3) {
      this.usDateValue = this.agencyViewForm.value.contact_2_phone;
    } else if (phonenumbrerlabel == 4) {
      this.usDateValue = this.agencyViewForm.value.contact_3_phone;
    } else if (phonenumbrerlabel == 5) {
      this.usDateValue = this.agencyViewForm.value.contact_4_phone;
    }
    else if (phonenumbrerlabel == 6)
    {
      this.usDateValue = this.agencyViewForm.value.contact_5_phone;
    }
    else if (phonenumbrerlabel == 7)
    {
      this.usDateValue = this.agencyViewForm.value.contact_6_phone;
    }else if (phonenumbrerlabel == 8)
    {
      this.usDateValue = this.agencyViewForm.value.contact_7_phone;
    }else if (phonenumbrerlabel == 9)
    {
      this.usDateValue = this.agencyViewForm.value.contact_8_phone;
    }else if (phonenumbrerlabel == 10)
    {
      this.usDateValue = this.agencyViewForm.value.contact_9_phone;
    }else if (phonenumbrerlabel == 11)
    {
      this.usDateValue = this.agencyViewForm.value.contact_10_phone;
    }
    this.formattedNumber = String(this.usDateValue.replace(/[- )(]/g, ''));
    //  this.c = (this.formattedNumber[0] == '1') ? '1 ' : '';
    //   this.formattedNumber = this.formattedNumber[0] == '1' ? this.formattedNumber.slice(1) : this.formattedNumber;

    // # (###) ###-#### as c (area) front-end
    this.area = this.formattedNumber.substring(0, 3);
    this.front = this.formattedNumber.substring(3, 6);
    this.end = this.formattedNumber.substring(6);

    if (this.front) {
      this.formattedNumber = ("(" + this.area + ") " + this.front);
    }
    if (this.end) {
      this.formattedNumber += ("-" + this.end);
    }

    // New Code for Ext Cleaned
    if( this.formattedNumber != undefined && this.formattedNumber != null )
    {
      var pContent = this.formattedNumber.toLowerCase().split( "ext" );
      var finalNum = "";
      if( typeof pContent !== 'undefined' && pContent.length )
      {
        if( typeof pContent[0] !== 'undefined' )
        {
          finalNum = pContent[0];
        }
        if( typeof pContent[1] !== 'undefined' )
        {
          finalNum += " Ext " + pContent[1];
        }
        this.formattedNumber = finalNum;
      }
    }


    if (phonenumbrerlabel == 1) {
      this.agencyViewForm.controls['agency_main_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 2) {
      this.agencyViewForm.controls['contact_1_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 3) {
      this.agencyViewForm.controls['contact_2_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 4) {
      this.agencyViewForm.controls['contact_3_phone'].setValue(this.formattedNumber);
    } else if (phonenumbrerlabel == 5) {
      this.agencyViewForm.controls['contact_4_phone'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 6)
    {
      this.agencyViewForm.controls['contact_5_phone'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 7)
    {
      this.agencyViewForm.controls['contact_6_phone'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 8)
    {
      this.agencyViewForm.controls['contact_7_phone'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 9)
    {
      this.agencyViewForm.controls['contact_8_phone'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 10)
    {
      this.agencyViewForm.controls['contact_9_phone'].setValue(this.formattedNumber);
    }
    else if (phonenumbrerlabel == 11)
    {
      this.agencyViewForm.controls['contact_10_phone'].setValue(this.formattedNumber);
    }
  }
  updateAgency(response) {
    this.getAgenciesObject = response[0];
    this.agencyViewForm.patchValue({
      agency_name: this.getAgenciesObject.agency_name,
      agency_web_site: this.getAgenciesObject.agency_web_site,
      agency_main_phone: this.getAgenciesObject.agency_main_phone,
      agency_main_fax: this.getAgenciesObject.agency_main_fax,
      physical_address: this.getAgenciesObject.physical_address,
      physical_city: this.getAgenciesObject.physical_city,
      physical_state: this.getAgenciesObject.physical_state,
      physical_zip: this.getAgenciesObject.physical_zip,
      mailing_address: this.getAgenciesObject.mailing_address,
      mailing_city: this.getAgenciesObject.mailing_city,
      mailing_state: this.getAgenciesObject.mailing_state,
      mailing_zip: this.getAgenciesObject.mailing_zip,
      contact_1_name: this.getAgenciesObject.contact_1_name,
      contact_1_role: this.getAgenciesObject.contact_1_role,
      contact_1_phone: this.getAgenciesObject.contact_1_phone,
      contact_1_email: this.getAgenciesObject.contact_1_email,
      contact_2_name: this.getAgenciesObject.contact_2_name,
      contact_2_role: this.getAgenciesObject.contact_2_role,
      contact_2_phone: this.getAgenciesObject.contact_2_phone,
      contact_2_email: this.getAgenciesObject.contact_2_email,
      contact_3_name: this.getAgenciesObject.contact_3_name,
      contact_3_role: this.getAgenciesObject.contact_3_role,
      contact_3_phone: this.getAgenciesObject.contact_3_phone,
      contact_3_email: this.getAgenciesObject.contact_3_email,
      contact_4_name: this.getAgenciesObject.contact_4_name,
      contact_4_role: this.getAgenciesObject.contact_4_role,
      contact_4_phone: this.getAgenciesObject.contact_4_phone,
      contact_4_email: this.getAgenciesObject.contact_4_email,
      contact_5_name: this.getAgenciesObject.contact_5_name,
      contact_5_role: this.getAgenciesObject.contact_5_role,
      contact_5_phone: this.getAgenciesObject.contact_5_phone,
      contact_5_email: this.getAgenciesObject.contact_5_email,
      contact_6_name: this.getAgenciesObject.contact_6_name,
      contact_6_role: this.getAgenciesObject.contact_6_role,
      contact_6_phone: this.getAgenciesObject.contact_6_phone,
      contact_6_email: this.getAgenciesObject.contact_6_email,
      contact_7_name: this.getAgenciesObject.contact_7_name,
      contact_7_role: this.getAgenciesObject.contact_7_role,
      contact_7_phone: this.getAgenciesObject.contact_7_phone,
      contact_7_email: this.getAgenciesObject.contact_7_email,
      contact_8_name: this.getAgenciesObject.contact_8_name,
      contact_8_role: this.getAgenciesObject.contact_8_role,
      contact_8_phone: this.getAgenciesObject.contact_8_phone,
      contact_8_email: this.getAgenciesObject.contact_8_email,
      contact_9_name: this.getAgenciesObject.contact_9_name,
      contact_9_role: this.getAgenciesObject.contact_9_role,
      contact_9_phone: this.getAgenciesObject.contact_9_phone,
      contact_9_email: this.getAgenciesObject.contact_9_email,
      contact_10_name: this.getAgenciesObject.contact_10_name,
      contact_10_role: this.getAgenciesObject.contact_10_role,
      contact_10_phone: this.getAgenciesObject.contact_10_phone,
      contact_10_email: this.getAgenciesObject.contact_10_email,
      agency_notes: this.getAgenciesObject.agency_notes,
      id: this.getAgenciesObject.id
    });
    this.spinnerService.hide();
  }
}
